import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiBaseResponse } from 'src/app/model.backend/_base.model';
import { IApiGeozone, IApiGeozoneResponse } from 'src/app/model.backend/geozone.model';
import { GeozoneService } from 'src/app/service.backend/geozone.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-geozone-name-edit',
  templateUrl: './geozone-name-edit.component.html',
  styleUrls: ['./geozone-name-edit.component.css']
})
export class GeozoneNameEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() isNew=true;
  @Input() isDel=false;
  @Input() geozone!: IApiGeozone;
  @Output() onHide = new EventEmitter();
  @Output() onSave = new EventEmitter<IApiGeozone|null>();
  @Output() onDel = new EventEmitter<string|null>();
  saving = false;
  destroy$ = new Subject<boolean>();
  
  getHeader = () => this.isDel ? 'Удалить геозону' : (this.isNew ? 'Добавить геозону': 'Редактировать геозону'); // this.translate.instant(this.isNew() ? 'cargroup.add': 'cargroup.edit');
  getButtonOkLabel = () => this.isDel ? 'Удалить' : (this.translate.instant(this.isNew ? 'form.add':'form.save'));
  getButtonOkIcon = () => this.isDel ? 'pi-trash' : (this.isNew ? 'pi-plus':'pi-save');
  

  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
  });

  
  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private geozoneService: GeozoneService,    
  ) {
  }

  ngOnInit(): void {
    this.isNew ? this.newRecord() : this.editRecord();
    if (this.isDel) {
      this.form.get('name')?.disable();
    }
    console.log('onInit', this.geozone);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this._loadFormFromObject(this.geozone.name ?? null);    
  }

  newRecord() {    
    this._loadFormFromObject(this.geozone.name ?? null);    
  }

  _loadFormFromObject(name: string|null) {
    this.form.get('name')?.patchValue(this.geozone.name ?? null);
  }

  _saveFormToObject() {
    this.geozone.name = this.form.get('name')?.value ?? null;
  }

  saveRecord() {
    if(this.form.invalid || !this.geozone) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.isDel) {
      this.geozoneService.delete(this.geozone.id)
      .subscribe({
        next: (res: IApiBaseResponse) => {
          if(res.ok) {
            this.onDel.emit(this.geozone.id);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
          } else {
            console.error('delRecord', res.errorMessage, res.errorMessageForUser);
            let errorSave = res.errorMessageForUser??this.translate.instant('form.deleteError');            
            this.messageService.add({severity: 'error', detail: errorSave??'' });
            this.onDel.emit('');
          }
          this.saving = false;
        },
        error: (err) => {
          console.error('delRecord', err);
          let errorSave = this.translate.instant('form.deleteError');
          this.messageService.add({severity: 'error', detail: errorSave??'' });
          this.saving=false;
          this.onDel.emit('');
        }
      });
    } else {        
      this._saveFormToObject();
      this.geozone.name = this.geozone.name ?? null;
      console.log('saving', this.geozone);
      this.saving = true;
      (
        this.isNew ? this.geozoneService.insert(this.geozone)
        : this.geozoneService.update(this.geozone.id??'xxx', this.geozone)
      ).subscribe({
        next: (res: IApiGeozoneResponse) => {
          if(res.ok) {
            this.onSave.emit(res.geozone);
            if(this.isNew){            
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
            } else {            
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
            }
          } else {
            console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
            let errorSave = '';
            if(this.isNew) errorSave = res.errorMessageForUser??this.translate.instant('form.addError');
            else errorSave = res.errorMessageForUser??this.translate.instant('form.updateError');
            this.messageService.add({severity: 'error', detail: errorSave??'' });
            this.onHide.emit(true);
          }
          this.saving = false;
        },
        error: (err) => {
          console.error('saveRecord', err);
          let errorSave = '';
          if(this.isNew) errorSave = this.translate.instant('form.addError')
          else errorSave = this.translate.instant('form.updateError')
          this.messageService.add({severity: 'error', detail: errorSave??'' });
          this.saving=false;
          this.onHide.emit(true);
        }
      });
    }
    


  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

}
