import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCarStatus } from 'src/app/model.backend/carstatus.model';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-driver-card',
  templateUrl: './driver-card.component.html',
  styleUrls: ['./driver-card.component.css']
})
export class DriverCardComponent implements OnInit, OnChanges {
  @Input() driver!: IApiDriver;
  @Input() carStatus! : IApiCarStatus|null; // статус текущей машины водителя
  @Input() viewMode = true;
  @Input() selected = false;

  @Output() onSelectChange = new EventEmitter<boolean>();
  @Output() onClickEdit = new EventEmitter<IApiDriver>();
  @Output() onClickDelete = new EventEmitter<{driver: IApiDriver, event: Event}>();
  
  ui = {
    select: false,
  };

  dialog = {
    driverEdit: {
      show: false,
      viewMode: this.viewMode,
      driverId: null as string|null
    }
  }

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private utils: UtilsService,
    private preloaderService: PreloaderService
  ) {
  }

  ngOnInit(): void {
    this.dialog.driverEdit.viewMode=this.viewMode;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selected']){
      this.ui.select=changes['selected'].currentValue;
    }
  }

  getCurrentCar$() {
    let t = this.getDriverInfo();
    return t?this.preloaderService.getCar$(t?.car_id??'xxx'):of(null);
  }

  getDriverAvatarName() {
    return (this.driver.family+' ').substring(0,1)+(this.driver.name+' ').substring(0,1);
  }

  getDriverPhone() {
    return this.utils.stdPhone(this.driver.phone);
  }

  getDriverPhoneWA() {
    return this.utils.trimPhone(this.driver.phone_wa)
  }

  getDriverInfo() {
    if(this.carStatus?.driver_info?.to==null) return this.carStatus;
    let toUTC= new Date(this.carStatus.driver_info.to);
    if(toUTC.getTime() > (new Date(Date.now()).getTime() )) return this.carStatus;
    return null;
  }

}
