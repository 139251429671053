import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiNotice, IApiNotice_ScheduleItem, IApiNotice_Type } from 'src/app/model.backend/notice.model';
import { NoticeService } from 'src/app/service.backend/notice.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-notice-edit',
  templateUrl: './notice-edit.component.html',
  styleUrls: ['./notice-edit.component.css']
})
export class NoticeEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() viewMode = false;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiNotice>();
  @Output() onInsert = new EventEmitter<IApiNotice>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiNotice|null;
  destroy$ = new Subject<boolean>();

  IApiNoticeType = IApiNotice_Type;

  ui = {
    options: {
      _notice_type: [
        {name: '-', code: null},
        {name: this.translate.instant('notice.type_offline'), code: IApiNotice_Type.offline},
        {name: this.translate.instant('notice.type_online'), code: IApiNotice_Type.online},
        {name: this.translate.instant('notice.type_sensor'), code: IApiNotice_Type.sensor},
        {name: this.translate.instant('notice.type_geozone'), code: IApiNotice_Type.geozone},
      ],
      car_groups: [] as INameCode[],
      cars: [] as INameCode[],
      geozones: //[] as INameCode[]
      [
        { name: '-', code: null}, { name: 'geozone1', code: 'g1'}, { name: 'geozone2', code: 'g2'}
      ],
      sensorNames: [] as INameCode[]
    }
  }

  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'notice.add':'notice.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(255)]),
    is_active: new FormControl({value: true as boolean|null, disabled: this.viewMode}),
    comment: new FormControl({ value: null as string|null, disabled: this.viewMode}, [Validators.maxLength(1000)]),
    hit_counter: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    hit_interval: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    min_rise_interval: new FormControl({ value: null as number|null, disabled: this.viewMode}),

    _notice_type: new FormControl({value: null as string|null, disabled: this.viewMode}, [Validators.required]),
    _car_offline_interval: new FormControl({value: null as number|null, disabled: this.viewMode}),
    _geozoneid: new FormControl({value: null as string|null, disabled: this.viewMode}, [Validators.required]),
    _in_check: new FormControl({value: null as boolean|null, disabled: this.viewMode}),
    _out_check: new FormControl({value: null as boolean|null, disabled: this.viewMode}),
    _in_geozone_time: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    _sensor_key: new FormControl({value: null as string|null, disabled: this.viewMode}, [Validators.required]),
    _from_value: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    _to_value: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    _active_time: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    _in_range: new FormControl({ value: null as boolean|null, disabled: this.viewMode}),
    _value_change_initial_value: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    _value_change_threshold: new FormControl({ value: null as number|null, disabled: this.viewMode}),
    _use_schedule: new FormControl({value:null as boolean|null, disabled: this.viewMode}),

    cars: new FormControl(null as string[]|null),
    car_groups: new FormControl(null as string[]|null),

    emails: new FormControl({value: null as string[]|null, disabled: this.viewMode}),
    phones: new FormControl({value: null as string[]|null, disabled: this.viewMode}),
    send_to_messages: new FormControl({value: false as boolean|null, disabled: this.viewMode}),
    message: new FormControl(null as string|null, [Validators.maxLength(4000)]),

    _group: new FormControl("y"),
    _groupInterval: new FormControl('y')
  });

  _week_days = {
    d0: {} as IApiNotice_ScheduleItem,
    d1: {} as IApiNotice_ScheduleItem,
    d2: {} as IApiNotice_ScheduleItem,
    d3: {} as IApiNotice_ScheduleItem,
    d4: {} as IApiNotice_ScheduleItem,
    d5: {} as IApiNotice_ScheduleItem,
    d6: {} as IApiNotice_ScheduleItem,
  }

  constructor(
    private authService: AuthService,
    private noticeService: NoticeService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.form.get('_notice_type')?.valueChanges.subscribe(value=>{
      console.log('notice_type changed to ', value);
      this.form.get('_sensor_key')?.removeValidators(Validators.required);
      this.form.get('_geozoneid')?.removeValidators(Validators.required);
      switch(value){
        case IApiNotice_Type.online:
        case IApiNotice_Type.offline: 
          break;
        case IApiNotice_Type.sensor:
          this.form.get('_sensor_key')?.addValidators(Validators.required);
          break;
        case IApiNotice_Type.geozone:
          this.form.get('_geozoneid')?.addValidators(Validators.required);
          break;
      }
      this.form.updateValueAndValidity();
    })

    this.isNew() ? this.newRecord(): this.editRecord(); 

    this.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.loading = false;
        this.ui.options.car_groups = this.preloaderService.availablecargroups$.value.map(x=>{ return { name: x.name, code: x.id} });
        this.ui.options.car_groups.unshift({name:'Все Группы', code: '*'});
        this.ui.options.cars = this.preloaderService.availablecars$.value.map(x=>{ 
          return { 
            name: x.display_name??'' + this.utils.prefixString(', №', x.number) + this.utils.prefixString(', ', x.brand) + this.utils.prefixString(', pin:', x.pin) , 
            code: x.id} 
        });
        this.ui.options.cars.unshift({name:'Все ТС', code: '*'});
        this.ui.options.sensorNames=this.preloaderService.sensorNames$.value.map(x=>{ return { name: x.display_name, code: x.key } })
        this.ui.options.sensorNames.sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
        this.ui.options.sensorNames.unshift({name:'-', code:null});
      },
      error: err => {
        this.loading = false;
        this.error = err; 
        this.messageService.add({severity: 'error', detail: this.error??'' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.noticeService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.notice);
          this.item = res.notice;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    let time00 = new Date(Date.now());
    time00.setHours(0); time00.setMinutes(0); time00.setSeconds(0); time00.setMilliseconds(999);
    let time24 = new Date(Date.now());
    time24.setHours(23); time24.setMinutes(59); time24.setSeconds(59); time24.setMilliseconds(999);
    this.item = {
      name: 'Новое уведомление',
      is_active: true,
      car_groups: [],
      cars: ['*'],
      notice_schedule: {
        use_schedule: false,
        week_days: [
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
          {is_active_time:false, is_active_weekday: false, from: time00.toISOString(), to: time24.toISOString()},
      ]
        
      }
    }
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(notice: IApiNotice) {
    notice.utc_offset = this.authService.getUser()?.settings?.utc_offset??null;

    notice.name = this.form.get('name')?.value??null;
    notice.is_active = this.form.get('is_active')?.value??null;
    notice.hit_counter = this.form.get('hit_counter')?.value??null;
    notice.hit_interval = this.form.get('hit_interval')?.value??null;
    notice.min_rise_interval = this.form.get('min_rise_interval')?.value??null;

    notice.car_groups=null;
    notice.cars=null;
    if(this.form.get('_group')?.value=='y') {
      notice.car_groups = this.form.get('car_groups')?.value;
    }
    else {
      notice.cars = this.form.get('cars')?.value??null;
    }

    notice.emails = this.form.get('emails')?.value??null;
    notice.phones = this.form.get('phones')?.value??null;
    (notice.phones||[]).forEach((x,ix)=>{ notice.phones![ix]=this.utils.stdTrimPhone(x) });
    notice.send_to_messages = this.form.get('send_to_messages')?.value??null;
    notice.message = this.form.get('message')?.value??null;

    notice.notice_type = {
      type: this.form.get('_notice_type')?.value??null
    }
    switch(notice.notice_type.type) {
      case IApiNotice_Type.offline: 
        notice.notice_type.offline = { car_offline_interval: this.form.get('_car_offline_interval')?.value??null };
        break;
      case IApiNotice_Type.online:
        break;
      case IApiNotice_Type.geozone:
        notice.notice_type.geozone = {
          geozoneid: this.form.get('_geozoneid')?.value??null,
          in_check: this.form.get('_in_check')?.value??null,
          out_check: this.form.get('_out_check')?.value??null,
          in_geozone_time: this.form.get('_in_geozone_time')?.value??null,
        }
        break;
      case IApiNotice_Type.sensor:
        notice.notice_type.sensor = {
          sensor_key: this.form.get('_sensor_key')?.value??null,
          is_interval: this.form.get('_groupInterval')?.value=='y',
          from_value: this.form.get('from_value')?.value??null,
          to_value: this.form.get('_to_value')?.value??null,
          active_time: this.form.get('_active_time')?.value??null,
          in_range: this.form.get('_in_range')?.value??null,
          value_change_initial_value: this.form.get('_value_change_initial_value')?.value??null,
          value_change_threshold: this.form.get('_value_change_threshold')?.value??null,     
        }
        break;
    }

    let time00 = new Date(Date.now());
    time00.setHours(0); time00.setMinutes(0); time00.setSeconds(0); time00.setMilliseconds(999);
    let time24 = new Date(Date.now());
    time24.setHours(23); time24.setMinutes(59); time24.setSeconds(59); time24.setMilliseconds(999);

    notice.notice_schedule = {
      use_schedule: this.form.get('_use_schedule')?.value, 
      week_days:[
        this._week_days.d0, this._week_days.d1, this._week_days.d2, this._week_days.d3, 
        this._week_days.d4, this._week_days.d5, this._week_days.d6
      ]
    };

    console.log('_saveFormToObject', this._week_days);
    console.log('_saveFormToObject notice', notice)

  }

  _loadFormFromObject(notice: IApiNotice|null) {
    let phones: string[]=[];
    (notice?.phones||[]).forEach(x=>phones.push(this.utils.stdPhone(x)));
    this.form.patchValue({
      name: notice?.name,
      is_active: notice?.is_active,
      hit_counter: notice?.hit_counter,
      hit_interval: notice?.hit_interval,
      min_rise_interval: notice?.min_rise_interval,

      _notice_type: notice?.notice_type?.type,

      _car_offline_interval: notice?.notice_type?.offline?.car_offline_interval,

      _geozoneid: notice?.notice_type?.geozone?.geozoneid,
      _in_check: notice?.notice_type?.geozone?.in_check,
      _out_check: notice?.notice_type?.geozone?.out_check,
      _in_geozone_time: notice?.notice_type?.geozone?.in_geozone_time,

      _sensor_key: notice?.notice_type?.sensor?.sensor_key,
      _groupInterval: notice?.notice_type?.sensor?.is_interval?'y':'n',

      _from_value: notice?.notice_type?.sensor?.from_value,
      _to_value: notice?.notice_type?.sensor?.to_value,
      _active_time: notice?.notice_type?.sensor?.active_time,
      _in_range: notice?.notice_type?.sensor?.in_range,

      _value_change_initial_value: notice?.notice_type?.sensor?.value_change_initial_value,
      _value_change_threshold: notice?.notice_type?.sensor?.value_change_initial_value,

      cars: notice?.cars,
      car_groups: notice?.car_groups,

      emails: notice?.emails,
      phones: notice?.phones ? phones:null,
      send_to_messages: notice?.send_to_messages,
      message: notice?.message
    });

    this.form.get('_group')?.patchValue( (notice?.cars||[]).length==0 ? 'y':'n');

    this.form.get('_use_schedule')?.patchValue(notice?.notice_schedule?.use_schedule??false);
    this._week_days = {
      d0: notice?.notice_schedule?.week_days?.[0]??{}, d1: notice?.notice_schedule?.week_days?.[1]??{},
      d2: notice?.notice_schedule?.week_days?.[2]??{}, d3: notice?.notice_schedule?.week_days?.[3]??{},
      d4: notice?.notice_schedule?.week_days?.[4]??{}, d5: notice?.notice_schedule?.week_days?.[5]??{},
      d6: notice?.notice_schedule?.week_days?.[6]??{},
    }??([] as IApiNotice_ScheduleItem[]);
  }

  saveRecord() {
    if(this.form.invalid) {
      const invalid = [];
        const controls:any = this.form.controls;
        for (const name in controls)
          if (controls[name]?.invalid) { invalid.push(name); }
      console.log('form is invalid', invalid)
      this.form.markAllAsTouched();
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.noticeService.insert(this.item)
      : this.noticeService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.notice};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

  isType(type: string|null) {
    return this.form.get('_notice_type')?.value==type;
  }

}
