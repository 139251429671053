import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IApiGeozone } from 'src/app/model.backend/geozone.model';

@Component({
  selector: 'app-geozone-card',
  templateUrl: './geozone-card.component.html',
  styleUrls: ['./geozone-card.component.css']
})
export class GeozoneCardComponent {
  @Input() geozone!: IApiGeozone;
  @Output() onEditKeyPress = new EventEmitter<string|null>();
  @Output() onDblclick = new EventEmitter<string|null>();

  constructor() {
  }

  isSelected() {
    return (this.geozone as any)['_selected'];
  }

  clickSelected() {
    (this.geozone as any)['_selected']=!(this.geozone as any)['_selected'];
  }

  onEditGeozone(event: MouseEvent)
  {
    console.log('onEditGeozone', event);
    (this.geozone as any)['_selected']=true;
    this.onEditKeyPress.emit(this.geozone?.id);
  }

  dblclickCard() {
    this.onDblclick.emit(this.geozone?.id);
  }

}
