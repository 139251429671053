import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api/treenode";

@Injectable({
    providedIn: 'root'
  })
  export class TreeNodeHelperService { 

    public static FindNode(source: TreeNode[], key: string, value: any): TreeNode|null {
        let ix=0, fnd: TreeNode|null =null;
        while(ix<source.length && fnd==null) {
            if(source[ix].data[key]==value) fnd=source[ix];
            else {
                if(source[ix].children!=null) {
                    let jx=0;
                    while(jx<source[ix].children!.length && fnd==null) {
                        if(source[ix]?.children!=null) 
                            fnd=this.FindNode(source[ix]!.children!, key, value);
                        jx++;
                    }
                }
            }
            ix++;
        }
        return fnd;
    }

    public static ShowAndCloseNodes(source: TreeNode[], destination: TreeNode[], key: string ) {
        source.forEach(src=>{
            let fnd = this.FindNode(destination, key, src.data[key] );
            if(fnd) fnd.expanded = src.expanded;
            if(src.children!=null)
                this.ShowAndCloseNodes(src.children, destination, key);
        })
    }


    
    
}