import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiCarStatus, IApiCarStatusListResponse, IApiCarStatusResponse } from "../model.backend/carstatus.model";
import { tap } from "rxjs/internal/operators/tap";
import { of } from "rxjs/internal/observable/of";
import { environment } from "src/environments/environment";
import { IUserSensor } from "../model.backend/user.model";

@Injectable({
    providedIn: 'root'
  })
  export class CarStatusService extends BaseService {
    protected controller: string = "carstatus";
    private fake_carstauses: IApiCarStatus[]=[];
    private fake_count=0;

    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    get(id: string): Observable<IApiCarStatusResponse> {
      return this.http.get<IApiCarStatusResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarStatusResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о статусе транспортного средства.'))
          );    
    }

    list(user_sensors: IUserSensor[]|undefined, groupid?: string): Observable<IApiCarStatusListResponse> {
      if(environment.carStatusFake) {
        this.fake_count++;
        if(this.fake_count==1)
          return this.http.get<IApiCarStatusListResponse>(`${this.apiUrl}/${this.controller}/list${groupid?'/'+encodeURIComponent(groupid||'xxx'):''}`, {headers: this.headers} )
              .pipe(
                tap(res=>{
                  this.fake_carstauses=res.items||[];
                }),
                catchError(this.handleError<IApiCarStatusListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения статусов транспортных средств.'))
              )
        else {
          this.fake_carstauses.forEach(x=>{
            x.speed = (x.speed??0)+1;
            x.lat = (x.lat??0)+0.002*Math.random();
            x.lon = (x.lon??0)+0.002*Math.random();
          })
          return of({ items: this.fake_carstauses, ok: true } as IApiCarStatusListResponse)
        }
      } 
      else {
        //const data = { sensor_snks: sensor_snks, groupid: groupid }

        // TODO для отладки
        const data = { user_sensors, groupid: groupid }

        console.log('carstatus', data);
        return this.http.post<IApiCarStatusListResponse>(`${this.apiUrl}/${this.controller}/list`, data, {headers: this.headers} )
            .pipe(              
              catchError(this.handleError<IApiCarStatusListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения статусов транспортных средств.'))
            );
      }
    }   


}
  