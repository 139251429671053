
<span *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.Offline">
    <i [pTooltip]="'cars.status_offline'|translate" class="pi pi-times text-red-600"></i>
    <span *ngIf="withText" class="pl-2">{{'cars.status_offline'|translate}}</span>
</span>
<span *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.IgnitionIsOn">
    <i [pTooltip]="'cars.status_ignition'|translate" class="pi pi-key text-green-600"></i>
    <span *ngIf="withText" class="pl-2">{{'cars.status_ignition'|translate}}</span>
</span>
<span *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.Movement">
    <i [pTooltip]="'cars.status_movement'|translate" class="pi pi-play text-green-600"></i>
    <span *ngIf="withText" class="pl-2">{{'cars.status_movement'|translate}}</span>
</span>
<span *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.Parking">
    <i [pTooltip]="'cars.status_parking'|translate" class="pi pi-stop text-blue-600"></i>
    <span *ngIf="withText" class="pl-2">{{'cars.status_parking'|translate}}</span>
</span>
