import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { IApiCharterer } from 'src/app/model.backend/charterer.model';
import { ChartererService } from 'src/app/service.backend/charterer.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-charterer-edit',
  templateUrl: './charterer-edit.component.html',
  styleUrls: ['./charterer-edit.component.css']
})
export class ChartererEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCharterer>();
  @Output() onInsert = new EventEmitter<IApiCharterer>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiCharterer|null;
  destroy$ = new Subject<boolean>();

  options = {
    cars: [] as INameCode[]
  };

  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'charterers.add':'charterers.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
    cars: new FormControl()
  });

  constructor(
    private chartererService: ChartererService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
//    this.isNew() ? this.newRecord(): this.editRecord(); 

    this.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.loading = false;
        this.initOptions();
        this.isNew() ? this.newRecord(): this.editRecord(); 
      },
      error: err => {
        this.loading = false;
        this.error = err; 
        this.messageService.add({severity: 'error', detail: this.error??'' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  initOptions(){
    this.options.cars=this.preloaderService.getAvailableCarsOptions();
  }

  editRecord() {
    this.loading=true;
    this.chartererService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.charterer);
          this.item = res.charterer;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    this.item={};
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(charterer: IApiCharterer) {
    charterer.name = this.form.get('name')?.value;
    charterer.cars = this.form.get('cars')?.value;
  }

  _loadFormFromObject(charterer: IApiCharterer|null) {
    this.form.get('name')?.patchValue(charterer?.name??null);
    this.form.get('cars')?.patchValue(charterer?.cars??null);
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.chartererService.insert(this.item)
      : this.chartererService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.charterer};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
