import { IApiBaseResponse, IApiBaseTable } from "./_base.model";
import { IApiCarGroupUser } from "./cargroupuser.model";

export interface IApiUser extends IApiBaseTable {
    login?: string|null;
    name?: string|null;
    password?: string|null;
    password_salt?: string|null;
    comment?: string|null;
    phone?: string|null;
    position?: string|null;
    blocked?: string|null;
    options?: string[]|null;
    car_groups?: string[]|null;
    settings?: IApiUserSettings|null;
    customerid?: string|null;
    email?: string|null;
    fill_at_login?: boolean|null;
    reportlistsid?: string[]|null;
    car_group_users?: IApiCarGroupUser[]|null;
}

export interface IApiUserSettings {
    utc_offset?: number|null;

    // отображать уведомления во всплывающем окне
    show_popup_messages?: boolean|null;
    show_popup_messages_time?: number|null;
    
    // настройки для карты
    track_width?: number|null;
    track_color?: string|null;
    geozone_color?: string|null;
    geozone_fill?: string|null;
    
    // ?отображать ТС как точку
//    show_cars_as_points?: boolean|null;
    // отображать статусы ТС
    show_car_status?: boolean|null;
    // отображать след ТС
    show_cars_track?: boolean|null;
    // следить за выбранным ТС
    follow_car?: boolean|null;
    // группировать ТС на карте
    cluster_cars_on_map?: boolean|null;
    // отображать геозоны
    show_geozones?: boolean|null;
    // отображать направление движения
    show_azimuth?: boolean|null;

    // формат ТС на карте
    // отображать номер
    view_number?: boolean|null;
    // отображать модель
    view_brand?: boolean|null;
    // 3 датчика для отображения на плитке ТС
    user_sensors?: IUserSensor[];

    view_branches?: string[]|null;

    viewmode?: string|null;
    language?: string|null;
}

export interface IUserSensor {
    snk: string|null,
    precision?: number|null
}

export enum IApiUser_Rights {
    admin = "admin",            // Администратор компании и всех нижестоящих
    notifyManage="notify",      // Работа с уведомлениями
    taskManage="task",          // Работа с заданиями
    reportManage="report",      // Работа с очетами
    videoManage="video",        // Работа с видеонаблюдением
    groupUserManage="groupuser",// Управление парками
    userManage="user",          // Управление пользователями
    commandSend="command",      // Отправка команд
    carManage="car",            // Управление TC
    toManage="to",              // Управление TО

    groupManage="group",        // Управление группами ТС
    reportShare="repshare",     // Построение общих отчетов
    reportEditor="repeditor",   // Редактор отчетов
    routeManage="route",        // -Управление маршрутами
    geozoneManage="geozone",    // Работа с геозонами
    trackManage="track",        // -Построение трека
    carMessages="carmessage",   // Сообщение ТС
    driverManage="driver",      // Управление водителями
    chartererManage="charterer",// Управление заказчиками
    reportFinance="repfinance"  // Доступ к финансам
}


export interface IApiUserListResponse extends IApiBaseResponse {
    users: IApiUser[]|null;
};
  
export interface IApiUserResponse extends IApiBaseResponse {
    user: IApiUser|null;
};

