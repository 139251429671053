import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiRequestSupport } from 'src/app/model.backend/requestsupport.model';
import { RequestSupportService } from 'src/app/service.backend/requestsupport.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import { MasterMenuService } from '../../master-page/master-menu/master-menu.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-requestsupport-list',
  templateUrl: './requestsupport-list.component.html',
  styleUrls: ['./requestsupport-list.component.css']
})
export class RequestSupportListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  options = {
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiRequestSupport[]|null,
    unreadMessages: 0
  };
  dialog = {
    edit: {
      show: false,
      id: null as string|null,
      viewMode: false
    }
  }

  constructor(
    private requestsupportService: RequestSupportService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private masterMenuService: MasterMenuService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.hideMasterManuOthers();
    this.loadRecords();
    this.masterMenuService.requestSupportCount$.pipe(takeUntil(this.destroy$))
    .subscribe((count)=>{
      if(count!=this.data.unreadMessages){
        this.data.unreadMessages=count;
        this.loadRecords();
      }
    })
  }

  hideMasterManuOthers() {
    try {
      let el:any = document.getElementById('masterMenuOthers');
      el.classList.add("hidden"); 
    } catch {}
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  loadRecords() {
    this.data.loading=true;
    this.requestsupportService.list().subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('loadRecords', res.request_supports);
          this.data.records = res.request_supports;
        } else {
          console.error('loadRecords', res.errorMessage, res.errorMessageForUser);
          this.data.error = res.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.applyFilterAndSort();
        this.data.loading=false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading=false;
      }
    })
  }

  applyFilterAndSort() {
    (this.data.records||[]).sort((a,b)=>(a.created??'')>(b.created??'')?1:-1);
  }

  // clickRecordEdit(record: IApiRequestSupport) {
  //   this.dialog.edit.id = record.id??'xxx';
  //   this.dialog.edit.show =true;
  //   this.dialog.edit.viewMode = false;
  // }

  clickRecordEdit(record: IApiRequestSupport) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
    this.dialog.edit.viewMode=!!record.closed || !!record.closed_by_customer;
  }

  clickRecordAdd() {
    this.dialog.edit.id = null;
    this.dialog.edit.show =true;
    this.dialog.edit.viewMode=false;
  }

  /*
  clickRecordDelete(record: IApiRequestSupport, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('requestsupport.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.requestsupportService.delete(record.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              let ix = this.data.records?.findIndex(x=>x.id==record.id)??-1;
              if(ix>=0) this.data.records?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }*/

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }

  onDialogEditUpdate(record: IApiRequestSupport) {
    let ix=this.data.records?.findIndex(x=>x.id==record.id)??-1;
    if(ix>=0 && this.data.records) {
      this.data.records[ix] = { ...record };
    } 
    this.applyFilterAndSort();
  }

  onDialogEditInsert(record: IApiRequestSupport) {
    if(!this.data.records) this.data.records=[];
    this.data.records.push({...record});
    this.applyFilterAndSort();
  }

}
