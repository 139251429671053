import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiCarGroup, IApiCarGroupCarSetResponse, IApiCarGroupListResponse, IApiCarGroupResponse, IApiCarGroupUserSetResponse } from "../model.backend/cargroup.model";
import { IApiBaseResponse } from "../model.backend/_base.model";

@Injectable({
    providedIn: 'root'
  })
  export class CarGroupService extends BaseService {
    protected controller: string = "cargroup";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    // list(customerId: string): Observable<IApiCarGroupListResponse> {
    //   return this.http.get<IApiCarGroupListResponse>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(customerId)}`, {headers: this.headers} )
    //     .pipe(
    //       catchError(this.handleError<IApiCarGroupListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка групп.'))
    //     );    
    // }   

    // get(id: string): Observable<IApiCarGroupResponse> {
    //   return this.http.get<IApiCarGroupResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
    //       .pipe(
    //         catchError(this.handleError<IApiCarGroupResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о группе.'))
    //       );    
    // }

    insert(obj: IApiCarGroup): Observable<IApiCarGroupResponse> {
      return this.http.post<IApiCarGroupResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления группы.'))
          );    
    }
  
    update(id: string, obj: IApiCarGroup): Observable<IApiCarGroupResponse> {
      return this.http.post<IApiCarGroupResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о группе.'))
          );    
    }

    // setUser(groupid: string, userid: string): Observable<IApiCarGroupUserSetResponse> {
    //   return this.http.get<IApiCarGroupUserSetResponse>(`${this.apiUrl}/${this.controller}/setuser/${encodeURIComponent(groupid||'xxx')}/${encodeURIComponent(userid||'xxx')}`, {headers: this.headers} )
    //       .pipe(
    //         catchError(this.handleError<IApiCarGroupUserSetResponse>(`GET ${this.controller}.setuser`, null, 'Ошибка обновления доступного списка групп.'))
    //       );    
    // }

    // resetUser(groupid: string, userid: string): Observable<IApiCarGroupUserSetResponse> {
    //   return this.http.get<IApiCarGroupUserSetResponse>(`${this.apiUrl}/${this.controller}/resetuser/${encodeURIComponent(groupid||'xxx')}/${encodeURIComponent(userid||'xxx')}`, {headers: this.headers} )
    //       .pipe(
    //         catchError(this.handleError<IApiCarGroupUserSetResponse>(`GET ${this.controller}.resetuser`, null, 'Ошибка обновления доступного списка групп.'))
    //       );    
    // }

    // setUserAll(customerId: string, userid: string): Observable<IApiCarGroupUserSetResponse> {
    //   return this.http.get<IApiCarGroupUserSetResponse>(`${this.apiUrl}/${this.controller}/setuserall/${encodeURIComponent(customerId||'xxx')}/${encodeURIComponent(userid||'xxx')}`, {headers: this.headers} )
    //       .pipe(
    //         catchError(this.handleError<IApiCarGroupUserSetResponse>(`GET ${this.controller}.setuserall`, null, 'Ошибка обновления доступного списка групп.'))
    //       );    
    // }

    // resetUserAll(customerId: string, userid: string): Observable<IApiCarGroupUserSetResponse> {
    //   return this.http.get<IApiCarGroupUserSetResponse>(`${this.apiUrl}/${this.controller}/resetuserall/${encodeURIComponent(customerId||'xxx')}/${encodeURIComponent(userid||'xxx')}`, {headers: this.headers} )
    //       .pipe(
    //         catchError(this.handleError<IApiCarGroupUserSetResponse>(`GET ${this.controller}.resetuserall`, null, 'Ошибка обновления доступного списка групп.'))
    //       );    
    // }


    setCar(groupid: string, carid: string): Observable<IApiCarGroupCarSetResponse> {
      return this.http.get<IApiCarGroupCarSetResponse>(`${this.apiUrl}/${this.controller}/setcar/${encodeURIComponent(groupid||'xxx')}/${encodeURIComponent(carid||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupCarSetResponse>(`GET ${this.controller}.setcar`, null, 'Ошибка обновления доступного списка автомобилей.'))
          );    
    }

    resetCar(groupid: string, carid: string): Observable<IApiCarGroupCarSetResponse> {
      return this.http.get<IApiCarGroupCarSetResponse>(`${this.apiUrl}/${this.controller}/resetcar/${encodeURIComponent(groupid||'xxx')}/${encodeURIComponent(carid||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupCarSetResponse>(`GET ${this.controller}.resetcar`, null, 'Ошибка обновления доступного списка автомобилей.'))
          );    
    }

    setCarAll(groupid: string): Observable<IApiCarGroupCarSetResponse> {
      return this.http.get<IApiCarGroupCarSetResponse>(`${this.apiUrl}/${this.controller}/setcarall/${encodeURIComponent(groupid||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupCarSetResponse>(`GET ${this.controller}.setcarall`, null, 'Ошибка обновления доступного списка автомобилей.'))
          );    
    }

    resetCarAll(groupid: string): Observable<IApiCarGroupCarSetResponse> {
      return this.http.get<IApiCarGroupCarSetResponse>(`${this.apiUrl}/${this.controller}/resetcarall/${encodeURIComponent(groupid||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupCarSetResponse>(`GET ${this.controller}.resetcarall`, null, 'Ошибка обновления доступного списка автомобилей.'))
          );    
    }

    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления группы.'))
          );    
    }

}
  