<div *ngIf="canViewGroupUser()" class="grid surface-ground px-2 pt-3">
    <div class="col-12 text-right">
        <p-button (onClick)="clickCarGroupUserAdd()" icon="pi pi-plus" [label]="'main.btnGroupAdd'|translate" styleClass="p-button-secondary p-button-sm p-button-rounded" ></p-button>
    </div>
    <div id='scrollelement' class="col-12" [ngStyle]="{'height':ui.innerHeight, 'overflow-y': 'auto'}">
        <p-table [value]="data.cargroupsuser||[]" class="p-datatable-sm sav3-table">
            <ng-template pTemplate="body" let-group>
                <tr>
                    <td class="w-full">
                        {{ group.name }}
                    </td>
                    <td class="p-1">
                        <button (click)="$event.stopPropagation();clickCarGroupUserEdit(group)" class="dh-onhover p-button p-button-rounded p-button-text p-1 sav3" pButton>
                            <i class="pi pi-pencil"></i>
                        </button>
                    </td>
                    <td class="p-1">
                        <button (click)="$event.stopPropagation();clickCarGroupUserDelete($event,group)" class="dh-onhover p-button p-button-rounded p-button-text p-button-danger p-1 sav3" pButton>
                            <i class="pi pi-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <app-norecords *ngIf="(data.cargroupsuser?.length??0)==0"></app-norecords>
    </div>
</div>

<app-structure-park-edit *ngIf="dialog.parkEdit.show"
    [id]="dialog.parkEdit.id"
    [userid]="dialog.parkEdit.userid"
    [customerid]="dialog.parkEdit.customerid"
    [ownEdit]="true"
    (onHide)="onDialogParkEditHide()"
    (onUpdate)="onDialogParkEditUpdate($event)"
    (onInsert)="onDialogParkEditInsert($event)"
>
</app-structure-park-edit>