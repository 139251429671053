import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCarGroupUser } from 'src/app/model.backend/cargroupuser.model';
import { CarGroupUserService } from 'src/app/service.backend/cargroupuser.service';

@Component({
  selector: 'app-cargroupuser-list',
  templateUrl: './cargroupuser-list.component.html',
  styleUrls: ['./cargroupuser-list.component.css']
})
export class CarGroupUserListComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event']) onResize() { this.correctInnerHeight() }
  @ViewChild('scrollelement') scrollelement?: HTMLDivElement;

  destroy$ = new Subject<boolean>();
  options = {
  };
  data = {
    loading: false,
    error: null as string|null,
    cargroupsuser: null as IApiCarGroupUser[]|null
  };
  ui = {
    innerHeight: '0px'
  };
  dialog = {
    parkEdit: {
      show: false,
      id: null as string|null,
      userid: null as string|null,
      customerid: null as string|null
    }
  }

  constructor(
    private carGroupUserService: CarGroupUserService,
    private preloaderService: PreloaderService,
    private authService: AuthService,    
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.data.cargroupsuser = this.preloaderService.getCarGroupUsersForUser(this.authService.getUser()?.id??'xxx')||[];
    this.correctInnerHeight();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  canViewGroupUser() {
    return this.authService.getUser()?.customerid;
  }

  clickCarGroupUserAdd() {
    this.dialog.parkEdit.id = null;
    this.dialog.parkEdit.userid = this.authService.getUser()?.id??null;
    this.dialog.parkEdit.customerid = this.preloaderService.customer$.value?.id??null;
    if(this.dialog.parkEdit.userid && this.dialog.parkEdit.customerid) {
      this.dialog.parkEdit.show = true;
    }
  }

  clickCarGroupUserEdit(group: IApiCarGroupUser) {
    this.dialog.parkEdit.id = group.id??null;
    this.dialog.parkEdit.userid = this.authService.getUser()?.id??null;
    this.dialog.parkEdit.customerid = this.preloaderService.customer$.value?.id??null;
    if(this.dialog.parkEdit.userid && this.dialog.parkEdit.customerid) {
      this.dialog.parkEdit.show = true;
    }
  }

  clickCarGroupUserDelete(event: Event, group: IApiCarGroupUser) {
    if(group==null) return;

    let uid = this.authService.getUser()?.id??'xxx';
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('main.questGroupDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.carGroupUserService.delete(group?.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              this.preloaderService.deleteCarGroupUser(uid, group?.id??'xxx');
              let ix = this.data.cargroupsuser?.findIndex(x=>x.id==group?.id)??-1;
              if(ix>=0) this.data.cargroupsuser?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  onDialogParkEditHide() {
    this.dialog.parkEdit.show=false;
  }

  onDialogParkEditUpdate(group: IApiCarGroupUser) {
    let ix = this.data.cargroupsuser?.findIndex(x=>x.id==group?.id)??-1;
    if(ix>=0) {
      this.data.cargroupsuser?.splice(ix,1,group);
    }
  }

  onDialogParkEditInsert(group: IApiCarGroupUser) {
    this.data.cargroupsuser?.unshift(group);
   }

  correctInnerHeight() {
    setTimeout(()=>{
      let h = window.innerHeight;
      let rect2=document.getElementById('scrollelement')?.getBoundingClientRect();
      h = Math.round(h - (rect2?.top??0)-2 );
      this.ui.innerHeight = h+"px"; 
    }, 1)
  }
}
