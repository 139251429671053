import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiCarGroup } from 'src/app/model.backend/cargroup.model';
import { CarGroupService } from 'src/app/service.backend/cargroup.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-cargroup-edit',
  templateUrl: './cargroup-edit.component.html',
  styleUrls: ['./cargroup-edit.component.css']
})
export class CarGroupEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() groupId = null as string|null;
  // for insert
  @Input() parentId = null as string|null;
  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCarGroup>();
  @Output() onInsert = new EventEmitter<IApiCarGroup>();
  loading = false;
  saving = false;
//  changingPass = false;
  errorLoad=null as string|null;  
  errorSave=null as string|null;  
  item = null as IApiCarGroup|null;
  destroy$ = new Subject<boolean>();

  isNew = () => !this.groupId;
  getHeader = () => this.translate.instant(this.isNew() ? 'cargroup.add': 'cargroup.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.groupId==null ? 'pi-plus':'pi-save')
  isNewMode = () => this.groupId==null;

  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
  });

  constructor(
    private carGroupService: CarGroupService,
    private messageService: MessageService,
    private preloaderService: PreloaderService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.isNewMode() ? this.newRecord() : this.editRecord();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.item = this.preloaderService.allcargroups$.value.find(x=>x.id==this.groupId)??null;
    this._loadFormFromObject(this.item);
    if(!this.item) {
      console.error('editRecord', 'Unknown cargroup id:'+this.groupId);
      this.errorLoad = this.translate.instant('form.loadError');
      this.messageService.add({severity: 'error', detail: this.errorLoad??'' });
    }
    this.loading=false;
  }

  newRecord() {
    this.item = { parentid: this.parentId, is_visible: true };
    this._loadFormFromObject(this.item);
    this.loading=false;
  }

  _loadFormFromObject(cargroup: IApiCarGroup|null) {
    this.form.get('name')?.patchValue(this.item?.name??null);
  }

  _saveFormToObject(cargroup: IApiCarGroup) {
    cargroup.name = this.form.get('name')?.value;
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(!this.item) this.item={};
    this._saveFormToObject(this.item!);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.carGroupService.insert(this.item)
      : this.carGroupService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.group};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.errorSave = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.errorSave = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.errorSave??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.errorSave = this.translate.instant('form.addError')
        else this.errorSave = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.errorSave??'' });
        this.saving=false;  
      }
    });
  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

}
