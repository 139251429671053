<p-dialog [header]="'profile.header'|translate" 
    [(visible)]="visible" 
    [style]="{ width: '80vw' }" 
    styleClass="shadow-5 sav3-dialog"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"    
    [modal]="true"        
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{'profile.header'|translate}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3 nogutter">
            <div class="col-6">
                <div class="grid">
                    <div class="col-12">
                        <div><label for="login" class="sav3-label">{{'profile.fieldLogin'|translate}}</label></div>
                        <input formControlName="login" id="login" type="text" pInputText class="w-full p-disabled sav3-input p-inputtext-sm">       
                    </div>
                    <div class="col-12">
                        <div><label for="name" class="sav3-label">{{'profile.fieldName'|translate}}</label></div>
                        <input formControlName="name" id="name" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                        <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
                    </div>
                    <div class="col-12">
                        <div><label for="phone" class="sav3-label">{{'profile.fieldPhone'|translate}}</label></div>
                        <input formControlName="phone" id="phone" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                        <p-message *ngIf="!validateMaxLength('phone')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                    </div>
                    <div class="col-12">
                        <div><label for="email" class="sav3-label">Email</label></div>
                        <input formControlName="email" id="email" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                        <p-message *ngIf="!validateMaxLength('email')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
                    </div>       
                    <div class="col-12">
                        <div><label for="utc_offset" class="sav3-label">{{'profile.timezone'|translate}}</label></div>
                        <p-dropdown formControlName="utc_offset" styleClass="w-full sav3-input p-inputtext-sm" [options]="options.timezones" optionLabel="name" optionValue="code" appendTo="body" ></p-dropdown>                      
                    </div>        
                    <div class="col-12">
                        <div><label for="language" class="sav3-label">{{'profile.language'|translate}}</label></div>
                        <p-dropdown formControlName="language" styleClass="w-full sav3-input p-inputtext-sm" [options]="options.languages" optionLabel="name" optionValue="code" appendTo="body" ></p-dropdown>
                    </div>        
                </div>
            </div>
            <div class="col-6">
                <div class="grid">
                    <div class="col-12">
                        <div><label for="viewmode" class="sav3-label">{{'profile.viewMode'|translate}}</label></div>
                        <p-dropdown formControlName="viewmode" styleClass="w-full sav3-input p-inputtext-sm" [options]="options.viewmodes" optionLabel="name" optionValue="code" appendTo="body" ></p-dropdown>
                    </div>        
                    <div class="col-12">
                        <div class="w-full sav3-checkbox">
                            <p-checkbox formControlName="show_popup_messages" [label]="'profile.showpopup'|translate" [binary]="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="col-12">
                        <h4 class="mb-0">{{'profile.colorSettings'|translate}}</h4>
                    </div>
                    <div class="col-3">
                        <div><label for="track_color" class="sav3-label">{{'profile.trackcolor'|translate}}</label></div>
                        <p-colorPicker formControlName="track_color" inputId="track_color" appendTo="body"></p-colorPicker>
                    </div>
                    <div class="col-3">
                        <div><label for="track_width" class="sav3-label">{{'profile.trackwidth'|translate}}</label></div>
                        <p-inputNumber formControlName="track_width" inputId="track_width" [min]="1" [max]="10" inputStyleClass="w-full sav3-input p-inputtext-sm" styleClass="w-full sav3-input p-inputtext-sm"></p-inputNumber>       
                    </div>
                    <div class="col-3">
                        <div><label for="geozone_color" class="sav3-label">{{'profile.geozonecolor'|translate}}</label></div>
                        <p-colorPicker formControlName="geozone_color" inputId="geozone_color" appendTo="body"></p-colorPicker>                       
                    </div>
                    <div class="col-3">
                        <div><label for="geozone_fill" class="sav3-label">{{'profile.geozonefill'|translate}}</label></div>
                        <p-colorPicker formControlName="geozone_fill" inputId="geozone_fill" appendTo="body"></p-colorPicker>
                    </div>
    
                    <div class="col-12">
                        <h4 class="mb-0">{{'profile.cardSettings'|translate}}</h4>
                    </div>
                    <div class="col-12 mb-3 p-3 bg-gray-200 sav3-border-radius">
                        <h5 class="my-1">Настройка таблицы</h5>
                        <app-car-card-list previewMode="table" [cars]="data.samplecars"></app-car-card-list>
                    </div>
                    <div class="col-12 p-3 bg-gray-200 sav3-border-radius">
                        <h5 class="my-1">Настройка карточек</h5>
                        <app-car-card-list 
                            previewMode="card" 
                            [previewSensors]="data.sensors"
                            [cars]="data.samplecars"
                            (onPreviewSensorsChange)="onPreviewSensors_Change($event)"
                        >
                        </app-car-card-list>
                    </div>

                </div>
            </div>

            <!--
            <div class="col-4">
                <div><label for="position">{{'profile.fieldPosition'|translate}}</label></div>
                <input formControlName="position" id="position" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateMaxLength('position')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-6">
                <p-checkbox formControlName="show_popup_messages" [binary]="true" inputId="show_popup_messages"></p-checkbox>
                <label for="show_popup_messages" class="ml-2">{{'profile.showpopup'|translate}}</label>                       
                <div class="pt-3"><label for="show_popup_messages_time">{{'profile.showpopuptime'|translate}}</label></div>
                <p-inputNumber formControlName="show_popup_messages_time" inputId="show_popup_messages_time"></p-inputNumber>
            </div>
            <div class="col-2">
                <div><label for="track_width">{{'profile.trackwidth'|translate}}</label></div>
                <p-inputNumber formControlName="track_width" inputId="track_width" [min]="1" [max]="10" [showButtons]="true" inputStyleClass="w-full text-center" styleClass="w-full"></p-inputNumber>
            </div>
            <div class="col-4">
                <div class="pl-5">
                    <div class="mb-1">
                        <p-colorPicker formControlName="track_color" inputId="track_color" appendTo="body"></p-colorPicker>
                        <label for="track_color" class="ml-2 mr-5">{{'profile.trackcolor'|translate}}</label>    
                    </div>
                    <div class="mb-1">
                        <p-colorPicker formControlName="geozone_color" inputId="geozone_color" appendTo="body"></p-colorPicker>
                        <label for="geozone_color" class="ml-2">{{'profile.geozonecolor'|translate}}</label>        
                    </div>
                    <div >
                        <p-colorPicker formControlName="geozone_fill" inputId="geozone_fill" appendTo="body"></p-colorPicker>
                        <label for="geozone_fill" class="ml-2">{{'profile.geozonefill'|translate}}</label>        
                    </div>
                </div>
            </div>
            <div class="col-6 pt-3">
                <div class="grid">
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="show_car_status" [binary]="true" inputId="show_car_status" class="mr-2"></p-checkbox>
                            <label for="show_car_status">{{'profile.showstatus'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="show_cars_track" [binary]="true" inputId="show_cars_track" class="mr-2"></p-checkbox>
                            <label for="show_cars_track">{{'profile.showtrack'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="follow_car" [binary]="true" inputId="follow_car" class="mr-2"></p-checkbox>
                            <label for="follow_car">{{'profile.follow'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="cluster_cars_on_map" [binary]="true" inputId="cluster_cars_on_map" class="mr-2"></p-checkbox>
                            <label for="cluster_cars_on_map">{{'profile.cluster'|translate}}</label>
                        </div>
                    </div>
                </div>
            </div>      
            <div class="col-6 pt-3">
                <div class="grid">
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="show_geozones" [binary]="true" inputId="show_geozones" class="mr-2"></p-checkbox>
                            <label for="show_geozones">{{'profile.showgeozone'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="show_azimuth" [binary]="true" inputId="show_azimuth" class="mr-2"></p-checkbox>
                            <label for="show_azimuth">{{'profile.showazimuth'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="view_number" [binary]="true" inputId="view_number" class="mr-2"></p-checkbox>
                            <label for="view_number">{{'profile.shownumber'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 flex align-items-center">
                            <p-checkbox formControlName="view_brand" [binary]="true" inputId="view_brand" class="mr-2"></p-checkbox>
                            <label for="view_brand">{{'profile.showbrand'|translate}}</label>
                        </div>               
                    </div>
                </div>                
            </div>      
        -->
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
        <p-button (click)="changePassword()" 
                  [disabled]="loading"
                  [label]="'profile.btnChangePassword'|translate" 
                  icon="pi pi-key" styleClass="p-button-secondary p-button-rounded">
        </p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary p-button-rounded">
        </p-button>
    </ng-template>
</p-dialog>

<app-change-password *ngIf="ui.change.show" 
    (onHide)="ui.change.show=false"
>
</app-change-password>
