<p-dialog [header]="'cars.sendcommandtitle'|translate" 
    [(visible)]="visible" 
    [style]="{ width: '60vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false"
    [modal]="true"
>  
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{'cars.sendcommandtitle'|translate}}</span>
    </ng-template> 

    <app-progressbar [loading]="data.loading"></app-progressbar>
    <app-errorbar *ngIf="data.error" [showError]="data.error">{{data.error}}</app-errorbar>

    <div class="flex align-items-center">
        <div class="mr-2">
            <label class="white-space-nowrap sav3-label">{{'cars.selectcommand'|translate}}</label>
        </div>
        <div class="mr-2 w-full">
            <p-dropdown [(ngModel)]="ui.templateid" 
                [options]="options.commands" 
                optionLabel="name" optionValue="code" 
                appendTo="body" 
                styleClass="sav3-input w-full"
                class="w-full"
            ></p-dropdown>
        </div>
        <div class="py-2">
            <p-button 
                (onClick)="sendCommand()" 
                icon="pi pi-send" 
                [label]="'cars.sendCommand'|translate" 
                styleClass="p-button p-button-rounded p-button-secondary white-space-nowrap" 
                [ngClass]="{'p-disabled':!canSendCommand()}"
            ></p-button>
        </div>
    </div>
    <p-table [value]="data.messages" [tableStyle]="{ 'min-width': '100%' }" styleClass="p-datatable p-datatable-sm" dataKey="id" [rows]="15">
        <ng-template pTemplate="header">
            <tr>
                <th>{{ 'common.created'|translate }}</th>
                <th>{{ 'cars.teplatecommand'|translate }}</th>
                <th>{{ 'common.sendtime'|translate }}</th>
                <th class="text-center">{{ 'cars.sendresult'|translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-message let-ri="index">
            <tr>
                <td class="white-space-nowrap">
                    <small>{{ utils.UTCstringToLocalYYYYMMDD_HHMM(message.created) }}</small>
                </td>
                <td class="white-space-nowrap">
                    {{getTemplateName(message.smstemplateid)}}
                </td>
                <td class="white-space-nowrap">
                    <small>{{ utils.UTCstringToLocalYYYYMMDD_HHMM(message.proceed_completed) }}</small>
                </td>
                <td class="text-center">
                    <div *ngIf="message.result_code=='ok'"><i class="pi pi-check text-green-500"></i></div>
                    <div *ngIf="message.result_code!='ok' && message.proceed_completed"><i class="pi pi-times text-red-500"></i> {{message.result_code}}</div>                   
                </td>
            </tr>
        </ng-template>
    </p-table>

    <app-norecords *ngIf="!data.error && !data.loading && (data.messages||[]).length==0"></app-norecords>
    
    <div class="p-2">&nbsp;</div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-times" [label]="'form.close'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
    </ng-template>


</p-dialog>

