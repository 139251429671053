<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '60vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-12 field">
                <div><label for="title">{{'requestsupport.fieldTitle'|translate}}<span *ngIf="!viewMode && isNew()" class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="title" id="title" type="text" pInputText class="w-full" [readOnly]="viewMode || !isNew()">
                <p-message *ngIf="!validateRequired('title')" severity="error" [text]="'requestsupport.titleRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('title')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div *ngIf="!viewMode" class="col-12 field">
                <div *ngIf="isNew()"><label for="message">{{'requestsupport.fieldDescription'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <div *ngIf="!isNew()"><label for="message">{{'requestsupport.fieldDescription2'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <textarea  formControlName="message" id="description" [autoResize]="false" [rows]="3" pInputTextarea class="w-full"></textarea>
                <p-message *ngIf="!validateRequired('message')" severity="error" [text]="'requestsupport.descriptionRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('message')" severity="error" [text]="'form.maxLength4000'|translate"></p-message>
            </div>
        </div>
        <div *ngIf="!isNew() && (item?.items||[]).length>0" class="grid">
            <label>История вопросов и ответов</label>
            <ng-container *ngFor="let i of (item?.items||[]); let ix=index">
                <div [ngClass]="{'pr-5':i?.type=='question', 'pl-5 col-offset-1':i?.type=='answer'}" class="col-11">
                    <div *ngIf="i.type=='question'" class="text-sm text-gray-500">{{'requestsupport.question'|translate}}</div>
                    <div *ngIf="i.type=='answer'" class="text-sm text-gray-500">{{'requestsupport.answer'|translate}}</div>
                    <div class="p-2 border-1 border-round border-gray-200">
                        {{i.message}}
                    </div>
                    <div class="text-sm text-gray-500 text-right">{{(i.created??'')|isoToDDMMYYYY_HHMMSS}}</div>
                </div>
            </ng-container>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button *ngIf="!isNew() && !viewMode"
            (click)="closeRecord()" 
            [disabled]="loading"
            [label]="'requestsupport.requestClose'|translate" 
            icon="pi pi-check" styleClass="p-button-success"
        ></p-button>
        <p-button *ngIf="!viewMode"
                  (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

