import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiClientLoadAllResponse } from "../model.backend/system.model";


@Injectable({
    providedIn: 'root'
  })
  export class SystemService extends BaseService {
    protected controller: string = "system";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    loadAll(): Observable<IApiClientLoadAllResponse> {
        return this.http.get<IApiClientLoadAllResponse>(`${this.apiUrl}/${this.controller}/loadall`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiClientLoadAllResponse>(`GET ${this.controller}.loadAll`, null, 'Ошибка загрузки конфигурации.'))
            );    
    }   

}
  