import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiCarGroup } from 'src/app/model.backend/cargroup.model';
import { CarGroupService } from 'src/app/service.backend/cargroup.service';
import { PreloaderService } from 'src/app/service/preloader.service';

interface IApiCarGroupFlat extends IApiCarGroup {
  _level: number,
  _open: boolean,
  _hasChild: boolean  
}

@Component({
  selector: 'app-car-group',
  templateUrl: './cargroup.component.html',
  styleUrls: ['./cargroup.component.css']
})
export class CarGroupComponent implements OnInit, OnDestroy {
  @Input() showcheck=false;
  @Input() viewMode=false;
  @Input() selectAllonInit=true;
  @Output('onSelect') onSelect=new EventEmitter<IApiCarGroup>();
  destroy$ = new Subject<boolean>();

  data = {
    groups:  null as IApiCarGroup[]|null,
    groupsFlat: null as IApiCarGroupFlat[]|null,
    groupSelect: null as IApiCarGroup|null,
    selectedGroups: [] as IApiCarGroupFlat[]|null,

    loading: false,  
    error: null as string|null
  }

  dialog = {
    group: {
      groupId: null as string|null,
      parentId: null as string|null,
      show: false
    },
  }

  constructor(
    private authService: AuthService,
    private carGroupService: CarGroupService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private preloaderService: PreloaderService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.data.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.data.loading = false;
//        this.initOptions();
        this.data.groups=this.preloaderService.availablecargroups$.value;
        this.prepareFlat();
        if(this.selectAllonInit){
          let f = this.data.groupsFlat?.find(x=>x.id=="*");
          if(f) this.data.groupSelect=f;
          this.onSelect.emit(f);
        }
      },
      error: err => {
        this.data.loading = false;
        this.data.error = err; 
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
      }
    });   
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  prepareFlat() {
    this.data.groups?.sort((a,b) => (a.name??'')>(b.name??'') ? 1:-1);
    this.data.groupsFlat=[];
    this._prepareFlat(null, 0, this.data.groupsFlat);
    this.data.groupsFlat.unshift({id:"*", name:"Все ТС", _level: 0, _open: false, _hasChild: false});
    this.prepareSelectedGroups();
    console.log('prepareFlat', this.data.groupsFlat);
  }

  _prepareFlat(parentId:string|null, level: number, result: IApiCarGroupFlat[]) {
    let groups = this.getGroupChild(parentId);
    groups.forEach(group=>{
      result.push({ 
        ...group, 
        _level: level, 
        _open: this.isCarGroupOpen(group),
        _hasChild: this.isGroupHasChild(group.id??null) 
      });
      if( this.isGroupHasChild(group.id??null) && this.isCarGroupOpen(group)) {
        this._prepareFlat(group.id??null, level+1, result);
      }
    });
  }

  prepareSelectedGroups() {
    let res:IApiCarGroupFlat[] = [];
    // this.data.userSelect?.car_groups?.forEach(g=>{
    //   let fnd = this.data.groupsFlat?.find(x=>x.id==g)??null;
    //   if(fnd) res.push(fnd);
    // });
    // console.log('prepareSelectedGroups', this.data.userSelect?.car_groups, res);
    this.data.selectedGroups=res;
  }

  prepareSelectedCars() {
    // let res:IApiCar[] = [];
    // this.data.groupSelect?.cars?.forEach(g=>{
    //   let fnd = this.data.cars?.find(x=>x.id==g)??null;
    //   if(fnd) res.push(fnd);
    // });
    // console.log('prepareSelectedCars', this.data.groupSelect?.cars, res);
    // this.data.selectedCars=res;
  }
  
  isGroupHasChild(groupId: string|null) {
    return !groupId ? false : (this.data.groups?.filter(x=>x.parentid==groupId).length??0)>0;
  }

  getGroupChild(groupId: string|null) {
    return this.data.groups?.filter(x=>x.parentid==groupId)??[];
  }

  isCarGroupOpen(group: IApiCarGroup|null) {
    return !!((group as any)['_locale']?.open);
  }

  onRowGroupSelectUnselect(obj:any, set:boolean){
    console.log('onRowSelect', obj);
    /*
    let groupId=obj.data?.id;
    if(groupId && this.data.userSelect?.id){
      (obj.data as any)['_locale']={saving: true};
      (set 
        ? this.carGroupService.setUser(groupId,this.data.userSelect?.id)
        : this.carGroupService.resetUser(groupId,this.data.userSelect?.id)
      )
      .subscribe(
        {
          next: (res)=>{
            console.log('onRowSelect res=', res);
            if(!res.ok) {
              console.error('onRowSelect', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: this.data.groupError??''})
            } else {
            }
            (obj.data as any)['_locale']={saving: false}
            this.data.userSelect!.car_groups=res.groupids??null;
            this.prepareSelectedGroups();
          },
          error: (err)=>{
            (obj.data as any)['_locale']={saving: false}
            this.messageService.add({severity: 'error', detail: this.data.clientsError??''})
            console.error('onRowSelect',err);            
          }
        }
      )
    }
    */
  }

  onHeaderGroupCheckboxToggle(obj: any) {
    console.log('onHeaderCheckboxToggle', obj)

    /*
    let customerId = this.data.clientSelect?.id;
    if( customerId && this.data.userSelect?.id){
      this.data.groupLoading = true;
      (obj.checked
        ? this.carGroupService.setUserAll(customerId, this.data.userSelect?.id)
        : this.carGroupService.resetUserAll(customerId, this.data.userSelect?.id)
      )
      .subscribe(
        {
          next: (res)=>{
            console.log('onHeaderCheckboxToggle res=', res);
            if(!res.ok) {
              console.error('onHeaderCheckboxToggle', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: this.data.groupError??''})
            } else {
            }
            this.data.groupLoading = false;
            this.data.userSelect!.car_groups=res.groupids??null;
            this.prepareSelectedGroups();
          },
          error: (err)=>{
            this.data.groupLoading = false;
            this.messageService.add({severity: 'error', detail: this.data.clientsError??''})
            console.error('onHeaderCheckboxToggle',err);            
          }
        }
      )
    }
    */
  }

  isCarGroupSelected(group: IApiCarGroup) {
    return group.id==this.data.groupSelect?.id;
  }

  clickCarGroup(group: IApiCarGroupFlat|null) {
    this.data.groupSelect = group;
//    this.showcheck = !!group && group?.id!='*';
    this.prepareSelectedCars();
    if(group) this.onSelect.emit(group);
  }

  clickCarGroupOpen(group: IApiCarGroupFlat|null) {
    this.data.groupSelect = group;
    let _group = this.data.groups?.find(x=>x.id==group?.id);
    if(_group)
      if(!(_group as any)['_locale']) {
        (_group as any)['_locale']= { open:true };
      } else {
        (_group as any)['_locale'].open = !(_group as any)['_locale'].open ; 
      }

    this.prepareFlat();
  }

  clickCarGroupAdd() {
    this.dialog.group.parentId = null;
    this.dialog.group.groupId = null;
    this.dialog.group.show=true;    
  }

  clickCarGroupAddChild(parentId: string|null) {
    this.dialog.group.parentId = parentId;
    this.dialog.group.groupId = null;
    this.dialog.group.show=true;    
  }

  clickCarGroupEdit(group: IApiCarGroup) {
    this.dialog.group.parentId = group.parentid??null;
    this.dialog.group.groupId = group.id??'xxx';
    this.dialog.group.show=true;    
  }

  onCarGroupEdit(group: IApiCarGroup) {
    let fnd = this.data.groups?.find(x=>x.id==group.id);
    if(fnd) {
      fnd.name = group.name;
      fnd.is_visible = group.is_visible;
      this.prepareFlat();
    }
  }

  onCarGroupNew(group: IApiCarGroup) {
    if(!this.data.groups) {
      this.data.groups=[];
    }
    this.data.groups.push(group);  
    this.prepareFlat();
  }

  clickCarGroupDelete(group: IApiCarGroup, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('cargroup.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.carGroupService.delete(group.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              let ix = this.data.groups?.findIndex(x=>x.id==group.id)??-1;
              if(ix>=0) this.data.groups?.splice(ix,1);
              this.data.groupSelect=null;
              this.prepareFlat();
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });

  }
 





}
