<div class="flex flex-column flex-auto h-full overflow-y-auto w-full">
    <p-table [value]="data.groupsFlat||[]" 
             [rowHover]="true"
             [(selection)]="data.selectedGroups"
             dataKey="id"                   
             selectionMode="multiple" 
             styleClass="p-datatable-sm w-full"
             (onRowSelect)="onRowGroupSelectUnselect($event, true)"
             (onRowUnselect)="onRowGroupSelectUnselect($event, false)"
             (onHeaderCheckboxToggle)="onHeaderGroupCheckboxToggle($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="showcheck" style="width: 40px; height: 48px;" class="text-center">
                    <p-tableHeaderCheckbox *ngIf="showcheck"></p-tableHeaderCheckbox>
                </th>
                <th colspan="2" style="height: 48px;">{{'cargroup.header'|translate}}</th>
            </tr>
            <tr>
                <td [attr.colspan]="(showcheck?3:2)" style="height: 5px;">
                    <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                    <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
            <tr (click)="clickCarGroup(group)" 
                (dblclick)="clickCarGroupOpen(group)"
                [ngClass]="{'bg-green-100': isCarGroupSelected(group)}">
                <td *ngIf="showcheck" class="border-0 text-center" style="width:40px;">
                    <p-tableCheckbox *ngIf="showcheck && !group['_locale']?.saving" [value]="group"></p-tableCheckbox>
                    <i *ngIf="group['_locale']?.saving" class="pi pi-spin pi-spinner"></i>
                </td>
                <td class="border-0 dh-cursor-default white-space-normal px-0">
                    <div class="flex">
                        <div class="text-right flex-none" [ngStyle]="{width: (group._level+1)*12+'px'}">
                            <i *ngIf="group._hasChild && group._open" (click)="clickCarGroupOpen(group);$event.stopPropagation()" class="pi pi-angle-down"></i>
                            <i *ngIf="group._hasChild && !group._open" (click)="clickCarGroupOpen(group);;$event.stopPropagation()" class="pi pi-angle-right"></i>    
                        </div>
                        <div>
                            {{group.name}}
                        </div>
                    </div>
                </td>
                <td class="border-0 text-right pl-0 pr-3" style="height: 48px;">
                    <div *ngIf="group?.id!='*' && !viewMode">
                        <p-button (click)="clickCarGroupAddChild(group.id);$event.stopPropagation()" icon="pi pi-plus" styleClass="mr-1 dh-onhover"></p-button>
                        <p-button (click)="clickCarGroupEdit(group);$event.stopPropagation()" icon="pi pi-pencil" styleClass="mr-1 dh-onhover"></p-button>
                        <p-button (click)="clickCarGroupDelete(group, $event);$event.stopPropagation()" icon="pi pi-times" styleClass="p-button-danger dh-onhover"></p-button>
                    </div>
                    <div *ngIf="group?.id=='*' && !viewMode">
                        <p-button (click)="clickCarGroupAddChild(null);$event.stopPropagation()" icon="pi pi-plus" styleClass="mr-1 dh-onhover"></p-button>
                    </div>                   
            </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-cargroup-edit *ngIf="dialog.group.show"
    [parentId]="dialog.group.parentId" 
    [groupId]="dialog.group.groupId"
    (onHide)="dialog.group.show=!dialog.group.show"
    (onUpdate)="onCarGroupEdit($event)"
    (onInsert)="onCarGroupNew($event)"
>
</app-cargroup-edit>
