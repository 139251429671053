import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { IApiCar } from 'src/app/model.backend/car.model';
import { CarService } from 'src/app/service.backend/car.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, INameCodeNumber, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-car-edit',
  templateUrl: './car-edit.component.html',
  styleUrls: ['./car-edit.component.css']
})
export class CarEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() carId = null as string|null;
  @Input() viewMode = false;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCar>();
  @Output() onInsert = new EventEmitter<IApiCar>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiCar|null;
  destroy$ = new Subject<boolean>();

  options = {
    tariff: [] as INameCode[],
    state: [ 
      { name: '-', code: null },
      { name: 'Активно', code: 0 },
      { name: 'Заблокировано', code: 1 },
      { name: 'Заблокировано биллингом', code: 2 }      
    ] as INameCodeNumber[],
    icon: [] as INameCode[],

  };

  isNew = () => !this.carId;
  getHeader = () => this.translate.instant(this.isNew() ? 'car-edit.add': this.viewMode ? 'car-edit.view':'car-edit.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    // readonly
    active_state: new FormControl(null as number|null),
    number: new FormControl(null as string|null),
    vin: new FormControl(null as string|null),
    pin: new FormControl(null as string|null),
    paid_before: new FormControl(null as Date|null),

    // editable
    brand: new FormControl(null as string|null, [Validators.maxLength(50)]),
    year: new FormControl(null as Date|null),
    color: new FormControl(null as string|null, [Validators.maxLength(50)]),
    display_name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
    iconid: new FormControl(null as string|null),
    comment: new FormControl(null as string|null, [Validators.maxLength(2000)]),
  });

  constructor(
    private carService: CarService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.loading = false;
        this.initOptions();
        this.isNew() ? this.newRecord(): this.editRecord(); 
      },
      error: err => {
        this.loading = false;
        this.error = err; 
        this.messageService.add({severity: 'error', detail: this.error??'' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  initOptions() {
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.carService.get(this.carId??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.car);
          this.item = res.car;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    this.item={};
    this._loadFormFromObject(this.item);
  }

  _loadFormFromObject(car: IApiCar|null) {
    // readonly
    this.form.get('active_state')?.patchValue(car?.active_state??null);
    this.form.get('number')?.patchValue(car?.number??null);
    this.form.get('vin')?.patchValue(car?.vin??null);
    this.form.get('pin')?.patchValue(car?.pin??null);
    if(car?.paid_before) {
      let d: Date|null = new Date(Date.parse(car?.paid_before));
      this.form.get('paid_before')?.patchValue(d);
    } else {
      this.form.get('paid_before')?.patchValue(null)
    }

    // editable
    this.form.get('brand')?.patchValue(this.item?.brand??null);
    if(car?.year) {
      let d: Date = new Date(car?.year,1,1);
      this.form.get('year')?.patchValue(d);
    } else {
      this.form.get('year')?.patchValue(null);
    }
    this.form.get('color')?.patchValue(car?.color??null);
    this.form.get('display_name')?.patchValue(car?.display_name??null);
    this.form.get('iconid')?.patchValue(car?.iconid??null);
    this.form.get('comment')?.patchValue(car?.comment??null);
  
  }

  _saveFormToObject(car: IApiCar) {
    car.brand = this.form.get('brand')?.value;
    car.color = this.form.get('color')?.value;
    car.display_name = this.form.get('display_name')?.value;
    car.iconid = this.form.get('iconid')?.value;
    car.comment = this.form.get('comment')?.value;
    try {
      let d = this.form.get('year')?.value;
      if(d) car.year = d.getFullYear() 
    }
    catch{}
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.carService.insert(this.item)
      : this.carService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.car};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });
  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
