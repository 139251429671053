import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IApiAuthserverUserModel } from '../model.backend/authserver.model';
import { IApiUser, IApiUser_Rights } from '../model.backend/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: BehaviorSubject<IApiAuthserverUserModel|null> = new BehaviorSubject<IApiAuthserverUserModel|null>(this.getUserFromStorage()) ;

  constructor(
    private router: Router,
  ) {    
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  getUserFromStorage() {
    let au: IApiAuthserverUserModel|null = null;
    try { au = JSON.parse(sessionStorage.getItem('authUser')??""); } catch {}
    return au;
  }

  storeTemorarayToken( token: string) {
    sessionStorage.setItem('token', token);
  }

  loggedIn(authUser: IApiAuthserverUserModel, token: string) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('tokentime', Date.now().toString());
    sessionStorage.setItem('authUser', JSON.stringify(authUser));
    this.user$.next(authUser);
  }

  // раз в 30 минут
  isNeedRefreshToken() {
    let tm = sessionStorage.getItem('tokentime');
    if(!tm) return true;
    let nm = parseInt(tm);
    if( Date.now()-nm > 30 * 60 *1000 ) return true;
    return false;
  }

  refreshToken(token: string) {
    sessionStorage.setItem('token', token);
  }

  logOut(saveReturnUrl: boolean = true, newUrl?: string) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('authUser');
    this.user$.next(null);
    if(saveReturnUrl) {
      this.router.navigate(['/login'], {queryParams: { returnUrl: newUrl ?? this.router.routerState.snapshot.url }});
    } else {
      this.router.navigate(['/login']);
    }
  }

  isLoggedIn() {
    let token = sessionStorage.getItem('token');
    let au: IApiAuthserverUserModel = JSON.parse(sessionStorage.getItem('authUser')||'{}');
    return !!token && !!au?.id;
  }

  isMasterAdministrator() {
    return this.user$.value?.original_user ? this.user$.value?.original_user?.customerid==null : false;
  }

  // оригинальная запись User, для администраторов будет customerid==null
  getUser() {
    return this.user$.value?.original_user;
  }


  // доступ пользователя
  isAllow(right: IApiUser_Rights) {
    return this.isMasterAdministrator() 
           || this.getUser()?.options?.find(x=>x=='*') 
           || this.getUser()?.options?.find(x=>x==right) 
           ? true : false;
  }

  updateUser(user: IApiUser, silent: boolean = false) {
    //update in storage
    let au=this.getUserFromStorage()??this.user$.value;
    if(au) {
      au.original_user = {...user};
      au.login = user.login;
      au.name = user.name;
      sessionStorage.setItem('authUser', JSON.stringify(au));  
      if(!silent) this.user$.next(au);
    }
  }

}

