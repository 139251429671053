import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-cars',
  templateUrl: './page-cars.component.html',
  styleUrls: ['./page-cars.component.css']
})
export class PageCarsComponent implements OnInit {
  selectGroupId="*" as string|null;

  constructor(){

  }

  ngOnInit(): void {
    this.hideMasterManuOthers();     
  }

  hideMasterManuOthers() {
    try {
      let el:any = document.getElementById('masterMenuOthers');
      el.classList.add("hidden"); 
    } catch {}
  }

}
