import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiUser, IApiUser_Rights } from 'src/app/model.backend/user.model';
import { UserService } from 'src/app/service.backend/user.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-structure-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class StructureUserEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() customerid = null as string|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiUser>();
  @Output() onInsert = new EventEmitter<IApiUser>();

  loading = false;
  saving = false;
  error = null as string|null;  
  disableSave = false;
  item = null as IApiUser|null;
  destroy$ = new Subject<boolean>();

  options = {
    status: [
      {label: "активный", code: "active"},
      {label: "блокирован", code: "blocked"}
    ],
    options: [
      {label: "Администратор компании", code:  IApiUser_Rights.admin},
      {label: "Может редактировать пользователей", code:  IApiUser_Rights.userManage},
      {label: "Может редактировать водителей", code:  IApiUser_Rights.driverManage},

      {label: "Доступ к уведомлениям", code:  IApiUser_Rights.notifyManage},
      {label: "Доступ к заданиям", code:  IApiUser_Rights.taskManage},
      {label: "Доступ к отчетам", code:  IApiUser_Rights.reportManage},
      {label: "Доступ к видеонаблюдению", code:  IApiUser_Rights.videoManage},
//      {label: "Может редактировать парки", code:  IApiUser_Rights.groupUserManage},
//      {label: "Может создавать пользователей", code:  IApiUser_Rights.userManage},
      {label: "Может отправлять команды", code:  IApiUser_Rights.commandSend},
      {label: "Может редактировать ТС", code:  IApiUser_Rights.carManage},
      {label: "Может управлять ТО", code:  IApiUser_Rights.toManage},
    ],
    reports: [] as INameCode[]
  };

  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'structure.userEdit.add' : 'structure.userEdit.edit');

  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')
  
  form = new FormGroup({
    l: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
    name: new FormControl(null as string|null, [Validators.maxLength(255)]),
    p: new FormControl(null as string|null, [ Validators.required, Validators.maxLength(255)]),
    p2: new FormControl(null as string|null, [ Validators.required, Validators.maxLength(255)]),
    email: new FormControl(null as string|null, [Validators.maxLength(255)]),
    phone: new FormControl(null as string|null, [Validators.maxLength(100)]),
    comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    fill_at_login: new FormControl(null as boolean|null),
    status: new FormControl(null as string|null),
    options: new FormControl(null as string[]|null),
    reportlistsid: new FormControl(null as string[]|null),
  });

  constructor(
//    private customerService: CustomerService,
    private userService: UserService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.isNew() ? this.newRecord(): this.editRecord(); 
    (this.preloaderService.reportLists$.value||[]).forEach(rep=>this.options.reports.push({name: rep.name, code: rep.id}));    
    if(!this.isNew()) {
      this.form.get('p')?.removeValidators(Validators.required);
      this.form.get('p2')?.removeValidators(Validators.required);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.userService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.user);
          this.item = res.user;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
          this.disableSave = true;
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
        this.disableSave = true;
      }
    })
  }

  newRecord() {
    this.item={ car_groups: ["*"] };
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(user: IApiUser) {
    user.customerid = this.customerid;
    user.login = this.form.get('l')?.value;
    user.name = this.form.get('name')?.value;
    user.password = this.form.get('p')?.value;
    user.password_salt = this.form.get('p2')?.value;
    user.email = this.form.get('email')?.value;
    user.phone = this.form.get('phone')?.value;
    user.comment = this.form.get('comment')?.value;
    user.fill_at_login = this.form.get('fill_at_login')?.value;
    user.options = this.form.get('options')?.value;
    user.reportlistsid = this.form.get('reportlistsid')?.value;

    if(this.form.get('status')?.value=='active') {
      user.blocked=null;
    } else {
      if(this.item?.blocked==null) {
        user.blocked =(new Date()).toISOString();
      }
    }
  }

  _loadFormFromObject(user: IApiUser|null) {
    this.form.get('l')?.patchValue(user?.login??null);
    this.form.get('name')?.patchValue(user?.name??null);
    this.form.get('p')?.patchValue(null);
    this.form.get('p2')?.patchValue(null);
    this.form.get('email')?.patchValue(user?.email??null);
    this.form.get('phone')?.patchValue(user?.phone??null);
    this.form.get('comment')?.patchValue(user?.comment??null);
    this.form.get('fill_at_login')?.patchValue(user?.fill_at_login??null);
    this.form.get('options')?.patchValue(user?.options??null);
    this.form.get('reportlistsid')?.patchValue(user?.reportlistsid??null);
    this.form.get('status')?.patchValue(user?.blocked ? "blocked":"active");
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if( (this.form.get('p')?.value||'') != (this.form.get('p2')?.value||'') )
    {
      this.messageService.add({severity: 'error', detail: this.translate.instant('form.passwordNotSame') });  
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.userService.insert(this.item)
      : this.userService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.user};             
          this.visible=false;
          if(this.isNew()){
            this.preloaderService.addNewUser(this.customerid??'xxx', this.item);
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.preloaderService.updateUser(this.customerid??'xxx', this.item);
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
