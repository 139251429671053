import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import { filter, take, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiCarStatus } from 'src/app/model.backend/carstatus.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';
import { IApiCar } from 'src/app/model.backend/car.model';
import { CarStatusHelper } from '../../../model.helpers/carstatus-helper';

@Component({
  selector: 'app-car-card-list',
  templateUrl: './car-card-list.component.html',
  styleUrls: ['./car-card-list.component.css']
})
export class CarCardListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() customerId = null as string|null;
  @Input() userId = null as string|null;
  @Input() carGroupId = null as string|null;
  @Input() cars: IApiCar[]=[];
  @Input() currentTrips: IApiDriverTrip[]=[];
  @Input() currentCarStatuses: IApiCarStatus[]=[];
  @Input() selectedCars: string[]=[];
  @Input() previewMode = null as string|null;
  @Input() previewSensors = [] as (string|null)[];

  @Output() selectedCarsChange = new EventEmitter<string[]>();
  @Output() onFiltered = new EventEmitter<IApiCar[]>();
  @Output() onCarUpdated = new EventEmitter<IApiDriver>();
  @Output() onCarClick = new EventEmitter<IApiCar>();


  @HostListener('window:resize', ['$event']) onResize() { this.correctInnerHeight() }
  @ViewChild('scrollelement') scrollelement?: HTMLDivElement;
  @Output() onPreviewSensorsChange = new EventEmitter<(string|null)[]>();

  CarStatusHelper = CarStatusHelper
  JSON=JSON
  
  destroy$ = new Subject<boolean>();
  options = {
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiCar[]|null,
    filteredRecords: null as IApiCar[]|null,
    currentTrips: [] as IApiDriverTrip[],
    currentCarStatuses: [] as IApiCarStatus[]
  };
  ui = {
    filterText: '',
    selectedCarGroupUser: null as string|null,
    selectAll: false,
    selectedCarsId: [] as string[],
    selectedCars: [] as IApiCar[],
    viewMode: true,
    viewAsCard: (this.authService.getUser()?.settings?.viewmode??'card')=='card',

    selectedFilter: this.preloaderService.selectedFilter,
    innerHeight: '0px'
  };
  dialog = {
    edit: {
      show: false,
      car: null as IApiCar|null,
      carstatus: null as IApiCarStatus|null,
      drivertrip: null as IApiDriverTrip|null
    },
    filter: {
      show: false
    }
  }

  constructor(
    private preloaderService: PreloaderService,
    private authService: AuthService,    
    private messageService: MessageService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.ui.viewMode = !this.canEditCar();
    if(this.previewMode!=null) {
      this.ui.viewMode=true;
      this.ui.viewAsCard = this.previewMode=='card';
    }
//    this.ui.selectedClient = this.preloaderService.selectedClient;

    // this.ui.selectedCars = this.selectedCars;
    // this.ui.selectedCarGroupUser = this.selectedCarGroupUser;

    this.correctInnerHeight();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedCarGroupUser']) {
      this.ui.selectedCarGroupUser = changes['selectedCarGroupUser'].currentValue;
    }
    if(changes['selectedCars']) {
      this.ui.selectedCarsId = [...changes['selectedCars'].currentValue];
      this._calcSelectAll();
    }
    if(changes['currentTrips']) {
      this.data.currentTrips = [...changes['currentTrips'].currentValue];
    }
    if(changes['currentCarStatuses']) {
      this.data.currentCarStatuses = [...changes['currentCarStatuses'].currentValue];
    }
    if(changes['cars']) {
      this.data.records = [...changes['cars'].currentValue];
      this._applyFilterAndSort();
    }

  }

  _load() {
    this.data.loading=true;  
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1),
      // switchMap(x=>forkJoin({
      //   drivers: this.preloaderService.drivers$.pipe(take(1))
      // }))
    ).subscribe({
      next: (obj)=>{
//        this.data.records = this.drivers;
//        this.data.tripsRecords = this.currentTrips;
//        this.applyFilterAndSort();
        this.data.loading = false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading = false;
      }
    });  
  }

  _applyFilterAndSort() {
    this.data.filteredRecords = (this.data.records||[]).filter(
      x => (this.ui.filterText??'')=='' 
           || (x.number?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase()) 
           || (x.brand?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase()) 
           || (x.vin?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase())
           || (x.display_name?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase())
           || (x.pin?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase())
           || (this._getCarStatus(x.id??'xxx')?.driver_info?.name?.toLowerCase().includes(this.ui.filterText?.toLowerCase()))
           
    );

    this.data.filteredRecords = (this.data.filteredRecords||[]).filter(
      car => {
        let res = true;
        let carStatus = this._getCarStatus(car.id??'xxx')??null;

        // статус
        let status = this.preloaderService.selectedFilter.status||[];
        if(status.length>0) {
          res = res && status.includes(carStatus?.status??-1);
        }
        
        // ошибки
        let errors = this.preloaderService.selectedFilter.errors||[];
        let res2 = (errors.includes('nogps') && CarStatusHelper.isGarNoGps(carStatus)) 
                   || (errors.includes('nonet') && CarStatusHelper.isCarOffline(carStatus)) 
                   || (errors.includes('error') && CarStatusHelper.isCarError(carStatus));
        res = res && (res2 || errors.length==0);

        // прицеп
        if( this.preloaderService.selectedFilter.isTruck==true && !CarStatusHelper.isTruck(carStatus)) res = false;

        // просрочено ТО
        if( this.preloaderService.selectedFilter.skipedTO==true && !CarStatusHelper.isSkippedTo(carStatus)) res = false;

        // Блокировка двигателя
        if( this.preloaderService.selectedFilter.blockEngine==true && !CarStatusHelper.isBlockEngine(carStatus)) res = false;

        return res;
      }
    );

    (this.data.filteredRecords||[]).sort((a,b)=>(a.number??'')+(a.display_name??'')>(b.number??'')+(b.display_name??'') ? 1:-1);

    this._calcSelectAll();
    this.correctInnerHeight();
  }

  _getCarStatus(carid: string) {
    return this.data.currentCarStatuses.find(x=>x.car_id==carid)??null;
  }

  _calcSelectAll() {
    let s = true;
    (this.data.filteredRecords||[]).forEach(car => { s = s && this.isCarSelected(car); } );
    this.ui.selectAll=s;
  }

  // clickCarEdit(record: IApiCar) {
  //   this.dialog.edit.carId = record.id??'xxx';
  //   this.dialog.edit.show =true;
  // }

  // clickCarAdd() {
  //   this.dialog.edit.carId = null;
  //   this.dialog.edit.show =true;
  // }

  clickCarSelectAll(select: boolean) {
    let selected: string[] = [];
    if(select) (this.data.filteredRecords||[]).forEach(driver=>selected.push(driver.id??'xxx'));
    this.ui.selectedCarsId = selected;
    this.data.filteredRecords = [...this.data.filteredRecords||[]];
    this.selectedCarsChange.emit(this.ui.selectedCarsId);
  }

  isCarSelected(car: IApiCar) {
    let id = car.id??'xxx';
    return this.ui.selectedCarsId.findIndex(x=>x==id)>=0;
  }

  convertToSelectedCars() {
    this.ui.selectedCars=[];
    (this.ui.selectedCarsId||[]).forEach(id=>{
      let car = this.data.records?.find(x=>x.id==id);
      if(car && this.isCarSelected(car)) this.ui.selectedCars.push(car);
    });
  }

  onCarTableSelected(event: any, select: boolean) {
    console.log('onCarTableSelected', event, select)
    let car = event.data;
    if(select) {
      if(!this.ui.selectedCarsId.find(x=>x==car.id)) this.ui.selectedCarsId.push(car.id??'xxx');
    } else {
      let ix = this.ui.selectedCarsId.findIndex(x=>x==car.id)
      if(ix>=0) this.ui.selectedCarsId.splice(ix,1);
    }
    this.selectedCarsChange.emit(this.ui.selectedCarsId);
  }

  // getDriverCurrentTrip(driver: IApiDriver) {
  //   return this.currentTrips?.find(x=>x.driverid==driver.id)??null;
  // }

  // getCarStatus(driver: IApiDriver) {
  //   let car = this.getDriverCurrentTrip(driver);
  //   return car ? this.currentCarStatuses?.find(x=>x.car_id==car?.carid)??null : null;
  // }

  // clickDriverDelete(record: IApiDriver, event: Event) {
  //   this.confirmationService.confirm({
  //     target: event.target as EventTarget,
  //     message: this.translate.instant('driver.questDelete'),      
  //     acceptLabel: this.translate.instant('form.delete'),
  //     acceptButtonStyleClass: 'p-button-danger',
  //     rejectLabel: this.translate.instant('form.cancel'),
  //     rejectButtonStyleClass: 'p-button-text p-button-secondary',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.driverService.delete(record.id??'xxx').subscribe({
  //         next: (res)=>{
  //           if(!res.ok) {
  //             console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
  //             this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
  //           } else {
  //             // let ix = this.data.records?.findIndex(x=>x.id==record.id)??-1;
  //             // if(ix>=0) this.data.records?.splice(ix,1);
  //             this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
  //             this.onDriverDelete.next(record);
  //           }
  //         },
  //         error: (err)=>{
  //           console.error('clickRecordDelete',err);
  //           this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
  //         }
  //       })
  //     },
  //   });
  // }

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }

  onDialogEditUpdate(record: IApiCar) {
    this.onCarUpdated.next(record);
    this._applyFilterAndSort();
  }

  // onDialogEditInsert(record: IApiDriver) {
  //   this.onDriverInsert.next(record);
  // }

  canEditCar(){
    return this.authService.isAllow(IApiUser_Rights.admin) || this.authService.isAllow(IApiUser_Rights.carManage || this.authService.isMasterAdministrator());
  }


  onFilterChange() {
    this._applyFilterAndSort();
    this.onFiltered.emit(this.data.filteredRecords||[]);
//    this.selectedCarGroupUserChange.emit(this.ui.selectedCarGroupUser);
    // this.mapComponent.RedrawCars(this.data.cars_filtered, this.data.carstatuses);
    // this.mapComponent.RedrawTrackHistory(this.data.trackshistory);
  }

  onCarCard_Updated(car: IApiCar) {
    let ix=(this.data.records||[]).findIndex(x=>x.id==car.id);
    if(ix>=0) {
      this.data.records![ix]={...car};
      this._applyFilterAndSort();
    }
  }

  onCarCard_SelectChange(car: IApiCar, select: boolean) {
    let id = car.id??'xxx';
    let ix = this.ui.selectedCarsId.findIndex(x=>x==id);
    if(select && ix<0) this.ui.selectedCarsId.push(id);
    if(!select && ix>=0) this.ui.selectedCarsId.splice(ix,1);
    if(!select) this.ui.selectAll=false;
    this.selectedCarsChange.emit(this.ui.selectedCarsId);
  }

  onCarCard_Edit(car: IApiCar) {
    this.dialog.filter.show = false;
    this.dialog.edit.show = true;
    this.dialog.edit.car = car;
    this.dialog.edit.carstatus = this.data.currentCarStatuses.find(x=>x.car_id==car.id)??null;
    this.dialog.edit.drivertrip = this.data.currentTrips.find(x=>x.carid==car.id)??null;
  }


  correctInnerHeight() {
    setTimeout(()=>{
      let h = window.innerHeight;
      let rect2=document.getElementById('scrollelement')?.getBoundingClientRect();
      h = Math.round(h - (rect2?.top??0)-2 );
      this.ui.innerHeight = h+"px"; 
    }, 1)
  }

  onCarCard_Click(car: IApiCar) {
    this.onCarClick.emit(car);
  }
  
  onPreviewSensors_Change(sensors: (string|null)[]) {
    this.onPreviewSensorsChange.emit(sensors);
  }

  getSensorValueString(carid: string, ix: number) {
    return CarStatusHelper.getSensorFixedValue(this.authService.getUser()??null, this._getCarStatus(carid), ix);
  }

}
