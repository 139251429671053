import { formatNumber } from "@angular/common";
import { INameCode, INameCodeNumber, UtilsService } from "../service/utils.service";
import { IApiBaseResponse, IApiBaseTable } from "./_base.model";
import { IApiCar } from "./car.model";
import { IApiSensorName_KeyEnum } from "./sensor-name.model";

export enum IApiCarStatus_StatusEnum
{
    None=0,          // Пустой
    Parking=10,      // Парковка
    Movement=20,     // Движение
    IgnitionIsOn=30, // Включено зажигание
    Offline = 40     // Потеря связи   
}

export interface IApiCarStatus extends IApiBaseTable  {
    car_id?: string|null;
    last_check_time?: string|null;
    lat?: number|null;
    lon?: number|null;
    status?: number|null;
    azimuth?: number|null;
    speed?: number|null;
    engine_block?: boolean|null;
    motor_hour_value?: number|null;
    race_value?: number|null;
    location?: string|null;
    geozones?: string[]|null;
    car?: IApiCar|null;
    car_vals?: IApiCarVal[]|null;
    driverid?: string|null;
    driver_info?: IApiCarStatusDriverInfo|null;
}

export interface IApiCarStatusResponse extends IApiBaseResponse {
  item: IApiCarStatus|null;
};

export interface IApiCarStatusListResponse extends IApiBaseResponse {
    items: IApiCarStatus[]|null;
};

export interface IApiCarVal {
    t?: string;     // time
    snk?: string;   // sensor_key
    s?: string;
    n?: number;
    add_s?: string;
}

export interface IApiCarStatusDriverInfo {
    name?: string|null;
    from?: string|null;
    to?: string|null;
}

// export class CarStatusHelper {
//     public static isCarError(carstatus: IApiCarStatus|null) {
//         let f = carstatus?.car_vals?.find( x => x.snk==IApiSensorName_KeyEnum.Error_count 
//           || x.snk==IApiSensorName_KeyEnum.Error_address 
//           || x.snk==IApiSensorName_KeyEnum.Error_fmi 
//           || x.snk==IApiSensorName_KeyEnum.Error_spn 
//           || x.snk==IApiSensorName_KeyEnum.Error_lamp
//         );
//         return f!=null;
//     }

//     public static isCarOffline(carstatus: IApiCarStatus|null) {
//         let dt= UtilsService.isoDateParseToDate(carstatus?.last_check_time??undefined);
//         dt?.setHours(dt?.getHours()+3);
         
//         return dt ? dt < new Date() : true;
//       }
    
//     public static isGarNoGps(carstatus: IApiCarStatus|null) {
//       return true;
//     }

//     public static getSensorValue(carstatus: IApiCarStatus|null, ix: number) {
//         let res = null as string|null;
//         if(ix==0) {
//           let f = carstatus?.car_vals?.find(x=>x.snk==IApiSensorName_KeyEnum.Speed);
//           if(f?.n) {
//             res = formatNumber(f.n,'en-US','1.0-0') + 'км/ч';
//           }
//         } else if(ix==1) {
//           let f = carstatus?.car_vals?.find(x=>x.snk==IApiSensorName_KeyEnum.Mileage);
//           if(f?.n) {
//             res = formatNumber(f.n,'en-US','1.0-0') + 'км';
//           }
//         } else {
//           let f = carstatus?.car_vals?.find(x=>x.snk==IApiSensorName_KeyEnum.Fuel);
//           if(f?.n) {
//             res = formatNumber(f.n,'en-US','1.0-0') + 'л';
//           }
//         }
//         return res
//       }

// }

