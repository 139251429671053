<div class="h-full h-width p-1 surface-ground">
    <div class="h-full h-width p-2 bg-white border-1 border-white border-round">
        <div class="px-1 py-2 flex align-items-center">
            <div>
                <h2 class="dh-page-caption mr-5 my-0">
                    <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-money-bill"></p-avatar>
                    {{'finances.title'|translate}}
                </h2>
            </div>
        </div>       
        <div class="grid">
            <div class="col-9">
                <label>&nbsp;</label>
                <app-finance-list></app-finance-list>
            </div>
            <div class="col-3">
                <label>{{'finances.paidTitle'|translate}}</label>
                <p-table [value]="data.records||[]" [rowHover]="false" styleClass="p-datatable p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <td colspan="3" style="height: 5px;">
                                <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                                <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
                            </td>
                        </tr>
                        <tr class="bg-gray-100 font-bold">
                            <th>{{'common.name'|translate}}</th>
                            <th class="text-right">{{'common.cost'|translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record let-index="rowIndex">
                        <tr>
                            <td>{{record.name}}</td>
                            <td class="text-right">{{record.cost|number:'0.2-2'}}</td>
                            <td></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>    
        </div>
    </div>
</div>