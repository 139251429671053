import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  let res = authService?.isLoggedIn()??false;
  console.log('authGuard called', res);
  if(!res) {
    authService.logOut(true, state.url);              
  }
  return res;
};
