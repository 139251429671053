import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BaseService  {
  protected headers = new HttpHeaders({ 'Content-Type': 'application/json', 'accept': 'text/plain' });
  protected apiUrl: string;
  protected authApiUrl: string;

  constructor() { 
    this.apiUrl = environment.apiUrl;
    this.authApiUrl = environment.authApiUrl;
  }

  protected handleError<T>(operation = '', obj: any,  messageForUser?: string) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(operation, '-baseService.handleError: ', error, 'obj: ', obj);

//      if (error.error instanceof ErrorEvent) { return throwError(() => { throw 'Ошибка сети.' })};
//      if (error.error instanceof ProgressEvent) { return throwError(() => { throw 'Ошибка соединения.' } )};
//      if (error.status === 401) { return throwError(() => { throw 'Ошибка авторизации.' } )};
//      if (error.status === 403) { return throwError(() => { throw 'Доступ запрещен.' } )};
      if (error?.error instanceof ErrorEvent) throw 'Ошибка сети.';
      if (error?.error instanceof ProgressEvent) throw 'Ошибка соединения.';
      if (error?.status === 401) throw 'Ошибка авторизации.';
      if (error?.status === 403) throw 'Доступ запрещен.';
      if (error?.status === 500) {      
        if ( messageForUser ) {
//          return throwError(() => { throw messageForUser; } ) 
          throw messageForUser; 
        } else{
          throw 'Ошибка сервера.';
        }
      };

      // ModelState - validation error
      if ( error.error && typeof error.error != "string" ) {
//        return throwError(() => new Error('Ошибка проверки данных, некорректные данные.'));
        throw 'Ошибка проверки данных, некорректные данные.';
      }

      // Simple message showed to user
      if ( messageForUser ) {
//        return environment.detailBackendApiError && error?.error ? throwError(() => new Error(`${messageForUser} ${error?.error}`)) : throwError(() => new Error(`${messageForUser}`));
        if(error?.error) {
          throw `${messageForUser} ${error?.error}`
        } else { 
          throw messageForUser;
        }
      }

      throw (error && error.error) || 'Ошибка сервера.';

//      if ( typeof error.error === 'string' ) {
//        return throwError(() => new Error(((error && error.error) || 'Ошибка сервера.') + ' (' + error.status + ')'));
//      }
//      return throwError(() => new Error((error.message || 'Ошибка сервера.') + ' (' + error.status + ')'));
    };
  } 
}
