import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { IApiBusinessTransaction } from 'src/app/model.backend/business-transaction.model';
import { PreloaderService } from 'src/app/service/preloader.service';

@Component({
  selector: 'app-page-finances',
  templateUrl: './page-finances.component.html',
  styleUrls: ['./page-finances.component.css']
})
export class PageFinancesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  data = {
    records: [] as IApiBusinessTransaction[],
    loading: false,
    error: null as string|null
  }

  constructor(
    private preloaderService: PreloaderService
  ) {

  }

  ngOnInit(): void {
    this.loadRecords();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  loadRecords() {
    this.data.loading=true;
    this.preloaderService.loaded$.pipe(takeUntil(this.destroy$),filter(loaded=>!!loaded))
    .subscribe({
      next: ()=>{
        this.data.records = this.preloaderService.paidService$.value;
        this.data.loading=false;
      }
    })
  }

}
