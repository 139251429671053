import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiTask, IApiTask_LastType, IApiTask_PriodicType, IApiTask_ReportFormat, IApiTask_WeekDays } from 'src/app/model.backend/task.model';
import { TaskService } from 'src/app/service.backend/task.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.css']
})
export class TaskEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() viewMode = false;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiTask>();
  @Output() onInsert = new EventEmitter<IApiTask>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiTask|null;
  destroy$ = new Subject<boolean>();

  ui = {
    options: {
      reportid: [
        {name: '-', code: null},
        {name: 'Отчет 1', code: 'Отчет 1'},
        {name: 'Отчет 2', code: 'Отчет 2'},
        {name: 'Отчет 3', code: 'Отчет 3'},
      ],
      report_format: [
        {name:'Excel', code: IApiTask_ReportFormat.xls}, 
        {name: 'PDF', code: IApiTask_ReportFormat.pdf}
      ],
      periodic_type: [
        {name: 'Единоразово', code: IApiTask_PriodicType.once},
        {name: 'Еженедельно', code: IApiTask_PriodicType.weekdays},
        {name: 'Ежемесячно', code: IApiTask_PriodicType.days}
      ],
      week_days: [
        {name: 'ПН', code: IApiTask_WeekDays.monday},
        {name: 'ВТ', code: IApiTask_WeekDays.tuesday},
        {name: 'СР', code: IApiTask_WeekDays.wednesday},
        {name: 'ЧТ', code: IApiTask_WeekDays.thursday},
        {name: 'ПТ', code: IApiTask_WeekDays.friday},
        {name: 'СБ', code: IApiTask_WeekDays.saturday},
        {name: 'ВС', code: IApiTask_WeekDays.sunday},
      ],
      last_type: [
        {name: 'часов', code: IApiTask_LastType.hours},
        {name: 'дней', code: IApiTask_LastType.days},
        {name: 'месяцев', code: IApiTask_LastType.months}
      ],
      month_days: [
        {name: '1', code: 1}, {name: '2', code: 2}, {name: '3', code: 3}, {name: '4', code: 4}, {name: '5', code: 5},
        {name: '6', code: 6}, {name: '7', code: 7}, {name: '8', code: 8}, {name: '9', code: 9}, {name: '10', code: 10},
        {name: '11', code: 11}, {name: '12', code: 12}, {name: '13', code: 13}, {name: '14', code: 14}, {name: '15', code: 15},
        {name: '16', code: 16}, {name: '17', code: 17}, {name: '18', code: 18}, {name: '19', code: 19}, {name: '20', code: 20},
        {name: '21', code: 21}, {name: '22', code: 22}, {name: '23', code: 23}, {name: '24', code: 24}, {name: '25', code: 25},
        {name: '26', code: 26}, {name: '27', code: 27}, {name: '28', code: 28}, {name: '29', code: 29}, {name: '30', code: 30},
        {name: '31', code: 31},
      ],
      car_groups: [] as INameCode[],
      cars: [] as INameCode[],
    }
  }

  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'task.add':'task.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(255)]),
    is_active: new FormControl({value: true as boolean|null, disabled: this.viewMode}),
    comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    reportid: new FormControl(null as string|null, [Validators.required]),
    report_format: new FormControl(null as string|null, [Validators.required]),
    hit_counter: new FormControl(null as number|null),
    by_day: new FormControl(null as boolean|null),

    periodic_type: new FormControl(null as string|null, [Validators.required]),
    // periodic_type=='once'
    report_from_date: new FormControl(null as Date|null, [Validators.required]),
    report_to_date: new FormControl(null as Date|null, [Validators.required]),
    task_start_time: new FormControl(null as Date|null, [Validators.required]),
    // periodic_type=='weekdays'
    week_days: new FormControl(null as string[]|null),
    // periodic_type=='days'
    month_days: new FormControl(null as number[]|null),
    last: new FormControl({value: null as number|null, disabled: this.viewMode}, [Validators.required]),
    last_type: new FormControl(null as string|null, [Validators.required]),
    task_delay_hours: new FormControl({value: null as number|null, disabled: this.viewMode}),

    cars: new FormControl(null as string[]|null),
    car_groups: new FormControl(null as string[]|null),

    emails: new FormControl({value: null as string[]|null, disabled: this.viewMode}),
    ftp: new FormControl(null as string|null, [Validators.maxLength(255)]),
    ftp_login: new FormControl(null as string|null, [Validators.maxLength(100)]),
    ftp_password: new FormControl(null as string|null, [Validators.maxLength(100)]),
    file_name: new FormControl(null as string|null, [Validators.maxLength(255)]),

    _group: new FormControl("y")
  });

  constructor(
    private authService: AuthService,
    private taskService: TaskService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.form.get('periodic_type')?.valueChanges.subscribe(value=>{
      console.log('periodic_type changed to ', value);
      if(value==IApiTask_PriodicType.once) {
        this.form.get('report_from_date')?.addValidators(Validators.required);
        this.form.get('report_to_date')?.addValidators(Validators.required);
        this.form.get('last_type')?.removeValidators(Validators.required);
        this.form.get('last')?.removeValidators(Validators.required);
      } else {
        this.form.get('report_from_date')?.removeValidators(Validators.required);
        this.form.get('report_to_date')?.removeValidators(Validators.required);
        this.form.get('last_type')?.addValidators(Validators.required);
        this.form.get('last')?.addValidators(Validators.required);
      }
      this.form.updateValueAndValidity();
    })


    this.isNew() ? this.newRecord(): this.editRecord(); 

    this.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.loading = false;
        this.ui.options.car_groups = this.preloaderService.availablecargroups$.value.map(x=>{ return { name: x.name, code: x.id} });
        this.ui.options.car_groups.unshift({name:'Все Группы', code: '*'});
        this.ui.options.cars = this.preloaderService.availablecars$.value.map(x=>{ 
          return { 
            name: x.display_name??'' + this.utils.prefixString(', №', x.number) + this.utils.prefixString(', ', x.brand) + this.utils.prefixString(', pin:', x.pin) , 
            code: x.id} 
        });
        this.ui.options.cars.unshift({name:'Все ТС', code: '*'});
      },
      error: err => {
        this.loading = false;
        this.error = err; 
        this.messageService.add({severity: 'error', detail: this.error??'' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.taskService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.task);
          this.item = res.task;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    let time00 = new Date(Date.now());
    time00.setHours(0); time00.setHours(0); time00.setMinutes(0); time00.setMilliseconds(0);
    this.item = {
      name: 'Новое задание',
      is_active: true,
      report_format: IApiTask_ReportFormat.xls,

      periodic_type: IApiTask_PriodicType.weekdays,
      week_days: [],
      last: 24,
      last_type: IApiTask_LastType.hours,
      task_start_time: time00.toISOString(),

      car_groups: [],
      cars: ['*'],

      file_name: 'Новое задание'
    }
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(task: IApiTask) {
    task.utc_offset = this.authService.getUser()?.settings?.utc_offset??null;

    task.name = this.form.get('name')?.value??null;
    task.is_active = this.form.get('is_active')?.value??null;
    task.reportid = this.form.get('reportid')?.value??null;
    task.report_format = this.form.get('report_format')?.value??null;
    task.hit_counter = this.form.get('hit_counter')?.value??null;
    task.by_day = this.form.get('by_day')?.value??null;
    task.periodic_type = this.form.get('periodic_type')?.value??null;
    task.report_from_date = this.form.get('report_from_date')?.value?.toISOString()??null;
    task.report_to_date = this.form.get('report_to_date')?.value?.toISOString()??null;
    task.task_start_time = this.form.get('task_start_time')?.value?.toISOString()??null;
    task.task_delay_hours = this.form.get('task_delay_hours')?.value??null;
    task.week_days = this.form.get('week_days')?.value??null;
    task.month_days = this.form.get('month_days')?.value??null;
    task.last = this.form.get('last')?.value??null;
    task.last_type = this.form.get('last_type')?.value??null;

    task.car_groups=null;
    task.cars=null;
    if(this.form.get('_group')?.value=='y') {
      task.car_groups = this.form.get('car_groups')?.value;
    }
    else {
      task.cars = this.form.get('cars')?.value??null;
    }

    task.emails = this.form.get('emails')?.value??null;
    task.ftp = this.form.get('ftp')?.value??null;
    task.file_name = this.form.get('file_name')?.value??null;
    task.ftp_login = this.form.get('ftp_login')?.value??null;
    task.ftp_password = this.form.get('ftp_password')?.value??null;  
  }

  _loadFormFromObject(task: IApiTask|null) {
    this.form.patchValue({
      name: task?.name,
      is_active: task?.is_active,
      reportid: task?.reportid,
      report_format: task?.report_format,
      hit_counter: task?.hit_counter,
      by_day: task?.by_day,
      periodic_type: task?.periodic_type,
      report_from_date: this.utils.isoToDate(task?.report_from_date),
      report_to_date: this.utils.isoToDate(task?.report_to_date),
      task_start_time: this.utils.isoToDate(task?.task_start_time),
      task_delay_hours: task?.task_delay_hours,
      week_days: task?.week_days,
      month_days: task?.month_days,
      last: task?.last,
      last_type: task?.last_type,
      cars: task?.cars,
      car_groups: task?.car_groups,
      emails: task?.emails,
      ftp: task?.ftp,
      file_name: task?.file_name,
      ftp_login: task?.ftp_login,
      ftp_password: task?.ftp_password,
    });

    this.form.get('_group')?.patchValue( (task?.cars||[]).length==0 ? 'y':'n');
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.taskService.insert(this.item)
      : this.taskService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.task};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
