import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stdphone'
})
export class StdphonePipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
      if (!value) return "-";
      value = value.trim();
      var s = value.replace("'", '').replace('+', '').replace('(', "").replace(')', "").replace(new RegExp('-', 'g'), "").replace(new RegExp(' ', 'g'), "");
      if(s.length==10) s = '8'+s;
      if (s.length != 11) return value;
      return ((s[0] === '7' || s[0] === '8')?'+':'')+ (s[0]==='8' ? '7':s[0]) +
          "(" + s[1] + s[2] + s[3] + ")" + s[4] + s[5] + s[6] + "-" + s[7] + s[8] + s[9] + s[10]
  }  
}
