<p-dialog  
    [modal]="false"
    [(visible)]="visible" 
    [style]="{ width: '60vw' }" 
    styleClass="sav3-dialog sav3-dialog-nopadding sav3-dialog-inner fadeinleft animation-duration-200"
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <div [ngClass]="{'p-disabled':data.loading}">
        <div class="sticky top-0 bg-gray-100">
            <div class="bg-white z-1">
                <div style="height: 3px;"><app-progressbar [loading]="data.loading"></app-progressbar></div>       
                <p-messages *ngIf="data.error" [value]="[{severity:'error', detail:data.error}]" [closable]="true" [enableService]="false"></p-messages>
                <div class="flex align-items-center p-4">
                    <div>
                        <p-avatar [size]="'large'" [label]=""></p-avatar>
                    </div>
                    <div class="pl-4">
                        <div class="text-2xl font-bold">
                            {{data.car?.display_name}}
                        </div>
                        <div class="text-sm text-gray-500">
                            <div>
                                {{'cars.lastchange'|translate}} {{ (carstatus?.modified) ? utils.isoDateTimeToDDMMYYHHMMSS(carstatus!.modified!):''}}
                            </div>
                            <div>
                                {{'cars.terminaltime'|translate}} {{(carstatus?.last_check_time) ? utils.isoDateTimeToDDMMYYHHMMSS(carstatus!.last_check_time!):''}}
                            </div>
                        </div>
                    </div>
                    <div class="ml-auto text-gray-500 pl-4">
                        <div>{{car?.brand}}</div>
                        <div>{{car?.vin}}</div>
                    </div>
                    <div class="pl-4">
                        <div class="sav3-car-number bg-white text-2xl font-bold">{{car?.number}}</div>
                    </div>
                    <div class="pl-4">
                        <p-button 
                            (onClick)="hideDialog()" 
                            icon="pi pi-times" 
                            [label]="'form.close'|translate" 
                            styleClass="p-button-secondary p-button-rounded" 
                        ></p-button>
                    </div>
                </div>   
            </div>
            <div class="flex align-items-center p-3 z-1">
                <div>
                    <p-selectButton 
                        [(ngModel)]="ui.selectButtonsValue" 
                        [options]="options.selectButtons" 
                        optionLabel="name" 
                        class="sav3-selectButton-top">
                    </p-selectButton>
                </div>
                <div class="ml-auto">
                    <div class="border-rounded bg-yellow-300  py-2 px-3 text-white">
                        ?
                    </div>
                </div>
                <div class="ml-2">
                    <div class="border-rounded bg-red-500 py-2 px-3 text-white">
                        ?
                    </div>
                </div>
                <div class="ml-4">
                    <p-button 
                        (click)="clickSendCommand()"
                        icon="pi pi-send" 
                        [label]="'cars.sendCommand'|translate" 
                        styleClass="p-button-primary p-button-rounded" 
                        [disabled]="!canSendCommand()"
                    ></p-button>                
                </div>
            </div>    
        </div>
        <!-- ОБЩЕЕ -->
        <div *ngIf="ui.selectButtonsValue?.index==0" class="grid grid-nogutter bg-white">
            <div class="col-4 p-2">
                <div *ngIf="data.carstatus?.status" class="border-1 border-round border-gray-300 p-3" style="width: fit-content;">
                    <app-car-card2-status [carstatus]="data.carstatus" [withText]="true"></app-car-card2-status>
                    &nbsp;
                </div>
            </div>
            <div class="col-4 p-2 text-gray-500">
                <div *ngIf="getDriverName()" class="py-3">
                    <i class="pi pi-user mr-2"></i>{{getDriverName()}}
                </div>
            </div>
            <div class="col-4 p-2 text-gray-500">
                <div *ngIf="getLocation()" class="py-3">
                    <i class="pi pi-map-marker mr-2"></i>{{getLocation()}}
                </div>
            </div>

            <div class="col-4 p-4">
                <div><label>{{'cars.sensorRange'|translate}}</label></div>
                <div class="font-bold text-xl">{{getSensorValueN(IApiSensorName_KeyEnum.Mileage, ' км')}}</div>
                <div class="mt-3"><label>{{'cars.sensorMotohour'|translate}}</label></div>
                <div class="font-bold text-xl">{{getSensorValueN(IApiSensorName_KeyEnum.Engine_hours, ' ч.')}}</div>
                <div class="mt-3"><label>{{'cars.sensorTo'|translate}}</label></div>
                <div class="font-bold text-xl">{{getToTO()}}</div>
            </div>
            <div class="col-4 p-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleGroup'|translate}}</label></div>
                <p-chip *ngFor="let g of getCarGroup()" [label]="g.name??''" styleClass="p-chip-sm mr-2 mt-2 text-sm"></p-chip>
            </div>
            <div class="col-4 p-4 text-gray-500">
                <div><label class="font-bold text-gray-500">{{'cars.titleError'|translate}}</label></div>
                <div>
                    <table *ngIf="getSensorValueN(IApiSensorName_KeyEnum.Error_count)!='-'" class="mt-2 w-full">
                        <tr>
                            <td class="p-1 text-gray-500"><label>{{'common.count'|translate}}</label></td>
                            <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_count)}}</td>
                        </tr>
                        <tr>
                            <td class="p-1 text-gray-500"><label>SPN</label></td>
                            <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_spn)}}</td>
                        </tr>
                        <tr>
                            <td class="p-1 text-gray-500"><label>FMI</label></td>
                            <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_fmi)}}</td>
                        </tr>
                        <tr>
                            <td class="p-1 text-gray-500"><label>LAMP</label></td>
                            <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_lamp)}}</td>
                        </tr>   
                    </table>
                    <p-chip *ngIf="getSensorValueN(IApiSensorName_KeyEnum.Error_count)=='-'" label="Нет ошибок" styleClass="p-chip-sm mt-2 text-sm"></p-chip>   
                </div>
            </div>

            <div class="col-4 p-2">
                <div><label class="font-bold text-gray-500">{{'cars.titleAkb'|translate}}</label></div>
                <table class="mt-2 w-full">
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorBatPercent'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Battery_lvl, " %")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorBatVoltage'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Battery_voltage, " В")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorVoltage'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Power_voltage, " В")}}</td>
                    </tr>   
                </table>
            </div>
            <div class="col-4 p-2">
                <div><label class="font-bold text-gray-500">{{'cars.titleFuel'|translate}}</label></div>
                <table class="mt-2 w-full">
                    <tr>
                        <td class="p-1 text-gray-500"><label>Adblue</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Adblue, " %")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorFuel'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Fuel_lvl, " л")}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-4 p-2">
                <div><label class="font-bold text-gray-500">{{'cars.titleNet'|translate}}</label></div>
                <table class="mt-2 w-full">
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorGpsError'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Hdop, ' м')}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorGpsSat'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Sats)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.sensorGpsLevel'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Gsm_lvl, ' dBm')}}</td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- ДАТЧИКИ -->
        <div *ngIf="ui.selectButtonsValue?.index==3" class="grid grid-nogutter px-3">
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleSensorGeneral'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorTime'|translate}}</label></td>
                        <td class="p-1 white-space-nowrap w-10rem">{{data.carstatus?.last_check_time ? utils.isoDateTimeToDDMMYYHHMMSS(data.carstatus!.last_check_time!):'-'}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorLat'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Lat)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorLon'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Lon)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorSpeed'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Speed,' км/ч')}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorCourse'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Course)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleSensorAuth'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorDriver'|translate}}</label></td>
                        <td class="p-1 white-space-nowrap w-10rem">{{getDriverName()}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorAuthkey'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Auth_key)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorErrorSPN'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_spn)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorErrorFMI'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_fmi)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorErrorLamp'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_lamp)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorErrorAddress'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_address)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorErrorCount'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_count)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorErrorDescription'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Error_description)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleSensorNet'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorGsmLvl'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueN(IApiSensorName_KeyEnum.Gsm_lvl, " dBm")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorSats'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Sats)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorHdop'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Hdop, " м")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorNogps'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Nogps)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorTimepoweron'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueS(IApiSensorName_KeyEnum.Time_power_on)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorTimepoweroff'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueS(IApiSensorName_KeyEnum.Time_power_off)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleEngineWork'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorIgnition'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueB(IApiSensorName_KeyEnum.Ignition)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEngineWork'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Engine_work)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEngineRpm'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Engine_rpm)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEngineHours'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Engine_hours, " ч")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEngineRpmHigh'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Engine_rpm_high)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorParkingWork'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Parking_work)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 pt-3"><label class="text-gray-500 font-bold ">{{'cars.titleFuelsystem'|translate}}</label></td>
                        <td class="p-1"></td>
                    </tr>                    
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorFuelLvl'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Fuel_lvl, ' л')}}</td>
                    </tr>                    
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorFuelLvlFiltr'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Fuel_lvl_filtr, ' л')}}</td>
                    </tr>                
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorFuelLvl1'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Fuel_lvl1, ' л')}}</td>
                    </tr>                
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorFuelLvl2'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Fuel_lvl2, ' л')}}</td>
                    </tr>                
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorFuelRate'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Fuel_rate)}}</td>
                    </tr>                
                    <tr>
                        <td class="p-1 text-gray-500"><label>Adblue</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Adblue, " %")}}</td>
                    </tr>                
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleBattery'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorBatteryVoltage'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueN(IApiSensorName_KeyEnum.Battery_voltage, " В")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorPowerVoltage'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Power_voltage, " В")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorBatteryLvl'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Battery_lvl, " %")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorCharging'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Charging)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 pt-3"><label class="text-gray-500 font-bold">{{'cars.titleMechanical'|translate}}</label></td>
                        <td class="p-1"></td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorMileageCAN'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Mileage_can, " км")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorMileageGPS'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Mileage_gps, " км")}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorMileage'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Mileage, " км")}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleRegime'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorEngineLoad'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueB(IApiSensorName_KeyEnum.Engine_load)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEngineIdle'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Engine_idle)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEngineBlock'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Engine_block)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorPowerTakeoff'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Power_takeoff)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorPowerSave'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Power_save)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensoeCruise'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Cruise)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorParking'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Parking)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleSirena'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorAlarm'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueB(IApiSensorName_KeyEnum.Alarm)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorGuard'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Guard)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorWeight'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Weight)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorWeightCargo'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Weight_cargo)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorTrailerWightCargo'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Trailer_weight_cargo)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorDoorOpen'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Door_open)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorPanic'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Panic_button)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleWhell'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorNoWhell'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueN(IApiSensorName_KeyEnum.Wheel1)}}</td>
                    </tr>
                    <tr *ngFor="let n of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorPressureTire'|translate}} {{n}}</label></td>
                        <td class="p-1">{{getSensorValueN("pressure_tire"+n)}}</td>
                    </tr>
                    <tr *ngFor="let n of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorTempTire'|translate}} {{n}}</label></td>
                        <td class="p-1">{{getSensorValueN("temp_tire"+n)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleAdd'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorAddEquipWork'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueB(IApiSensorName_KeyEnum.Add_eqip_work)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorTerminal'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueS(IApiSensorName_KeyEnum.Terminal)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorValidator'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Validator)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorRotBrush'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Rot_brush)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorRefWork'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Ref_work)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorMixerRot'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Mixer_rotation)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorMixerUnload'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Mixer_unload)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorEgineHoursExt'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Engine_hours_ext, ' ч')}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 pt-3"><label class="text-gray-500 font-bold">{{'cars.titleOil'|translate}}</label></td>
                        <td class="p-1"></td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorHydroPressure'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Hydro_pressure)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorHydroTemp'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Hydro_temp)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorOilPressure'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Oil_pressure)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorOilTemp'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Oil_temp)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorOilLvl'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Oil_lvl)}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 bg-white sav3-border-radius p-4 mt-4">
                <div><label class="font-bold text-gray-500">{{'cars.titleAgregate'|translate}}</label></div>
                <table class="mt-2">
                    <tr>
                        <td class="p-1 text-gray-500 w-20rem"><label>{{'cars.lblSensorAngleAgg1'|translate}}</label></td>
                        <td class="p-1 w-10rem">{{getSensorValueN(IApiSensorName_KeyEnum.Angle_agg1)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorAngleAgg2'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Angle_agg2)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorAngleAgg3'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Angle_agg3)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorBucket'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Bucket)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorArm'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Arm)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorBlade'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueB(IApiSensorName_KeyEnum.Blade)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorAirPressure'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Air_pressure)}}</td>
                    </tr>
                    <tr>
                        <td class="p-1 text-gray-500"><label>{{'cars.lblSensorAirTemp'|translate}}</label></td>
                        <td class="p-1">{{getSensorValueN(IApiSensorName_KeyEnum.Air_temp)}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-12 p-2">&nbsp;</div>
    </div>
</p-dialog>

<app-car-command *ngIf="ui.sendForm.show"
    [carid]="ui.sendForm.carid"
    (onHide)="onSendForm_Hide()"
></app-car-command>