import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';

import packageJson from '../../package.json';
import { Subject } from 'rxjs/internal/Subject';
import { AuthService } from './auth/auth.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { PreloaderService } from './service/preloader.service';
import { BrowserStorageService } from './service/browserstorage.service';
import { filter } from 'rxjs';
import { AuthserverService } from './service.backend/authserver.service';
import { SignalRService } from './service.backend/signalR.service';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event']) onResize() { this.correctHeightMain() }

  public title = 'client-lk';
  public heightMain = '300px';
//  public hideMenu: boolean = false;
  destroy$ = new Subject<boolean>();

  
  constructor(
    private browserSettings: BrowserStorageService,
    private primengConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private messageService: MessageService,
    private preloaderService: PreloaderService,
    private authService: AuthService,
    private authServer: AuthserverService,
    private signalRService: SignalRService
  ) {
      translateService.onLangChange.subscribe(()=>
        this.translateService.get('primeng')
        .subscribe(res => this.primengConfig.setTranslation(res))
      );
      
      // this language will be used as a fallback when a translation isn't found in the current language
      translateService.setDefaultLang('ru');
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translateService.use('ru');

      authService.user$.pipe(takeUntil(this.destroy$),filter(x=>!!x)).subscribe(
        user=>{
          console.warn('New user:',user);
          if(user) {
            if(user.original_user?.settings?.language) {
              translateService.use(user.original_user?.settings?.language);
            }

            // загрузка настроек из браузера
            browserSettings.loadSettings();
            // загрузка preloader
            preloaderService.loadAll().subscribe({
              next: res=>{
                console.warn('Configuration loaded:', res);
              },
              error: err=>{
                console.error(err);
                this.messageService.add({severity: 'error', detail: err });
              }
            });
          }
        });
  }

  ngOnInit() {
    console.log(`${packageJson.name} v${packageJson.version} init`);
    console.log(`env.production:${environment.production}, env.dev:${environment.dev}`);
    console.log(`env.apiUrl:${environment.apiUrl}, env.authUrl:${environment.authApiUrl}`);
    console.log(`env.signalRUrl:${environment.signalRUrl}, env.carFake:${environment.carStatusFake}`);

    this.primengConfig.ripple = true;
    setTimeout(()=>this.correctHeightMain(),100);

    // перезапрос на обновление токена раз в 30 минут
    setInterval(()=>{
//      console.log('Check token');
      if(this.authService.isLoggedIn() && this.authService.isNeedRefreshToken() ) {
        this.authServer.refreshToken().subscribe({
          next: (res)=>{
            if(res.token && res.ok) {
              console.warn('Refresh token success');
              this.authService.refreshToken(res.token); 
            }
          },
          error: (err)=>console.error('Refresh token error', err)
        })
        
      }
    }, 1*60000);

    // SignalR
    this.authService.user$
    .subscribe({
      next: (authUser) => {
        this.signalRService.connectDiconnect(this.authService.getToken());
      }
    });

    //
    // PrimeNg confoguration
    //

    /* locale
    this.primengConfig.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      //translations
    });
    */

    /* zindex
      this.primengConfig.zIndex = {
        modal: 1100,    // dialog, sidebar
        overlay: 1000,  // dropdown, overlaypanel
        menu: 1000,     // overlay menus
        tooltip: 1100   // tooltip
      };
    */

    /* filter
      primengConfig.filterMatchModeOptions = {
        text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
        numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
        date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER]
      };
    */

  }

  ngOnDestroy(): void {
    this.destroy$.next(true); this.destroy$.complete(); this.destroy$.unsubscribe();   
  }

  correctHeightMain() {
    let h = window.innerHeight;
    let rect2=document.getElementById('mainWindow2')?.getBoundingClientRect();
//    console.log('innerHeight', h, rect1, rect2);
    h = Math.round(h - (rect2?.y??0)/*-4*/);
    this.heightMain = h+"px";
  }

}
