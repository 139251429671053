import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ListboxClickEvent } from 'primeng/listbox';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { CarService } from 'src/app/service.backend/car.service';
import { DriverService } from 'src/app/service.backend/driver.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-driver-edit',
  templateUrl: './driver-edit.component.html',
  styleUrls: ['./driver-edit.component.css']
})
export class DriverEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() cars = [] as INameCode[];

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiDriver>();
  @Output() onInsert = new EventEmitter<IApiDriver>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiDriver|null;
  destroy$ = new Subject<boolean>();

  options = {
  };

  carsAvailable: INameCode[] = [];
  carsNotSelected: INameCode[] = [];


  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'driver.add':'driver.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    family: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
    surname: new FormControl(null as string|null, [Validators.maxLength(100)]),
    phone: new FormControl(null as string|null, [Validators.maxLength(100)]),
    comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    any_car: new FormControl(null as boolean|null),
    carsAvailable: new FormControl(),
    carsNotSelected: new FormControl(),
  });

  constructor(
    private driverService: DriverService,
    private carService: CarService,
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.isNew() ? this.newRecord(): this.editRecord(); 

    // this.loading = true;   
    // forkJoin({
    //   options: this.preloaderService.ensureLoad$(this.destroy$)
    // })
    // .subscribe({
    //   next: (res) => {
    //     this.loading = false;
    //     this.isNew() ? this.newRecord(): this.editRecord(); 
    //   },
    //   error: err => {
    //     this.loading = false;
    //     this.error = err; 
    //     this.messageService.add({severity: 'error', detail: this.error??'' });
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    forkJoin({
      dr: this.driverService.get(this.id??'xxx'),
      //crs: this.carService.listNameCode(this.authService.user$.value?.customerid),
    })
    .subscribe({
      next: (res)=>{
        if(res.dr.ok) {
          console.log('res', res);
          this.item = res.dr.driver;
          this.carsAvailable = this.cars.filter(x => this.item?.cars && this.item.cars!.indexOf(x.code??'')>=0);
          console.log('carsAvailable', this.carsAvailable);
          this.carsNotSelected = this.cars.filter(x => !this.carsAvailable.find(y => y.code === x.code));
          console.log('carsNotSelected', this.carsNotSelected);
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.dr.errorMessage, res.dr.errorMessageForUser);
          this.error = res.dr.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    this.form.get('name')?.patchValue(null);
    this.form.get('surname')?.patchValue(null);
    this.form.get('family')?.patchValue(null);
    this.form.get('phone')?.patchValue(null);
    this.form.get('any_car')?.patchValue(null);
    this.form.get('comment')?.patchValue(null);

    this.carsNotSelected = [...this.cars];
  }

  carsAvailableClick(event: ListboxClickEvent) {
    this.carsAvailable = this.carsAvailable.filter(x => x.code !== event.value.code);
    this.carsNotSelected = [event.value, ...this.carsNotSelected];
  }
  
  carsNotSelectedClick(event: ListboxClickEvent) {
    this.carsNotSelected = this.carsNotSelected.filter(x => x.code !== event.value.code);
    this.carsAvailable = [event.value, ...this.carsAvailable];
  }


  _saveFormToObject(driver: IApiDriver) {
    driver.name = this.form.get('name')?.value;
    driver.surname = this.form.get('surname')?.value;
    driver.family = this.form.get('family')?.value;
    driver.phone = this.utils.stdTrimPhone(this.form.get('phone')?.value);
    driver.any_car = this.form.get('any_car')?.value;
    if (driver.any_car) {
      driver.cars = [];
    } else {
      driver.cars = this.carsAvailable.map(x => x.code!);
    }
    driver.comment = this.form.get('comment')?.value;
  }

  _loadFormFromObject(driver: IApiDriver|null) {
    this.form.get('name')?.patchValue(driver?.name??null);
    this.form.get('surname')?.patchValue(driver?.surname??null);
    this.form.get('family')?.patchValue(driver?.family??null);
    this.form.get('phone')?.patchValue( this.utils.stdPhone(driver?.phone??null));
    this.form.get('any_car')?.patchValue(driver?.any_car??null);
    this.form.get('comment')?.patchValue(driver?.comment??null);
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.driverService.insert(this.item)
      : this.driverService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.driver};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
