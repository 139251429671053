<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '30vw' }" 
    styleClass="shadow-5 sav3-dialog"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="l">{{'structure.userEdit.fieldLogin'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="l" id="l" type="text" pInputText class="w-full sav3-input p-inputtext-sm" [ngClass]="{'p-disabled':!isNew()}" >
                <p-message *ngIf="!validateRequired('l')" severity="error" [text]="'form.loginRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('l')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="p">{{'structure.userEdit.fieldPassword'|translate}}</label></div>
                <input formControlName="p" id="p" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateRequired('p')" severity="error" [text]="'form.passwordRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('p')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="p2">{{'structure.userEdit.fieldPassword2'|translate}}</label></div>
                <input formControlName="p2" id="p2" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateRequired('p2')" severity="error" [text]="'form.passwordRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('p2')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="name">{{'structure.userEdit.fieldName'|translate}}</label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="email">{{'structure.userEdit.fieldEmail'|translate}}</label></div>
                <input formControlName="email" id="email" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('email')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="phone">{{'structure.userEdit.fieldPhone'|translate}}</label></div>
                <input formControlName="phone" id="phone" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('phone')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="comment">{{'structure.userEdit.fieldComment'|translate}}</label></div>
                <input formControlName="comment" id="comment" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('comment')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div class="w-full sav3-checkbox">
                    <p-checkbox formControlName="fill_at_login" [label]="'structure.userEdit.fieldFillAtLogin'|translate" [binary]="true"></p-checkbox>
                </div>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="status">{{'structure.userEdit.fieldStatus'|translate}}</label></div>
                <p-dropdown formControlName="status" id="status" optionLabel="label" optionValue="code" [options]="options.status" class="sav3-input w-full p-inputtext-sm" styleClass="sav3-input w-full " appendTo="body" ></p-dropdown>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="options">{{'structure.userEdit.fieldOptions'|translate}}</label></div>
                <p-multiSelect 
                    id="options"
                    [options]="options.options" 
                    formControlName="options" 
                    optionLabel="label" 
                    optionValue="code"
                    class="w-full sav3-input p-inputtext-sm"
                    styleClass="w-full sav3-input"
                    [filter]="false"
                    appendTo="body"
                />
            </div>
            <div class="col-12">
                <div><label class="sav3-label" for="reportlistsid">{{'structure.userEdit.fieldReports'|translate}}</label></div>
                <p-multiSelect 
                    id="reportlistsid"
                    [options]="options.reports" 
                    formControlName="reportlistsid" 
                    optionLabel="name" 
                    optionValue="code"
                    class="w-full sav3-input p-inputtext-sm"
                    styleClass="w-full sav3-input"
                    [filter]="false"
                    appendTo="body"
                />
            </div>


        </div>
    </div>

    <ng-template pTemplate="footer" class="text-center">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading || disableSave"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary p-button-rounded">
        </p-button>
    </ng-template>
</p-dialog>

