import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiUser } from 'src/app/model.backend/user.model';
import { UserService } from 'src/app/service.backend/user.service';
import { PreloaderService } from 'src/app/service/preloader.service';

@Component({
  selector: 'app-user-firstlogin-edit',
  templateUrl: './user-firstlogin-edit.component.html',
  styleUrls: ['./user-firstlogin-edit.component.css']
})
export class UserFirstLoginEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() item!: {id: string, name: string|null, phone: string|null, email: string|null };

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<{id: string, name: string|null, phone: string|null, email: string|null }>();

  loading = false;
  saving = false;
  error = null as string|null;  
  destroy$ = new Subject<boolean>();

  getHeader = () => this.translate.instant('structure.userEdit.edit');

  getButtonOkLabel = () => this.translate.instant('form.save')
  getButtonOkIcon = () => 'pi-save'
  
  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.maxLength(255)]),
    email: new FormControl(null as string|null, [Validators.maxLength(255)]),
    phone: new FormControl(null as string|null, [Validators.maxLength(100)])
  });

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this._loadFormFromObject(this.item!);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  _saveFormToObject(item: {name: string|null, phone: string|null, email: string|null }) {
    item.name = this.form.get('name')?.value??null;
    item.email = this.form.get('email')?.value??null;
    item.phone = this.form.get('phone')?.value??null;
  }

  _loadFormFromObject(item: {name: string|null, phone: string|null, email: string|null }) {
    this.form.get('name')?.patchValue(item.name??null);
    this.form.get('email')?.patchValue(item.email??null);
    this.form.get('phone')?.patchValue(item.phone??null);
  }

  saveRecord() {
    if(this.form.invalid || !this.item?.id) {
      this.form.markAllAsTouched();
      return;
    }
   
    console.log('saving',this.item);
    this.saving = true;
    this._saveFormToObject(this.item);
    let user: IApiUser = {
      id: this.item.id,
      name: this.item.name,
      phone: this.item.phone,
      email: this.item.email
    }
    this.userService.updateFirstLogin(this.item.id??'xxx', user)
    .subscribe({
      next: (res) => {
        if(res.ok && res.user) {
          this.item = {
            id: res.user.id!,
            name: res.user.name??null,
            phone: res.user.phone??null,
            email: res.user.email??null
          };             
//          this.preloaderService.updateUser(res.user.customerid??'xxx', this.item!);
          this.onUpdate.emit(this.item!);
          this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          this.visible=false;
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
