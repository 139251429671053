<div class="bg-gray-200 p-2" [style]="{position: 'absolute', right: '30px', top: '80px', 'z-index': 1, 'border-radius': '15px'}" >
    <button (click)="op.toggle($event)" 
        icon="pi pi-cog" 
        class="p-button-lg px-2 pt-3 pb-0 p-button-secondary p-button-text block sav3-big-pi w-6rem" pButton >
        <div class="text-xs">{{'map.lblSetting'|translate}}</div>
    </button>
    <p-overlayPanel (onHide)="Settings_Hide()" #op>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_car_status" [binary]="true" inputId="show_car_status" class="mr-2"></p-checkbox>
            <label for="show_car_status">{{'map.lblShowStatus'|translate}}</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_cars_track" [binary]="true" inputId="show_cars_track" class="mr-2"></p-checkbox>
            <label for="show_cars_track">{{'map.lblShowTrack'|translate}}</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.follow_car" [binary]="true" inputId="follow_car" class="mr-2"></p-checkbox>
            <label for="follow_car">{{'map.lblInfocus'|translate}}</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.cluster_cars_on_map" [binary]="true" inputId="cluster_cars_on_map" class="mr-2"></p-checkbox>
            <label for="cluster_cars_on_map">{{'map.lblGrouping'|translate}}</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_geozones" [binary]="true" inputId="show_geozones" class="mr-2"></p-checkbox>
            <label for="show_geozones">{{'map.lblShowGeozone'|translate}}</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_azimuth" [binary]="true" inputId="show_azimuth" class="mr-2"></p-checkbox>
            <label for="show_azimuth">{{'map.lblShowDirection'|translate}}</label>
        </div>
        <div class="mb-1 mt-3 flex align-items-center">
            <small class="font-bold">{{'map.lblFormat'|translate}}</small>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.view_number" [binary]="true" inputId="view_number" class="mr-2"></p-checkbox>
            <label for="view_number">{{'map.lblShowNumber'|translate}}</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.view_brand" [binary]="true" inputId="view_brand" class="mr-2"></p-checkbox>
            <label for="view_brand">{{'map.lblShowModel'|translate}}</label>
        </div>
        <div class="mb-1 mt-3 flex align-items-center">
            <small class="font-bold">{{'map.lblBranch'|translate}}</small>
        </div>
        <div *ngFor="let branch of getBranches()" class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="ui.branches_model[branch.id!]" [binary]="true" [inputId]="'branch_'+branch.id" class="mr-2"></p-checkbox>
            <label [for]="'branch_'+branch.id">{{branch.name}}</label>
        </div>
    </p-overlayPanel>
</div>
<div id="map" class="map"></div>

<app-car-info *ngIf="ui.info.show" [carId]="ui.info.carid" (onHide)="ui.info.show=false">
</app-car-info>