<div class="px-1 py-2 flex align-items-center">
    <div>
        <h2 class="dh-page-caption mr-5 my-0">
            <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-question-circle"></p-avatar>
            {{'task.title'|translate}}
        </h2>
    </div>
    <p-button (click)="clickRecordAdd()" icon="pi pi-plus" [label]="'task.new'|translate" styleClass="dh-button-hover p-button-sm p-button-outlined p-button-secondary mr-1"></p-button>
</div>
<p-table [value]="data.records||[]" [rowHover]="true" styleClass="p-datatable">
    <ng-template pTemplate="header">
        <tr>
            <td colspan="3" style="height: 5px;">
                <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
            </td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <th>№</th>
            <th class="w-full">{{'task.task'|translate}}</th>
            <th class="white-space-nowrap">{{'task.state'|translate}}</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-index="rowIndex">
        <tr>
            <td>
                {{index+1}}.
            </td>
            <td>
                {{record.name}}
            </td>
            <td class="white-space-nowrap">
                <span *ngIf="record.is_active"><i class="pi pi-check"></i></span>
            </td>
            <td class="text-right white-space-nowrap">
                <p-button (click)="clickRecordEdit(record);$event.stopPropagation()" [pTooltip]="'form.edit'|translate" icon="pi pi-pencil" styleClass="p-button-sm mr-1 dh-onhover"></p-button>
                <p-button (click)="clickRecordDelete(record, $event);$event.stopPropagation()" [pTooltip]="'form.delete'|translate" icon="pi pi-times" styleClass="p-button-sm p-button-danger dh-onhover"></p-button>
            </td>
        </tr>
    </ng-template>
</p-table>
<app-norecords *ngIf="(data.records||[]).length==0 && !data.error"></app-norecords>


<app-task-edit *ngIf="dialog.edit.show"
    [id]="dialog.edit.id" 
    [viewMode]="dialog.edit.viewMode"
    (onHide)="onDialogEditHide()"
    (onUpdate)="onDialogEditUpdate($event)"
    (onInsert)="onDialogEditInsert($event)" 
>
</app-task-edit>

