import { IApiBaseRequest, IApiBaseResponse, IApiBaseTable } from "./_base.model";
import { IApiCustomer } from "./customer.model";
import { IApiTerminal } from "./terminal.model";

export enum CarActiveState {
    Active = 0,
    Blocking = 1,
    BlockingByBilling = 2
}

export interface IApiCar extends IApiBaseTable {   
    customerid?: string|null;
    pin?: string|null;
    brand?: string|null;
    vin?: string|null;
    number?: string|null;
    color?: string|null;
    year?: number|null;
    display_name?: string|null;
    comment?: string|null;
    comment_sp?: string|null;
    comment_other?: string|null;
    in_billing?: boolean|null;
    paid_before?: string|null;
    in_mobile_app?: boolean|null;

    motor_hour_value?: number|null;
    race_value?: number|null;
    active_state?: number|null;
    
    tariffid?: string|null;
//    car_groups?: string[]|null;
    iconid?: string|null;   
    type?: string|null;

    report_params?: IApiCarReportParams|null;
    terminals?: IApiTerminal[]|null;
    customers_access?: string[]|null;   
    customer?: IApiCustomer|null;
    template_name?: string|null;

    serverdbid?: number|null;
}

export interface IApiCarReportParams
{
    // расчет движения
    race_report?: IApiCarReportParams_RaceReport|null;
    // расчет моточасов
    motorhour_report?: IApiCarReportParams_MotorhourReport|null;
    // счетчик пробега
    race?: IApiCarReportParams_Race|null;
    fuel?: IApiCarReportParams_Fuel|null;
}

export interface IApiCarReportParams_RaceReport
{
    terminalid?: string|null;
    /// Выбранный метод расчета пробега:
    /// 0 - Датчик зажигания,
    /// 1 - Датчик пробега,
    /// 2 - Датчик скорости,
    /// 3 - По координатам
    race_report_type?: number|null;
    min_parking_time?: number|null;
    min_gps_speed?: number|null;
}

export interface IApiCarReportParams_MotorhourReport
{
    terminalid?: string|null;
    /// Выбранный метод расчета моточасов для данного ТС.
    /// 0 - Датчик зажигания,
    /// 1 - Датчик моточасов,
    /// 2 - Счетчик моточасов
    motorhour_report_type?: number|null;
}

export interface IApiCarReportParams_Race
{
    terminalid?: string|null;
    /// Выбранный метод  автоматического расчета пробега. (Счетчик пробега).
    /// 0 - Датчик пробега,
    /// 1 - Одометр,
    /// 2 - По координатам
    race_type?: number|null;
}


export interface IApiCarReportParams_Fuel
{
    // Выбор варианта для расчета потраченного топлива.
    // ДУТ - 0,
    // ДАРТ - 1
    fuel_consumption_type?: number|null;

    // 0 - без сглаживания
    // 1 - по N точкам
    smooth_type?: number|null;
    smooth_points?: number|null;

    // расчет заправок, сливов
    // 0 - линейный
    // 1 - по пробегу
    // 2 - по моточасам
    fuel_report_type?: number|null;
    // K
    fuel_report_decreasing?: number|null;
    // delta S
    fuel_report_interval?: number|null;


    // объем банка
    tank_capacity?: number|null;

    // мин. объем слива
    min_discharge_volume?: number|null;
    // мин объем заправки
    min_charge_volume?: number|null;
    // мин.разница заправка-слив (мин.)
    minutes_for_exclude?: number|null;
    // мин.разница заправка-слив (%)
    percents_for_exclude?: number|null;
    // задержка для доп датчиков
    sensor_delay?: number|null;
}


export interface IApiCarListResponse extends IApiBaseResponse {
    cars: IApiCar[]|null;
};

export interface IApiCarResponse extends IApiBaseResponse {
    car: IApiCar|null;
};

export interface IApiCarCustomerRequest extends IApiBaseRequest {
    customerId: string|null;
    pin?: string|null;
    action: string|null;        // 'changeowner', 'addaccess', 'removeaccess'
}
  
  
