<p-dialog [header]="'driver-trips.title'|translate" 
    [(visible)]="visible" 
    [style]="{ width: '60vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false"
    [modal]="true"
>  
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{'driver-trips.title'|translate}}</span>
    </ng-template> 

    <app-progressbar [loading]="data.loading"></app-progressbar>
    <app-errorbar *ngIf="data.error" [showError]="data.error">{{data.error}}</app-errorbar>

    <div class="flex align-items-center">
        <div class="ml-auto py-2">
            <p-button 
                (onClick)="clickAddTrip()" 
                icon="pi pi-plus" 
                [label]="'driver-trips.add'|translate" 
                styleClass="p-button p-button-rounded p-button-secondary" 
                [ngClass]="{'p-disabled':!canEditTrips() || ui.disabledEdit || inRowEditing() }"
            ></p-button>
        </div>
    </div>
    <p-table #Table [value]="data.items" [tableStyle]="{ 'min-width': '100%' }" styleClass="p-datatable p-datatable-sm" dataKey="id" editMode="row">
        <ng-template pTemplate="header">
            <tr>
                <th class="w-15rem">{{ 'driver-trips.from'|translate }}</th>
                <th class="w-15rem">{{ 'driver-trips.to'|translate }}</th>
                <th>{{ 'driver-trips.car'|translate }}</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-trip let-editing="editing" let-ri="index">
            <tr [pEditableRow]="trip">
                <td [vAlign]="'top'">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-calendar [(ngModel)]="data.dataFrom" [showTime]="true" [showSeconds]="false" class="p-inputtext-sm sav3-input w-full" appendTo="body" />
                            <p-message *ngIf="ui.rowError && data.dataFrom==null" severity="error" [text]="'form.required'|translate"></p-message>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ utils.UTCstringToLocalYYYYMMDD_HHMM(trip.from) }}
                        </ng-template>
                    </p-cellEditor>   
                </td>
                <td [vAlign]="'top'">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-calendar [(ngModel)]="data.dataTo" [showTime]="true" [showSeconds]="false" class="p-inputtext-sm sav3-input w-full" appendTo="body" dateFormat="" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ utils.UTCstringToLocalYYYYMMDD_HHMM(trip.to) }}
                        </ng-template>
                    </p-cellEditor>   
                </td>
                <td [vAlign]="'top'">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown inputId="default_carid" 
                                [(ngModel)]="trip.carid" 
                                [options]="options.cars" 
                                styleClass="sav3-input p-inputtext-sm w-full"
                                class="p-inputtext-sm"
                                optionLabel="name"
                                optionValue="code"
                                [filter]="true"
                                appendTo="body"
                            ></p-dropdown>
                            <p-message *ngIf="ui.rowError && trip.carid==null" severity="error" [text]="'form.required'|translate"></p-message>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ getCarName(trip.carid) }}
                        </ng-template>
                    </p-cellEditor>   
                </td>
                <td class="white-space-nowrap py-0">
                    <div class="flex align-items-center justify-content-end">
                        <button *ngIf="!editing" (click)="onRowEditInit(trip)" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-sm dh-onhover"></button>
                        <button *ngIf="!editing" (click)="clickTripDelete(trip,$event)" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger p-button-sm dh-onhover"></button>
                        <button *ngIf="editing" (click)="onRowEditSave(trip)" [loading]="data.saving" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" class="p-button-rounded p-button-text p-button-sm p-button-success mr-1"></button>
                        <button *ngIf="editing" (click)="onRowEditCancel(trip, ri)" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-sm p-button-danger"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <app-norecords *ngIf="!data.error && !data.loading && (data.items||[]).length==0"></app-norecords>
    
    <div class="p-2">&nbsp;</div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-times" [label]="'form.close'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
    </ng-template>

</p-dialog>

