<div id="mainLogin" class="flex -min-h-screen">
    <div class="-hidden -md:block -w-6 -bg-no-repeat bg-cover flex-grow-0 flex-shrink-0" 
        style="width: 450px; background-image: url('assets/images/blocks/signin.jpg');">
    </div>
    <div class="surface-section p-8 align-self-center" style="width: 650px;">
         <div class="mb-5">
            <!--
             <img src="../../../assets/images/logo.png" alt="Image" height="30" class="mb-5">
            -->
             <div class="text-900 text-3xl font-medium mb-2">{{'formLogin.textWelcome'|translate}}</div>
<!--
             <span class="text-600 font-medium mr-2">Don't have an account?</span>
             <a class="font-medium no-underline text-blue-500 cursor-pointer">Create today!</a>
-->
         </div>

         <div [formGroup]="form" class="mb-5">
            <div *ngIf="ui.step==1">
                <label for="login" class="text-sm">{{'formLogin.login'|translate}}</label>
                <input formControlName="login" id="login" type="text" placeholder="{{'formLogin.loginPlaceholder'|translate}}" pInputText class="w-full mb-3">
    
                <label for="password" class="text-sm">{{'formLogin.password'|translate}}</label>
                <input  formControlName="password" id="password" type="password" placeholder="{{'formLogin.passwordPlaceholder'|translate}}" pInputText class="w-full mb-3">   
            </div>

            <div *ngIf="ui.step==2">
                <!--
                <label for="customer" class="text-sm">{{'formLogin.customer'|translate}}</label>
                <p-dropdown formControlName="customer" inputId="customer" 
                    [options]="ui.customers" 
                    optionLabel="name" 
                    optionValue="code"
                    [filter]="true"
                    styleClass="w-full mb-3 p-2"
                    appendTo="body"
                ></p-dropdown>
            -->
                <label for="customer" class="text-sm">{{'formLogin.customer'|translate}}</label>
                <p-tree (onNodeSelect)="onNodeSelect($event)" class="w-full h-2rem" [value]="ui.treeNode" [filter]="true" [selectionMode]="'single'">

                </p-tree>

            </div>

<!--
             <div class="flex align-items-center justify-content-between mb-6">
                 <div class="flex align-items-center">
                     <p-checkbox id="rememberme" [binary]="true" styleClass="mr-2"></p-checkbox>
                     <label for="rememberme2">Remember me</label>
                 </div>
                 <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
             </div>
-->
 
             <button (click)="onLogin()" 
                pButton pRipple label="{{'formLogin.buttonEnter'|translate}}" 
                [icon]="(loading?'pi pi-spin pi-spinner':'pi pi-user')" 
                class="w-full p-3"
                [disabled]="!canLogin() || loading"
                [ngClass]="{'p-disabled':loading || !canLogin()}"
             ></button>
             <p-messages *ngIf="errorText!=''" 
                [value]="[{ severity: 'error', detail: errorText }]" 
                [enableService]="false" 
                [closable]="false">
             </p-messages>
          </div>
    </div>
</div>

<app-user-firstlogin-edit *ngIf="ui.editFIO.show"
    [item]="ui.editFIO.item"
    (onHide)="onEditFIO_hide()"
    (onUpdate)="onEditFIO_updated($event)"
></app-user-firstlogin-edit>