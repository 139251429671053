<div id="mainmenu" class="surface-overlay flex align-items-center justify-content-between static" style="height: 60px;">
    <!--
    <a pRipple class="cursor-pointer block lg:hidden text-700 ml-auto" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
        <i class="pi pi-bars text-4xl"></i>
    </a>

        class="z-2 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 shadow-2 lg:shadow-none">
    -->
    <div class="align-items-center flex-grow-1 flex shadow-none -absolute" style="background-color: #d9d9d9;">
        <div class="flex align-items-center sav3-topMenu-left">
            <div>
                <a routerLink="/" pRipple class="hidden lg:block">
                    <img src="assets/images/logo2.png" alt="Image" height="30" class="mr-0 lg:mr-6">
                </a>        
            </div>
            <div class="ml-auto">
                <div *ngIf="show_time!=''" [innerHTML]="show_time" class="sav3-topMenu-left-time"></div>       
            </div>
        </div>
        <div *ngIf="isLoggedIn()" class="sav3-topMenu-center flex align-items-center">
            <div>
                <p-selectButton (onOptionClick)="onTopSelectButtonClick($event)" 
                    [(ngModel)]="ui.topSelectButtonValue" 
                    [options]="ui.topSelectButtonOptions" 
                    optionLabel="label" 
                    class="sav3-selectButton-top">
                </p-selectButton>
            </div>
        </div>
        <div class="ml-auto">
        </div>
        <div class="sav3-topMenu-right flex align-items-center">
            <!-- удалить после переноса -->
            <!--
            <div *ngIf="isLoggedIn()" class="ml-2">
                <a pRipple class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-file mr-2 pibig"></i>
                    <span class="hidden md:inline">{{'masterMenu.report'|translate}}</span>
                    <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="masterMenuReports" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-25rem cursor-pointer">        
                    <li *ngFor="let r of (isAllow(IApiUser_Rights.reportShare)? (getReportList$()|async):[])">
                        <a routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-file mr-2"></i>
                            <span class="font-medium">{{r.name}}</span>
                        </a>
                    </li>    
                    <li class="border-top-1 surface-border"></li>
                    <li>
                        <a routerLink="/reportseditor" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-file-edit mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.reportseditor'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li *ngIf="isAllow(IApiUser_Rights.taskAndNoticeManage)">
                        <a routerLink="/tasks" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-clock mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.tasks'|translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        -->

            <div *ngIf="isLoggedIn()" class="ml-2">
                <a pRipple class="flex p-2 lg:mr-3 align-items-center chover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-bars mr-2 pibig"></i>
                    <i class="pi pi-angle-down ml-2"></i>
                </a> 
                <ul id="masterMenuOthers" style="z-index: 1105;" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-20rem cursor-pointer">
                    <!--
                    <li *ngIf="isAllow(IApiUser_Rights.taskAndNoticeManage)">
                        <a routerLink="/notices" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-bolt mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.notification'|translate}}</span>
                        </a>
                    </li>
                -->
                    <li>
                        <a routerLink="/reportevent" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-history mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.carevent'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li *ngIf="isAllow(IApiUser_Rights.groupManage)">
                        <a routerLink="/cargroups" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-bookmark mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.groups'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.carManage)">
                        <a routerLink="/cars" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-car mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.cars'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.carManage)">
                        <a routerLink="/objects" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-home mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.objects'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li *ngIf="isAllow(IApiUser_Rights.chartererManage)">
                        <a routerLink="/charterers" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-building mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.charterers'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.driverManage)">
                        <a routerLink="/drivers" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-id-card mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.drivers'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.driverManage)">
                        <a routerLink="/driver-trips" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-id-card mr-2"></i>
                            <span class="font-medium">Поездки водителей</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/users" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-users mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.users'|translate}}</span>
                        </a>
                    </li>
                    <!--
                    <li *ngIf="isAllow(IApiUser_Rights.reportFinance)" class="border-top-1 surface-border">
                        <a routerLink="/finances" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-money-bill mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.reportFinance'|translate}}</span>
                        </a>
                    </li>
                    -->
                </ul>

            </div>
            <!-- удалить после переноса -->




            <div *ngIf="isLoggedIn()" class="ml-2">
                <a *ngIf="(getMessageCount$()|async)!=0" routerLink="/messages" id="supportIcon" pRipple 
                    pBadge severity="danger" [value]="(getMessageCount$()|async)!.toString()"
                    class="flex align-items-center text-900 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-envelope pibig"></i>
                </a>
                <a *ngIf="(getMessageCount$()|async)==0" routerLink="/messages" id="supportIcon" pRipple 
                    class="p-2 flex align-items-center text-900 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-envelope pibig"></i>
                </a>    
            </div>
            <div *ngIf="isLoggedIn()" class="ml-2">
                <a *ngIf="(getRequestSuppportCount$()|async)!=0" routerLink="/requestsupport" id="supportIcon" pRipple 
                    pBadge severity="danger" [value]="(getRequestSuppportCount$()|async)!.toString()"
                    class="flex p-2 align-items-center text-900 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-question-circle pibig"></i>
                </a>
                <a *ngIf="(getRequestSuppportCount$()|async)==0" routerLink="/requestsupport" id="supportIcon" pRipple 
                    class="flex p-2 align-items-center text-900 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-question-circle pibig"></i>
                </a>    
            </div>
            <div *ngIf="isLoggedIn()" class="ml-2">
                <a pRipple class="flex p-2 align-items-center hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-user mr-2 pibig"></i>
                    <span class="white-space-nowrap" [innerHTML]="getUserLoginName()"></span>
                    <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="tm_prof_02" style="right: 0px;z-index: 1105;" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-20rem cursor-pointer -right-100 -top-0">
                    <li style="height: 60px;">
                        <div class="flex align-items-center mx-2 my-4">
                            <div>
                                <p-avatar icon="pi pi-user" size="large" shape="circle"></p-avatar>
                            </div>
                            <div class="pl-4">
                                <div class="font-bold">{{getUserName()}}</div>
                                <div class="text-sm">{{getUserRole()}}</div>
                            </div>
                        </div>
                    </li>
                    <li style="height: 60px;">
                        <div class="flex align-items-center sav3-border-radius border-gray-200 bg-gray-200 mx-2 px-4">
                            <h4>{{'masterMenu.profileMenus.balance'|translate}}: 28 000 руб.</h4>
                        </div>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.taskManage)">
                        <a routerLink="/tasks" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-clock mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.tasks'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.notifyManage)">
                        <a routerLink="/notices" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-bolt mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.notification'|translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-circle mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.retranslators'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.reportFinance)">
                        <a routerLink="/finances" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-money-bill mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.reportFinance'|translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/structure" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-circle mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.companyStructure'|translate}}</span>
                        </a>
                    </li>


                    <li class="border-top-1 surface-border"></li>
                    <li>
                        <a (click)="clickProfile()" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-user mr-2"></i>
                            <span class="font-medium">{{'masterMenu.profileMenus.profile'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li class="relative">
                        <a (click)="clickLogout();setClass('tm_prof_02','hidden');" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-sign-out mr-2"></i>
                            <span class="font-medium">{{'masterMenu.profileMenus.exit'|translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
            
            <div class="ml-2">
                <a pRipple class="flex p-2 align-items-center hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true" [hideOnEscape]="true">
                    <span class="font-medium">{{getLanguage()}}</span>
                    <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="tm_lang_02" style="right: 0px;z-index: 1105;" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-10rem cursor-pointer -left-100 -top-0">
                    <li>
                        <a (click)="setLanguage('ru');setClass('tm_lang_02','hidden');" pRipple class="flex p-3 align-items-center hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <span class="font-medium">Русский</span>
                        </a>
                    </li>
                    <li class="relative">
                        <a (click)="setLanguage('en');setClass('tm_lang_02','hidden');" pRipple class="flex p-3 align-items-center hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <span class="font-medium">English</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="ml-2">
                <span class="text-sm" style="color: #9b9b9b">v{{version}}</span>
            </div>
        </div>





        <!--
        <ul *ngIf="isLoggedIn()" class="list-none p-0 m-0 flex align-items-center select-none flex-row">
            <li>
                <a routerLink="/map" routerLinkActive="active" pRipple class="flex p-2 md:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-map mr-2 pibig"></i>
                    <span class="hidden md:inline">{{'masterMenu.map'|translate}}</span>
                </a>
            </li>
            <li><span class="md:mr-3 text-gray-300">|</span></li>
            <li>
                <a pRipple class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-file mr-2 pibig"></i>
                    <span class="hidden md:inline">{{'masterMenu.report'|translate}}</span>
                    <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="masterMenuReports" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-25rem cursor-pointer">        
                    <li>
                        <a routerLink="/reporttrack" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-play mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.playtrack'|translate}}</span>
                        </a>
                    </li>
                    <li *ngFor="let r of (isAllow(IApiUser_Rights.reportShare)? (getReportList$()|async):[])">
                        <a routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-file mr-2"></i>
                            <span class="font-medium">{{r.name}}</span>
                        </a>
                    </li>    

                    <li>
                        <a routerLink="/reportdetail" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-circle mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.detail'|translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/reportfuel" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-circle mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.fuelreport'|translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/reportspec" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-circle mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.specreport'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li>
                        <a routerLink="/reportseditor" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-file-edit mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.reportseditor'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li *ngIf="isAllow(IApiUser_Rights.taskAndNoticeManage)">
                        <a routerLink="/tasks" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-clock mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.tasks'|translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li><span class="md:mr-3 text-gray-300">|</span></li>
            <li>
                <a pRipple class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-bars mr-2 pibig"></i>
                    <span class="hidden md:inline">{{'masterMenu.others'|translate}}</span>
                    <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="masterMenuOthers" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-20rem cursor-pointer">
                    <li *ngIf="isAllow(IApiUser_Rights.taskAndNoticeManage)">
                        <a routerLink="/notices" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-bolt mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.notification'|translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/reportevent" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-history mr-2"></i>
                            <span class="font-medium">{{'masterMenu.reportMenu.carevent'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li *ngIf="isAllow(IApiUser_Rights.groupManage)">
                        <a routerLink="/cargroups" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-bookmark mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.groups'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.carManage)">
                        <a routerLink="/cars" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-car mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.cars'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.carManage)">
                        <a routerLink="/objects" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-home mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.objects'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border"></li>
                    <li *ngIf="isAllow(IApiUser_Rights.chartererManage)">
                        <a routerLink="/charterers" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-building mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.charterers'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.driverManage)">
                        <a routerLink="/drivers" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-id-card mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.drivers'|translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="isAllow(IApiUser_Rights.driverManage)">
                        <a routerLink="/driver-trips" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-id-card mr-2"></i>
                            <span class="font-medium">Поездки водителей</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/users" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-users mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.users'|translate}}</span>
                        </a>
                    </li>
                    <li class="border-top-1 surface-border">
                        <a  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-whatsapp mr-2"></i>
                            <span class="font-medium">{{'masterMenu.othersMenu.support'|translate}}</span>
                        </a>
                    </li>
                <li *ngIf="isAllow(IApiUser_Rights.reportFinance)" class="border-top-1 surface-border">
                    <a routerLink="/finances" routerLinkActive="active" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                        <i class="pi pi-money-bill mr-2"></i>
                        <span class="font-medium">{{'masterMenu.othersMenu.reportFinance'|translate}}</span>
                    </a>
                </li>
                <li class="border-top-1 surface-border">
                    <a routerLink="/settings" routerLinkActive="active" pRipple class="p-disabled flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                        <i class="pi pi-cog mr-2"></i>
                        <span class="font-medium">{{'masterMenu.othersMenu.settings'|translate}}</span>
                    </a>
                </li>
                </ul>
            </li>
            <li *ngIf="isLoggedIn()">
                <a (click)="clickRelay($event)" pRipple class="flex p-2 md:mr-3 align-items-center text-red-600 hover:text-900 hover:surface-100 font-medium border-round border-red-600 border-1 cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-exclamation-triangle mx-2 pibig text-red-600"></i>
                    <span class="hidden md:inline">{{'masterMenu.needRelay'|translate}}</span>
                </a>
            </li>
        </ul>
        -->

        <!--
        <ul *ngIf="!isLoggedIn()" class="list-none p-0 m-0 flex lg:align-items-center select-none flex-row">
        </ul>        
        <ul class="-ml-auto list-none p-0 m-0 flex align-items-center select-none flex-row">
            <li *ngIf="isLoggedIn() && show_time!=''" class="relative pr-5">
                <div class="border-1 border-round border-gray-500 py-2 px-3 text-sm" [innerHTML]="show_time"></div>
            </li>
            -->
            <!--
            <li class="relative">
                <a *ngIf="isLoggedIn() && (getMessageCount$()|async)!=0" routerLink="/messages" id="supportIcon" pRipple 
                    pBadge severity="danger" [value]="(getMessageCount$()|async)!.toString()"
                    class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-envelope mr-2 pibig"></i>
                </a>
                <a *ngIf="isLoggedIn() && (getMessageCount$()|async)==0" routerLink="/messages" id="supportIcon" pRipple 
                    class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-envelope mr-2 pibig"></i>
                </a>            
            </li>
            <li class="relative">
                <a *ngIf="isLoggedIn() && (getRequestSuppportCount$()|async)!=0" routerLink="/requestsupport" id="supportIcon" pRipple 
                    pBadge severity="danger" [value]="(getRequestSuppportCount$()|async)!.toString()"
                    class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-whatsapp mr-2 pibig"></i>
                </a>
                <a *ngIf="isLoggedIn() && (getRequestSuppportCount$()|async)==0" routerLink="/requestsupport" id="supportIcon" pRipple 
                    class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-whatsapp mr-2 pibig"></i>
                </a>            
            </li>
            <li class="relative">
                <a *ngIf="!isLoggedIn()" routerLink="/login" pRipple class="p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150">
                    <i class="pi pi-sign-in mr-2 pibig"></i>
                    <span class="font-medium">{{'masterMenu.profileMenus.login'|translate}}</span>
                </a>
                <a  *ngIf="isLoggedIn()" pRipple class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-user mr-2 pibig"></i>
                    <span class="font-medium hidden md:inline">{{getUserName()}}</span>
                      <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="tm_prof_01" style="right: 0px;" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-15rem cursor-pointer -right-100 -top-0">
                    <li>
                        <a (click)="clickProfile()" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-user mr-2"></i>
                            <span class="font-medium">{{'masterMenu.profileMenus.profile'|translate}}</span>
                        </a>
                    </li>
                    <li class="relative">
                        <a (click)="clickLogout();setClass('tm_prof_01','hidden');" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <i class="pi pi-sign-out mr-2"></i>
                            <span class="font-medium">{{'masterMenu.profileMenus.exit'|translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li><span class="md:mr-3 text-gray-300">|</span></li>
            <li class="relative">
                <a pRipple class="flex p-2 lg:mr-3 align-items-center text-600 hover:text-900 hover:surface-100 border-round transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true" [hideOnEscape]="true">
                    <span class="font-medium">{{getLanguage()}}</span>
                    <i class="pi pi-angle-down ml-2"></i>
                </a>
                <ul id="tm_lang_01" style="right: 0px;" class="list-none py-0 m-0 px-0 border-round shadow-2 border-1 border-50 absolute surface-overlay hidden origin-top w-full w-10rem cursor-pointer -left-100 -top-0">
                    <li>
                        <a (click)="setLanguage('ru');setClass('tm_lang_01','hidden');" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <span class="font-medium">Русский</span>
                        </a>
                    </li>
                    <li class="relative">
                        <a (click)="setLanguage('en');setClass('tm_lang_01','hidden');" pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">
                            <span class="font-medium">English</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="relative">
                <span class="text-sm text-gray-400">v{{version}}</span>
            </li>
            -->


            <!--

            <li>
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                    <span class="block lg:hidden font-medium">Inbox</span>
                </a>
            </li>
            <li>
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" pBadge severity="danger"></i>
                    <span class="block lg:hidden font-medium">Notifications</span>
                </a>
            </li>
            <li class="border-top-1 surface-border lg:border-top-none">
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <img src="assets/images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                    <div class="block lg:hidden">
                        <div class="text-900 font-medium">Josephine Lillard</div>
                        <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                    </div>
                </a>
            </li>

        </ul>
                        -->
    </div>
</div>

<app-profile-edit2 *ngIf="ui.profile.show"
    (onHide)="ui.profile.show=false"
    (onUpdate)="onProfile_Update($event)"
></app-profile-edit2>