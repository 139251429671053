<div class="px-1 py-2 flex align-items-center">
    <div>
        <h2 class="dh-page-caption mr-5 my-0">
            <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-building"></p-avatar>
            {{'structure.title'|translate}}
        </h2>
    </div>
    <!--
    <div>
        <p-button (click)="clickRecordAdd()" icon="pi pi-plus" [label]="'stucture.add'|translate" styleClass="dh-button-hover p-button-sm p-button-outlined p-button-secondary mr-1"></p-button>
    </div>
    <div class="ml-auto">
        <p-splitButton [label]="'charterers.export'|translate" icon="pi pi-file-export" appendTo="body" [model]="options.export" styleClass="p-button-sm p-button-secondary"></p-splitButton>
        <p-button (click)="clickImport()" [label]="'driver.import'|translate" icon="pi pi-file-import" styleClass="p-button-sm p-button-secondary ml-1"></p-button>
    </div>
    -->
</div>
<app-progressbar [loading]="data.loading"></app-progressbar>
<p-messages *ngIf="data.error" [value]="[{severity:'error', detail:data.error}]" [closable]="true" [enableService]="false"></p-messages>
<div *ngIf="!data.loading" class="grid" >
    <div class="col-6">
        <div class="flex align-items-center my-2">
            <div class="pl-4">
                <h3 class="my-0">{{'structure.clients'|translate}}</h3>
            </div>
            <div class="ml-auto">
                <p-button (onClick)="clickDistributorAdd()" icon="pi pi-file" styleClass="p-button-rounded" [disabled]="!canDistributorAdd()"/>
                <p-button (onClick)="clickCompanyAdd()" icon="pi pi-plus" styleClass="p-button-rounded ml-1" [disabled]="!canCompanyChange() || !isClientSelected() || isClientSelectedEndCustomer()"/>
                <p-button (onClick)="clickCompanyEdit()" icon="pi pi-pencil" styleClass="p-button-rounded ml-1" [disabled]="!canCompanyChange() || !isClientSelected()"/>
                <p-button (onClick)="clickCompanyDelete($event)" icon="pi pi-trash" styleClass="p-button-rounded ml-1 bg-red-500 border-red-500" [disabled]="!canCompanyChange() || !isClientSelected() || isClientSelectedHasChildren()" />
            </div>
        </div>
        <div id="innerWindow" style="height: 0px;"></div>
        <p-tree 
            class="w-full sav3-tree"
            [value]="data.nodes" 
            selectionMode="single"
            [(selection)]="data.clientSelected"
            [filter]="true" 
            [filterPlaceholder]="'common.find'|translate"
            [style]="{'height':heightWindow}"
            (onNodeSelect)="onCompanyNodeSelect($event)"
        >
        </p-tree>
        <app-norecords *ngIf="(data.records||[]).length==0 && !data.error"></app-norecords>   
    </div>
    <div class="col-3">
        <div class="flex align-items-center my-2">
            <div class="pl-4">
                <h3 class="my-0">{{'structure.users'|translate}}</h3>
                <small>{{getClientSelectedName()}}</small>
            </div>
            <div class="ml-auto">
                <p-button (onClick)="clickUserAdd()" icon="pi pi-plus" styleClass="p-button-rounded ml-1" [disabled]="!canUserChange() || !isClientSelected()"/>
                <p-button (onClick)="clickUserEdit()" icon="pi pi-pencil" styleClass="p-button-rounded ml-1" [disabled]="!canUserChange() || !isUserSelected()"/>
                <p-button (onClick)="clickUserDelete($event)" icon="pi pi-trash" styleClass="p-button-rounded ml-1 bg-red-500 border-red-500" [disabled]="!canUserChange() || !isUserSelected()" />
            </div>
        </div>
        <p-tree 
            class="w-full sav3-tree"
            [value]="data.userNodes"
            selectionMode="single"
            [(selection)]="data.userSelected"
            [filter]="true" 
            [filterPlaceholder]="'common.find'|translate"
            [style]="{'height':heightWindow}"
            (onNodeSelect)="onUserNodeSelect($event)"
        >
        </p-tree>
    </div>
    <div class="col-3">
        <div class="flex align-items-center my-2">
            <div class="pl-4">
                <h3 class="my-0">{{'structure.cargroupsuser'|translate}}</h3>
                <small>{{getUserSelectedName()}}</small>
            </div>
            <div class="ml-auto">
                <p-button (onClick)="clickParkAdd()" icon="pi pi-plus" styleClass="p-button-rounded ml-1" [disabled]="!canUserChange() || !isUserSelected()"/>
                <p-button (onClick)="clickParkEdit()" icon="pi pi-pencil" styleClass="p-button-rounded ml-1" [disabled]="!canUserChange() || !isParkSelected()"/>
                <p-button (onClick)="clickParkDelete($event)" icon="pi pi-trash" styleClass="p-button-rounded ml-1 bg-red-500 border-red-500" [hidden]="!canUserChange() || !isParkSelected()" />
            </div>
        </div>
        <p-tree 
            class="w-full sav3-tree"
            [value]="data.parkNodes"
            selectionMode="single"
            [(selection)]="data.parkSelected"
            [filter]="true" 
            [filterPlaceholder]="'common.find'|translate"
            [style]="{'height':heightWindow}"
        >
        </p-tree>
    </div>

</div>


<app-structure-edit *ngIf="dialog.companyEdit.show"
    [id]="dialog.companyEdit.id"
    [parent]="dialog.companyEdit.parent"
    (onHide)="onDialogCompanyEditHide()"
    (onUpdate)="onDialogCompanyEditUpdate($event)"
    (onInsert)="onDialogCompanyEditInsert($event)" 
>
</app-structure-edit>


<app-structure-user-edit *ngIf="dialog.userEdit.show"
    [id]="dialog.userEdit.id"
    [customerid]="dialog.userEdit.customerid"
    (onHide)="onDialogUserEditHide()"
    (onUpdate)="onDialogUserEditUpdate($event)"
    (onInsert)="onDialogUserEditInsert($event)" 
>
</app-structure-user-edit>

<app-structure-park-edit *ngIf="dialog.parkEdit.show"
    [id]="dialog.parkEdit.id"
    [userid]="dialog.parkEdit.userid"
    [customerid]="dialog.parkEdit.customerid"
    (onHide)="onDialogParkEditHide()"
    (onUpdate)="onDialogParkEditUpdate($event)"
    (onInsert)="onDialogParkEditInsert($event)"
>
</app-structure-park-edit>