import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common'

@Component({
  selector: 'app-pagessys-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.css']
})
export class PagesSysPage404Component implements OnInit {

  constructor(private location: Location) { 
  }

  ngOnInit(): void {
      
  }

  backClicked() {
    this.location.back();
  }

}
