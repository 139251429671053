import { Injectable } from "@angular/core";
import { SystemService } from "../service.backend/system.service";
import { IApiReferer } from "../model.backend/referer.model";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { retry } from "rxjs/internal/operators/retry";
import { tap } from "rxjs/internal/operators/tap";
import { IApiClientLoadAllResponse } from "../model.backend/system.model";
import { of } from "rxjs/internal/observable/of";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import { filter } from "rxjs/internal/operators/filter";
import { take } from "rxjs/internal/operators/take";
import { catchError } from "rxjs/internal/operators/catchError";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { IApiCustomer, IApiCustomer_CustomerTypeEnum } from "../model.backend/customer.model";
import { IApiCar } from "../model.backend/car.model";
import { AuthService } from "../auth/auth.service";
import { IApiCarGroup } from "../model.backend/cargroup.model";
import { IApiSensorName } from "../model.backend/sensor-name.model";
import { IApiUser, IApiUser_Rights } from "../model.backend/user.model";
import { IApiReportList } from "../model.backend/report-list.model";
import { IApiIconStore } from "../model.backend/icon-store.model";
import { IApiGeozone } from "../model.backend/geozone.model";
import { INameCode } from "./utils.service";
import { TranslateService } from "@ngx-translate/core";
import { IApiPaidService } from "../model.backend/paid-service.model";
import { IApiCarGroupUser } from "../model.backend/cargroupuser.model";
import { IApiDriver } from "../model.backend/driver.model";
import { IApiCarStatus } from "../model.backend/carstatus.model";
import { IApiDriverTrip } from "../model.backend/driver-trip.model";
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class PreloaderService {
    public loaded$ = new BehaviorSubject<boolean>(false);
    public customer$ = new BehaviorSubject<IApiCustomer|null>(null);
    private alreadyLoaded: IApiClientLoadAllResponse|null = null;
    //
    // Загруженные данные
    //
    public referers$ = new BehaviorSubject<IApiReferer[]>([]);
    // все датчики
    public sensorNames$ = new BehaviorSubject<IApiSensorName[]>([]);
    // платные услуги
    public paidService$ = new BehaviorSubject<IApiPaidService[]>([]);
    // все отчеты
    public reportLists$ = new BehaviorSubject<IApiReportList[]>([]);    
    // доступные клиенту отчеты
    public availableReportLists$ = new BehaviorSubject<IApiReportList[]>([]);    
    // все иконки
    public iconStores$ = new BehaviorSubject<IApiIconStore[]>([]);    
    // доступные клиенту иконки
    public availableIconStores$ = new BehaviorSubject<IApiIconStore[]>([]);    
    // все ТС доступные клиенту
    public allcars$ = new BehaviorSubject<IApiCar[]>([]);
    // todelete
    // ТС доступные текущему пользователю
    public availablecars$ = new BehaviorSubject<IApiCar[]>([]);
    // все группы клиента
    public allcargroups$ = new BehaviorSubject<IApiCarGroup[]>([]);
    // группы доступные текущему пользователю
    public availablecargroups$ = new BehaviorSubject<IApiCarGroup[]>([]);
    // геозоны
    public geozones$ = new BehaviorSubject<IApiGeozone[]>([]);
    // водители
    public drivers$ =  new BehaviorSubject<IApiDriver[]>([]);
    // доступные клиенты
    public allcustomers$ = new BehaviorSubject<IApiCustomer[]>([]); 

    // выбранный клиент на карте
    public selectedFilter = {
        customerId: null as string|null, 
        userId:  null as string|null,
        carGroupUserId: null as string|null,
        // статусы ТС
        status: [] as number[]|null,
        // ошибки: nogps,nonet,error
        errors: [] as string[]|null,
        // наличие прицеаа
        isTruck: null as boolean|null,
        // просрочено ТО
        skipedTO: null as boolean|null,
        // блокировка двигателя
        blockEngine: null as boolean|null
    };

    // периодическое обновление
    public carStatuses$ = new BehaviorSubject<IApiCarStatus[]>([]);
//    public currentDriverTrips$ = new BehaviorSubject<IApiDriverTrip[]>([]);

    constructor(
        private systemService: SystemService,
        private authService: AuthService,
        private translateService: TranslateService
    ){
    }

    public loadIfNotLoaded() {
        return !this.alreadyLoaded ? this.loadAll() : of({ ok: true } as IApiClientLoadAllResponse)
    }


    // можно использовать в диалогах, если использовать на страницах возможно множественные вызовы, т.к. alreadyLoaded еще не установлен
    public ensureLoad$(destroy$: Subject<boolean> ) {
        if(this.alreadyLoaded) return of(true);
        console.warn('Loading configuration');
        return this.loadIfNotLoaded()
            .pipe(
                takeUntil(destroy$),
                switchMap(x=>this.loaded$),
                filter(loaded=>!!loaded),
                take(1)
            );
    }

    public loadAll() {
        return this.systemService.loadAll()
        .pipe(
            retry({count:3, delay:1000}),
            tap(res => {
//                console.log('loadAll$', res);

                this.alreadyLoaded=res;
                this.customer$.next(res.customer);
                console.log('customer', res.customer);

                this.referers$.next(res.referers??[]);
                
                let s1 = res.sensorNames??[];
                s1.sort((a,b)=>(a.display_name??'')>(b.display_name??'')?1:-1);
                this.sensorNames$.next(s1);

                let ps = res.paidServices??[];
                ps.sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
                this.paidService$.next(ps);

                this.reportLists$.next(res.reportLists??[]);
                let r1=this._getAvailableReportLists(res.reportLists||[]);
                r1.sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
                this.availableReportLists$.next(r1)

                this.iconStores$.next(res.iconStores??[]);
                let i1=this._getAvailableIcons(res.reportLists||[]);
                i1.sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
                this.availableIconStores$.next(i1)

                this.allcars$.next(res.cars||[]);
                this.allcargroups$.next(res.customer?.car_groups??[]);
               
                let t2=this._getAvailableCarGroups(res.customer?.car_groups??[]);
                t2=t2.filter(x=>x.is_visible);
                t2.sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
                this.availablecargroups$.next(t2);
//                console.log('allcars$', res.cars);
//                console.log('allcargroups$', res.customer?.car_groups);
//                console.log('availablecargroups$', t2);

                let t1=this._getAvailableCars(res.cars||[], t2);
                t1.sort((a,b)=>(a.display_name??'')>(b.display_name??'')?1:-1);
                this.availablecars$.next(t1);
                console.log('availablecars$', t1);

                let g1=res.geozones||[];
                g1.sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
                this.geozones$.next(g1);

                let drv=res.drivers||[];
                drv.sort((a,b)=> (a.family??'')+(a.name??'')+(a.surname??'')>(b.family??'')+(b.name??'')+(b.surname??'')?1:-1);
                this.drivers$.next(drv);

                this.allcustomers$.next(res.customers||[]);
                console.log('allcustomers$', this.allcustomers$.value);

                this.selectedFilter.customerId = this.customer$.value?.id??null;
                this.selectedFilter.userId = this.authService.getUser()?.id??null;

                this.loaded$.next(true);
            })
        );
    }

    public getReferer(area: string, key: string|null) {
        let fnd = this.referers$.value.find(x=>x.area==area && x.key==key);
        return fnd;
    }

    public getSensorName(key: string) {
        return this.sensorNames$.value.find(x=>x.key==key);
    }

    public getIcon(id: string) {
        return this.iconStores$.value.find(x=>x.id==id);
    }

    private _getAvailableReportLists(src: IApiReportList[]) {
        const c=this.customer$.value?.reportlistsid||[];
        return src.filter(x => x.allow_to_all==true || c.includes(x.id!));
    }

    private _getAvailableIcons(src: IApiIconStore[] ) {
        const c=this.customer$.value?.iconsid||[];
        return src.filter(x => x.allow_to_all==true || c.includes(x.id!));

    }

    // todelete
    // доступные машины для текущего пользоваеля
    private _getAvailableCars(allcars: IApiCar[], allgroups: IApiCarGroup[]) {
        let cars: IApiCar[]=[];
        // если есть доступ ко всем группам *
        // или право управлять группами, то доступны все автомобили
        if( (this.authService.user$.value?.original_user?.car_groups?.indexOf("*")??-1)>=0
            || this.authService.isAllow(IApiUser_Rights.groupManage)
        ) {
            return [...allcars];
        }
        
        let availableGroups = this._getAvailableCarGroups(allgroups);
        (availableGroups||[]).forEach(grp=>{
            (grp?.cars||[]).forEach(cid=>{
                let fnd=(allcars||[]).find(x=>x.id==cid);
                let fnd2=(cars||[]).find(x=>x.id==cid);
                if(fnd && !fnd2) cars.push({...fnd});
            })
        });
        return cars;
    }

    // todelete
    // доступные группы для текущего пользоваеля
    private _getAvailableCarGroups(allgroups: IApiCarGroup[]) {       
        let groups: IApiCarGroup[]=[];
        (allgroups||[]).forEach(grp=>{
            let fnd = this.authService.user$.value?.original_user?.car_groups?.find(x=>x==grp.id||x=='*');
            if(fnd || this.authService.isMasterAdministrator()) {
                groups.push(grp);
            }
        })
        return groups;
    }
    
    public getCustomer() {
        return this.customer$.value;
    }

    // todelete
    public isCarInGroup(carid: string, groupid: string) {
        if(groupid=='*') return true;
        let g=this.availablecargroups$.value.find(x=>x.id==groupid);
        if(!g) return false;
        return (g.cars?.indexOf(carid)??-1)>=0;
    }

    // todelete
    public getAvailableCarsOptions(addAny: boolean=true) {
        let res = this.availablecars$.value.map(x=>{
            let name = x.display_name;
            if(x.brand || x.number) name+=' (';
            if(x.brand) name+=x.brand;
            if(x.number) name+=' ' + x.number;
            if(x.brand || x.number) name+=')';
            return {name: name, code: x.id }
        });
        if(addAny) res.unshift({name: this.translateService.instant('common.allcars'), code:'*'})
        return res;
    }

    getCompany = (id:string)=>this.allcustomers$.value.find(x=>x.id==id);

    addNewCompany(customer: IApiCustomer) {
        if(customer?.id!=null && this.getCompany(customer.id)==null) {
            this.allcustomers$.next([...this.allcustomers$.value, customer])
        }
    }
    updateCompany(customer: IApiCustomer) {
        if(customer?.id!=null) {
            let c = this.allcustomers$.value;
            let ix=c.findIndex(x=>x.id==customer.id);
            if(ix>=0) {
                c[ix]={...customer};
                this.allcustomers$.next(c);
            }
        }
    }
    deleteCompany(id:string) {
        let c = this.allcustomers$.value;
        let ix=c.findIndex(x=>x.id==id);
        if(ix>=0) {
            c.splice(ix,1);
            this.allcustomers$.next(c);
        }
    }

    addNewUser(customerId: string, user: IApiUser) {
        let c = this.getCompany(customerId);
        if(c!=null) {
            if(c.users==null) c.users=[];
            c.users.push(user);
        }
    }
    updateUser(customerId: string, user: IApiUser) {
        let c = this.getCompany(customerId);
        if(c!=null) {
            let ix=(c.users||[]).findIndex(x=>x.id==user.id);
            if(ix>=0) c.users![ix]={...user};
        }
    }
    deleteUser(customerId: string, id: string) {
        let c = this.getCompany(customerId);
        if(c!=null) {
            let ix=(c.users||[]).findIndex(x=>x.id==id);
            if(ix>=0){
                c.users?.splice(ix,1);
            }
        }
    }

    addNewCarGroupUser(userId: string, group: IApiCarGroupUser) {
        let u = this.getCompanyUser(userId);
        if(u!=null) {
            if(u.car_group_users==null) u.car_group_users=[];
            u.car_group_users.push(group);
        }
    }
    updateCarGroupUser(userId: string, group: IApiCarGroupUser) {
        let u = this.getCompanyUser(userId);
        if(u!=null) {
            let ix=(u.car_group_users||[]).findIndex(x=>x.id==group.id);
            if(ix>=0) u.car_group_users![ix]={...group};
        }
    }
    deleteCarGroupUser(userId: string, groupId: string) {
        let u = this.getCompanyUser(userId);
        if(u!=null) {
            let ix=(u.car_group_users||[]).findIndex(x=>x.id==groupId);
            if(ix>=0){
                u.car_group_users?.splice(ix,1);
            }
        }
    }

    getCompanyUsers(customerId:string) {
        let f = this.allcustomers$.value.find(x=>x.id==customerId);
        let users = f?.users||[]; 
        return [...users].sort((a,b)=>(a.name??'')>(b.name??'')?1:-1)??null;
    }

    getCompanyUser(userId: string): IApiUser {
        let c = this.allcustomers$.value.find(x=>x.users?.find(z=>z.id==userId));
        let res = c?.users?.find(z=>z.id==userId); 
        return {...res};
    }

    getCar(carid: string): IApiCar {
        let car = this.allcars$.value.find(x=>x.id==carid) 
        return {...car};
    }

    getCar$(carid: string) {
        return this.allcars$.pipe(map(x=>x.find(z=>z.id==carid))); 
    }

    // служебная группа для доступа к ТС
    getCarGroup(groupId: string): IApiCarGroup {
        let res = this.allcargroups$.value.find(x=>x.id==groupId);
        return {...res};
    }

    getCarGroups(carid: string): IApiCarGroup[] {
        let res = this.allcargroups$.value.filter(x=>(x.cars||[]).includes(carid));
        return [...res];
    }

    getCarGroupUser(userId: string, groupId: string): IApiCarGroupUser {
        let customer = this.allcustomers$.value.find(c=>!!(c.users?.find(u=>u.id==userId)??false));
        let user = customer?.users?.find(x=>x.id==userId);
        let res = user?.car_group_users?.find(x=>x.id==groupId);
        return {...res};
    }

    // список парка для пользователя
    getCarGroupUsersForUser(userId: string): IApiCarGroupUser[] {
        let customer = this.allcustomers$.value.find(c=>!!(c.users?.find(u=>u.id==userId)??false));
        let user = customer?.users?.find(x=>x.id==userId);
        let res = user?.car_group_users||[]; 
        return [...res].sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
    }

    // все ТС доступные компании (включая нижестоящие)
    getCarsForCustomer(customerId: string) {
        let res = this.allcars$.value.filter(x=>x.customerid==customerId || (x.customers_access||'').includes(customerId));
        return [...res.sort((a,b)=>(a.number??'')>(b.number??'')?1:-1)];
    }

    // все ТС доступные пользователю с учетом его групп доступа
    getCarsForUser(userId: string, customerId: string) {
        let cars=this.getCarsForCustomer(customerId);
        return this.сheckedCarsForUser(cars, userId);
    }

    // корректные автомобили, согласно правам доступа пользователя (недоступные исключены)
    сheckedCarsForUser(cars: IApiCar[], userId: string) {
        let res:IApiCar[]=[];
        cars.forEach(car=>{
            if(this.isCarVisibleForUser(car.id??'xxx', userId)) res.push(car);
        })
        return res;
    }

    isCarVisibleForUser(carId: string, userId: string) {
        let user = this.getCompanyUser(userId);
        let visible = (user?.car_groups||[]).includes('*');
        if(!visible) { 
            (user?.car_groups||[]).forEach(groupId=>{
                if(!visible) {
                    let group = this.getCarGroup(groupId);
                    if(group?.cars?.includes(carId??'xxx')) visible = true;
                }
            })
        }
        return visible;
    }


    // статусы машин и водителей

    updateCarStatuses(statuses: IApiCarStatus[]){
        this.carStatuses$.next(statuses);
    }

//    updateCurrentDriverTrips(trips: IApiDriverTrip[]) {
//        this.currentDriverTrips$.next(trips);
//    }

    getCarStatus(carid: string|null) {
        return this.carStatuses$.value.find(x=>x.car_id==carid)??null;
    }

    getCarStatus$(carid: string|null) {
        return this.carStatuses$.pipe(
            map(s=>s?.find(x=>x.car_id==carid)??null)
        );
    } 

    getCarStatusByDriverId$(driverid: string|null) {
        return this.carStatuses$.pipe(
            map(s=>s?.find(x=>x.driverid==driverid)??null)
        );
    } 

//    getCurrentDriverTrip(driverid: string) {
//        return this.currentDriverTrips$.value.find(x=>x.driverid==driverid)??null;
//    }


//    getCurrentDriverTrip$(driverid: string) {
//        return this.currentDriverTrips$.pipe(
//            map(s=>s.find(x=>x.driverid==driverid)??null)
//        );
//    }

//    getCurrentDriverCar(driverid: string) {
//        let trip = this.getCurrentDriverTrip(driverid);      
//        return trip?.carid ? this.getCar(trip.carid):null;
//    }

//    getCurrentDriverCar$(driverid: string) {
//        return this.currentDriverTrips$.pipe(
//            map(s=>s.find(x=>x.driverid==driverid)??null),
//            map(x=>x?.carid ? this.getCar(x.carid):null)
//        );
//    }

//    getCurrentDriverCarStatus(driverid: string) {
//        let car = this.getCurrentDriverCar(driverid);      
//        return car?.id ? this.getCar(car.id):null;
//    }

//    getCurrentDriverCarStatus$(driverid: string) {
//        return this.currentDriverTrips$.pipe(
//            map(t=>t.find(x=>x.driverid==driverid)??null),
//            map(x=>x?.carid ? this.getCar(x?.carid):null)
//        );
//    }


    addNewDriver(driver: IApiDriver) {
        let d = this.drivers$.value||[];
        d.push({...driver});
        this.drivers$.next([...d]);
    }
    updateDriver(driver: IApiDriver) {
        let d = this.drivers$.value||[];
        let ix = d.findIndex(x=>x.id==driver.id);
        if(ix>=0){
            d[ix]={...driver};
            this.drivers$.next([...d]);
        }
    }
    deleteDriver(driverId: string) {
        let d = this.drivers$.value||[];
        let ix = d.findIndex(x=>x.id==driverId);
        if(ix>=0){
            d.splice(ix,1);
            this.drivers$.next([...d]);
        }
    }


    getDistribBranches() {
        let cust = this.customer$.value||{};
        if(cust && (cust?.customer_type==IApiCustomer_CustomerTypeEnum.Distributor || cust.customer_type==null) ) return cust?.branches;
        let cust2 = cust?.parent;
        if(cust2 && (cust2?.customer_type==IApiCustomer_CustomerTypeEnum.Distributor || cust2?.customer_type==null) ) return cust2?.branches;
        let cust3 = cust2?.parent;
        if(cust3 && (cust3?.customer_type==IApiCustomer_CustomerTypeEnum.Distributor || cust3?.customer_type==null) ) return cust2?.branches;
        return null;
    }

}
