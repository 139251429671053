<div class="px-1 py-2 flex align-items-center">
    <div>
        <h2 class="dh-page-caption mr-5 my-0">
            <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-envelope"></p-avatar>
            {{'messages.title'|translate}}
        </h2>
    </div>
</div>
<p-table [value]="data.records||[]" [rowHover]="true" styleClass="p-datatable">
    <ng-template pTemplate="header">
        <tr>
            <td colspan="3" style="height: 5px;">
                <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
            </td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <th>{{'form.time'|translate}}</th>
            <th class="w-full">{{'messages.message'|translate}}</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-index="rowIndex">
        <tr>
            <td>
                {{record.created|isoToDDMMYYYY_HHMMSS}}
            </td>
            <td>
                {{record.name}}
            </td>
            <td class="text-right white-space-nowrap">
                <p-button (click)="clickRecordView(record);$event.stopPropagation()" [pTooltip]="'form.view'|translate" icon="pi pi-search" styleClass="p-button-sm mr-1 dh-onhover"></p-button>
            </td>
        </tr>
    </ng-template>
</p-table>
<app-norecords *ngIf="(data.records||[]).length==0 && !data.error"></app-norecords>

<!--

<app-notice-edit *ngIf="dialog.edit.show"
    [id]="dialog.edit.id" 
    [viewMode]="dialog.edit.viewMode"
    (onHide)="onDialogEditHide()"
>
</app-notice-edit>

 -->
