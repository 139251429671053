import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api/public_api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCarGroup } from 'src/app/model.backend/cargroup.model';
import { IApiCustomer } from 'src/app/model.backend/customer.model';
import { IApiUser } from 'src/app/model.backend/user.model';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UserHelper } from 'src/app/service/user-helper.service';
import { UtilsService } from 'src/app/service/utils.service';



@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.css']
})
export class CustomerSelectComponent implements OnInit, OnChanges {
  @Input() customerId: string|null=null;
  @Input() userId: string|null=null;
  @Output() customerIdChange = new EventEmitter<string|null>();
  @Output() userIdChange = new EventEmitter<string|null>();

  node_selected: TreeNode<{customer: IApiCustomer, user: IApiUser|null}>|null = null;
  nodes: TreeNode<{customer: IApiCustomer, user: IApiUser|null}>[]=[];
  destroy$ = new Subject<boolean>();

  constructor(
    private preloaderService: PreloaderService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private authServce: AuthService
  ) {
  }

  ngOnInit(): void {
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$),
      filter(loaded=>!!loaded),
    ).subscribe(()=>this._fillTree());

    //this.translateService.onLangChange.subscribe(()=>this._fillTree());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['customerId'] || changes['userId']) {
      console.log('customerId', this.customerId, 'userId', this.userId);  

      let fnd = this._findNode(this.customerId??'xxx', this.userId, this.nodes);     
      this.node_selected = fnd;
    }
  }

  _fillTree() {
    console.log('customerId', this.customerId, 'userId', this.userId);    

    let old_selected = this.node_selected;

    this.nodes=this.GetNodesForParent(null);
//    this.nodes.unshift({label: this.translateService.instant('common.notselect'), data: {id: null} });
    if(!old_selected?.data) {
      this.node_selected = this._findNode(this.customerId??'xxx', this.userId, this.nodes);
      // для пользователя без доступа к пользователям выбираем его организацию
      if(this.node_selected==null && this.userId==this.authServce.getUser()?.id) {
        this.node_selected = this._findNode(this.customerId??'xxx', null, this.nodes);
      }
    } 
    else {
      this.node_selected = this._findNode(old_selected.data?.customer?.id??'xxx', old_selected.data.user?.id??null, this.nodes);
    }
  }

  _findNode(customerId: string, userId: string|null, nodes: TreeNode<{customer: IApiCustomer, user: IApiUser|null}>[]): TreeNode<{customer: IApiCustomer, user: IApiUser|null}>|null {
    for(let ix=0; ix<nodes.length;ix++) {
      let node = nodes[ix];
      if(node.data?.customer?.id==customerId && node.data?.user?.id==userId) return node;
      let fnd = this._findNode(customerId, userId, node.children||[]);
      if(fnd) return fnd;
    }
    return null;
  }

  GetNodesForParent(parentid: string|null): TreeNode<{customer: IApiCustomer, user: IApiUser|null}>[] {
    let user=this.authServce.getUser()??null;
    let n: TreeNode<{customer: IApiCustomer, user: IApiUser|null}>[]=[];
    let f = this.preloaderService.allcustomers$.value?.filter(x=>x.parentid==parentid)??[];
    f.forEach(x=>{
      let node: TreeNode<{customer: IApiCustomer, user: IApiUser|null}> = { label: x.name??'', data: {customer: x, user: null }, icon: 'pi pi-building' };
      let children = this.GetNodesForParent(x.id??'xxx');
      if((children||[]).length>0) {
        node.children = children;
      }
      // добавить пользователей только для Администратора
      if( user && (UserHelper.isCompanyAdmin(user) || UserHelper.isMasterAdmin(user) || x.id!=user.customerid )) {
        x.users?.forEach(u=>{
          let usernode: TreeNode<{customer: IApiCustomer, user: IApiUser|null}> = { label: `${u.login} (${x.name})`, data: { customer: x, user: u }, icon: 'pi pi-user' };
          if(node.children==null) node.children=[];
          node.children.push(usernode);
        });  
      } 
      n.push(node)
    });
    return n;
  }



  onNodeSelect(node: TreeNodeSelectEvent) {
    this.customerIdChange.emit(node.node?.data?.customer?.id);
    this.userIdChange.emit(node.node?.data?.user?.id);
  }
}
