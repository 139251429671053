
    <div *ngIf="canList()" class="grid surface-ground px-2 pt-3">
        <div class="col-12 flex align-items-center">
            <div class="w-full">
                <span class="p-input-icon-right w-full">
                    <i (click)="ui.filterText='';_applyFilterAndSort()" class="pi pi-times text-gray-500" style="right:35px;"></i>
                    <i class="pi pi-search"></i>
                    <input [(ngModel)]="ui.filterText" (keyup)="_applyFilterAndSort()" type="text" pInputText class="sav3-input w-full" [placeholder]="'common.find'|translate" />
                </span>   
            </div>
        </div>
        <div class="col-12 flex align-items-center">
            <div class="pl-3 pr-2">
                <p-checkbox *ngIf="(data.filteredRecords||[]).length!=0" (onChange)="clickDriverSelectAll($event.checked)" [binary]="true" [(ngModel)]="ui.selectAll"></p-checkbox>
            </div>
            <div *ngIf="!ui.viewMode" class="ml-auto">
                <p-button (onClick)="clickDriverAdd()" icon="pi pi-plus" [label]="'driver.btnDriverAdd'|translate" styleClass="p-button-secondary p-button-sm p-button-rounded" ></p-button>
            </div>
        </div>
    </div>
    <div id='scrollelement' *ngIf="canList()" class="-h-full surface-ground px-2" [ngStyle]="{'height':ui.innerHeight, 'overflow-y': 'auto'}" >
        <app-progressbar [loading]="data.loading"></app-progressbar>
        <app-errorbar *ngIf="data.error" [showError]="data.error"></app-errorbar>
        <app-norecords *ngIf="data.filteredRecords?.length==0"></app-norecords>
        <div *ngIf="data.filteredRecords?.length!=0">
            <app-driver-card *ngFor="let driver of data.filteredRecords" 
                [driver]="driver"
                [carStatus]="getCarStatus(driver.id??null)"
                [viewMode]="ui.viewMode"
                [selected]="isDriverSelected(driver)"
                (onSelectChange)="clickDriverSelectChange(driver, $event)"
                (onClickEdit)="clickDriverEdit($event)"
                (onClickDelete)="clickDriverDelete($event.driver, $event.event)"           
            ></app-driver-card>
        </div>
    </div>

    <app-driver-edit2 *ngIf="dialog.edit.show"
        [driverid]="dialog.edit.driverId"      
        (onHide)="dialog.edit.show=false" 
        (onUpdate)="onDialogEditUpdate($event)"
        (onInsert)="onDialogEditInsert($event)"
        (onTripUpdated)="onTripUpdated_event()"
    ></app-driver-edit2>
