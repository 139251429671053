<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '30vw' }" 
    styleClass="shadow-5 sav3-dialog"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="name">{{'structure.branchEdit.fieldName'|translate}}</label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.required'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="address">{{'structure.branchEdit.fieldAddress'|translate}}</label></div>
                <input formControlName="address" id="address" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('address')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
            </div>
            <div class="col-6 pb-0">
                <div><label class="sav3-label" for="lat">{{'structure.branchEdit.fieldLat'|translate}}</label></div>
                <p-inputNumber formControlName="lat" id="lat" [maxFractionDigits]="8" styleClass="w-full sav3-input p-inputtext-sm"></p-inputNumber>
            </div>
            <div class="col-6 pb-0">
                <div><label class="sav3-label" for="ln">{{'structure.branchEdit.fieldLon'|translate}}</label></div>
                <p-inputNumber formControlName="lon" id="lon" [maxFractionDigits]="8" styleClass="w-full sav3-input p-inputtext-sm"></p-inputNumber>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="work_time">{{'structure.branchEdit.fieldWorktime'|translate}}</label></div>
                <input formControlName="work_time" id="work_time" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('work_time')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="phone">{{'structure.branchEdit.fieldPhone'|translate}}</label></div>
                <input formControlName="phone" id="phone" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('phone')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="email">{{'structure.branchEdit.fieldEmail'|translate}}</label></div>
                <input formControlName="email" id="email" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('email')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="comment">{{'structure.branchEdit.fieldComment'|translate}}</label></div>
                <input formControlName="comment" id="comment" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('comment')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div class="w-full sav3-checkbox">
                    <p-checkbox formControlName="visible" [label]="'structure.branchEdit.fieldVisible'|translate" [binary]="true"></p-checkbox>
                </div>
            </div>

        </div>
    </div>

    <ng-template pTemplate="footer" class="text-center">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary p-button-rounded">
        </p-button>
    </ng-template>
</p-dialog>

