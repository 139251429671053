import { Injectable } from "@angular/core";
import { IApiUser, IApiUser_Rights } from "../model.backend/user.model";

@Injectable({
    providedIn: 'root'
 })
 export class UserHelper {
        
    public static isCompanyAdmin(user: IApiUser) {
        return user.options?.find(x=>x==IApiUser_Rights.admin)?true:false;
    }

    public static isMasterAdmin(user: IApiUser) {
        return user.customerid==null;
    }

    // доступ пользователя
    public static isAllow(user: IApiUser, right: IApiUser_Rights) {
    return this.isMasterAdmin(user) 
           || user?.options?.find(x=>x=='*') 
           || user?.options?.find(x=>x==right) 
           ? true : false;
  }

 }