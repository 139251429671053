import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesSysPage404Component } from './pages-sys/page404/page404.component';
import { PagesSysLoginComponent } from './pages-sys/login-page/login.component';
import { authGuard } from './auth/auth.guard';
import { PageMainComponent } from './pages/page-main/page-main.component';
import { PageMapComponent } from './pages/page-map/page-map.component';
import { PageDriversComponent } from './pages/page-drivers/page-drivers.component';
import { PageRequestSupportComponent } from './pages/page-requestsupport/page-requestsupport.component';
import { PageTasksComponent } from './pages/page-tasks/page-tasks.component';
import { PageNoticesComponent } from './pages/page-notices/page-notices.component';
import { accessGuard } from './auth/access-right.guard';
import { IApiUser_Rights } from './model.backend/user.model';
import { PageMessagesComponent } from './pages/page-messages/page-messages.component';
import { PageCharterersComponent } from './pages/page-charterers/page-charterers.component';
import { PageCarGroupsComponent } from './pages/page-cargroups/page-cargroups.component';
import { PageCarsComponent } from './pages/page-cars/page-cars.component';
import { PageObjectsComponent } from './pages/page-objects/page-objects.component';
import { PageFinancesComponent } from './pages/page-finances/page-finances.component';
import { PageDriverTripsComponent } from './pages/page-driver-trips/page-driver-trips.component';
import { PageStructureComponent } from './pages/page-structure/page-structure.component';

const routes: Routes = [
//  {
//    path: 'auth',
//    children: 
//    [
//      { path: 'login', loadChildren: () => import('./pages-sys/auth/login/login.module').then(m => m.PagesSysAuthLoginModule) },
//    ]
//  },
//  {
//    path: 'm', component: PagesMasterPageComponent, canActivate: [AuthGuard],
//    children: 
//    [
//
//      { path: 'patients', loadChildren: () => import('./pages/patients-page/patients-page.module').then(m => m.PagesPatientModule), canActivate: [AuthGuard] },
//      { path: 'personals', loadChildren: () => import('./pages/personals-page/personals-page.module').then(m => m.PersonalsPageModule), canActivate: [AuthGuard] },  
//      { path: 'error404', loadChildren: () => import('./pages-sys/page404/page404.module').then(m => m.PagesSysPage404Module) },
//     
//
//      { path: '', component: PagesHomeComponent },      
//      { path: '**', loadChildren: () => import('./pages-sys/page404/page404.module').then(m => m.PagesSysPage404Module) }
//    ]
//  },
  //{ path: 'error404', component: PagesSysPage404Component},
//  { path: 'error404', loadChildren: () => import('./pages-sys/page404/page404.module').then(m => m.PagesSysPage404Module) },
  { path: 'login', component: PagesSysLoginComponent },
  
  { path: 'map', component: PageMapComponent, canActivate: [authGuard]  },  
  { path: 'drivers', component: PageDriversComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.driverManage}  },
  { path: 'driver-trips', component: PageDriverTripsComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.driverManage}  },
  { path: 'requestsupport', component: PageRequestSupportComponent, canActivate: [authGuard]  },  
  { path: 'tasks', component: PageTasksComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.taskManage} },  
  { path: 'notices', component: PageNoticesComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.notifyManage}},
  { path: 'messages', component: PageMessagesComponent, canActivate: [authGuard, accessGuard] },
  { path: 'charterers', component: PageCharterersComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.chartererManage}},
  { path: 'cargroups', component: PageCarGroupsComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.groupManage}},
  { path: 'cars', component: PageCarsComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.carManage}},
  { path: 'objects', component: PageObjectsComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.carManage}},
  { path: 'finances', component: PageFinancesComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.reportFinance}},
  { path: 'structure', component: PageStructureComponent, canActivate: [authGuard, accessGuard], data: {role: IApiUser_Rights.admin, role2: IApiUser_Rights.userManage}},
//  { path: 'main', component: PageMainComponent, canActivate: [authGuard]  },  
  { path: 'error404', component: PagesSysPage404Component },
  { path: '', redirectTo: 'map', pathMatch: 'full' },  
  { path: '**', redirectTo: 'error404', pathMatch: 'full'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
