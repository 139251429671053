import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../service/utils.service';

@Pipe({
  name: 'isoToDDMMYYYY_HHMMSS'
})
export class IsoToDDMMYYYY_HHMMSSPipe implements PipeTransform {
  constructor(public utils: UtilsService){}
  transform(value: string, ...args: string[]): string|null {
    if (!value) return "";
    let dt = this.utils.isoToDate(value);
    if (!dt) return "";  
    return `${this.utils.to2digits(dt.getDate())}.${this.utils.to2digits(dt.getMonth()+1)}.${this.utils.to4digits(dt.getFullYear())}` + ' '
    + `${this.utils.to2digits(dt.getHours())}:${this.utils.to2digits(dt.getMinutes())}:${this.utils.to2digits(dt.getSeconds())}`;
}

}
