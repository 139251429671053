import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiTask } from 'src/app/model.backend/task.model';
import { TaskService } from 'src/app/service.backend/task.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  options = {
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiTask[]|null
  };
  dialog = {
    edit: {
      show: false,
      id: null as string|null,
      viewMode: false
    }
  }

  constructor(
    private taskService: TaskService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.hideMasterManuReports();
    this.loadRecords();
  }

  hideMasterManuReports() {
    try {
      let el:any = document.getElementById('masterMenuReports');
      el.classList.add("hidden"); 
    } catch {}
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  loadRecords() {
    this.data.loading=true;
    this.taskService.list().subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('loadRecords', res.tasks);
          this.data.records = res.tasks;
        } else {
          console.error('loadRecords', res.errorMessage, res.errorMessageForUser);
          this.data.error = res.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.applyFilterAndSort();
        this.data.loading=false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading=false;
      }
    })
  }

  applyFilterAndSort() {
    (this.data.records||[]).sort((a,b)=>(a.created??'')>(b.created??'')?1:-1);
  }

  clickRecordEdit(record: IApiTask) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
    this.dialog.edit.viewMode = false;
  }

  clickRecordView(record: IApiTask) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
    this.dialog.edit.viewMode = true;
  }

  clickRecordAdd() {
    this.dialog.edit.id = null;
    this.dialog.edit.show =true;
  }

  clickRecordDelete(record: IApiTask, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('task.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.taskService.delete(record.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              let ix = this.data.records?.findIndex(x=>x.id==record.id)??-1;
              if(ix>=0) this.data.records?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }

  onDialogEditUpdate(record: IApiTask) {
    let ix=this.data.records?.findIndex(x=>x.id==record.id)??-1;
    if(ix>=0 && this.data.records) {
      this.data.records[ix] = { ...record };
    } 
    this.applyFilterAndSort();
  }

  onDialogEditInsert(record: IApiTask) {
    if(!this.data.records) this.data.records=[];
    this.data.records.push({...record});
    this.applyFilterAndSort();
  }

}
