import { formatNumber } from "@angular/common";
import { IApiCarStatus, IApiCarStatus_StatusEnum } from "src/app/model.backend/carstatus.model";
import { IApiSensorName_KeyEnum } from "src/app/model.backend/sensor-name.model";
import { INameCodeNumber, UtilsService } from "src/app/service/utils.service";
import { IApiUser } from "../model.backend/user.model";


export class CarStatusHelper {
    public static isCarError(carstatus: IApiCarStatus|null) {
        let f = carstatus?.car_vals?.find( x => x.snk==IApiSensorName_KeyEnum.Error_count 
          || x.snk==IApiSensorName_KeyEnum.Error_address 
          || x.snk==IApiSensorName_KeyEnum.Error_fmi 
          || x.snk==IApiSensorName_KeyEnum.Error_spn 
          || x.snk==IApiSensorName_KeyEnum.Error_lamp
        );
        return f!=null;
    }

    public static isCarOffline(carstatus: IApiCarStatus|null) {
        let dt= UtilsService.isoDateParseToDate(carstatus?.last_check_time??undefined);
        dt?.setHours(dt?.getHours()+3);
         
        return dt ? dt < new Date() : true;
      }
    
    public static isGarNoGps(carstatus: IApiCarStatus|null) {
      let b = this.getSensorValueB(carstatus, IApiSensorName_KeyEnum.Nogps);
      return b==null ? true : b;
    }

    public static isTruck(carstatus: IApiCarStatus|null) {
      return false;
    }

    public static isSkippedTo(carstatus: IApiCarStatus|null) {
      return false;
    }

    public static isBlockEngine(carstatus: IApiCarStatus|null) {
      return false;
    }


    public static getSensorFixedValue(user: IApiUser|null, carstatus: IApiCarStatus|null, ix: number) {
      let sensors=[null,null,null] as (string|null)[];
      let user_sensors=user?.settings?.user_sensors;
      if(user_sensors && user_sensors.length>0) sensors[0]=user_sensors[0].snk;
      if(user_sensors && user_sensors.length>1) sensors[1]=user_sensors[1].snk;
      if(user_sensors && user_sensors.length>2) sensors[2]=user_sensors[2].snk;

      let res = null as string|null;
      let fnd = carstatus?.car_vals?.find(x=>x.snk==sensors[ix]);
      if(fnd?.n) {
        res = formatNumber(fnd.n,'en-US','1.0-0');
        if(sensors[ix]==IApiSensorName_KeyEnum.Speed) res+=' км/ч';
        if(sensors[ix]==IApiSensorName_KeyEnum.Mileage) res+=' км';
        if(sensors[ix]==IApiSensorName_KeyEnum.Fuel_lvl) res+=' л';
      }
      return res
    }

      public static getSensorValueN(carstatus: IApiCarStatus|null, snk: string, postfix: string='', nullvalue: string|null = null) {
        let res = nullvalue as string|null;
        let f = carstatus?.car_vals?.find(x=>x.snk==snk);
        if(f?.n) {
            res = formatNumber(f.n,'en-US','1.0-0') + postfix;
        }
        return res
      }

      public static getSensorValueS(carstatus: IApiCarStatus|null, snk: string, postfix: string='', nullvalue: string|null = null) {
        let res = nullvalue as string|null;
        let f = carstatus?.car_vals?.find(x=>x.snk==snk);
        if(f?.s) {
            res = f.s + postfix;
        }
        return res
      }
      public static getSensorValueB(carstatus: IApiCarStatus|null, snk: string, nullvalue: string|null = null) {
        let res = nullvalue as boolean|null;
        let f = carstatus?.car_vals?.find(x=>x.snk==snk);
        if(f?.n) {
          res = f.n > 0 ;
        }
        return res
      }


      public static getStatusOptionsI18() {
          return [
              {name: 'cars.status_offline', code: IApiCarStatus_StatusEnum.Offline  },
              {name: 'cars.status_ignition', code: IApiCarStatus_StatusEnum.IgnitionIsOn },
              {name: 'cars.status_movement', code: IApiCarStatus_StatusEnum.Movement },
              {name: 'cars.status_parking', code: IApiCarStatus_StatusEnum.Parking }
          ] as INameCodeNumber[]
      }
  
}