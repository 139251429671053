import { IApiBaseResponse, IApiBaseTable } from "./_base.model";
import { IApiCarGroup } from "./cargroup.model";
import { IApiUser } from "./user.model";

export enum IApiCustomer_CustomerTypeEnum {
    Distributor="distributor",
    Dealer="dealer",
    Company="company"
}

export interface IApiCustomer extends IApiBaseTable {
    name?: string|null;
    is_organization?: boolean|null;
    pin?: string|null;
    balance?: number|null;
    bonuse?: number|null;
    credit_limit?: number|null;
    blocked?: string|null;
    in_billing?: boolean|null;
    in_adv?: boolean|null;
    is_visible?: boolean|null;
    comment?: string|null;
    // Какие дополнительные иконки доступны клиенту
    iconsid?: string[]|null;
    // Какие дополнительные отчеты доступны клиенту
    reportlistsid?: string[]|null;

    users? :IApiUser[]|null;
    car_groups?: IApiCarGroup[]|null;

    customer_type?: string|null;
    parentid?: string|null;
    parent?: IApiCustomer; 
    parents_access?: string[]|null;

    inn?: string|null;
    email?: string|null;

    branches?: IApiCustomerBranch[]|null;
}

export interface IApiCustomerBranch {
    id: string|null;
    name?: string|null;
    address?: string|null;
    lat?: number|null;
    lon?: number|null;
    work_time?: string|null;
    phone?: string|null;
    email?: string|null;
    comment?: string|null;
    visible?: boolean|null;
}

export interface IApiCustomerListResponse extends IApiBaseResponse {
    customers: IApiCustomer[]|null;
};
  
export interface IApiCustomerResponse extends IApiBaseResponse {
    customer: IApiCustomer|null;
};

