import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiCharterer } from 'src/app/model.backend/charterer.model';
import { ChartererService } from 'src/app/service.backend/charterer.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import {jsPDF} from "jspdf";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-charterer-list',
  templateUrl: './charterer-list.component.html',
  styleUrls: ['./charterer-list.component.css']
})
export class ChartererListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  options = {
    export: [
      { label: "PDF", icon: "pi pi-file-pdf", command: ()=>this.exportPDF() },
      { label: "XLS", icon: "pi pi-file-excel", command: ()=>this.exportXLS() },
    ]
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiCharterer[]|null
  };
  dialog = {
    edit: {
      show: false,
      id: null as string|null     
    },
    import: {
      show: false
    }
  }

  constructor(
    private chartererService: ChartererService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.hideMasterManuOthers();
    this.loadRecords();
  }

  hideMasterManuOthers() {
    try {
      let el:any = document.getElementById('masterMenuOthers');
      el.classList.add("hidden"); 
    } catch {}
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  loadRecords() {
    this.data.loading=true;
    this.chartererService.list().subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('loadRecords', res.charterers);
          this.data.records = res.charterers;
        } else {
          console.error('loadRecords', res.errorMessage, res.errorMessageForUser);
          this.data.error = res.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.applyFilterAndSort();
        this.data.loading=false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading=false;
      }
    })
  }

  applyFilterAndSort() {
    (this.data.records||[]).sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
  }

  clickRecordEdit(record: IApiCharterer) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
  }

  clickRecordAdd() {
    this.dialog.edit.id = null;
    this.dialog.edit.show =true;
  }

  clickRecordDelete(record: IApiCharterer, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('charterers.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.chartererService.delete(record.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              let ix = this.data.records?.findIndex(x=>x.id==record.id)??-1;
              if(ix>=0) this.data.records?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }

  onDialogEditUpdate(record: IApiCharterer) {
    let ix=this.data.records?.findIndex(x=>x.id==record.id)??-1;
    if(ix>=0 && this.data.records) {
      this.data.records[ix] = { ...record };
    } 
    this.applyFilterAndSort();
  }

  onDialogEditInsert(record: IApiCharterer) {
    if(!this.data.records) this.data.records=[];
    this.data.records.push({...record});
    this.applyFilterAndSort();
  }

  exportPDF() {
 		var pdf = new jsPDF('p', 'pt', 'A4');   
    pdf.addFont("assets/fonts/PTSans.ttf", "PTSans", "normal");   
    pdf.setFont("PTSans"); 
 		pdf.html(document.getElementById('toexport')!, {
 			callback: function (pdf2) {
         pdf2.output("dataurlnewwindow", {filename: 'Заказчики.pdf'});
 			},
       x:0, y:0, html2canvas: {scale: 0.75}
 		});
  }

  exportXLS() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('toexport'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    var fmt = "@";
//    wb.Sheets["Sheet1"]["F"] = fmt;

    /* save to file */
    XLSX.writeFile(wb, "Заказчики.xlsx");
  }

  clickImport() {
    this.dialog.import.show=true;
  }

  onImport(items: any[]) {
    this.dialog.import.show=false;
  
    if((items||[]).length==0 || items[0].length<1 ) {
      this.messageService.add({severity: 'error', detail: this.translate.instant('form.importIncorrectFormat') })
      return
    }
  
    let charterers: IApiCharterer[]=[];
    (items||[]).forEach(item=>{
      charterers.push({
        name: item[0],
      })
    });
  
    this.chartererService.import(charterers).subscribe({
      next: (res)=>{
        if(!res.ok) {
          console.error('onImport', res.errorMessage, res.errorMessageForUser);
          this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.importError')});
        } else {
          this.messageService.add({severity: 'success', detail: this.translate.instant('form.importSuccess')});
          this.loadRecords();
        }
      },
      error: (err)=>{
        console.error('onImport',err);
        this.messageService.add({severity: 'error', detail: this.translate.instant('form.importError')});
      }
    })
   }

}
