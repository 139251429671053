import { Component } from '@angular/core';

@Component({
  selector: 'app-page-charterers',
  templateUrl: './page-charterers.component.html',
  styleUrls: ['./page-charterers.component.css']
})
export class PageCharterersComponent {

}
