
<div class="surface-section px-4 py-4 md:px-6 lg:px-8">
    <div style="background: radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%);" class="text-center">
        <span class="bg-white text-pink-500 font-bold text-2xl inline-block px-3">404</span>
    </div>
    <div class="mt-6 mb-5 font-bold text-4xl text-900 text-center">
        <div class="text-300 text-2xl">Page not found</div>
        Страница не найдена
    </div>
    <p class="text-700 text-xl mt-0 mb-6 text-center">К сожалению запрашиваемая страница не найдена</p>
    <div class="text-center">
        <button (click)="backClicked()" type="button" pButton pRipple class="p-button-text p-button-sm mr-2" label="Назад" icon="pi pi-arrow-left"></button>
        <button routerLink="/" type="button" pButton pRipple class="p-button-sm" label="Перейти на главную страницу" icon="pi pi-home"></button>
    </div>
</div>