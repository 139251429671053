import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiGeozone, IApiGeozoneListResponse, IApiGeozoneResponse } from "../model.backend/geozone.model";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { of } from "rxjs/internal/observable/of";

@Injectable({
    providedIn: 'root'
  })
  export class GeozoneService extends BaseService {
    protected controller: string = "geozone";
  
    constructor(
      private http: HttpClient,
    ) 
    { super(); }

    /*
    list(): Observable<IApiGeozoneListResponse> {
        return this.http.get<IApiGeozoneListResponse>(`${this.apiUrl}/${this.controller}/list`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiGeozoneListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка geozone.'))
            );    
    }
    */

    insert(obj: IApiGeozone): Observable<IApiGeozoneResponse> {
      return this.http.post<IApiGeozoneResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiGeozoneResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления геозоны.'))
          );    
    }

    update(id: string, obj: IApiGeozone): Observable<IApiGeozoneResponse> {
      return this.http.post<IApiGeozoneResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiGeozoneResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о геозоне.'))
          );    
    }

    delete(id: string|null|undefined): Observable<IApiBaseResponse> {
      if (!id) return of({ok: false} as IApiBaseResponse);
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id)}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`GET ${this.controller}.delete`, null, 'Ошибка удаления геозоны.'))
          );    
    }


  }