import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiCharterer } from 'src/app/model.backend/charterer.model';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import {jsPDF} from "jspdf";
import * as XLSX from "xlsx";
import { CustomerService } from 'src/app/service.backend/customer.service';
import { MasterMenuService } from '../../master-page/master-menu/master-menu.service';
import { IApiCustomer, IApiCustomer_CustomerTypeEnum } from 'src/app/model.backend/customer.model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { filter, take, takeUntil } from 'rxjs';
import { TreeNodeHelperService } from 'src/app/service/treenode-helper.service';
import { IApiUser, IApiUser_Rights } from 'src/app/model.backend/user.model';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { UserHelper } from 'src/app/service/user-helper.service';
import { UserService } from 'src/app/service.backend/user.service';
import { IApiCarGroupUser } from 'src/app/model.backend/cargroupuser.model';
import { CarGroupUserService } from 'src/app/service.backend/cargroupuser.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-structure-list',
  templateUrl: './structure-list.component.html',
  styleUrls: ['./structure-list.component.css']
})
export class StructureListComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event']) onResize() { this.correctHeightWindow() }
  public heightWindow = '0px';
  
  destroy$ = new Subject<boolean>();
  options = {
//    export: [
//      { label: "PDF", icon: "pi pi-file-pdf", command: ()=>this.exportPDF() },
//      { label: "XLS", icon: "pi pi-file-excel", command: ()=>this.exportXLS() },
//    ]
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiCustomer[]|null,
    nodes: null as TreeNode[]|null,
    userNodes: null as TreeNode[]|null,
    parkNodes: null as TreeNode[]|null,
    clientSelected: null as TreeNode<IApiCustomer>|TreeNode<IApiCustomer>[]|null,
    userSelected: null as TreeNode<IApiCustomer>|TreeNode<IApiCustomer>[]|null,
    parkSelected: null as TreeNode<IApiCarGroupUser>|TreeNode<IApiCarGroupUser>[]|null
  };
  dialog = {
    companyEdit: {
      show: false,
      id: null as string|null,
      parent: null as IApiCustomer|null
    },
    userEdit: {
      show: false,
      id: null as string|null,
      customerid: null as string|null
    },
    parkEdit: {
      show: false,
      id: null as string|null,
      userid: null as string|null,
      customerid: null as string|null
    }
  }

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    private authService: AuthService, 
    private carGroupUserService: CarGroupUserService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService,
    private masterMenuService: MasterMenuService,


  ) {
  }

  isClientSelected = ()=> this.data.clientSelected!=null;
  isUserSelected = ()=> this.data.userSelected!=null;
  isParkSelected = ()=> this.data.parkSelected!=null;
  public getClientSelected = () => (this.data.clientSelected as TreeNode<IApiCustomer>)?.data??null;
  isClientSelectedEndCustomer = () => this.getClientSelected()?.customer_type==IApiCustomer_CustomerTypeEnum.Company;
  isClientSelectedHasChildren = () => ((this.data.clientSelected as TreeNode<IApiCustomer>)?.children??[]).length>0;
  getClientSelectedName = () => this.getClientSelected()?.name;
  getUserSelected = () => (this.data.userSelected as TreeNode<IApiUser>)?.data;
  getUserSelectedName = () => this.getUserSelected()?.name;
  getParkSelected = () => (this.data.parkSelected as TreeNode<IApiCarGroupUser>)?.data;

  ngOnInit(): void {
    this.masterMenuService.hideMenuProfile();

    this.data.loading = true;   
    forkJoin({
      preloader: this.preloaderService.loaded$.pipe(takeUntil(this.destroy$),filter(loaded=>!!loaded), take(1)),
      records: this.customerService.list()
    })
    .subscribe({
      next: (res) => {
        if(res.records.ok) {
          console.log('loadRecords', res.records.customers);
          this.data.records = res.records.customers;
          this.fillClientsTree();
        } else {
          console.error('loadRecords', res.records.errorMessage, res.records.errorMessageForUser);
          this.data.error = res.records.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.data.loading = false;
        this.correctHeightWindow();
      },
      error: err => {
        this.data.loading = false;
        this.data.error = err; 
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
      }
    });
  }

  correctHeightWindow() {
    setTimeout(()=>{
      let h = window.innerHeight;
      let rect2=document.getElementById('innerWindow')?.getBoundingClientRect();
      h = Math.round(h - (rect2?.top??0) - 12 );
      this.heightWindow = h+"px"; 
    }, 1)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }


  fillClientsTree() {
    let oldnodes= this.data.nodes;
    let oldid = (this.data.clientSelected as TreeNode<IApiCustomer>)?.data?.id;
    this.data.nodes=[];
    // обрабатываем все не имеющих родителей
    this.data.nodes = this._fillClientsChildrenNodes2( (this.data.records||[]).filter(x => x.parentid==null || (this.data.records||[]).find(z=>z.id==x.parentid)==null ))??[];

    if(oldnodes!=null && this.data.nodes!=null ) TreeNodeHelperService.ShowAndCloseNodes(oldnodes, this.data.nodes, "id")
    this.data.clientSelected=null;
    if(oldid!=null && this.data.nodes!=null) this.data.clientSelected = TreeNodeHelperService.FindNode(this.data.nodes, "id", oldid);
    this.fillUsersTree()
  }

  /*
  _fillClientsChildrenNodes(parentid: string|null) {
    var res: TreeNode<IApiCustomer>[] = [];   
    this.data.records?.filter(x=>x.parentid==parentid)?.forEach(x=>{
      var node: TreeNode<IApiCustomer> = {
        label: x.name??='-',
        data: x,
        children: this._fillClientsChildrenNodes(x.id??'xxx')
      };
      res.push(node);
    });
    return res.length==0 ? undefined:res;
  }
    */

  _fillClientsChildrenNodes2(source: IApiCustomer[]) {
    var res: TreeNode<IApiCustomer>[] = [];   
    source.forEach(x=>{
      var node: TreeNode<IApiCustomer> = {
        label: x.name??='-',
        data: x,
        children: this._fillClientsChildrenNodes2(this.data.records?.filter(y=>y.parentid==x.id)||[])
      };
      res.push(node);
    });
    return res.length==0 ? undefined:res;
  }

  fillUsersTree() {
    let oldid = (this.data.userSelected as TreeNode<IApiUser>)?.data?.id;
    this.data.userNodes = [];
    let usrs = (this.data.clientSelected as TreeNode<IApiCustomer>)?.data?.users;
    (usrs||[]).forEach(u=> this.data.userNodes?.push( { label: (u.name??'-') +(UserHelper.isCompanyAdmin(u)?' [admin]':'') , data: u }));
    this.data.userSelected=null;
    if(oldid!=null && this.data.userNodes!=null) this.data.userSelected=TreeNodeHelperService.FindNode(this.data.userNodes, "id", oldid);
  }

  fillParksTree() {
    let oldid = (this.data.parkSelected as TreeNode<IApiCarGroupUser>)?.data?.id;
    this.data.parkNodes = [];
    let userid=(this.data.userSelected as TreeNode<IApiUser>)?.data?.id;
    if(userid) {
      let parks = this.preloaderService.getCarGroupUsersForUser(userid);
      (parks||[]).forEach(p=> this.data.parkNodes?.push( { label: p.name??'', data: p }));
      this.data.parkSelected=null; 
    }

    if(oldid!=null && this.data.parkNodes!=null) this.data.parkSelected=TreeNodeHelperService.FindNode(this.data.parkNodes, "id", oldid);
  }

  //
  // Company
  //
  clickCompanyDelete(event: Event) {
    if(this.data.clientSelected==null) return;
    let record=this.getClientSelected();
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('structure.questCustomerDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.customerService.delete(record?.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              this.preloaderService.deleteCompany(record?.id??'xxx');
              let ix = this.data.records?.findIndex(x=>x.id==record?.id)??-1;
              if(ix>=0) this.data.records?.splice(ix,1);
              this.fillClientsTree();
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  clickCompanyEdit() {
    if(this.data.clientSelected) {
      var parent = this.preloaderService.getCompany((this.data.clientSelected as TreeNode<IApiCustomer>).data?.parentid??'xxx')
      this.dialog.companyEdit.id = (this.data.clientSelected as TreeNode<IApiCustomer>).data?.id??'xxx';
      this.dialog.companyEdit.parent = parent??null;
      this.dialog.companyEdit.show =true; 
    }
  }

  clickCompanyAdd() {
    if(this.data.clientSelected) {
      var parent = this.preloaderService.getCompany((this.data.clientSelected as TreeNode<IApiCustomer>).data?.id??'xxx')
      this.dialog.companyEdit.id = null;
      this.dialog.companyEdit.parent = parent??null;
      this.dialog.companyEdit.show =true; 
    }
  }

  clickDistributorAdd() {
    this.dialog.companyEdit.id = null;
    this.dialog.companyEdit.parent = null;
    this.dialog.companyEdit.show =true;
  }

  onDialogCompanyEditHide() {
    this.dialog.companyEdit.show = false;
  }

  onDialogCompanyEditUpdate(record: IApiCustomer) {
    let ix=this.data.records?.findIndex(x=>x.id==record.id)??-1;
    if(ix>=0 && this.data.records) {
      this.data.records[ix] = { ...record };
    } 
    this.fillClientsTree();
  }

  onDialogCompanyEditInsert(record: IApiCharterer) {
    if(!this.data.records) this.data.records=[];
    this.data.records.push({...record});
    this.fillClientsTree();
  }

  onCompanyNodeSelect(event: TreeNodeSelectEvent) {
    this.fillUsersTree();
  }

  //
  // User
  //
  
  clickUserEdit() {
    let c = this.getClientSelected();
    let u = this.getUserSelected();
    if(c&&u) {
      this.dialog.userEdit.id = u.id??'xxx';
      this.dialog.userEdit.customerid = c.id??'xxx';
      this.dialog.userEdit.show =true; 
    }
  }

  clickUserAdd() {
    let c = this.getClientSelected();
    if(c) {
      this.dialog.userEdit.id = null;
      this.dialog.userEdit.customerid = c.id??'xxx';
      this.dialog.userEdit.show =true; 
    }
  }

  clickUserDelete(event: Event) {
    if(this.data.clientSelected==null) return;
    let record=this.getUserSelected();
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('structure.questUserDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.delete(record?.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              this.preloaderService.deleteUser(this.getClientSelected()?.id??'xxx', record?.id??'xxx');
              let c = this.getClientSelected();
              let ix = c?.users?.findIndex(x=>x.id==record?.id)??-1;
              if(ix>=0) c?.users?.splice(ix,1);
              this.fillUsersTree();
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  onDialogUserEditHide() {
    this.dialog.userEdit.show = false;
  }

  onDialogUserEditUpdate(user: IApiUser) {
    let c= this.getClientSelected()
    if(c) {
      let ix = c.users?.findIndex(x=>x.id==user.id)??-1;
      if(ix>=0 && c.users) {
        c.users[ix] = { ...user };
      } 
      this.fillUsersTree();
     }
  }

  onDialogUserEditInsert(user: IApiUser) {
    let c= this.getClientSelected()
    if(c) {
      if(!c.users) c.users=[];
      c.users.push({...user});
      this.fillUsersTree();
     }
  }

  onUserNodeSelect(event: TreeNodeSelectEvent) {
    this.fillParksTree();
  }


  //
  // Park
  //
  clickParkEdit() {
    let u = this.getUserSelected();
    let p = this.getParkSelected();
    if(u&&p) {
      this.dialog.parkEdit.id = p.id??'xxx';
      this.dialog.parkEdit.userid = u.id??'xxx';
      this.dialog.parkEdit.customerid = u.customerid??'xxx';
      this.dialog.parkEdit.show =true; 
    }
  }

  clickParkAdd() {
    let u = this.getUserSelected();
    if(u) {
      this.dialog.parkEdit.id = null;
      this.dialog.parkEdit.userid = u.id??'xxx';
      this.dialog.parkEdit.customerid = u.customerid??'xxx';
      this.dialog.parkEdit.show =true; 
    }
  }

  onDialogParkEditHide() {
    this.dialog.parkEdit.show = false;
  }

  onDialogParkEditUpdate(group: IApiCarGroupUser) {
    let u= this.getUserSelected()
    if(u) {
      let ix = u.car_group_users?.findIndex(x=>x.id==group.id)??-1;
      if(ix>=0 && u.car_group_users) {
        u.car_group_users[ix] = { ...group };
      } 
      this.fillParksTree();
     }
  }

  onDialogParkEditInsert(group: IApiCarGroupUser) {
    let u= this.getUserSelected()
    if(u) {
      if(!u.car_group_users) u.car_group_users=[];
      u.car_group_users.push({...group});
      this.fillParksTree();
     }
  }

  clickParkDelete(event: Event) {
    if(this.data.parkSelected==null) return;
    let record=this.getParkSelected();
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('structure.questParkDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.carGroupUserService.delete(record?.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              this.preloaderService.deleteCarGroupUser(this.getUserSelected()?.id??'xxx', record?.id??'xxx');
              let u = this.getUserSelected();
              let ix = u?.car_group_users?.findIndex(x=>x.id==record?.id)??-1;
              if(ix>=0) u?.car_group_users?.splice(ix,1);
              this.fillParksTree();
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  canDistributorAdd() {
    return this.authService.isMasterAdministrator();
  }

  canCompanyChange() {
    let customer = this.getClientSelected();
    let cid = this.preloaderService.getCustomer()?.id??'xxx';
    return this.authService.isMasterAdministrator() 
          || ( this.authService.isAllow(IApiUser_Rights.admin) 
             && ((customer?.parents_access?.includes(cid)??false) || customer?.id==cid) );
  }

  canUserChange() {
    let customer = this.getClientSelected();
    let cid = this.preloaderService.getCustomer()?.id??'xxx';
    return this.authService.isMasterAdministrator() 
          || ( (this.authService.isAllow(IApiUser_Rights.admin) || this.authService.isAllow(IApiUser_Rights.userManage)) 
             && ((customer?.parents_access?.includes(cid)??false) || customer?.id==cid) );

  }
}
