import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiCar, IApiCarCustomerRequest, IApiCarListResponse, IApiCarResponse } from "../model.backend/car.model";
import { IApiCarSensorsResponse } from "../model.backend/carsensor.model";
import { UtilsService } from "../service/utils.service";
import { IApiResponseG, IApiResponseGList } from "../model.backend/response-g.model";
import { IApiDriverTrip } from "../model.backend/driver-trip.model";

@Injectable({
    providedIn: 'root'
  })
  export class DriverTripService extends BaseService {
    protected controller: string = "DriverTrip";
  
    constructor(
      private http: HttpClient,
      private utils: UtilsService,
    ) { super(); }

    
    get(id: string): Observable<IApiResponseG<IApiDriverTrip>> {
      return this.http.get<IApiResponseG<IApiDriverTrip>>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiResponseG<IApiDriverTrip>>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о поездке.'))
          );    
    }   
    
    listOnDate(onDate: Date): Observable<IApiResponseGList<IApiDriverTrip>> {
      return this.http.get<IApiResponseGList<IApiDriverTrip>>(`${this.apiUrl}/${this.controller}/listOnDate/${encodeURIComponent(this.utils.toNetCoreDatetime(onDate))}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiResponseGList<IApiDriverTrip>>(`GET ${this.controller}.list`, null, 'Ошибка получения списка поездок водителя.'))
          );
    }

    list(driverId: string|null|undefined): Observable<IApiResponseGList<IApiDriverTrip>> {
      return this.http.get<IApiResponseGList<IApiDriverTrip>>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(driverId ?? 'xxx')}`, {headers: this.headers})
          .pipe(
            catchError(this.handleError<IApiResponseGList<IApiDriverTrip>>(`GET ${this.controller}.list`, null, 'Ошибка получения списка поездок.'))
          );
    }

    delete(driverTripId: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(driverTripId||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления поездки.'))
          );    
    }

    insert(obj: IApiDriverTrip): Observable<IApiResponseG<IApiDriverTrip>> {
      return this.http.post<IApiResponseG<IApiDriverTrip>>(`${this.apiUrl}/${this.controller}/insert`, obj, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiResponseG<IApiDriverTrip>>(`POST ${this.controller}.insert`, null, 'Ошибка добавления поездки.'))
          );    
    }

    update(obj: IApiDriverTrip): Observable<IApiResponseG<IApiDriverTrip>> {
      return this.http.post<IApiResponseG<IApiDriverTrip>>(`${this.apiUrl}/${this.controller}/update`, obj, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiResponseG<IApiDriverTrip>>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о поездке.'))
          );    
    }
      

    /*

    list(customerid: string|null|undefined, filter: string): Observable<IApiCarListResponse> {
        return this.http.get<IApiCarListResponse>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(customerid||'xxx')}?filter=${encodeURIComponent(filter||'')}`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiCarListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка транспортных средств.'))
            );
    }

    

    getWithSensors(id: string): Observable<IApiCarSensorsResponse> {
      return this.http.get<IApiCarSensorsResponse>(`${this.apiUrl}/${this.controller}/sensors/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarSensorsResponse>(`GET ${this.controller}.getWithSensors`, null, 'Ошибка получения информации о транспортном средстве.'))
          );    
    }

    
    changeOwner(id: string, newcustomerId: string, newpin: string): Observable<IApiCarResponse> {
      let obj: IApiCarCustomerRequest = { customerId: newcustomerId, pin: newpin, action: 'changeowner' };
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/changecustomer/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.changeOwner`, null, 'Ошибка смены владельца транспортного средства.'))
          );    
    }

    /*
    addAccess(id: string, newcustomerId: string): Observable<IApiCarResponse> {
      let obj: IApiCarCustomerRequest = { customerId: newcustomerId, action: 'addaccess' };
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/changecustomer/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.addAccess`, null, 'Ошибка добавления компании для доступа транспортного средства.'))
          );    
    }

    removeAccess(id: string, customerId: string): Observable<IApiCarResponse> {
      let obj: IApiCarCustomerRequest = { customerId: customerId, action: 'removeaccess' };
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/changecustomer/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.removeAccess`, null, 'Ошибка добавления компании для доступа транспортного средства.'))
          );    
    }
*/

}
  