import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiCustomerBranch } from 'src/app/model.backend/customer.model';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import { nanoid } from 'nanoid'

@Component({
  selector: 'app-structure-branch-edit',
  templateUrl: './branch-edit.component.html',
  styleUrls: ['./branch-edit.component.css']
})
export class StructureBranchEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() branch = null as IApiCustomerBranch|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCustomerBranch>();
  @Output() onInsert = new EventEmitter<IApiCustomerBranch>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiCustomerBranch|null;
  destroy$ = new Subject<boolean>();

  options = {
  };

  isNew = () => this.branch==null;
  getHeader = () => this.translate.instant(this.isNew() ? 'structure.branchEdit.add' : 'structure.branchEdit.edit');

  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')
  
  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.maxLength(255), Validators.required]),
    address: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    lat: new FormControl(null as number|null),
    lon: new FormControl(null as number|null),
    work_time: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    phone: new FormControl(null as string|null, [Validators.maxLength(100)]),
    email: new FormControl(null as string|null, [Validators.maxLength(255)]),
    comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    visible: new FormControl(null as boolean|null),
  });

  constructor(
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.isNew() ? this.newRecord(): this.editRecord(); 
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.item=this.branch;
    this._loadFormFromObject(this.item);
    this.loading=false;
  }

  newRecord() {
    this.item={ id: nanoid() };
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(branch: IApiCustomerBranch) {
    branch.name = this.form.get('name')?.value;
    branch.address = this.form.get('address')?.value;
    branch.lat = this.form.get('lat')?.value;
    branch.lon = this.form.get('lon')?.value;
    branch.work_time = this.form.get('work_time')?.value;
    branch.phone = this.form.get('phone')?.value;
    branch.email = this.form.get('email')?.value;
    branch.comment = this.form.get('comment')?.value;
    branch.visible = this.form.get('visible')?.value;
  }

  _loadFormFromObject(branch: IApiCustomerBranch|null) {
    this.form.get('name')?.patchValue(branch?.name??null);
    this.form.get('address')?.patchValue(branch?.address??null);
    this.form.get('lat')?.patchValue(branch?.lat??null);
    this.form.get('lon')?.patchValue(branch?.lon??null);
    this.form.get('work_time')?.patchValue(branch?.work_time??null);
    this.form.get('email')?.patchValue(branch?.email??null);
    this.form.get('phone')?.patchValue(branch?.phone??null);
    this.form.get('comment')?.patchValue(branch?.comment??null);
    this.form.get('visible')?.patchValue(branch?.visible??null);
  }

  saveRecord() {
    if(this.form.invalid || !this.item) {
      this.form.markAllAsTouched();
      return;
    }
   
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.visible=false;
    if(this.isNew()) this.onInsert.emit(this.item);
    else this.onUpdate.emit(this.item);

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
