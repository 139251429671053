import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiCarGroupUser } from 'src/app/model.backend/cargroupuser.model';
import { CarGroupUserService } from 'src/app/service.backend/cargroupuser.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-structure-park-edit',
  templateUrl: './park-edit.component.html',
  styleUrls: ['./park-edit.component.css']
})
export class StructureParkEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() userid = null as string|null;
  @Input() customerid = null as string|null;
  @Input() ownEdit = false;
  @ViewChild('dt1') dt1 =null as Table|null;
  @ViewChild('dt2') dt2 =null as Table|null;


  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCarGroupUser>();
  @Output() onInsert = new EventEmitter<IApiCarGroupUser>();

  loading = false;
  saving = false;
  error = null as string|null;  
  disableSave = false;
  item = {} as IApiCarGroupUser|null;
  destroy$ = new Subject<boolean>();

  options = {
    users: [] as INameCode[],
    srcparks: [] as INameCode[]
  };

  data = {
    srccars: [] as IApiCar[],
    cars: [] as IApiCar[],
    selectedSrcCars: [] as IApiCar[],
    selectedCars: [] as IApiCar[],
  }

  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'structure.parkEdit.add' : 'structure.parkEdit.edit');

  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')
  
  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
    srcuserid: new FormControl(null as string|null ),
    userid: new FormControl({value: null as string|null, disabled: true}),
    srcpark: new FormControl(null as string|null),
    filter1: new FormControl(null as string|null),
    filter2: new FormControl(null as string|null),
  });

  constructor(
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private carGroupUserService: CarGroupUserService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {

    this.loadOptionsUsers();
    this.form.get('srcpark')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(parkid => {
      this.loadDataSrcCars();
      this.removeDataSrcCarsInCars();
    });
    this.form.get('srcuserid')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(uid => {
      this.loadOptionsSrcParks();
      this.form.get('srcpark')?.patchValue('*');
    });
    this.form.get('userid')?.patchValue(this.userid);
    this.form.get('srcuserid')!.patchValue(this.userid);
    if( this.ownEdit) this.form.get('srcuserid')?.disable();

    this.isNew() ? this.newRecord(): this.editRecord(); 
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  loadOptionsUsers() {
    this.options.users=[];
    if(this.customerid) {
      let srcusers=this.preloaderService.getCompanyUsers(this.customerid);
      (srcusers||[]).forEach(u=>this.options.users.push({name: u.login, code: u.id})); 
    }
//    console.log('loadOptionsUsers', this.customerid, this.options.users);
  }

  loadOptionsSrcParks() {
//    console.log('loadOptionsSrcParks');
    this.options.srcparks=[{name: 'Все доступные ТС', code: '*'}];
    let srcuserid=this.form.get('srcuserid')?.value;
    if(srcuserid && this.customerid) {
      let parks=this.preloaderService.getCarGroupUsersForUser(srcuserid);
      (parks||[]).forEach(p=>this.options.srcparks.push({name: p.name, code: p.id}));
    }
  }

  loadDataSrcCars() {
//    console.log('loadDataSrcCars');
    this.data.srccars=[];
    let srcuserid=this.form.get('srcuserid')?.value;
    if(srcuserid && this.customerid) {
      // все ТС
      let cars: IApiCar[]|null = [];
      let parkid = this.form.get('srcpark')?.value;
      if(parkid=='*') {
        cars = this.preloaderService.getCarsForUser(srcuserid, this.customerid);
      } else {       
        let c = this.preloaderService.getCarGroupUser(srcuserid, parkid??'xxx')?.carids||[];
        (c||[]).forEach(carid=>{
           let car = this.preloaderService.getCar(carid);
           if(car) cars?.push({...car});
        });
        cars = this.preloaderService.сheckedCarsForUser(cars,this.userid??'xxx');
      }
      this.data.srccars=[...cars.sort((a,b)=>(a.number??'')>(b.number??'')?1:-1)]; 
    }
  }

  removeDataSrcCarsInCars() {
    let ix=0;
    while(ix<(this.data.srccars||[]).length){
      let f  = this.data.cars.find(x=>x.id==this.data.srccars[ix].id);
      if(f) this.data.srccars.splice(ix,1);
      else ix++;
    }
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.carGroupUserService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.group);
          this.item = res.group;
          this._loadFormFromObject(this.item);
          this.removeDataSrcCarsInCars();
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
          this.disableSave = true;
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
        this.disableSave = true;
      }
    })
  }

  newRecord() {
    this.item={customerid: this.customerid, userid: this.userid};
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(group: IApiCarGroupUser) {
    group.name = this.form.get('name')?.value;
    group.carids = this.data.cars.map(x=>x.id??'').filter(x=>x!='');
  }

  _loadFormFromObject(group: IApiCarGroupUser|null) {
    this.form.get('name')?.patchValue(group?.name??null);
    if(this.userid && this.customerid) {
      let cars=this.preloaderService.getCarsForUser(this.userid, this.customerid);     
      this.data.cars =[];
      group?.carids?.forEach(c=>{
        let f = cars.find(x=>x.id==c);
        if(f) this.data.cars.push({...f});
      })
    }
  }

  saveRecord() {
    if(this.form.invalid || !this.item) {
      this.form.markAllAsTouched();
      return;
    }
  
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.carGroupUserService.insert(this.item)
      : this.carGroupUserService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.group};             
          this.visible=false;
          if(this.isNew()){
            this.preloaderService.addNewCarGroupUser(this.item.userid??'xxx', this.item);
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.preloaderService.updateCarGroupUser(this.item.userid??'xxx', this.item);
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

  onClickAllToLeft() {
    (this.data.cars||[]).forEach(c=>{
      let f = this.data.srccars.find(x=>x.id==c.id);
      if(!f) this.data.srccars.push({...c});
    })
    this.data.cars=[];
    this.data.selectedCars=[];
  }

  onClickAllToRight() {
    (this.data.srccars||[]).forEach(c=>{
      let f = this.data.cars.find(x=>x.id==c.id);
      if(!f) this.data.cars.push({...c});
    })
    this.data.srccars=[];
    this.data.selectedSrcCars=[]
  }

  onClickToRight() {
    (this.data.selectedSrcCars||[]).forEach(c=>{
      let f = this.data.cars.find(x=>x.id==c.id);
      if(!f) this.data.cars.push({...c});
    });
    this.removeDataSrcCarsInCars();
    this.data.selectedSrcCars=[];
  }
  
  onClickToLeft() {
    (this.data.selectedCars||[]).forEach(c=>{
      let f = this.data.srccars.find(x=>x.id==c.id);
      if(!f) this.data.srccars.push({...c});
      let ix = this.data.cars.findIndex(x=>x.id==c.id);
      if(ix>=0) this.data.cars.splice(ix,1);
    });
    this.data.selectedCars=[];

    let srcuserid=this.form.get('srcuserid')?.value;
    this.data.srccars = this.preloaderService.сheckedCarsForUser(this.data.srccars, srcuserid??'xxx')
  }

  onInputFilter1(event: any) {
    this.dt1?.filterGlobal(event.target.value, 'contains')
  }

  onInputFilter2(event: any) {
    this.dt2?.filterGlobal(event.target.value, 'contains')
  }

  onClickFilter1Clear() {
    this.form.get('filter1')?.patchValue(null);
    this.dt1?.filterGlobal('', 'contains') 
  } 

  onClickFilter2Clear()  {
     this.form.get('filter2')?.patchValue(null);
     this.dt2?.filterGlobal('', 'contains') 
  }
}
