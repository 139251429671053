<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '70vw' }" 
    styleClass="shadow-5 sav3-dialog"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-6 pb-0">
                <div><label class="sav3-label" for="srcuserid">{{'structure.parkEdit.fieldSrcLogin'|translate}}</label></div>
                <p-dropdown formControlName="srcuserid" 
                    [options]="options.users"
                    optionLabel="name"
                    optionValue="code"
                    id="srcuserid" 
                    class="w-full sav3-input p-inputtext-sm" 
                    styleClass="w-full sav3-input">
                </p-dropdown>
            </div>
            <div class="col-6 pb-0">
                <div><label class="sav3-label" for="userid">{{'structure.parkEdit.fieldLogin'|translate}}</label></div>
                <p-dropdown formControlName="userid" 
                    [options]="options.users"
                    optionLabel="name"
                    optionValue="code"
                    id="userid" 
                    class="w-full sav3-input p-inputtext-sm" 
                    styleClass="w-full sav3-input">
                </p-dropdown>
            </div>
            <div class="col-6">
                <div><label class="sav3-label" for="srcpark">{{'structure.parkEdit.fieldSrcPark'|translate}}</label></div>
                <p-dropdown formControlName="srcpark" 
                    [options]="options.srcparks"
                    optionLabel="name"
                    optionValue="code"
                    id="srcpark" 
                    class="w-full sav3-input p-inputtext-sm"  
                    styleClass="w-full sav3-input ">
                </p-dropdown>
            </div>
            <div class="col-6">
                <div><label class="sav3-label" for="name">{{'structure.parkEdit.fieldPark'|translate}}</label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.required'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div style="width: 47%;">
                <p-table #dt1
                    [value]="data.srccars"
                    [globalFilterFields]="['number','display_name']" 
                    [(selection)]="data.selectedSrcCars"
                    dataKey="id"
                    class="p-datatable-sm" 
                    [tableStyle]="{'width':'100%'}"
                    [style]="{'height':'320px'}"
                    scrollHeight="250px"
                
                >
                    <ng-template pTemplate="caption">
                        <span class="p-input-icon-right w-full">
                            <i (click)="onClickFilter1Clear()" class="pi pi-times" [style]="{'right':'30px'}"></i>
                            <i class="pi pi-search"></i>
                            <input 
                                formControlName="filter1"
                                (input)="onInputFilter1($event)" 
                                pInputText type="text" 
                                class="w-full sav3-input p-inputtext-sm" 
                                placeholder="{{'structure.parkEdit.fieldSrcSearch'|translate}}" 
                            />
                        </span>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr class="text-sm">
                            <th class="p-1"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                            <th class="p-1">{{'structure.parkEdit.fieldNumber'|translate}}</th>
                            <th class="p-1">{{'structure.parkEdit.fieldName'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-car>
                        <tr class="text-sm">
                            <td class="p-1"><p-tableCheckbox [value]="car"></p-tableCheckbox></td>
                            <td class="p-1">{{car.number}}</td>
                            <td class="p-1">{{car.display_name}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div style="width: 6%;">
                <div class="flex align-items-center justify-content-center flex-column h-full">
                    <div class="h-3rem"></div>
                    <div><p-button (onClick)="onClickAllToLeft()" icon="pi pi-angle-double-left" styleClass="p-button-outlined p-button-secondary p-button-sm" [disabled]="data.cars.length==0" /></div>
                    <div><p-button (onClick)="onClickToLeft()" icon="pi pi-angle-left" styleClass="p-button-outlined p-button-secondary p-button-sm mt-2" [disabled]="data.selectedCars.length==0" /></div>
                    <div><p-button (onClick)="onClickToRight()" icon="pi pi-angle-right" styleClass="p-button-outlined p-button-secondary p-button-sm mt-2" [disabled]="data.selectedSrcCars.length==0" /></div>
                    <div><p-button (onClick)="onClickAllToRight()" icon="pi pi-angle-double-right" styleClass="p-button-outlined p-button-secondary p-button-sm mt-2" [disabled]="data.srccars.length==0" /></div>
                </div>
            </div>
            <div style="width: 47%;">
                <p-table #dt2
                    [value]="data.cars"
                    [globalFilterFields]="['number','display_name']" 
                    [(selection)]="data.selectedCars"
                    dataKey="id"
                    class="p-datatable-sm" 
                    [tableStyle]="{'width':'100%'}"
                    [style]="{'height':'320px'}"
                    scrollHeight="250px"
                >
                    <ng-template pTemplate="caption">
                        <span class="p-input-icon-right w-full">
                            <i (click)="onClickFilter2Clear()" class="pi pi-times" [style]="{'right':'30px'}"></i>
                            <i class="pi pi-search"></i>
                            <input 
                                formControlName="filter2"
                                (input)="onInputFilter2($event)" 
                                pInputText type="text" 
                                class="w-full sav3-input p-inputtext-sm" 
                                placeholder="{{'structure.parkEdit.fieldSrcSearch'|translate}}" 
                            />
                        </span>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr class="text-sm">
                            <th class="p-1"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                            <th class="p-1">{{'structure.parkEdit.fieldNumber'|translate}}</th>
                            <th class="p-1">{{'structure.parkEdit.fieldName'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-car>
                        <tr class="text-sm">
                            <td class="p-1"><p-tableCheckbox [value]="car"></p-tableCheckbox></td>
                            <td class="p-1">{{car.number}}</td>
                            <td class="p-1">{{car.display_name}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer" class="text-center">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading || disableSave"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary p-button-rounded">
        </p-button>
    </ng-template>
</p-dialog>

