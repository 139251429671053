import { Component } from '@angular/core';

@Component({
  selector: 'app-norecords',
  templateUrl: './norecords.component.html',
  styleUrls: ['./norecords.component.css']
})
export class NoRecordsComponent {
  constructor(
  ) {
  }

}
