import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StyleClassModule } from 'primeng/styleclass';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { BlockUIModule } from 'primeng/blockui'
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { BadgeModule } from 'primeng/badge';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { PasswordModule } from 'primeng/password';
import { ListboxModule } from 'primeng/listbox';
import { TabViewModule } from 'primeng/tabview';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AvatarModule } from 'primeng/avatar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ChipsModule } from 'primeng/chips';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TreeSelectModule } from 'primeng/treeselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { FileUploadModule } from 'primeng/fileupload';
import { ColorPickerModule } from 'primeng/colorpicker';
import { SelectButtonModule } from 'primeng/selectbutton';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { StdphonePipe } from './pipe/stdphone.pipe';
import { IsoToDDMMYYYYPipe } from './pipe/iso-to-ddmmyy.pipe';
import { IsoToHHMMSSPipe } from './pipe/iso-to-hhmmss.pipe';
import { IsoToHHMMPipe } from './pipe/iso-to-hhmm.pipe';
import { IsoToDDMMYYYY_HHMMSSPipe } from './pipe/iso-to-ddmmyy-hhmmss.pipe';
import { SixDigitPipe } from './pipe/six-digit.pipe';

import { MasterMenuComponent } from './component/master-page/master-menu/master-menu.component';
import { MasterBreadcrumbComponent } from './component/master-page/master-breadcrumb/master-breadcrumb.component';
import { PagesSysLoginComponent } from './pages-sys/login-page/login.component';
import { AuthJwtInterceptor } from './interceptor/auth-jwt.interceptor';
import { AuthError401Interceptor } from './interceptor/auth-error401.interceptor';
import { PagesSysPage404Component } from './pages-sys/page404/page404.component';
import { PageMainComponent } from './pages/page-main/page-main.component';
import { ErrorBarComponent } from './component/page-elements/errorbar/errorbar.component';
import { ProgressBarComponent } from './component/page-elements/progress-bar/progressbar.component';
import { NoRecordsComponent } from './component/page-elements/norecords/norecords.component';
import { PageMapComponent } from './pages/page-map/page-map.component';
import { PageDriversComponent } from './pages/page-drivers/page-drivers.component';
import { DriverListComponent } from './component/drivers/~driver-list/driver-list.component';
import { DriverEditComponent } from './component/drivers/~driver-edit/driver-edit.component';
import { PageRequestSupportComponent } from './pages/page-requestsupport/page-requestsupport.component';
import { RequestSupportListComponent } from './component/request-supports/requestsupport-list/requestsupport-list.component';
import { RequestSupportEditComponent } from './component/request-supports/requestsupport-edit/requestsupport-edit.component';
import { PageTasksComponent } from './pages/page-tasks/page-tasks.component';
import { TaskListComponent } from './component/tasks/task-list/task-list.component';
import { TaskEditComponent } from './component/tasks/task-edit/task-edit.component';
import { NoticeListComponent } from './component/notice/notice-list/notice-list.component';
import { NoticeEditComponent } from './component/notice/notice-edit/notice-edit.component';
import { PageNoticesComponent } from './pages/page-notices/page-notices.component';
import { NoticeScheduleComponent } from './component/notice/notice-schedule/notice-schedule.component';
import { CarGroupSelectComponent } from './component/cargroup/cargroup-select/cargroup-select.component';
import { CarCardComponent } from './component/cars/~car-card/car-card.component';
import { MessageListComponent } from './component/messages/message-list/message-list.component';
import { PageMessagesComponent } from './pages/page-messages/page-messages.component';
import { GeozoneCardComponent } from './component/geozones/geozone-card/geozone-card.component';
import { ChartererListComponent } from './component/charterers/charterer-list/charterer-list.component';
import { PageCharterersComponent } from './pages/page-charterers/page-charterers.component';
import { ChartererEditComponent } from './component/charterers/charterer-edit/charterer-edit.component';
import { CarEditComponent } from './component/cars/car-edit/car-edit.component';
import { CarGroupComponent } from './component/cargroup/cargroup/cargroup.component';
import { PageCarGroupsComponent } from './pages/page-cargroups/page-cargroups.component';
import { CarGroupEditComponent } from './component/cargroup/cargroup-edit/cargroup-edit.component';
import { PageCarsComponent } from './pages/page-cars/page-cars.component';
import { CarListComponent } from './component/cars/car-list/car-list.component';
import { PageObjectsComponent } from './pages/page-objects/page-objects.component';
import { ImportDialogComponent } from './component/page-elements/import-dialog/import-dialog.component';
import { PageFinancesComponent } from './pages/page-finances/page-finances.component';
import { FinanceListComponent } from './component/finances/finance-list/finance-list.component';
import { ProfileEditComponent } from './component/user/profile-edit/profile-edit.component';
import { ChangePasswordComponent } from './component/user/change-password/change-password.component';
import { MapComponent } from './component/map/map.component';
import { CarInfoComponent } from './component/cars/car-info/car-info.component';
import { GeozoneNameEditComponent } from './component/geozones/geozone-name-edit/geozone-name-edit.component';
import { DriverTripListComponent } from './component/drivers/~driver-trip-list/driver-trip-list.component';
import { PageDriverTripsComponent } from './pages/page-driver-trips/page-driver-trips.component';
import { DriverTripEditComponent } from './component/drivers/~driver-trip-edit/driver-trip-edit.component';
import { CarCardComponent2 } from './component/cars/car-card2/car-card2.component';
import { PageStructureComponent } from './pages/page-structure/page-structure.component';
import { StructureListComponent } from './component/structure/structure-list/structure-list.component';
import { StructureEditComponent } from './component/structure/structure-edit/structure-edit.component';
import { StructureUserEditComponent } from './component/structure/user-edit/user-edit.component';
import { StructureParkEditComponent } from './component/structure/park-edit/park-edit.component';
import { StructureBranchEditComponent } from './component/structure/branch-edit/branch-edit.component';
import { DriverCardListComponent } from './component/drivers/driver-card-list/driver-card-list.component';
import { DriverCardComponent } from './component/drivers/driver-card/driver-card.component';
import { CarCard2StatusComponent } from './component/cars/car-card2-status/car-card2-status.component';
import { DriverEdit2Component } from './component/drivers/driver-edit2/driver-edit2.component';
import { DriverTripsComponent } from './component/drivers/driver-trips/driver-trips.component';
import { from } from 'rxjs';
import { CarCardListComponent } from './component/cars/car-card-list/car-card-list.component';
import { CarEdit2Component } from './component/cars/car-edit2/car-edit2.component';
import { CustomerSelectComponent } from './component/customer/customer-select/customer-select.component';
import { CarGroupUserSelectComponent } from './component/cargroupuser/cargroupuser-select/cargroupuser-select.component';
import { CarFilterComponent } from './component/cars/car-filter/car-filter.component';
import { CarGroupUserListComponent } from './component/cargroupuser/cargroupuser-list/cargroupuser-list.component';
import { UserFirstLoginEditComponent } from './component/user/user-firstlogin-edit/user-firstlogin-edit.component';
import { CarCommandComponent } from './component/cars/car-command/car-command.component';
import { ProfileEdit2Component } from './component/user/profile-edit2/profile-edit2.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    StdphonePipe,
    IsoToDDMMYYYYPipe,
    IsoToHHMMSSPipe,
    IsoToHHMMPipe,
    IsoToDDMMYYYY_HHMMSSPipe,
    SixDigitPipe,
    
  // components
    MasterMenuComponent,
    MasterBreadcrumbComponent,
    ProgressBarComponent,
    ErrorBarComponent,
    NoRecordsComponent,
    NoticeScheduleComponent,

    CarGroupSelectComponent,
    CarCardComponent,
    CarCardComponent2,
    CarCard2StatusComponent,
    GeozoneCardComponent,
    GeozoneNameEditComponent,
    ImportDialogComponent,

    DriverListComponent,
    DriverEditComponent,
    DriverTripListComponent,
    DriverTripEditComponent,
    RequestSupportListComponent,
    RequestSupportEditComponent,
    TaskListComponent,
    TaskEditComponent,
    NoticeListComponent,
    NoticeEditComponent,
    MessageListComponent,
    ChartererListComponent,
    ChartererEditComponent,
    CarEditComponent,
    CarInfoComponent,
    CarGroupComponent,
    CarGroupEditComponent,
    CarListComponent,
    FinanceListComponent,
    ProfileEditComponent,
    ProfileEdit2Component,
    ChangePasswordComponent,
    MapComponent,
    StructureListComponent,
    StructureEditComponent,
    StructureUserEditComponent,
    StructureParkEditComponent,
    StructureBranchEditComponent,
    DriverCardListComponent,
    DriverCardComponent,
    DriverEdit2Component,
    DriverTripsComponent,
    CarCardListComponent,
    CarEdit2Component,
    CustomerSelectComponent,
    CarGroupUserSelectComponent,
    CarFilterComponent,
    CarGroupUserListComponent,
    UserFirstLoginEditComponent,
    CarCommandComponent,
    
    PagesSysPage404Component,
    PagesSysLoginComponent,
    PageMainComponent,
    PageMapComponent,
    PageDriversComponent,
    PageDriverTripsComponent,
    PageRequestSupportComponent,
    PageTasksComponent,
    PageNoticesComponent,
    PageMessagesComponent,
    PageCharterersComponent,
    PageCarGroupsComponent,
    PageCarsComponent,
    PageObjectsComponent,
    PageFinancesComponent,
    PageStructureComponent,
    
    AppComponent,   
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    //i18
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'ru'
        }),
    BrowserAnimationsModule,
  
    //prime-ng   
    StyleClassModule ,
    ToastModule ,
    MessagesModule,
    MessageModule,
    BlockUIModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    CheckboxModule,
    TableModule,
    TagModule,
    ChipModule,
    BadgeModule,
    ProgressBarModule, 
    DialogModule,
    InputTextareaModule,
    ConfirmPopupModule,
    PasswordModule,
    ListboxModule,
    TabViewModule,
    InputNumberModule,
    DropdownModule,
    CalendarModule,
    DividerModule,
    TooltipModule,
    TreeModule,
    RadioButtonModule,
    AvatarModule,
    MultiSelectModule,
    PanelModule,
    ChipsModule,
    OverlayPanelModule,
    TreeSelectModule,    
    SplitButtonModule,
    FileUploadModule,
    ColorPickerModule,
    SelectButtonModule,
    
    //app,
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthJwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthError401Interceptor, multi: true },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],
  exports: [
    TranslateModule,
  ]
})
export class AppModule { }
