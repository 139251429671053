<div class="px-1 py-2 flex align-items-center">
  <div>
      <h2 class="dh-page-caption mr-5 my-0">
          <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-globe"></p-avatar>
          {{'driver-trips.title'|translate}}
      </h2>
  </div>
  <!--
  <div>
      <p-button (click)="clickRecordAdd()" icon="pi pi-plus" [label]="'Добавить поездку'" styleClass="dh-button-hover p-button-sm p-button-outlined p-button-secondary mr-1"></p-button>
  </div>
  <div class="ml-auto">
      <p-splitButton [label]="'driver.export'|translate" icon="pi pi-file-export" appendTo="body" [model]="options.export" styleClass="p-button-sm p-button-secondary"></p-splitButton>       
      <p-button (click)="clickImport()" [label]="'driver.import'|translate" icon="pi pi-file-import" styleClass="p-button-sm p-button-secondary ml-1"></p-button>
  </div>
  -->
</div>
<div id="toexport">
  <p-table [value]="data.drivers||[]" [rowHover]="true" styleClass="p-datatable">
      <ng-template pTemplate="header">
          <tr>
              <td colspan="3" style="height: 5px;">
                  <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                  <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
              </td>
          </tr>
          <tr class="bg-gray-100 font-bold">
              <th>№</th>
              <th>{{'driver.driver'|translate}}</th>
              <th>Текущее ТС</th>
              <th>Начало работы на ТС</th>
              <th>Окончание работы на ТС</th>
              <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-driverExt let-index="rowIndex">
          <tr>
              <td>
                  {{index+1}}.
              </td>
              <td>
                  {{driverExt.family}} {{driverExt.name}} {{driverExt.surname}}
              </td>
              <td>
                <div *ngFor="let trip of driverExt.trips">
                    {{trip.carName}}
                </div>
              </td>
              <td>
                <div *ngFor="let trip of driverExt.trips">
                    {{trip.from|isoToDDMMYYYY_HHMMSS}}
                </div>
              </td>
              <td>
                <div *ngFor="let trip of driverExt.trips">
                    {{trip.to ? (trip.to|isoToDDMMYYYY_HHMMSS) : '&nbsp;'}}
                </div>
              </td>
              <td class="text-right white-space-nowrap">
                <p-button (click)="clickRecordAdd(driverExt);$event.stopPropagation()" [pTooltip]="'form.add'|translate" icon="pi pi-plus" styleClass="p-button-sm mr-1 dh-onhover"></p-button>                    
                <p-button (click)="clickRecordEdit(driverExt);$event.stopPropagation()" [pTooltip]="'form.edit'|translate" icon="pi pi-pencil" styleClass="p-button-sm mr-1 dh-onhover"></p-button>
                  <!--
                  <p-button (click)="clickRecordDelete(driverExt, $event);$event.stopPropagation()" [pTooltip]="'form.delete'|translate" icon="pi pi-times" styleClass="p-button-sm p-button-danger dh-onhover"></p-button>
                  -->
              </td>
          </tr>
      </ng-template>
  </p-table>
  <app-norecords *ngIf="(data.drivers||[]).length==0 && !data.error"></app-norecords>   
</div>


<app-driver-trip-edit *ngIf="dialog.edit.show"
  [isNew]="dialog.isNew"
  [cars]="data.cars"
  [driversExt]="data.drivers"
  [driver]="dialog.edit.driver"
  [driverTrips]="data.driverTrips"
  (onHide)="onDialogEditHide()"
  (onUpdate)="onDialogEditUpdate($event)"
>
</app-driver-trip-edit>

