import { Component } from '@angular/core';

@Component({
  selector: 'app-page-tasks',
  templateUrl: './page-tasks.component.html',
  styleUrls: ['./page-tasks.component.css']
})
export class PageTasksComponent {

}
