import { Component, Input } from '@angular/core';
import { IApiCarStatus, IApiCarStatus_StatusEnum } from 'src/app/model.backend/carstatus.model';


@Component({
  selector: 'app-car-card2-status',
  templateUrl: './car-card2-status.component.html',
  styleUrls: ['./car-card2-status.component.css']
})
export class CarCard2StatusComponent {
  @Input() carstatus!: IApiCarStatus|null;
  @Input() withText = false

  IApiCarStatus_StatusEnum = IApiCarStatus_StatusEnum;

  constructor(
  ) {
  }

}
