import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiDriver, IApiDriverListResponse, IApiDriverResponse } from "../model.backend/driver.model";
import { IApiCharterer, IApiChartererListResponse, IApiChartererResponse } from "../model.backend/charterer.model";

@Injectable({
    providedIn: 'root'
  })
  export class ChartererService extends BaseService {
    protected controller: string = "charterer";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(): Observable<IApiChartererListResponse> {
        return this.http.get<IApiChartererListResponse>(`${this.apiUrl}/${this.controller}/list`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiChartererListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка заказчиков.'))
            );    
    }   

    get(id: string): Observable<IApiChartererResponse> {
      return this.http.get<IApiChartererResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiChartererResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о заказчике.'))
          );    
    }

    insert(obj: IApiCharterer): Observable<IApiChartererResponse> {
      return this.http.post<IApiChartererResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiChartererResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления заказчика.'))
          );    
    }
  
    update(id: string, obj: IApiCharterer): Observable<IApiChartererResponse> {
      return this.http.post<IApiChartererResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiChartererResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о заказчике.'))
          );    
    }

    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления заказчика.'))
          );    
    }


    import(obj: IApiCharterer[]): Observable<IApiChartererListResponse> {
      return this.http.post<IApiChartererListResponse>(`${this.apiUrl}/${this.controller}/import`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiChartererListResponse>(`POST ${this.controller}.import`, null, 'Ошибка импорта заказчиков.'))
          );    
    }
}
  