import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { IApiAuthserverLoginRequest, IApiAuthServerLoginResponse } from "../model.backend/authserver.model";
import { catchError } from "rxjs/internal/operators/catchError";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class AuthserverService extends BaseService {
    protected controller: string = "auth";
  
    constructor(
      private http: HttpClient,
      private translate: TranslateService 
    ) 
    { 
        super();
    }

    login(login: string, password: string, loginasuser: string|null): Observable<IApiAuthServerLoginResponse> {
        let req: IApiAuthserverLoginRequest = { login: login, password: password, loginasuser: loginasuser, language: this.translate.currentLang, app: 'client-lk' };
        return this.http.post<IApiAuthServerLoginResponse>(`${this.authApiUrl}/${this.controller}/Login`, JSON.stringify(req), {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiAuthServerLoginResponse>(`POST ${this.controller}.Login`, null, 'Ошибка подключения.'))
            );    
    }   

    loginWithToken(): Observable<IApiAuthServerLoginResponse> {
        return this.http.get<IApiAuthServerLoginResponse>(`${this.authApiUrl}/${this.controller}/LoginWithToken`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiAuthServerLoginResponse>(`POST ${this.controller}.LoginWithToken`, null, 'Ошибка подключения.'))
            );    
    }

    refreshToken(): Observable<IApiAuthServerLoginResponse> {
        return this.http.get<IApiAuthServerLoginResponse>(`${this.authApiUrl}/${this.controller}/RefreshToken`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiAuthServerLoginResponse>(`POST ${this.controller}.RefreshToken`, null, 'Ошибка подключения.'))
            );    
    }


}
  