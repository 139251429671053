<div class="car-card shadow-1 hover:shadow-3 mb-2">
    <div class="flex align-items-start">
        <div class="mr-3">
            <p-checkbox (onChange)="onSelectChange.emit(ui.select)" [(ngModel)]="ui.select" [binary]="true"></p-checkbox>
        </div>
        <div (click)="Card_click()"  class="w-full">
            <div class="flex">
                <div class="border-1 border-round border-gray-300 mr-3" style="padding: 3px;">
                    <img src="assets/images/car-default.png" style="width: 50px;height: 50px;" />
                </div>
                <div class="flex flex-column w-full">
                    <div class="flex align-items-center w-full mb-2">
                        <div *ngIf="car.number" class="mr-2 font-bold sav3-car-number">
                            {{car.number}}
                        </div>
                        <div class="mr-2 text-sm">
                            {{car.brand}}
                        </div>
                        <div class="ml-auto flex align-items-start">
                            <div class="mr-1">
                                <button class="p-disabled p-button p-button-rounded p-button-text p-1 sav3" [ngClass]="{'p-disabled': previewMode}" pButton>
                                    <i class="pi pi-video"></i>
                                </button>
                            </div>
                            <div class="mr-1">
                                <button (click)="$event.stopPropagation(); ui.favorite=!ui.favorite; onFavoriteChange.emit(ui.favorite)" class="p-disabled p-button p-button-rounded p-button-text p-1 sav3" [ngClass]="{'p-disabled': previewMode}" pButton>
                                    <i class="pi {{ui.favorite?'pi-star-fill':'pi-star'}}"></i>
                                </button>
                            </div>
                            <div class="mr-1">
                                <button (click)="$event.stopPropagation(); onCarEdit.emit(car)" class="p-button p-button-rounded p-button-text p-1 sav3" [ngClass]="{'p-disabled': previewMode}" pButton>
                                    <i class="pi pi-cog"></i>
                                </button>
                            </div>                
                        </div>    
                    </div>
                    <div class="flex w-full">
                        <div class="mr-2 text-xs w-6">
                            {{car.vin}}
                        </div>
                        <div *ngIf="getDriverInfo()" class="flex align-items-center text-xs w-6">
                            <div><i class="pi pi-user mr-1 text-gray-400"></i></div>
                            <div>{{getDriverInfo()?.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex align-items-center text-sm">
                <div class="flex align-items-center">
                    <div *ngIf="!previewMode && getSensorValueString(0)"><i class="pi pi-wrench text-gray-400 text-sm"></i></div>
                    <div *ngIf="!previewMode && getSensorValueString(0)" class="mr-3">{{getSensorValueString(0)}}</div>
                    <p-dropdown *ngIf="previewMode" 
                        (onChange)="onPreviewSensor_Change()"
                        [(ngModel)]="previewSensors[0]"
                        [options]="options.sensors" optionLabel="name" optionValue="code" 
                        class="w-full sav3-input-xs" 
                        styleClass="w-full sav3-input-xs" 
                        appendTo="body">
                    </p-dropdown>
                </div>
                <div class="flex align-items-center">
                    <div *ngIf="!previewMode && getSensorValueString(1)"><i class="pi pi-wrench  text-gray-400 text-sm"></i></div>
                    <div *ngIf="!previewMode && getSensorValueString(1)" class="mr-3">{{getSensorValueString(1)}}</div>
                    <p-dropdown *ngIf="previewMode" 
                        (onChange)="onPreviewSensor_Change()"
                        [(ngModel)]="previewSensors[1]"
                        [options]="options.sensors" optionLabel="name" optionValue="code" 
                        class="w-full sav3-input-xs" 
                        styleClass="w-full sav3-input-xs" 
                        appendTo="body">
                    </p-dropdown>
                </div>
                <div class="flex align-items-center">
                    <div *ngIf="!previewMode && getSensorValueString(2)"><i class="pi pi-wrench  text-gray-400 text-sm"></i></div>
                    <div *ngIf="!previewMode && getSensorValueString(2)" class="mr-3">{{getSensorValueString(2)}}</div>
                    <p-dropdown *ngIf="previewMode" 
                        (onChange)="onPreviewSensor_Change()"
                        [(ngModel)]="previewSensors[2]"
                        [options]="options.sensors" optionLabel="name" optionValue="code" 
                        class="w-full sav3-input-xs" 
                        styleClass="w-full sav3-input-xs" 
                        appendTo="body">
                    </p-dropdown>
                </div>
                <div class="flex ml-auto">
                    <div *ngIf="CarStatusHelper.isCarOffline(carstatus)" style="height: 26px; width: 26px; border-radius: 13px;" class="flex align-items-center justify-content-center bg-gray-200 mr-1">
                        <div>
                            <i class="pi pi-wifi mt-1 text-red-500"></i>
                        </div>
                    </div>
                    <div *ngIf="!CarStatusHelper.isCarOffline(carstatus)" style="height: 26px; width: 26px; border-radius: 13px;" class="flex align-items-center justify-content-center mr-1">
                        <div>
                            <i class="pi pi-wifi mt-1 text-green-500"></i>
                        </div>
                    </div>
                    <div *ngIf="CarStatusHelper.isGarNoGps(carstatus)" style="height: 26px; width: 26px; border-radius: 13px;" class="flex align-items-center justify-content-center bg-gray-200 mr-1">
                        <div>
                            <i class="pi pi-compass mt-1 text-red-500"></i>
                        </div>
                    </div>
                    <div *ngIf="!CarStatusHelper.isGarNoGps(carstatus)" style="height: 26px; width: 26px; border-radius: 13px;" class="flex align-items-center justify-content-center mr-1">
                        <div>
                            <i class="pi pi-compass mt-1 text-green-500"></i>
                        </div>
                    </div>                    
                    <div *ngIf="CarStatusHelper.isCarError(carstatus)" style="height: 26px; width: 26px; border-radius: 13px;" class="flex align-items-center justify-content-center bg-red-50 mr-1">
                        <div>
                            <i class="pi pi-exclamation-triangle mt-1 text-red-300"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="carstatus?.location || carstatus?.status" class="flex text-sm align-items-start mt-3">
                <div *ngIf="carstatus?.location" class="mr-1">
                    <i class="pi pi-map-marker text-red-600"></i>
                </div>
                <div *ngIf="carstatus?.location" class="mr-1">
                    {{carstatus?.location}}
                </div>
                <div class="ml-auto mr-2" style="padding-right: 2px;">
                    <app-car-card2-status [carstatus]="carstatus"></app-car-card2-status>
                    <!--
                    <i *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.Offline" 
                        [pTooltip]="'cars.status_offline'|translate"
                        class="pi pi-times text-red-600"
                    ></i>
                    <i *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.IgnitionIsOn" 
                        [pTooltip]="'cars.status_ignition'|translate"
                        class="pi pi-key text-green-600"
                    ></i>
                    <i *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.Movement" 
                        [pTooltip]="'cars.status_movement'|translate"
                        class="pi pi-play text-green-600"
                    ></i>
                    <i *ngIf="carstatus?.status==IApiCarStatus_StatusEnum.Parking" 
                        [pTooltip]="'cars.status_parking'|translate"
                        class="pi pi-stop text-blue-600"
                    ></i>
                    -->
                </div>
            </div>

        </div>
    </div>


</div>
