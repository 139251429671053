import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiCarGroupUser, IApiCarGroupUserListResponse, IApiCarGroupUserResponse } from "../model.backend/cargroupuser.model";

@Injectable({
    providedIn: 'root'
  })
  export class CarGroupUserService extends BaseService {
    protected controller: string = "cargroupuser";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

   list(userid: string): Observable<IApiCarGroupUserListResponse> {
      return this.http.get<IApiCarGroupUserListResponse>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(userid)}`, {headers: this.headers} )
        .pipe(
          catchError(this.handleError<IApiCarGroupUserListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка групп пользователя.'))
        );    
    }   

    get(id: string): Observable<IApiCarGroupUserResponse> {
      return this.http.get<IApiCarGroupUserResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupUserResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о группе пользователя.'))
          );    
    }

    insert(obj: IApiCarGroupUser): Observable<IApiCarGroupUserResponse> {
      return this.http.post<IApiCarGroupUserResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupUserResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления группы пользователя.'))
          );    
    }
  
    update(id: string, obj: IApiCarGroupUser): Observable<IApiCarGroupUserResponse> {
      return this.http.post<IApiCarGroupUserResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarGroupUserResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации группы пользователя.'))
          );    
    }


    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления группы пользователя.'))
          );    
    }

}
  