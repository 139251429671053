import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { filter } from 'rxjs/internal/operators/filter';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';
import { DriverTripService } from 'src/app/service.backend/driver-trip.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';
import { nanoid } from 'nanoid'
import { Table } from 'primeng/table';
import { IApiCar } from 'src/app/model.backend/car.model';
import { Observable } from 'rxjs';
import { IApiSmsMessage } from 'src/app/model.backend/smsmessage.model';
import { SmsMessageService } from 'src/app/service.backend/smsmessage.service';
import { IApiSmsTemplate } from 'src/app/model.backend/smstemplate.model';
import { UserHelper } from 'src/app/service/user-helper.service';

@Component({
  selector: 'app-car-command',
  templateUrl: './car-command.component.html',
  styleUrls: ['./car-command.component.css']
})
export class CarCommandComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() carid = null as string|null;

  @Output() onHide = new EventEmitter();  

  destroy$ = new Subject<boolean>();
  data = {
    loading: false,
    saving: false,
    error: null as string|null,  
    messages: [] as IApiSmsMessage[],
    templates: [] as IApiSmsTemplate[]
  };
  ui = {
    templateid: null as string|null
  }
  options = {
    commands: [] as INameCode[]
  };


  constructor(
    private authService: AuthService,
    private smsMessageService: SmsMessageService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    public utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this._load();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  _load() {
    this.data.loading=true;  
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1),
      switchMap(()=>this.smsMessageService.list(this.carid))
    )
    .subscribe({
      next: res=>{
        this.data.loading = false;
        if(res.ok) {
          this.data.messages = res.messages||[];
//          this.data.templates = res.templates||[];
          this.options.commands = (res.templates||[]).map(x=>{ return {name: x.title, code: x.id}}).sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);       
          this.options.commands.unshift({name: '-', code: null});
          this._applySort();
        } else {
          console.error('loadRecords', res.errorMessage, res.errorMessageForUser);
          this.data.error = res.errorMessageForUser ?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
      },
      error: err=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading = false;
      }
    })
  }

  _applySort() {
    this.data.messages = this.data.messages.sort((a,b) =>  ((a.created??'') > (b.created??'')) ? -1:1);
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  canSendCommand() {
    let user = this.authService.getUser();
    return user ? UserHelper.isMasterAdmin(user) || UserHelper.isCompanyAdmin(user) || UserHelper.isAllow(user, IApiUser_Rights.commandSend) : false;
  }

  getTemplateName(templateid: string) {
    return this.options.commands.find(x=>x.code==templateid)?.name;
  }

  sendCommand() {
    if(!this.ui.templateid) return;

    let cmd: IApiSmsMessage = {
      carid: this.carid,
      smstemplateid: this.ui.templateid,     
    };

    this.data.saving = true;
    this.smsMessageService.send(cmd).subscribe({
      next: (res)=>{ 
        if(res.ok) { 
          this.data.messages.push(res.message??{});
          this._applySort();   
          this.data.saving=false;
        }
        else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          let e = res.errorMessageForUser??this.translate.instant('form.addError');
          this.messageService.add({severity: 'error', detail: e??'' });  
          this.data.saving=false;         
        }
      },
      error: (err)=> {
        console.error('saveRecord', err);
        let e = this.translate.instant('form.addError');
        this.messageService.add({severity: 'error', detail: e??'' });
        this.data.saving=false;         
      }
    })

  }

}
