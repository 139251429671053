import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { DriverService } from 'src/app/service.backend/driver.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import { filter, take, takeUntil } from 'rxjs';
import { CarService } from 'src/app/service.backend/car.service';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiCarStatus } from 'src/app/model.backend/carstatus.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';

@Component({
  selector: 'app-driver-card-list',
  templateUrl: './driver-card-list.component.html',
  styleUrls: ['./driver-card-list.component.css']
})
export class DriverCardListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() drivers: IApiDriver[]=[];
  @Input() currentCarStatuses: IApiCarStatus[]=[];
  @Input() selectedDrivers: string[]=[];
  @Output() selectedDriversChange = new EventEmitter<string[]>();
  
  @Output() onDriverUpdated = new EventEmitter<IApiDriver>();
  @Output() onDriverInserted = new EventEmitter<IApiDriver>();
  @Output() onDriverDeleted = new EventEmitter<IApiDriver>();
  @Output() onTripUpdated = new EventEmitter();

  @HostListener('window:resize', ['$event']) onResize() { this.correctInnerHeight() }
  @ViewChild('scrollelement') scrollelement?: HTMLDivElement;

  destroy$ = new Subject<boolean>();
  options = {
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiDriver[]|null,
    filteredRecords: null as IApiDriver[]|null,
    currentCarStatuses: [] as IApiCarStatus[]
  };
  ui = {
    filterText: '',
    selectAll: false,
    viewMode: true,
    selectedDrivers: [] as string[],
    innerHeight: '0px'
  };
  dialog = {
    edit: {
      show: false,
      driverId: null as string|null     
    },
  }

  constructor(
    private driverService: DriverService,
    private preloaderService: PreloaderService,
    private carService: CarService,
    private authService: AuthService,    
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.ui.viewMode = !this.canEditDriver();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedDrivers']) {
      this.ui.selectedDrivers=[...changes['selectedDrivers'].currentValue];
      this._calcSelectAll();
    }
    if(changes['currentCarStatuses']) {
      this.data.currentCarStatuses = [...changes['currentCarStatuses'].currentValue];
    }
    if(changes['drivers']) {
      this.data.records = [...changes['drivers'].currentValue];
      this._applyFilterAndSort();
    }
  }

  _load() {
    this.data.loading=true;  
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1),
      // switchMap(x=>forkJoin({
      //   drivers: this.preloaderService.drivers$.pipe(take(1))
      // }))
    ).subscribe({
      next: (obj)=>{
//        this.data.records = this.drivers;
//        this.data.tripsRecords = this.currentTrips;
//        this.applyFilterAndSort();
        this.data.loading = false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading = false;
      }
    });  
  }

  _applyFilterAndSort() {
    this.data.filteredRecords = (this.data.records||[]).filter(
      x => (this.ui.filterText??'')=='' 
           || (x.name?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase()) 
           || (x.family?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase()) 
           || (x.surname?.toLowerCase()??'').includes(this.ui.filterText?.toLowerCase())
    );
    (this.data.filteredRecords||[]).sort((a,b)=>(a.family??'')+(a.name??'')+(a.surname??'')>(b.family??'')+(b.name??'')+(b.surname??'')?1:-1);

    this._calcSelectAll();
    this.correctInnerHeight()
  }

  _calcSelectAll() {
    let s = true;
    (this.data.filteredRecords||[]).forEach(driver => { s = s && this.isDriverSelected(driver); } );
    this.ui.selectAll=s;
  }

  clickDriverEdit(record: IApiDriver) {
    this.dialog.edit.driverId = record.id??'xxx';
    this.dialog.edit.show =true;
  }

  clickDriverAdd() {
    this.dialog.edit.driverId = null;
    this.dialog.edit.show =true;
  }

  clickDriverSelectChange(driver: IApiDriver, select: boolean) {
    let id = driver.id??'xxx';
    let ix = this.ui.selectedDrivers.findIndex(x=>x==id);
    if(select && ix<0) this.ui.selectedDrivers.push(id);
    if(!select && ix>=0) this.ui.selectedDrivers.splice(ix,1);
    if(!select) this.ui.selectAll=false;
    this.selectedDriversChange.emit(this.ui.selectedDrivers);
  }

  clickDriverSelectAll(select: boolean) {
    let selected: string[] = [];
    if(select) (this.data.filteredRecords||[]).forEach(driver=>selected.push(driver.id??'xxx'));
    this.ui.selectedDrivers = selected;
    this.data.filteredRecords = [...this.data.filteredRecords||[]];
    this.selectedDriversChange.emit(this.ui.selectedDrivers);
  }

  isDriverSelected(driver: IApiDriver) {
    let id = driver.id??'xxx';
    return this.ui.selectedDrivers.findIndex(x=>x==id)>=0;
  }

  clickDriverDelete(record: IApiDriver, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('driver.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.driverService.delete(record.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              // let ix = this.data.records?.findIndex(x=>x.id==record.id)??-1;
              // if(ix>=0) this.data.records?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
              this.onDriverDeleted.next(record);
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }

  onDialogEditUpdate(record: IApiDriver) {
    this.onDriverUpdated.next(record);
  }

  onDialogEditInsert(record: IApiDriver) {
    this.onDriverInserted.next(record);
  }

  canEditDriver(){
    return this.authService.isAllow(IApiUser_Rights.admin) || this.authService.isAllow(IApiUser_Rights.driverManage);
  }

  canList() {
    return this.authService.getUser()?.customerid;
  }

  getCarStatus(driverid: string|null) {
    return this.data.currentCarStatuses.find(x=>x.driverid==driverid)??null;
  }

  onTripUpdated_event() {
    this.onTripUpdated.emit();
  }

  correctInnerHeight() {
    setTimeout(()=>{
      let h = window.innerHeight;
      let rect2=document.getElementById('scrollelement')?.getBoundingClientRect();
      h = Math.round(h - (rect2?.top??0)-2 );
      this.ui.innerHeight = h+"px"; 
    }, 1)
  }
}
