<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '70vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-4 field">
                <div><label for="family">{{'form.family'|translate}}</label></div>
                <input formControlName="family" id="family" type="text" pInputText class="w-full">
            </div>
            <div class="col-4 field">
                <div><label for="name">{{'form.name'|translate}}</label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full">
            </div>
            <div class="col-4 field">
                <div><label for="surname">{{'form.surname'|translate}}</label></div>
                <input formControlName="surname" id="surname" type="text" pInputText class="w-full">
            </div>

            <div class="col-12 field">
                <div><label for="carsAvailable">Поездки</label></div>                
                <p-table [value]="driverTripsExt" [scrollable]="true" maximizable="false" [rowHover]="true"
                    styleClass="p-datatable">
                  <ng-template pTemplate="header">
                      <tr class="bg-gray-100 font-bold">
                          <th>№</th>
                          <th>С</th>
                          <th>По</th>
                          <th>ТС</th>
                          <th *ngIf="!tableReadOnly"></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-driverTrExt let-index="rowIndex">
                      <tr (dblclick)="clickRecordEdit(driverTrExt)">
                          <td>
                              {{index+1}}.
                          </td>
                          <td>
                              {{driverTrExt.from|isoToDDMMYYYY_HHMMSS}}
                          </td>
                          <td>
                            {{driverTrExt.to|isoToDDMMYYYY_HHMMSS}}
                          </td>
                          <td>
                            {{driverTrExt.carName}}
                          </td>
                          <td *ngIf="!tableReadOnly" class="text-right white-space-nowrap">
                              <p-button (click)="clickRecordEdit(driverTrExt);$event.stopPropagation();" [pTooltip]="'form.edit'|translate" icon="pi pi-pencil" styleClass="p-button-sm mr-1 dh-onhover"></p-button>
                              <p-button (click)="clickRecordDelete(driverTrExt, $event);$event.stopPropagation();" [pTooltip]="'form.delete'|translate" icon="pi pi-times" styleClass="p-button-sm p-button-danger dh-onhover"></p-button>                              
                          </td>                          
                      </tr>
                  </ng-template>
              </p-table>
              <app-norecords *ngIf="(driverTripsExt||[]).length==0 && !error"></app-norecords>   
            </div>


            <div *ngIf="showAddField" class="col-4 field">
                <div><label for="car">ТС</label></div>
                <p-dropdown formControlName="car" [options]="cars" [autoDisplayFirst]="false" optionLabel="name" styleClass="w-full" appendTo="body">
                </p-dropdown>                
            </div>
            <div *ngIf="showAddField" class="col-4 field">
                <div><label for="from">От</label></div>
                <p-calendar formControlName="from" [showTime]="true" dateFormat="dd.mm.yy" inputId="from" appendTo="body" inputStyleClass="text-center w-full"></p-calendar>
            </div>
            <div *ngIf="showAddField" class="col-4 field">
                <div><label for="to">До</label></div>
                <p-calendar formControlName="to" [showTime]="true" dateFormat="dd.mm.yy" inputId="to" appendTo="body" inputStyleClass="text-center w-full"></p-calendar>
            </div>


        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>


