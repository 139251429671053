import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IApiNotice_ScheduleItem } from 'src/app/model.backend/notice.model';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-notice-schedule',
  templateUrl: './notice-schedule.component.html',
  styleUrls: ['./notice-schedule.component.css']
})
export class NoticeScheduleComponent implements OnInit {
  @Input() value!: IApiNotice_ScheduleItem;
  @Input() day: number=0;
  @Output() valueChange = new EventEmitter<IApiNotice_ScheduleItem>();

  form = new FormGroup({
    'chk1': new FormControl(false),
    'chk2': new FormControl(false),
    'from': new FormControl(null as Date|null),
    'to': new FormControl(null as Date|null)
  })
  constructor(
    private translateService: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.form.patchValue({
      'chk1': this.value.is_active_weekday,
      'chk2': this.value.is_active_time,
      'from': this.utils.isoToDate(this.value.from),
      'to': this.utils.isoToDate(this.value.to)
    });
    this.form.valueChanges.subscribe(x=>{
      console.log('form change', x);
      this.valueChange.next({is_active_weekday: x.chk1, is_active_time: x.chk2, from: x.from?.toISOString(), to: x.to?.toISOString()});
    });
  }

  getDayName() {
    let res='';
    try { res =  this.translateService.instant('primeng.dayNamesMin')[(this.day+1)%7]; } catch {}
    return res.toUpperCase();
  }
}
