import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiCustomer, IApiCustomer_CustomerTypeEnum, IApiCustomerBranch } from 'src/app/model.backend/customer.model';
import { CustomerService } from 'src/app/service.backend/customer.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-structure-edit',
  templateUrl: './structure-edit.component.html',
  styleUrls: ['./structure-edit.component.css']
})
export class StructureEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() parent = null as IApiCustomer|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCustomer>();
  @Output() onInsert = new EventEmitter<IApiCustomer>();

  loading = false;
  saving = false;
  error = null as string|null;  
  disabeSave = false;
  item = null as IApiCustomer|null;
  header = '';
  destroy$ = new Subject<boolean>();

  options = {
    status: [
      {label: "активный", code: "active"},
      {label: "блокирован", code: "blocked"}
    ]
  };

  dialog = {
    branch: {
      show: false,
      branch: null as IApiCustomerBranch|null
    }
  }

  isNew = () => !this.id;
  getHeader() {
    var add='structure.edit.add', edit='structure.edit.edit';
    if(this.parent==null ) {
      add='structure.edit.addDistributor';
    } else if(this.parent.customer_type==null || this.parent.customer_type==IApiCustomer_CustomerTypeEnum.Distributor) {
      add='structure.edit.addDealer';
    } 
    if( this.item?.customer_type==IApiCustomer_CustomerTypeEnum.Distributor || this.item?.customer_type==null){
      edit='structure.edit.editDistributor';
    } else if( this.item?.customer_type==IApiCustomer_CustomerTypeEnum.Dealer){
      edit='structure.edit.editDealer';
    } 
    return this.translate.instant(this.isNew() ? add : edit);
  }
  isDistributor = () => this.item?.parentid==null ;

  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
    inn: new FormControl(null as string|null, [Validators.maxLength(50)]),
    pin: new FormControl(null as string|null, [Validators.maxLength(50)]),
    balance: new FormControl(null as number|null),
    bonuse: new FormControl(null as number|null),
    credit_limit: new FormControl(null as number|null),
    in_adv: new FormControl(null as boolean|null),
    email: new FormControl(null as string|null, [Validators.maxLength(255)]),
    comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),
    status: new FormControl(null as string|null),
    owner: new FormControl(null as string|null)
  });

  constructor(
    private customerService: CustomerService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.header=this.getHeader();
    this.isNew() ? this.newRecord(): this.editRecord(); 

    // this.loading = true;   
    // forkJoin({
    //   options: this.preloaderService.ensureLoad$(this.destroy$)
    // })
    // .subscribe({
    //   next: (res) => {
    //     this.loading = false;
    //     this.initOptions();
    //     this.isNew() ? this.newRecord(): this.editRecord(); 
    //   },
    //   error: err => {
    //     this.loading = false;
    //     this.error = err; 
    //     this.messageService.add({severity: 'error', detail: this.error??'' });
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

//  initOptions(){
//    this.options.cars=this.preloaderService.getAvailableCarsOptions();
//  }

  editRecord() {
    this.loading=true;
    this.customerService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.customer);
          this.item = res.customer;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
          this.disabeSave = true;
        }
        this.header=this.getHeader();
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
        this.disabeSave = true;
      }
    })
  }

  newRecord() {
    this.item={};
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(customer: IApiCustomer) {
    customer.parentid = this.parent?.id??null;
    customer.name = this.form.get('name')?.value;
    customer.inn = this.form.get('inn')?.value;
    customer.pin = this.form.get('pin')?.value;
    customer.balance = this.form.get('balance')?.value;
    customer.bonuse = this.form.get('bonuse')?.value;
    customer.credit_limit = this.form.get('credit_limit')?.value
    customer.in_adv = this.form.get('in_adv')?.value;
    customer.email = this.form.get('email')?.value;
    customer.comment = this.form.get('comment')?.value
    if(this.form.get('status')?.value=='active') {
      customer.blocked=null;
    } else {
      if(this.item?.blocked==null) {
        customer.blocked =(new Date()).toISOString();
      }
    }
    if(this.parent==null) {
      customer.customer_type=IApiCustomer_CustomerTypeEnum.Distributor;
    } else if(this.parent.customer_type==IApiCustomer_CustomerTypeEnum.Distributor || this.parent.customer_type==null) {
      customer.customer_type=IApiCustomer_CustomerTypeEnum.Dealer;
    } else {
      customer.customer_type=IApiCustomer_CustomerTypeEnum.Company;
    }
  }

  _loadFormFromObject(customer: IApiCustomer|null) {
    this.form.get('name')?.patchValue(customer?.name??null);
    this.form.get('inn')?.patchValue(customer?.inn??null);
    this.form.get('pin')?.patchValue(customer?.pin??null);
    this.form.get('balance')?.patchValue(customer?.balance??null);
    this.form.get('bonuse')?.patchValue(customer?.bonuse??null);
    this.form.get('credit_limit')?.patchValue(customer?.credit_limit??null);
    this.form.get('in_adv')?.patchValue(customer?.in_adv??null);
    this.form.get('email')?.patchValue(customer?.email??null);
    this.form.get('comment')?.patchValue(customer?.comment??null);
    this.form.get('status')?.patchValue(customer?.blocked ? "blocked":"active");
    this.form.get('owner')?.patchValue(this.parent?.name??null);
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(this.isNew() || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew() ? this.customerService.insert(this.item)
      : this.customerService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.customer};             
          this.visible=false;
          if(this.isNew()){
            this.preloaderService.addNewCompany(this.item);
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.preloaderService.updateCompany(this.item);
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

  clickBranchAdd() {
    this.dialog.branch.branch=null;
    this.dialog.branch.show=true;
  }

  clickBranchEdit(branch: IApiCustomerBranch) {
    this.dialog.branch.branch=branch;
    this.dialog.branch.show=true;
  }

  clickBranchDelete(event: Event, branch: IApiCustomerBranch) {
    if(branch==null || !this.item) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('structure.branchEdit.questBranchDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let ix = this.item?.branches?.findIndex(x=>x.id==branch.id)??-1;
        if(ix>=0) this.item!.branches?.splice(ix,1);
      },
    });
  }

  onCustomerBranchInsert(branch: IApiCustomerBranch) {
    if(!this.item) return;
    if(!this.item?.branches) this.item.branches=[];
    this.item.branches.push(branch);
  }

  onCustomerBranchUpdate(branch: IApiCustomerBranch) {
    if(!this.item) return;
    let ix = this.item.branches?.findIndex(x=>x.id==branch.id)??-1;
    if(ix>=0) this.item.branches?.splice(ix,1,{...branch});
  }

}
