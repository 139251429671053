import { formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiCarStatus, IApiCarStatus_StatusEnum } from 'src/app/model.backend/carstatus.model';
import { IApiSensorName_KeyEnum } from 'src/app/model.backend/sensor-name.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';
import { INameCode, UtilsService } from 'src/app/service/utils.service';
import { CarStatusHelper } from '../../../model.helpers/carstatus-helper';

@Component({
  selector: 'app-car-card2',
  templateUrl: './car-card2.component.html',
  styleUrls: ['./car-card2.component.css']
})
export class CarCardComponent2 implements OnChanges {
  @Input() car!: IApiCar;
  @Input() carstatus!: IApiCarStatus|null;
  @Input() selected: boolean = false;
  @Input() previewMode = null as string|null;
  @Input() previewSensors = [] as (string|null)[];

  @Output() onSelectChange = new EventEmitter<boolean>();
  @Output() onFavoriteChange = new EventEmitter<boolean>();
  @Output() onCarEdit = new EventEmitter<IApiCar>();
  @Output() onSensorChange = new EventEmitter<string[]>();
  @Output() onCarClick = new EventEmitter<IApiCar>();
  @Output() onPreviewSensorsChange = new EventEmitter<(string|null)[]>();

  IApiCarStatus_StatusEnum = IApiCarStatus_StatusEnum;
  CarStatusHelper = CarStatusHelper;

  ui = {
    select: false,
    favorite: false
  };

  options =  {
    sensors: [
      { name: '-', code: null },
      { name: 'Скорость', code: IApiSensorName_KeyEnum.Speed },
      { name: 'Пробег', code: IApiSensorName_KeyEnum.Mileage},
      { name: 'Топливо', code: IApiSensorName_KeyEnum.Fuel_lvl }
    ] as INameCode[]
  };
  
  dialog = {
    carEdit: {
      show: false,
      viewMode: false,
      carId: null as string|null
    }
  }

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private utils: UtilsService
  ) {
    if(this.previewMode)
    {
      if(!this.previewSensors) this.previewSensors=[IApiSensorName_KeyEnum.Speed, IApiSensorName_KeyEnum.Mileage, IApiSensorName_KeyEnum.Fuel_lvl];
      if(this.previewSensors.length<3)
        this.previewSensors=[...this.previewSensors, ...[IApiSensorName_KeyEnum.Speed, IApiSensorName_KeyEnum.Mileage, IApiSensorName_KeyEnum.Fuel_lvl]];
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selected']){
      this.ui.select=changes['selected'].currentValue;
    }
  }

  getDriverInfo() {
    if(this.carstatus?.driver_info?.to==null) return this.carstatus?.driver_info;
    let toUTC= new Date(this.carstatus.driver_info.to);
    if(toUTC.getTime() > (new Date(Date.now()).getTime())) return this.carstatus?.driver_info;
    return null;
  }

  Card_click() {
    this.onCarClick.emit(this.car);
  }
  
  onPreviewSensor_Change() {
    this.onPreviewSensorsChange.emit(this.previewSensors);
  }

  getSensorValueString(ix: number) {
    return CarStatusHelper.getSensorFixedValue(this.authService.getUser()??null, this.carstatus,ix);
  }
}
