<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '30vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form">
        <div class="grid mt-3">
            <div class="col-12 field">
                <div><label for="name">Наименование геозоны<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.nameRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm()"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

