<p-dialog  
    [modal]="false"
    [(visible)]="visible" 
    [style]="{ width: '15vw' }" 
    styleClass="sav3-dialog sav3-dialog-nopadding sav3-dialog-inner fadeinleft animation-duration-200"
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <div [formGroup]="form" [ngClass]="{'p-disabled':data.loading}">
        <div class="grid grid-nogutter mt-3 px-2">
            <div class="col-12 p-2 flex-grow-1">
                <h2 class="text-center mt-0">{{'common.filters'|translate}}</h2>
                <div class="field mt-3">
                    <div><label for="status">{{'cars.filterStatus'|translate}}</label></div>
                    <span class="p-input-icon-right w-full">
                        <i (click)="setFilter('status',[])" class="pi pi-times text-gray-500 z-1" style="right:35px;"></i>
                        <p-multiSelect formControlName="status" inputId="status" appendTo="body" optionLabel="name" optionValue="code" [options]="options.status" [showHeader]="false" styleClass="sav3-input w-full"></p-multiSelect>
                    </span>   
                </div>
                <div class="field mt-3">
                    <div><label for="errors">{{'cars.filterErrors'|translate}}</label></div>
                    <span class="p-input-icon-right w-full">
                        <i (click)="setFilter('errors',[])" class="pi pi-times text-gray-500 z-1" style="right:35px;"></i>
                        <p-multiSelect formControlName="errors" inputId="errors" appendTo="body" optionLabel="name" optionValue="code" [options]="options.errors" [showHeader]="false" styleClass="sav3-input w-full"></p-multiSelect>
                    </span>   
                </div>
                <div class="field mt-3">
                    <div><label for="isTruck">{{'cars.filterIsTruck'|translate}}</label></div>
                    <p-inputSwitch formControlName="isTruck" inputId="isTruck"></p-inputSwitch>
                </div>              
                <div class="field mt-3">
                    <div><label for="skipedTO">{{'cars.filterSkipedTO'|translate}}</label></div>
                    <p-inputSwitch formControlName="skipedTO" inputId="skipedTO"></p-inputSwitch>
                </div>              
                <div class="field mt-3">
                    <div><label for="skipedTO">{{'cars.filterBlockEngine'|translate}}</label></div>
                    <p-inputSwitch formControlName="blockEngine" inputId="skipedTO"></p-inputSwitch>
                </div>              
            </div>
        </div>
        <div class="col-12 px-2 text-center">
            <div>
                <p-button (onClick)="clickApply()" [label]="'common.apply'|translate" styleClass="p-button-rounded p-button-secondary p-button-sm mb-2" icon="pi pi-check"></p-button>
            </div>
            <p-button (onClick)="clickClear()" [label]="'common.clear'|translate" styleClass="p-button-rounded p-button-text p-button-sm" icon="pi pi-times"></p-button>
        </div>
    </div>
</p-dialog>

