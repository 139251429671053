import { IApiBaseResponse, IApiBaseTable } from "./_base.model";
import { IApiUser } from "./user.model";

export interface IApiNotice extends IApiBaseTable {   
    is_active?: boolean|null;
    name?: string|null;
    notice_type?: IApiNotice_Types|null;
    comment?: string|null;
    // заполняется из User.settings
    utc_offset?: number|null;
    // количество срабатываний
    hit_counter?: number|null;
    // интервал
    hit_interval?: number|null;
    emails?: string[]|null;
    phones?: string[]|null;
    // отправить в сообщения
    send_to_messages?: boolean|null;
    // расписание
    notice_schedule?: IAppiNotice_Schedule|null;
    // мин. разница времени
    min_rise_interval?: number|null;
    // список авто
    cars?: string[]|null;
    // список групп
    car_groups?: string[]|null;
    message?: string|null;
    
    userid?: string|null;
    user?: IApiUser|null;
}

export interface IApiNotice_Types {
    // IApiNotice_Type
    type?: string|null;    
    offline?: IApiNotice_TypeOffline|null;
    sensor?: IApiNotice_TypeSensor|null;
    geozone?: IApiNotice_TypeGeozone|null
}

export interface IApiNotice_TypeOffline {
    is_active?: boolean|null;
    // Минимальное время установки статуса "Потеря связи", мин.
    car_offline_interval?: number|null;
}

export interface IApiNotice_TypeSensor {
    sensor_key?: string|null;
    // интервал значений/Изменение значения
    is_interval?: boolean|null;
    // ====интервал значений is_interval==true
    // Нижняя граница
    from_value?: number|null;
    // Верхняя граница
    to_value?: number|null;
    // Время активного состояния
    active_time?: number|null;
    // Значение внутри диапазона
    in_range?: boolean|null;
    // Отсылать уведомление для каждого события
    rise_for_all_events?: boolean|null;
    
    // =====изменение значения is_interval==false
    // Начальное значение
    value_change_initial_value?: number|null;
    // Порог изменения
    value_change_threshold?: number|null;
}

export interface IApiNotice_TypeGeozone {
    // геозона
    geozoneid?: string|null;
    // вход
    in_check?: boolean|null;
    // выход
    out_check?: boolean|null;
    // Нахождение в геозоне (минут?)
    in_geozone_time?: number|null;
}

export interface IAppiNotice_Schedule {
    // использовать расписание
    use_schedule?: boolean|null;
    // [0..6] - пн..вс
    week_days?: IApiNotice_ScheduleItem[]|null;
}

export interface IApiNotice_ScheduleItem {
    is_active_weekday?: boolean|null;
    is_active_time?: boolean|null;
    from?: string|null;
    to?: string|null;
}

export enum IApiNotice_Type {
    offline = "offline",
    online = "online",
    sensor = "sensor",
    geozone = "geozone"
}

export interface IApiNoticeListResponse extends IApiBaseResponse {
    notices: IApiNotice[]|null;
};

export interface IApiNoticeResponse extends IApiBaseResponse {
    notice: IApiNotice|null;
};

 
  
