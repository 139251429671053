import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthserverService } from 'src/app/service.backend/authserver.service';
import packageJson from 'package.json'
import { PreloaderService } from 'src/app/service/preloader.service';
import { filter } from 'rxjs/internal/operators/filter';
import { tap } from 'rxjs/internal/operators/tap';
import { TreeNode } from 'primeng/api';
import { IApiAuthServerLoginResponse, IApiAuthserverUserModel } from 'src/app/model.backend/authserver.model';
import { IApiCustomer_CustomerTypeEnum } from 'src/app/model.backend/customer.model';
import { TreeNodeSelectEvent } from 'primeng/tree';

@Component({
  selector: 'app-pagessys-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class PagesSysLoginComponent implements OnInit, OnDestroy {
  loading = false;
  errorText = '';
  version = packageJson.version;

  ui = {
    step: 1,
    customers: [] as {name: string, code: string|null}[],
    treeNode: []  as TreeNode[],
    selectedTreeNode: null as TreeNode|null,
    editFIO: {
      show: false,
      item: {} as {id: string, name: string|null, phone: string|null, email: string|null },
      authUser: null as IApiAuthserverUserModel|null,
      token: null as string|null
    }
  }

  form: FormGroup = new FormGroup({
    login: new FormControl(null, [Validators.required, Validators.maxLength(30)]),
    password: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
//    customer: new FormControl(null)
  });

  constructor(
//    private app: AppComponent,
    private authServer: AuthserverService,
    private authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private preloaderService: PreloaderService
  ) {    
//    app.hideMenu = true;
    this.loading = false;
  } 

  ngOnInit(): void {    
  }

  ngOnDestroy(): void {  
  }

  onLogin() {
    let l = this.form.get('login')?.value;
    let p = this.form.get('password')?.value;
    let loginasuser = null as string|null;

    // is step 2 
    if(this.ui.step==2) {
      if(!this.ui.selectedTreeNode) return;
      loginasuser = this.ui.selectedTreeNode.data;
    }

    this.loading = true;
    this.authServer.login(l,p,loginasuser).subscribe(
      {
        next: (res)=> {
          console.log('onLogin result', res);
          this.errorText = '';
          if(res.ok && res.authUser && res.token) {
            // if step1
            if(res.customers!=null) {
              this.ui.step=2;
              this.FillTree(res);
              this.loading = false;
            }
            else {
              // is client, or administrator step2

              this.authService.storeTemorarayToken(res.token);
              // требуется заполнить ФИО, телефон, email 
              if(res.authUser.original_user?.fill_at_login) {
                this.ui.editFIO.item = {
                  id: res.authUser.id!,
                  name: res.authUser.original_user.name??null,
                  phone: res.authUser.original_user.phone??null,
                  email: res.authUser.original_user.email??null
                }
                this.ui.editFIO.authUser = res.authUser;
                this.ui.editFIO.token = res.token;
                this.ui.editFIO.show=true;
              } else {
                this.ContinueSuccessLogin(res.authUser, res.token);
              }

              // console.log('Waiting configuration load');
              // this.preloaderService.loaded$.pipe(filter(x=>!!x)).subscribe(()=>{
              //   let url = this.route.snapshot.queryParamMap.get('returnUrl')||'/';
              //   console.log('Loged user', res.authUser?.login, 'reallogin', res.authUser?.real_login);
              //   console.log('Navigate to', url);
              //   console.log('cars', [...this.preloaderService.allcars$.value]);
              //   console.log('user.car_groups', [...(this.authService.getUser()?.car_groups||[])] )
              //   console.log('Available cars', [...this.preloaderService.availablecars$.value] )
              //   setTimeout(() => this.router.navigate([url]), 500)                 
              // })
              // this.authService.loggedIn(res.authUser, res.token);
              // this.messageService.add({severity: 'success', detail: this.translate.instant('formLogin.successLogin') });
            }
          } else {
            if(!res.ok) {
              this.translate.get('formLogin.errorConnect').subscribe(msg => {
                this.errorText = res.errorMessageForUser ?? res.errorMessage ?? msg;
                this.messageService.add({severity: 'error', detail: this.errorText });
              });
            } else {
              if(!res.authUser || !res.token) {
                this.translate.get('formLogin.errorIncorrectAnswer').subscribe(msg => {
                  this.errorText = msg;
                  this.messageService.add({severity: 'error', detail: msg });
                });
              }
            }
            this.loading = false;
          }
        },
        error: (err) => {
          this.loading = false;
          this.translate.get('formLogin.errorResponse').subscribe(msg => {
            this.errorText = msg;
            this.messageService.add({severity: 'error', detail: msg });
          });
          console.error('onLogin', err);
        }//,
//        complete: ()=> this.loading = false
      } 
    );
  }

  private ContinueSuccessLogin(authUser: IApiAuthserverUserModel, token: string) {
    console.log('Waiting configuration load');
    this.preloaderService.loaded$.pipe(filter(x=>!!x)).subscribe(()=>{
      let url = this.route.snapshot.queryParamMap.get('returnUrl')||'/';
      console.log('Loged user', authUser?.login, 'reallogin', authUser?.real_login);
      console.log('Navigate to', url);
      console.log('cars', [...this.preloaderService.allcars$.value]);
      console.log('user.car_groups', [...(this.authService.getUser()?.car_groups||[])] )
      console.log('Available cars', [...this.preloaderService.availablecars$.value] )
      setTimeout(() => this.router.navigate([url]), 500)                 
    })
    this.authService.loggedIn(authUser, token);
    this.messageService.add({severity: 'success', detail: this.translate.instant('formLogin.successLogin') });
  }

  private FillTree(res: IApiAuthServerLoginResponse) {
    this.ui.treeNode=[];
    this.ui.treeNode.push({label: res.authUser?.login + ` (${res.authUser?.name})`, data: res.authUser?.login, icon: 'pi pi-user font-bold', styleClass: 'font-bold' });

    this.fillTree1(res, null, this.ui.treeNode);
  }

  private fillTree1(res: IApiAuthServerLoginResponse, parentid: string|null, treeNode: TreeNode[]) {
    let f = res.customers?.filter(x=>x.parentid==parentid);
    f?.forEach(c=>{
      let node: TreeNode = { label: c.name??'', icon: 'pi pi-building', children: [], styleClass: '' };
      if(c.customer_type==IApiCustomer_CustomerTypeEnum.Distributor) node.label = 'ДИСТРИБЬЮТОР - ' + node.label;
      if(c.customer_type==IApiCustomer_CustomerTypeEnum.Dealer) node.label = 'ДИЛЕР - '+node.label;      
      c.logins?.forEach(u=>{
        node.children!.push({label: u.login + ` (${u.name})` + (u.is_admin?' [Администратор]':''), icon: 'pi pi-user font-bold', data: u.login, styleClass: 'font-bold'});
      })
      treeNode.push(node);
      this.fillTree1(res, c.id??'', node.children!);
    })

  }

  onNodeSelect(event: TreeNodeSelectEvent) {
    console.log('onNodeSelect', event);
    if(event.node.data) {
      this.ui.selectedTreeNode = event.node;

    }
    else {
      this.ui.selectedTreeNode = null;
      event.node.expanded=!event.node.expanded;

    }
  }

  canLogin() {
    return this.ui.step!=2 || !!this.ui.selectedTreeNode;
  }

  onEditFIO_hide() {
    this.ui.editFIO.show=false;
    this.ContinueSuccessLogin(this.ui.editFIO.authUser!, this.ui.editFIO.token!);
  }

  onEditFIO_updated(item: {id: string, name: string|null, phone: string|null, email: string|null }) {
    if(this.ui.editFIO.authUser) {
      if(this.ui.editFIO.authUser?.original_user) {
        this.ui.editFIO.authUser.original_user.name = item.name;
        this.ui.editFIO.authUser.original_user.phone = item.phone;
        this.ui.editFIO.authUser.original_user.email = item.email;
      }
      this.ui.editFIO.authUser.name = item.name;  
    }
  }
}
