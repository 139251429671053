
    <div *ngIf="!previewMode" class="grid surface-ground px-2 pt-3">
        <div class="col-6">
            <!--
            <app-cargroup-select (onSelect)="onCarGroupUser_Selected($event)" [carGroupId]="ui.selectedCarGroupUser" ></app-cargroup-select>
        -->
            <app-cargroupuser-select 
                [userId]="ui.selectedFilter.userId" 
                [(carGroupUserId)]="ui.selectedFilter.carGroupUserId"
                (carGroupUserIdChange)="onFilterChange()"
            ></app-cargroupuser-select>
        </div>
        <div class="col-6 flex align-items-center">
            <div class="w-full">
                <span class="p-input-icon-right w-full">
                    <i (click)="ui.filterText='';_applyFilterAndSort()" class="pi pi-times text-gray-500" style="right:35px;"></i>
                    <i class="pi pi-search"></i>
                    <input [(ngModel)]="ui.filterText" (keyup)="_applyFilterAndSort()" type="text" pInputText class="sav3-input w-full" [placeholder]="'common.find'|translate" />
                </span>   
            </div>
            <div class="ml-auto pl-2">
                <button (click)="dialog.filter.show=true; dialog.edit.show=false" class="p-button p-button-rounded p-button-text p-1 sav3" pButton>
                    <i class="pi pi-filter"></i>
                </button>
            </div>
        </div>
        <div *ngIf="data.filteredRecords?.length!=0" class="col-12 flex align-items-center pb-0">
            <div *ngIf="ui.viewAsCard" [ngClass]="{'pl-3':ui.viewAsCard, 'pl-1':!ui.viewAsCard}" class="pr-2">
                <p-checkbox *ngIf="(data.filteredRecords||[]).length!=0" (onChange)="clickCarSelectAll($event.checked)" [binary]="true" [(ngModel)]="ui.selectAll"></p-checkbox>
            </div>
            <div>
                <button class="p-disabled p-button p-button-rounded p-button-text p-1 sav3" pButton>
                    <i class="pi pi-map-marker"></i>
                </button>
            </div>
            <div class="ml-auto">
                <button *ngIf="ui.viewAsCard" class="p-disabled p-button p-button-rounded p-button-text p-1 sav3" pButton>
                    <i class="pi pi-star"></i>
                </button>
            </div>
            <div class="pl-2 pr-3">
                <button (click)="ui.viewAsCard=!ui.viewAsCard; ui.viewAsCard?null:convertToSelectedCars()" class="p-button p-button-rounded p-button-text p-1 sav3" [ngClass]="{'bg-yellow-200':!ui.viewAsCard}" pButton>
                    <i class="pi pi-table"></i>
                </button>
            </div>
        </div>
    </div>
    <div id='scrollelement' class="px-2" [ngClass]="{'-h-full surface-ground':!previewMode, 'bg-gray-200': previewMode}" [ngStyle]="{'height':ui.innerHeight, 'overflow-y': 'auto'}">
        <app-progressbar [loading]="data.loading"></app-progressbar>
        <app-errorbar *ngIf="data.error" [showError]="data.error"></app-errorbar>
        <app-norecords *ngIf="data.filteredRecords?.length==0"></app-norecords>
        <div *ngIf="data.filteredRecords?.length!=0">
            <div *ngIf="ui.viewAsCard" class="col-12 -h-full">
                <app-car-card2 *ngFor="let car of data.filteredRecords" 
                    [car]="car"
                    [carstatus]="_getCarStatus(car?.id??'xxx')"
                    [selected]="isCarSelected(car)"
                    [previewMode]="previewMode"
                    [previewSensors]="previewSensors"
                    (onUpdate)="onCarCard_Updated($event)"
                    (onSelectChange)="onCarCard_SelectChange(car,$event)"
                    (onCarEdit)="onCarCard_Edit($event)"
                    (onCarClick)="onCarCard_Click($event)"
                    (onPreviewSensorsChange)="onPreviewSensors_Change($event)"
                ></app-car-card2>
            </div>

            <!--
            <app-driver-card *ngFor="let driver of data.filteredRecords" 
                [driver]="driver"
                [viewMode]="!canEditDriver()"
                [selected]="isDriverSelected(driver)"
                (onSelectChange)="clickDriverSelectChange(driver, $event)"
                (onClickEdit)="clickDriverEdit($event)"
                (onClickDelete)="clickDriverDelete($event.driver, $event.event)"           
            ></app-driver-card>
            -->
            <div *ngIf="!ui.viewAsCard" class="col-12 h-full">
                <p-table [value]="data.filteredRecords||[]" 
                    class="p-datatable-sm"
                    [(selection)]="ui.selectedCars"
                    dataKey="id"
                    (onRowSelect)="onCarTableSelected($event,true)"
                    (onRowUnselect)="onCarTableSelected($event,false)"
                >
                    <ng-template pTemplate="header">
                        <tr class="text-xs">
                            <th class="p-1"><p-tableHeaderCheckbox /></th>
                            <th class="p-1">{{'carcard.number'|translate}}</th>
                            <th class="p-1">VIN</th>
                            <th class="p-1">{{'carcard.sensors'|translate}}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-car>
                        <tr class="text-xs">
                            <td class="p-1">
                                <p-tableCheckbox [value]="car" />
                            </td>
                            <td class="p-1">
                                <div *ngIf="car.number" class="sav3-car-number">{{ car.number }}</div>
                            </td>
                            <td class="p-1">
                                {{ car.vin }}
                            </td>
                            <td class="p-1">
                                <div *ngIf="getSensorValueString(car.id, 0)">{{ getSensorValueString(car.id, 0) }}</div>
                                <div *ngIf="getSensorValueString(car.id, 1)">{{ getSensorValueString(car.id, 1) }}</div>
                                <div *ngIf="getSensorValueString(car.id, 2)">{{ getSensorValueString(car.id, 2) }}</div>
                            </td>
                            <td class="py-1 px-0">
                                <div *ngIf="CarStatusHelper.isCarOffline(_getCarStatus(car.id))">
                                    <i class="pi pi-wifi text-red-500"></i>
                                </div>
                                <div *ngIf="!CarStatusHelper.isCarOffline(_getCarStatus(car.id))">
                                    <i class="pi pi-wifi text-green-500"></i>
                                </div>
                            </td>
                            <td class="py-1 px-0">
                                <div *ngIf="CarStatusHelper.isCarError(_getCarStatus(car.id))">
                                    <i class="pi pi-exclamation-triangle text-red-300"></i>
                                </div>
                            </td>
                            <td class="p-1">
                                <button (click)="$event.stopPropagation();onCarCard_Edit(car)" class="dh-onhover p-button p-button-rounded p-button-text p-1 sav3" [ngClass]="{'p-disabled': previewMode}" pButton>
                                    <i class="pi pi-cog"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

<app-car-edit2 *ngIf="dialog.edit.show"
    [car]="dialog.edit.car"
    [carstatus]="dialog.edit.carstatus"
    
    (onHide)="dialog.edit.show=false" 
></app-car-edit2>

<app-car-filter *ngIf="dialog.filter.show"
    (onHide)="dialog.filter.show=false"
    (onFiltered)="onFilterChange()"
></app-car-filter>