import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { CarStatusHelper } from 'src/app/model.helpers/carstatus-helper';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, INameCodeNumber, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-car-filter',
  templateUrl: './car-filter.component.html',
  styleUrls: ['./car-filter.component.css']
})
export class CarFilterComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Output() onFiltered = new EventEmitter();
  @Output() onHide = new EventEmitter();

  destroy$ = new Subject<boolean>();
  data = {
    loading: false,
    saving: false,
    error: null as string|null,  
    item: null as IApiDriver|null  
  };
  ui = {
  }
  options = {
    status: [] as INameCodeNumber[],
    errors: [] as INameCode[]
  };

  form = new FormGroup({
    status: new FormControl(null as number[]|null),
    errors: new FormControl(null as string[]|null),
    isTruck: new FormControl(null as boolean|null),
    skipedTO: new FormControl(null as boolean|null),
    blockEngine: new FormControl(null as boolean|null),
  });

  constructor(
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this._fillOptions();
    this.translate.onLangChange.subscribe(()=>this._fillOptions);
    this._loadForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  _fillOptions() {
    CarStatusHelper.getStatusOptionsI18().forEach( x=> this.options.status.push({name: this.translate.instant(x.name??''), code: x.code}));
    this.options.errors = [
      {name: this.translate.instant('cars.filterErrorNoNet'), code: 'nonet'},
      {name: this.translate.instant('cars.filterErrorNoGps'), code: 'nogps' },
      {name: this.translate.instant('cars.filterErrorError'), code: 'error' }
    ]
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  _storeForm() {
    this.preloaderService.selectedFilter.status = this.form.get('status')?.value??null;
    this.preloaderService.selectedFilter.errors = this.form.get('errors')?.value??null;
    this.preloaderService.selectedFilter.isTruck = this.form.get('isTruck')?.value??null;
    this.preloaderService.selectedFilter.skipedTO = this.form.get('skipedTO')?.value??null;
    this.preloaderService.selectedFilter.blockEngine = this.form.get('blockEngine')?.value??null;
  }

  _loadForm() {
    this.form.get('status')?.patchValue(this.preloaderService.selectedFilter.status);
    this.form.get('errors')?.patchValue(this.preloaderService.selectedFilter.errors);
    this.form.get('isTruck')?.patchValue(this.preloaderService.selectedFilter.isTruck);
    this.form.get('skipedTO')?.patchValue(this.preloaderService.selectedFilter.skipedTO);
    this.form.get('blockEngine')?.patchValue(this.preloaderService.selectedFilter.blockEngine);
  }


  setFilter(field: string, value: any) {
    this.form.get(field)?.patchValue(value);
  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

  clickClear() {
    this.setFilter('status',[]);
    this.setFilter('errors',[]);
    this.setFilter('isTruck',null);
    this.setFilter('skipedTO',null);
    this.setFilter('blockEngine',null);
  }

  clickApply() {
    this._storeForm();
    this.onFiltered.emit();
    this.onHide.emit();
  }

}
