import { IApiBaseResponse, IApiBaseTable } from "./_base.model";
import { IApiUser } from "./user.model";

export class IApiTask_WeekDays
{
    public static monday = "пн";
    public static tuesday = "вт";
    public static wednesday = "ср";
    public static thursday = "чт";
    public static friday = "пт";
    public static saturday = "сб";
    public static sunday = "вс";
}

export class IApiTask_PriodicType
{
    public static once = "once";
    public static weekdays = "weekdays";
    public static days = "days";
}

export class IApiTask_ReportFormat
{
    public static xls = "xls";
    public static pdf = "pdf";
}

export class IApiTask_LastType
{
    public static hours = "hours";
    public static days = "days";
    public static months = "months";
}

export interface IApiTask extends IApiBaseTable {   
    name?: string|null;
    comment?: string|null;
    is_active?: boolean|null;
    // заполняется из User.settings
    utc_offset?: number|null;
    reportid?: string|null;
    // xls, pdf
    report_format?: string|null;
    // количество срабатываний
    hit_counter?: number|null;
    // по дням (на первой вкладке, группировка?)
    by_day?: boolean|null;
    // периодическое задание
    // once, weekdays, days
    periodic_type?: string|null;
    // единоразово periodic_type==false, дата начала и конца отчета 
    report_from_date?: string|null;
    report_to_date?: string|null;
    // время начала формирования отчета
    task_start_time?: string|null;
    // задержка начал формирования отчета, часов
    task_delay_hours?: number|null;
    // по дням недели
    // [пн, вт, ср, чт, пт, сб, вс] 
    week_days?: string[]|null;
    // по дням месяца для выполнения
    month_days?: number[]|null;
    // формировать за посление last
    last?: number|null;
    // hours, days, months
    last_type?: string|null;
    // список авто
    cars?: string[]|null;
    // список групп
    car_groups?: string[]|null;
    emails?: string[]|null;
    ftp?: string|null;
    ftp_login?: string|null;
    ftp_password?: string|null;
    file_name?: string|null;

    userid?: string|null;
    user?: IApiUser|null;
}


export interface IApiTaskListResponse extends IApiBaseResponse {
    tasks: IApiTask[]|null;
};

export interface IApiTaskResponse extends IApiBaseResponse {
    task: IApiTask|null;
};

 
  
