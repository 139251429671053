import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-errorbar',
  templateUrl: './errorbar.component.html',
  styleUrls: ['./errorbar.component.css']
})
export class ErrorBarComponent {
  @Input() showError=null as string|null;
  constructor(
  ) {
  }

}
