import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/auth/auth.service';
import { CarActiveState, IApiCar } from 'src/app/model.backend/car.model';
import { CarGroupService } from 'src/app/service.backend/cargroup.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import {jsPDF} from "jspdf";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-car-list',
  templateUrl: './car-list.component.html',
  styleUrls: ['./car-list.component.css']
})
export class CarListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showcheck=false; // режим присвоения ТС к группам (отображаются все ТС и выделяются присвоенные)
  @Input() carType="*";
  @Input() groupId="*" as string|null;
  CarActiveState = CarActiveState;
  destroy$ = new Subject<boolean>();

  data = {
    cars: null as IApiCar[]|null,
    carsFiltered: null as IApiCar[]|null,
    selectedCars: [] as IApiCar[],

    loading: false,  
    error: null as string|null
  }

  formdata = {
    filterTC: {
      text: '',
      show_active: true, show_block: true, show_notPaid: true
      //show_notOwn: true, show_notBilling: true
    }
  }

  dialog = {
    caredit: {
      carId: null as string|null,
      viewMode: false,
      show: false
    },
  }

  options = {
    export: [
      { label: "PDF", icon: "pi pi-file-pdf", command: ()=>this.exportPDF() },
      { label: "XLS", icon: "pi pi-file-excel", command: ()=>this.exportXLS() },
    ]
  }

  constructor(
    private authService: AuthService,
    private cargroupService: CarGroupService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private preloaderService: PreloaderService,
    private translate: TranslateService,
    public utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.data.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.data.loading = false;
        this.data.cars = this.preloaderService.availablecars$.value;
        this.ApplyCarFilter();
      },
      error: err => {
        this.data.loading = false;
        this.data.error = err; 
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
      }
    });   
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ApplyCarFilter();
  }

  clickCarClearFilter() {
    this.formdata.filterTC.text='';
    this.ApplyCarFilter();
  }

  ApplyCarFilter() {
    let res = (this.data.cars||[]).filter(x=>this.carType=="*" || x.type==this.carType);
    this.data.carsFiltered=[];
    res.forEach(car=>{
      if( this.showcheck || !this.groupId || this.groupId=="*" || this.preloaderService.isCarInGroup(car.id??'xxx', this.groupId)) { 
        this.data.carsFiltered?.push(car);
      }
    })

    if((this.formdata.filterTC.text||'')!='') {
      this.data.carsFiltered = this.data.carsFiltered.filter(x => 
        (x.number||'').indexOf(this.formdata.filterTC.text.toLowerCase().toLowerCase())>=0
        || (x.pin||'').toLowerCase().indexOf(this.formdata.filterTC.text.toLowerCase())>=0
        || (x.vin||'').toLowerCase().indexOf(this.formdata.filterTC.text.toLowerCase())>=0
        || (x.display_name||'').toLowerCase().indexOf(this.formdata.filterTC.text.toLowerCase())>=0
      ); 
    }

    // блокирован
    if((!this.formdata.filterTC.show_block)) {
      this.data.carsFiltered = this.data.carsFiltered.filter(x => x.active_state!=CarActiveState.Blocking )
    }

    // неоплата
    if((!this.formdata.filterTC.show_notPaid)) {
      this.data.carsFiltered = this.data.carsFiltered.filter(x => x.active_state!=CarActiveState.BlockingByBilling )
    }

    /*
    // не билинг
    if((!this.formdata.filterTC.show_notBilling)) {
      this.data.carsFiltered = this.data.carsFiltered.filter(x => x.in_billing )
    }

    // чужие
    if((!this.formdata.filterTC.show_notOwn)) {
      this.data.carsFiltered = this.data.carsFiltered.filter(x => this.isOwnCar(x) )
    }
    */

    // активные
    if((!this.formdata.filterTC.show_active)) {
      this.data.carsFiltered = this.data.carsFiltered.filter(x => x.active_state!=CarActiveState.Active )
    }

    if(this.showcheck) {
      this.markSelectedCars();
    }
  }

  markSelectedCars() {
    this.data.selectedCars=[];
    if(this.groupId!='*') {
      (this.data.carsFiltered)?.forEach(car=>{
        if(this.preloaderService.isCarInGroup(car.id??'xxx',this.groupId??'xxx')) {
          this.data.selectedCars.push(car);
        }
      });
    }
  }

  clickCarEdit(car: IApiCar) {
    this.dialog.caredit.carId = car.id??'xxx';
    this.dialog.caredit.viewMode = false;
    this.dialog.caredit.show=true;    
  }

  onCarEdit_Update(car: IApiCar) {
    console.log('onCarEdit_Update', car)
    let ix=this.data.cars?.findIndex(x=>x.id==car.id)??-1;
    if(ix>=0) {
      this.data.cars![ix]= {...car};
      this.ApplyCarFilter();
    }
  }

  // добавить-удалить машину из группы
  onRowCarSelectUnselect(obj:any, set:boolean){
//    console.log('onRowCarSelect', obj);
    let carId=obj.data?.id;
    if(carId && this.groupId && this.groupId!='*'){
      (obj.data as any)['_locale']={saving: true};
      (set 
        ? this.cargroupService.setCar(this.groupId, carId)
        : this.cargroupService.resetCar(this.groupId, carId)
      )
      .subscribe(
        {
          next: (res)=>{
//            console.log('onRowCarSelect res=', res);
            (obj.data as any)['_locale']={saving: false}
            if(!res.ok) {
              console.error('onRowCarSelect', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: this.translate.instant('form.updateError')})
            } else {
              let ix=this.preloaderService.allcargroups$.value.findIndex(x=>x.id==this.groupId)??-1;
              if(ix>=0) {
                this.preloaderService.allcargroups$.value[ix].cars=[...(res.carids??[])];
                this.ApplyCarFilter();
              }
            }
          },
          error: (err)=>{
            (obj.data as any)['_locale']={saving: false}
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.updateError')})
            console.error('onRowCarSelect',err);            
          }
        }
      )
    }
  }

  onHeaderCarCheckboxToggle(obj: any) {
//    console.log('onHeaderCarCheckboxToggle', obj)
    if( this.groupId && this.groupId!='*'){
      this.data.loading = true;
      (obj.checked
        ? this.cargroupService.setCarAll(this.groupId)
        : this.cargroupService.resetCarAll(this.groupId)
      )
      .subscribe(
        {
          next: (res)=>{
//            console.log('onHeaderCarCheckboxToggle res=', res);
            this.data.loading = false;
            if(!res.ok) {
              console.error('onHeaderCarCheckboxToggle', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: this.translate.instant('form.updateError')})
            } else {
              let ix=this.preloaderService.allcargroups$.value.findIndex(x=>x.id==this.groupId)??-1;
              if(ix>=0) {
                this.preloaderService.allcargroups$.value[ix].cars=[...(res.carids??[])];
                this.ApplyCarFilter();
              }
            }
          },
          error: (err)=>{
            this.data.loading = false;
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.updateError')})
            console.error('onHeaderCarCheckboxToggle',err);            
          }
        }
      )
    }
  }

  exportPDF() {
   var pdf = new jsPDF('p', 'pt', 'A4');   
   pdf.addFont("assets/fonts/PTSans.ttf", "PTSans", "normal");   
   pdf.addFont("assets/fonts/primeicons.ttf", "primeicons", "normal");   
   pdf.setFont("PTSans"); 
   let filename = this.carType=='car' ? 'Транспортные средства.pdf' : this.carType=='object' ? 'Объекты.pdf' : 'Транспортные средства и объекты.pdf';
   pdf.html(document.getElementById('toexport')!, {
    callback: function (pdf2) {
      pdf2.output("dataurlnewwindow", {filename: filename});
    },
    x:0, y:0, html2canvas: {scale: 0.50}
  });
 }

 exportXLS() {
   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('toexport'));
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
//   var fmt = "@";
//    wb.Sheets["Sheet1"]["F"] = fmt;

   /* save to file */
   let filename = this.carType=='car' ? 'Транспортные средства.xlsx' : this.carType=='object' ? 'Объекты.xlsx' : 'Транспортные средства и объекты.xlsx';
   XLSX.writeFile(wb, filename);
 }


}
