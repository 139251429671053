<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '50vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-4 field">
                <div><label for="family">{{'form.family'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="family" id="family" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateRequired('family')" severity="error" [text]="'form.familyRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('family')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-4 field">
                <div><label for="name">{{'form.name'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.nameRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-4 field">
                <div><label for="surname">{{'form.surname'|translate}}</label></div>
                <input formControlName="surname" id="surname" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateMaxLength('surname')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-4 field">
                <div><label for="phone">{{'form.phone'|translate}}</label></div>
                <input formControlName="phone" id="phone" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateMaxLength('phone')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-4 field">
                <div class="flex align-items-center pt-5">
                    <p-checkbox formControlName="any_car" [binary]="true" inputId="any_car" class="mr-1"></p-checkbox>
                    <div><label for="any_car">{{'driver.anyCar'|translate}}</label></div>    
                </div>
            </div>
            <div class="col-12 field">
                <div><label for="comment">{{'form.comment'|translate}}</label></div>
                <textarea formControlName="comment" id="comment" [autoResize]="true" pInputTextarea class="w-full"></textarea>
                <p-message *ngIf="!validateMaxLength('comment')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
            </div>


            <div class="col-6 field">
                <div><label for="carsAvailable">Доступные водителю ТС</label></div>
                <p-listbox (onClick)="carsAvailableClick($event)" [options]="carsAvailable" formControlName="carsAvailable" optionLabel="name" 
                    [listStyle]="{'max-height': '220px'}">
                </p-listbox>
            </div>
            <div class="col-6 field">
                <div><label for="carsNotSelected">Остальные ТС</label></div>
                <p-listbox (onClick)="carsNotSelectedClick($event)" [options]="carsNotSelected" formControlName="carsNotSelected" optionLabel="name" 
                    [listStyle]="{'max-height': '220px'}">
                </p-listbox>
            </div>
            


        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

