import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiTask, IApiTaskListResponse, IApiTaskResponse } from "../model.backend/task.model";

@Injectable({
    providedIn: 'root'
  })
  export class TaskService extends BaseService {
    protected controller: string = "task";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(): Observable<IApiTaskListResponse> {
        return this.http.get<IApiTaskListResponse>(`${this.apiUrl}/${this.controller}/list`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiTaskListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка заданий.'))
            );    
    }   

    get(id: string): Observable<IApiTaskResponse> {
      return this.http.get<IApiTaskResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiTaskResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о задании.'))
          );    
    }

    insert(obj: IApiTask): Observable<IApiTaskResponse> {
      return this.http.post<IApiTaskResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiTaskResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления задания.'))
          );    
    }
  
    update(id: string, obj: IApiTask): Observable<IApiTaskResponse> {
      return this.http.post<IApiTaskResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiTaskResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о задании.'))
          );    
    }

    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления задания.'))
          );    
    }


}
  