<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '30vw' }" 
    styleClass="shadow-5 sav3-dialog"
    [resizable]="false" 
    [maximizable]="false"
    [modal]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':saving}">
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div [formGroup]="form" class="grid mt-3 mb-5">
            <div class="col-12">
                <div><label for="field2" class="sav3-label">{{'changepassword.newpassword'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <p-password formControlName="password" inputId="field2" autocomplete="off" [toggleMask]="true" class="w-full sav3-input p-inputtext-sm" appendTo="body"></p-password>
                <p-message *ngIf="!validateRequired('password')" severity="error" [text]="'form.required'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('password')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>    
            <div class="col-12">
                <div><label for="field3" class="sav3-label">{{'changepassword.retrypassword'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <p-password formControlName="password2" inputId="field3" autocomplete="off" [toggleMask]="true" class="w-full sav3-input p-inputtext-sm" appendTo="body"></p-password>
                <p-message *ngIf="!validateRequired('password2')" severity="error" [text]="'form.required'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('password2')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
                <p-message *ngIf="!validatePasswordMatch('password2')" severity="error" [text]="'changepassword.notmatch'|translate"></p-message>
            </div>    
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
        <p-button (click)="changePassword()" 
                  [disabled]="saving"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary p-button-rounded">
        </p-button>
    </ng-template>
</p-dialog>

