<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '70vw', 'min-height': '90%' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}" class="py-3">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <p-tabView>
            <p-tabPanel [header]="'notice.headerGeneralSettings'|translate">        
                <p-panel [header]="'notice.headerNotice'|translate">
                    <div class="grid">
                        <div class="col-10">
                            <div><label for="name">{{'notice.fieldName'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <input formControlName="name" id="name" type="text" pInputText class="w-full" [readOnly]="viewMode">
                            <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'notice.fieldNameRequired'|translate"></p-message>
                            <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center justify-content-start pt-5">
                                <p-checkbox formControlName="is_active" [binary]="true" inputId="is_active" class="mr-1"></p-checkbox>
                                <div><label for="is_active">{{'notice.fieldIsActive'|translate}}</label></div>    
                            </div>
                        </div>
                        <div class="col-3">
                            <div><label for="_notice_type">{{'notice.fieldNoticeType'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-dropdown formControlName="_notice_type" inputId="_notice_type" styleClass="w-full"
                                [options]="ui.options._notice_type"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-dropdown>
                            <p-message *ngIf="!validateRequired('_notice_type')" severity="error" [text]="'notice.fieldNoticeTypeRequired'|translate"></p-message>
                        </div>

                        <div *ngIf="isType(null) || isType(IApiNoticeType.online)" class="col-9">
                        </div>
                        
                        <div *ngIf="isType(IApiNoticeType.offline)" class="col-9">
                            <div>
                                <div><label for="_car_offline_interval">{{'notice.fieldCarOfflineInterval'|translate}}</label></div>
                                <p-inputNumber formControlName="_car_offline_interval" inputId="_car_offline_interval" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                            </div>
                        </div>

                        <div *ngIf="isType(IApiNoticeType.geozone)" class="col-3">
                            <div><label for="_geozoneid">{{'notice.fieldGeozone'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-dropdown formControlName="_geozoneid" inputId="_geozoneid" styleClass="w-full"
                                [options]="ui.options.geozones"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-dropdown>
                            <p-message *ngIf="!validateRequired('_geozoneid')" severity="error" [text]="'notice.fieldGeozoneRequired'|translate"></p-message>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.geozone)" class="col-6">
                            <div class="flex">
                                <div>
                                    <div class="flex align-items-center justify-content-start pt-1">
                                        <p-checkbox formControlName="_in_check" [binary]="true" inputId="_in_check" class="mr-1"></p-checkbox>
                                        <div><label for="_in_check">{{'notice.fieldInCheck'|translate}}</label></div>    
                                    </div>
                                    <div class="flex align-items-center justify-content-start pt-1">
                                        <p-checkbox formControlName="_out_check" [binary]="true" inputId="_out_check" class="mr-1"></p-checkbox>
                                        <div><label for="_out_check">{{'notice.fieldOutCheck'|translate}}</label></div>    
                                    </div>        
                                </div>
                                <div class="ml-4">
                                    <div><label for="_in_geozone_time">{{'notice.fieldInGeozoneTime'|translate}}</label></div>
                                    <p-inputNumber formControlName="_in_geozone_time" inputId="_in_geozone_time" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>       
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isType(IApiNoticeType.sensor)" class="col-3">
                            <div><label for="_sensor_key">{{'notice.fieldSensor'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-dropdown formControlName="_sensor_key" inputId="_sensor_key" styleClass="w-full"
                                [options]="ui.options.sensorNames"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-dropdown>
                            <p-message *ngIf="!validateRequired('_sensor_key')" severity="error" [text]="'notice.fieldSensorRequired'|translate"></p-message>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)" class="flex col-6 pt-4">
                            <div class="flex align-items-start pt-3">
                                <p-radioButton formControlName="_groupInterval" value="y" inputId="_groupInterval1"></p-radioButton>
                                <label for="_groupInterval1" class="ml-1">{{'notice.fieldGroupInterval'|translate}}</label>
                            </div>                            
                            <div class="flex align-items-start pt-3 ml-4">
                                <p-radioButton formControlName="_groupInterval" value="n" inputId="_groupInterval2"></p-radioButton>
                                <label for="_groupInterval2" class="ml-1">{{'notice.fieldGroupIntervalChange'|translate}}</label>
                            </div>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value=='y')" class="col-3">
                            <div><label for="_from_value">{{'notice.fieldFromValue'|translate}}</label></div>
                            <p-inputNumber formControlName="_from_value" inputId="_from_value" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value=='y')" class="col-3">
                            <div><label for="_to_value">{{'notice.fieldToValue'|translate}}</label></div>
                            <p-inputNumber formControlName="_to_value" inputId="_to_value" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value=='y')" class="col-3">
                            <div><label for="_active_time">{{'notice.fieldActiveTime'|translate}}</label></div>
                            <p-inputNumber formControlName="_active_time" inputId="_active_time" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value=='y')" class="col-3">
                            <div class="flex align-items-center justify-content-start pt-5">
                                <p-checkbox formControlName="_in_range" [binary]="true" inputId="_in_range" class="mr-1"></p-checkbox>
                                <div><label for="_in_range">{{'notice.fieldInRange'|translate}}</label></div>    
                            </div>    
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value!='y')" class="col-3">
                            <div><label for="_value_change_initial_value">{{'notice.fieldValueChangeInitialValue'|translate}}</label></div>
                            <p-inputNumber formControlName="_value_change_initial_value" inputId="_value_change_initial_value" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value!='y')" class="col-3">
                            <div><label for="_value_change_threshold">{{'notice.fieldValueCHangeThreshold'|translate}}</label></div>
                            <p-inputNumber formControlName="_value_change_threshold" inputId="_value_change_threshold" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                        </div>
                        <div *ngIf="isType(IApiNoticeType.sensor)&&(form.get('_groupInterval')?.value!='y')" class="col-6">
                        </div>



                        <div *ngIf="this.form.get('_notice_type')?.value" class="col-3">
                            <div>
                                <div><label for="hit_counter">{{'notice.fieldHitCount'|translate}}</label></div>
                                <p-inputNumber formControlName="hit_counter" inputId="hit_counter" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                            </div>
                        </div>
                        <div *ngIf="this.form.get('_notice_type')?.value" class="col-3">
                            <div>
                                <div><label for="hit_interval">{{'notice.fieldHitInterval'|translate}}</label></div>
                                <p-inputNumber formControlName="hit_interval" inputId="hit_interval" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                            </div>
                        </div>
                        <div *ngIf="this.form.get('_notice_type')?.value" class="col-3">
                            <div>
                                <div><label for="min_rise_interval">{{'notice.fieldMinRiseInterval'|translate}}</label></div>
                                <p-inputNumber formControlName="min_rise_interval" inputId="min_rise_interval" inputStyleClass="text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                            </div>
                        </div>

                    </div>
                </p-panel>

                <p-panel [header]="'notice.headerPeriod'|translate" styleClass="mt-3">
                    <div *ngIf="item?.notice_schedule?.week_days" class="grid">
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d0" [day]="0"></app-notice-schedule>
                        </div>
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d1" [day]="1"></app-notice-schedule>
                        </div>
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d2" [day]="2"></app-notice-schedule>
                        </div>
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d3" [day]="3"></app-notice-schedule>
                        </div>
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d4" [day]="4"></app-notice-schedule>
                        </div>
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d5" [day]="5"></app-notice-schedule>
                        </div>
                        <div class="col-3">
                            <app-notice-schedule [(value)]="_week_days.d6" [day]="6"></app-notice-schedule>
                        </div>
                    </div>
                </p-panel>
            </p-tabPanel>
            <p-tabPanel [header]="'notice.headerGroup'|translate">        
                <div class="grid">
                    <div class="flex col-12">
                        <div class="flex align-items-center">
                            <p-radioButton formControlName="_group" value="y" inputId="_group1"></p-radioButton>
                            <label for="_group1" class="ml-1">{{'notice.fieldSelectGroup'|translate}}</label>
                        </div>
                        
                        <div class="flex align-items-center ml-4">
                            <p-radioButton formControlName="_group" value="n" inputId="_group2"></p-radioButton>
                            <label for="_group2" class="ml-1">{{'notice.fieldSelectCar'|translate}}</label>
                        </div>
                    </div>
                    <div *ngIf="form.get('_group')?.value=='y'"class="col-12">
                        <div><label for="car_groups">{{'notice.fieldCarGroups'|translate}}</label></div>
                        <p-multiSelect formControlName="car_groups" inputId="car_groups" styleClass="w-full"
                            [options]="ui.options.car_groups" optionLabel="name" optionValue="code" 
                            appendTo="body">
                        </p-multiSelect>
                    </div>
                    <div *ngIf="form.get('_group')?.value!='y'" class="col-12">
                        <div><label for="cars">{{'notice.fieldCars'|translate}}</label></div>
                        <p-multiSelect formControlName="cars" inputId="cars" styleClass="w-full"
                            [options]="ui.options.cars" optionLabel="name" optionValue="code" 
                            appendTo="body">
                        </p-multiSelect>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel [header]="'notice.headerDestination'|translate">      
                <div class="grid">
                    <div class="col-12">
                        <div><label for="emails">{{'notice.fieldEmail'|translate}}</label></div>
                        <p-chips formControlName="emails" id="emails" styleClass="w-full" inputStyleClass="w-full" class="w-full"></p-chips>
                    </div>
                    <div class="col-12">
                        <div><label for="phones">{{'notice.fieldPhones'|translate}}</label></div>
                        <p-chips formControlName="phones" id="phones" styleClass="w-full" inputStyleClass="w-full" class="w-full"></p-chips>
                    </div>
                    <div class="col-12">
                        <div class="flex align-items-center justify-content-start">
                            <p-checkbox formControlName="send_to_messages" [binary]="true" inputId="send_to_messages" class="mr-1"></p-checkbox>
                            <div><label for="send_to_messages">{{'notice.sendToMessages'|translate}}</label></div>    
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div><label for="message">{{'notice.fieldMessage'|translate}}</label></div>
                        <textarea formControlName="message" id="message" [rows]="7" pInputTextarea class="w-full" [readOnly]="viewMode"></textarea>
                        <p-message *ngIf="!validateMaxLength('message')" severity="error" [text]="'form.maxLength4000'|translate"></p-message>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button *ngIf="!viewMode"
                  (click)="saveRecord()" 
                  [disabled]="loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

