import * as signalR from "@microsoft/signalr";

export const environment = {
  production: true,
  dev: false,
  //apiUrl: 'http://sav3-client-api/api',
  apiUrl: 'https://sav3-client-api.dhsmart.ru/api',
  authApiUrl: 'https://sav3-auth.dhsmart.ru',
  signalRUrl: '',
  redirectToHttps: false,
  signalRLogLevel: signalR.LogLevel.Information,
  carStatusFake: false,
};
