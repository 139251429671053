import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { IApiHelpDeskUpdate } from '../model.backend/help-desk-update.model';
import { IApiCarStatusUpdate } from '../model.backend/car-status-update.model';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {  
  private reconnectTimeMs = 10000;
  private hubConnection: signalR.HubConnection|undefined;

  constructor(
    private messageService: MessageService,
  ) {}

  connectDiconnect(token: string|null) {
    if (!environment.signalRUrl) return;

    if (token) {   
      // подключится к хабу
      this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(environment.signalRLogLevel)
      .withUrl(`${environment.signalRUrl}/multihub`, {
        withCredentials: true,
        accessTokenFactory: () => {
          return token;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,        
      })
      .withAutomaticReconnect()
      .build();

      // События отключения
      this.hubConnection.onclose(err => {
        if (environment.signalRLogLevel === signalR.LogLevel.Information) {
          console.log('SignalR onClose err', err);
        }
        if (!err) {
          this.messageService.add({severity: 'error', detail: 'SignalR отключен сервером.' });
        }
        //this.reconnect;
      });

      // подписка обработчиков ---------------------
      /*
      this.hubConnection.on("messageReceived", (username: string, message: string) => {
        if (environment.signalRLogLevel === signalR.LogLevel.Information) {
          console.log('SignalR messageReceived', username, message);
        }
      });
      */

      this.hubConnection.on("messageReceived", this.messageReceived);
      this.hubConnection.on("carStatusUpdates", this.carStatusUpdates);
      this.hubConnection.on("helpDeskUpdate", this.helpDeskUpdate);
      // ----------------------------------------------------

      this.startConnection();
    } else if (this.hubConnection) {
      // отключится от хаба
      this.hubConnection.stop()
      .then(() =>{
        this.hubConnection = undefined;
        if (environment.signalRLogLevel === signalR.LogLevel.Information) {
          console.log('SignalR disconnected.');
        }
      });
    }
  };
    
  private startConnection() {
    if (!this.hubConnection) return;

    this.hubConnection
    .start()
    .then(() => {
      if (environment.signalRLogLevel === signalR.LogLevel.Information) {
        console.log('Connection established with SignalR hub');
      }
      this.messageService.add({severity: 'success', detail: 'SignalR подключен.' });
    })
    .catch((error) => {
      console.warn('Error connecting to SignalR hub:', error);
      this.messageService.add({severity: 'error', detail: 'SignalR ошибка подключения.' });
    });
  }

  /*
  private reconnect() {
    if (environment.signalRLogLevel === signalR.LogLevel.Information) {
      console.log('SignalR reconnecting...');
    }
    setTimeout(this.startConnection, this.reconnectTimeMs);
  }
  */

  sendMessage(message: string): void {
    if (!environment.signalRUrl) return;
    
    this.hubConnection?.invoke('SendMessage', 'aa', 'bb')
    .catch(err => {
      console.warn('SignalR SendMessage error', err);
    });
  }
   
  // ---- Обработчики сообщений -------------------------

  private messageReceived(username: string, message: string) {
    if (environment.signalRLogLevel === signalR.LogLevel.Information) {
      console.log('SignalR messageReceived', username, message);
    }
  };

  private carStatusUpdates(updates: IApiCarStatusUpdate[]) {
    if (environment.signalRLogLevel === signalR.LogLevel.Information) {
      console.log('SignalR carStatusUpdates', updates);
    }
  }

  private helpDeskUpdate(update: IApiHelpDeskUpdate) {
    if (environment.signalRLogLevel === signalR.LogLevel.Information) {
      console.log('SignalR helpDeskUpdate', update);
    }
  }
  
}