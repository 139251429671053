import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class AuthError401Interceptor implements HttpInterceptor {
    constructor( 
      private authService: AuthService    
    ) { 

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {
          if (err.status === 401) {
              // auto logout if 401 response returned from api
              console.log('AuthError401Interceptor', err);              
              this.authService.logOut(true);              
          }
          const error = err.error?.message || err.statusText;
          console.error(err);
          return throwError(() => error);  
      }))
  }

}
