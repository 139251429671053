import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { IApiBaseResponse } from '../model.backend/_base.model';

@Injectable({
  providedIn: 'root'
})
export class TelegramBotService extends BaseService {
  protected controller: string = "telegramBot";

  constructor(
    private http: HttpClient,
  ) 
  {  
    super();
  }
     
  sendMessage(message: string): Observable<[]> {
    //console.log('telegram sendMessage', message);
    return this.http.post<[]>(`${this.apiUrl}/${this.controller}/send`, JSON.stringify({message}), {headers: this.headers} )
        .pipe(
          catchError(this.handleError<[]>(`POST ${this.controller}.send`, null, 'Ошибка отправки уведомления в Telegram.'))
        );    
  }


}
