import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api/public_api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCarGroupUser } from 'src/app/model.backend/cargroupuser.model';
import { PreloaderService } from 'src/app/service/preloader.service';

@Component({
  selector: 'app-cargroupuser-select',
  templateUrl: './cargroupuser-select.component.html',
  styleUrls: ['./cargroupuser-select.component.css']
})
export class CarGroupUserSelectComponent implements OnInit, OnChanges {
  @Input() userId: string|null=null;
  @Input() carGroupUserId: string|null=null;
  @Output() carGroupUserIdChange = new EventEmitter<string|null>();

  node_selected: TreeNode<IApiCarGroupUser>|null = null;
  nodes: TreeNode<IApiCarGroupUser>[]=[];
  destroy$ = new Subject<boolean>();
  
  constructor(
    private preloaderService: PreloaderService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$),
      filter(loaded=>!!loaded)
    ).subscribe(()=>this._fillTree());

    this.translateService.onLangChange.subscribe(()=>{
      if((this.nodes||[]).length>0) {
        this.nodes[0].label=this.translateService.instant('common.allcars');
        if(this.node_selected==this.nodes[0]){
          this.node_selected=null;
          setTimeout(()=>this.node_selected=this.nodes[0],1);
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['carGroupId'] || changes['userId']) this._fillTree();
  }

  _fillTree() {
    let old_selected = this.node_selected;
    this.nodes=this.GetNodesForParent(null);
    this.nodes.unshift({label: this.translateService.instant('common.allcars'), data: {id: null} });
    if(old_selected) {
      let fnd = this.nodes.find(x=>x.data?.id==old_selected!.data?.id);
      if(fnd) this.node_selected = fnd;
      return;
    }
    this.node_selected = this.nodes[0];
  }

  GetNodesForParent(parentid: string|null): TreeNode<IApiCarGroupUser>[] {
    let n: TreeNode<IApiCarGroupUser>[]=[];
    let userId=this.userId;
    // если пользователь не выбран, то выбран компания и показать группы текущего пользователя
    if(!userId) userId=this.authService.getUser()?.id??null;
    if(this.userId){
      let f = this.preloaderService.getCarGroupUsersForUser(this.userId)??[];
      f.forEach(x=>{
        let node: TreeNode<IApiCarGroupUser> = { label: x.name??'', data: x, icon: 'pi pi-bookmark' };
        n.push(node)
      });       
    }
    return n;
  }

  onNodeSelect(node: TreeNodeSelectEvent) {
    this.carGroupUserIdChange.emit(node.node?.data?.id);
  }
}
