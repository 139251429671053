import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ListboxClickEvent } from 'primeng/listbox';
import { Subject } from 'rxjs/internal/Subject';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { DriverTripService } from 'src/app/service.backend/driver-trip.service';
import { DriverService } from 'src/app/service.backend/driver.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';
import { IDriverExt } from '../~driver-trip-list/driver-trip-list.component';

interface IDriverTripsExt extends IApiDriverTrip {
  carName: string;
}

@Component({
  selector: 'app-driver-trip-edit',
  templateUrl: './driver-trip-edit.component.html',
  styleUrls: ['./driver-trip-edit.component.css']
})
export class DriverTripEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() isNew=false;
  @Input() cars = [] as INameCode[];
  // Чтобы обновить данные в списке data-trip-list после редактирования
  @Input() driversExt = [] as IDriverExt[];
  @Input() driver = {} as IApiDriver;
  @Input() driverTrips = [] as IApiDriverTrip[];

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiDriverTrip>();  

  loading = false;
  saving = false;
  error = null as string|null;  
  tableReadOnly = false;
  destroy$ = new Subject<boolean>();

  options = {
  };
  
  driverTripsExt: IDriverTripsExt[] = [];
  showAddField = false;
  idForUpdate = '';

  getHeader = () => this.translate.instant(this.isNew ? 'driver-trips.add':'driver-trips.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew ? 'pi-plus':'pi-save')

  form = new FormGroup({
    family: new FormControl({value: null as string|null, disabled:true}, [Validators.required, Validators.maxLength(100)]),
    name: new FormControl({value: null as string|null, disabled:true}, [Validators.required, Validators.maxLength(100)]),
    surname: new FormControl({value: null as string|null, disabled:true}, [Validators.maxLength(100)]),
    car: new FormControl(null as INameCode|null, [Validators.required]),
    from: new FormControl(null as Date|null, [Validators.required]),
    to: new FormControl(),
  });

  constructor(
    private driverService: DriverService,
    private driverTripService: DriverTripService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.isNew ? this.newRecord(): this.editRecord();

    console.log('driver', this.driver);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.showAddField = false;
    this.tableReadOnly = false;
    this.loading=true;
    this.driverTripService.list(this.driver.id)
    .subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('res', res);          
          this.driverTripsExt = res.items as IDriverTripsExt[];
          this.driverTripsExt.forEach(dt => {
            dt.carName = this.cars.find(x => x.code===dt.carid)?.name ?? '';
          });
          this._loadFormFromObject(this.driver);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    this.showAddField = true;
    this.tableReadOnly = true;
    this.loading=true;
    this.driverTripService.list(this.driver.id)
    .subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('res', res);          
          this.driverTripsExt = res.items as IDriverTripsExt[];
          this.driverTripsExt.forEach(dt => {
            dt.carName = this.cars.find(x => x.code===dt.carid)?.name ?? '';
          });
          this._loadFormFromObject(this.driver);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
    
  }

  clickRecordEdit(dt: IDriverTripsExt) {
    console.log('clickRecordEdit', dt);

    this.showAddField = true;
    const carNC = this.cars.find(x => x.code===dt.carid);    
    this.idForUpdate = dt.id;
    if (carNC) {
      this.form.get('car')?.patchValue(carNC);
    } else {
      this.form.get('car')?.patchValue(null);
    }    
    const d1 = dt.from ? new Date(Date.parse(dt.from)) : null;
    this.form.get('from')?.patchValue(d1);
    const d2 = dt.to ? new Date(Date.parse(dt.to)) : null;
    this.form.get('to')?.patchValue(d2);
  }

  

  carsAvailableClick(event: ListboxClickEvent) {
  }
  
  carsNotSelectedClick(event: ListboxClickEvent) {
  }


  _saveFormToObject(): IApiDriverTrip {
    const drTr: IApiDriverTrip = {
      id: '',
      carid: this.form.get('car')?.value?.code!,
      driverid: this.driver.id!,
      from: this.form.get('from')?.value?.toISOString()??'',
      to: this.form.get('to')?.value?.toISOString()??null,
    }    
    return drTr;
  }

  _loadFormFromObject(driver: IApiDriver|null) {
    this.form.get('name')?.patchValue(driver?.name??null);
    this.form.get('surname')?.patchValue(driver?.surname??null);
    this.form.get('family')?.patchValue(driver?.family??null);
  }


  clickRecordDelete(record: IDriverTripsExt, event: Event) {
    console.log('clickRecordDelete', record);
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('driver-trips.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.driverTripService.delete(record.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              const ix = this.driverTripsExt.findIndex(x=>x.id==record.id)??-1;
              if(ix>=0) this.driverTripsExt.splice(ix,1);
              const ix2 = this.driverTrips.findIndex(x=>x.id==record.id)??-1;
              if(ix2>=0) this.driverTrips.splice(ix2,1);
              
              const driverInd = this.driversExt.findIndex(x=>x.id==this.driver.id)??-1;
              if(driverInd>=0) {
                /*
                const dtInd = this.driversExt[driverInd].cars?.findIndex(x => x===record.id) ?? -1;
                if (dtInd>=0) {
                  this.driversExt[driverInd].cars?.splice(dtInd, 1);
                }
                */
                const ind3 = this.driversExt[driverInd].trips?.findIndex(x => x.id===record.id) ?? -1;
                if (ind3>=0) {
                  this.driversExt[driverInd].trips!.splice(ind3, 1);
                }
              }
              this.onUpdate.emit(record);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
        
      },
    });
    
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const drTr = this._saveFormToObject();
    console.log('saving...', drTr);

    if (this.isNew) {
      this.saving = true;
      this.driverTripService.insert(drTr)
      .subscribe({
        next: (res) => {
          if(res.ok) {
              this.onUpdate.emit(drTr);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')});
              this.visible = false;
          } else {
            console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
            if(this.isNew) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
            else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
            this.messageService.add({severity: 'error', detail: this.error??'' });  
          }
          this.saving = false;
        },
        error: (err) => {
          console.error('saveRecord', err);
          if(this.isNew) this.error = this.translate.instant('form.addError')
          else this.error = this.translate.instant('form.updateError')
          this.messageService.add({severity: 'error', detail: this.error??'' });
          this.saving=false;  
        }
      });
    } else {
      this.saving = true;
      drTr.id = this.idForUpdate;
      this.driverTripService.update(drTr)
      .subscribe({
        next: (res) => {
          if(res.ok) {
            
            const ix = this.driverTripsExt.findIndex(x=>x.id==drTr.id)??-1;
            if(ix>=0) {
              this.driverTripsExt[ix].carid = drTr.carid;
              this.driverTripsExt[ix].from = drTr.from;
              this.driverTripsExt[ix].to = drTr.to;
            } 
            
            const ix2 = this.driverTrips.findIndex(x=>x.id==drTr.id)??-1;
            if(ix2>=0) {
              this.driverTrips[ix2].carid = drTr.carid;
              this.driverTrips[ix2].from = drTr.from;
              this.driverTrips[ix2].to = drTr.to;
            } 
            
            const driverInd = this.driversExt.findIndex(x=>x.id==this.driver.id)??-1;
            if(driverInd>=0) {
              const ind3 = this.driversExt[driverInd].trips?.findIndex(x => x.id===drTr.id) ?? -1;
              if (ind3>=0) {
                this.driversExt[driverInd].trips![ind3].from = drTr.from||null;
                this.driversExt[driverInd].trips![ind3].to = drTr.to;
                this.driversExt[driverInd].trips![ind3].carName = this.cars.find(x => x.code===drTr.carid)?.name ?? '';
              }
            }
            this.onUpdate.emit(drTr);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')});
            this.visible = false;
          } else {
            console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
            if(this.isNew) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
            else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
            this.messageService.add({severity: 'error', detail: this.error??'' });  
          }
          this.saving = false;
        },
        error: (err) => {
          console.error('saveRecord', err);
          if(this.isNew) this.error = this.translate.instant('form.addError')
          else this.error = this.translate.instant('form.updateError')
          this.messageService.add({severity: 'error', detail: this.error??'' });
          this.saving=false;  
        }
      });
    }
    
    /*
    if(this.isNew || !this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    (
      this.isNew ? this.driverService.insert(this.item)
      : this.driverService.update(this.item.id??'xxx', this.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.driver};             
          this.visible=false;
          if(this.isNew){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });
    */
  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
