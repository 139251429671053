<div class="h-full h-width p-1 surface-ground">
    <div class="h-full h-width p-2 bg-white border-1 border-white border-round">
        <div class="px-1 py-2 flex align-items-center">
            <div>
                <h2 class="dh-page-caption mr-5 my-0">
                    <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-car"></p-avatar>
                    {{'cars.title'|translate}}
                </h2>
            </div>
        </div>
        <div class="grid">
            <div class="col-3" style="padding-top: 58px;">
                <app-car-group [viewMode]="true" (onSelect)="selectGroupId=$event.id??null"></app-car-group>
            </div>
            <div class="col-9">
                <app-car-list [showcheck]="false" carType="car" [groupId]="selectGroupId"></app-car-list>
            </div>
        </div>               
    </div>
</div>