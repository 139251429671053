import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiCarSensor } from 'src/app/model.backend/carsensor.model';
import { CarService } from 'src/app/service.backend/car.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, INameCodeNumber, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-car-info',
  templateUrl: './car-info.component.html',
  styleUrls: ['./car-info.component.css']
})
export class CarInfoComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() carId = null as string|null;

  @Output() onHide = new EventEmitter();

  loading = false;
  error = null as string|null;  
  item = null as IApiCar|null;
  sensors= [] as IApiCarSensor[];
  destroy$ = new Subject<boolean>();

  options = {
  };

  getHeader = () => this.translate.instant('car-view.title');

  constructor(
    private carService: CarService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.loading = false;
        this.initOptions();
        this.viewRecord(); 
      },
      error: err => {
        this.loading = false;
        this.error = err; 
        this.messageService.add({severity: 'error', detail: this.error??'' });
      }
    });

    // сбросить выделение текста, который выделяется при dblclick по карте
    setTimeout(()=>{
      if (window?.getSelection) {
        if (window.getSelection()?.empty) {  // Chrome
          window.getSelection()!.empty();
        } else if (window.getSelection()?.removeAllRanges) {  // Firefox
          window.getSelection()!.removeAllRanges();
        }
      } else if ((document as any)?.selection) {  // IE?
        (document as any).selection.empty();
      }  
    }, 1);
    
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  initOptions() {
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  viewRecord() {
    this.loading=true;
    this.carService.getWithSensors(this.carId??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.car);
          this.item = res.car;
          this.sensors = res.sensors??[];
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  getSensorDisplayName(key: string) {
    return this.preloaderService.getSensorName(key)?.display_name;
  }

}
