import Map from 'ol/Map';
import View from 'ol/View';
import { OSM, Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from 'ol/layer/Tile';
import {Vector as VectorLayer} from 'ol/layer.js';
import {ScaleLine, ZoomSlider} from 'ol/control.js';
import { DblClickDragZoom, defaults as defaultInteractions } from 'ol/interaction.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import {fromLonLat} from 'ol/proj.js';
import {Icon, Style, Fill, Stroke, Text} from 'ol/style.js';
import { Circle, Geometry, LineString } from 'ol/geom';
import Select from 'ol/interaction/Select.js';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiCarStatus, IApiCarStatus_StatusEnum } from 'src/app/model.backend/carstatus.model';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiGeozone } from 'src/app/model.backend/geozone.model';
import { IApiUserSettings } from 'src/app/model.backend/user.model';
import { UserService } from 'src/app/service.backend/user.service';
import { IApiCarHistory } from 'src/app/model.backend/carhistory.model';
import { Coordinate } from 'ol/coordinate';
import { fromCircle } from 'ol/geom/Polygon';
import { click } from 'ol/events/condition';
import BaseEvent, { preventDefault, stopPropagation } from 'ol/events/Event';
import { ATTRIBUTION } from 'ol/source/OSM';
import { PreloaderService } from 'src/app/service/preloader.service';
import { filter, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy {
//  @Input() cars: IApiCar[]|null = null;
//  @Input() geozones: IApiGeozone[]|null = null;
//  @Input() carStatus: IApiCarStatus[]|null = null;

  destroy$ = new Subject<boolean>();

  data = {
    cars: null as IApiCar[]|null,
    geozones: null as IApiGeozone[]|null,
    carStatus: null as IApiCarStatus[]|null,
    carHistory: null as IApiCarHistory[]|null,
  }

  mapobject = {
    map: null as Map|null,
    geozonesLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null,
    carsLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null,
    tracksLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null,
    carsStateLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null,
    branchesLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null
  }

  ui = {
    info: {
      show: false,
      carid: null as string|null
    },
    branches_model: {} as any
  }

  userSettings = {} as IApiUserSettings;
  
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private preloaderService: PreloaderService
  ) {

  }

  ngOnInit(): void {
    this._loadUserSettingsToOverlay();
    this.InitMap();   
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();  
  }

  InitMap() {
    console.log('InitMap');

    this.mapobject.map = new Map({
      interactions: defaultInteractions().extend([new DblClickDragZoom()]),
      layers: [ new TileLayer({ 
        source: new OSM(),
        preload: Infinity,
      }) ],
	    /*
      // custom tile server
      layers: [ new TileLayer({ 
        source: new OSM({
          attributions: [
            'All maps © <a href="https://www.opencyclemap.org/">OpenCycleMap</a>',
            ATTRIBUTION,
          ],
          //url: 'http://89.108.108.110:18080/',
          //url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        }),
        preload: Infinity,
      }) ],
      */
      target: 'map',
      view: new View({ 
        center: fromLonLat([37.618423,55.751244]), //[0, 0],
        zoom: 10, maxZoom: 18, 
        padding: [16,16,16,16]
      }),
    });
    this.mapobject.map.addControl(new ScaleLine({units: "metric"}));
    this.mapobject.map.addControl(new ZoomSlider());

    // слой геозон
    let geoSource = new VectorSource({features: [] }) as VectorSource<Feature<Geometry>>;
    this.mapobject.geozonesLayer = new VectorLayer({ source: geoSource })
    this.mapobject.map?.addLayer(this.mapobject.geozonesLayer!);

    // слой трека пройденного
    let trackSource = new VectorSource({features: [] }) as VectorSource<Feature<Geometry>>;
    this.mapobject.tracksLayer = new VectorLayer({ source: trackSource })
    this.mapobject.map?.addLayer(this.mapobject.tracksLayer!);
    
    // слой автомобилей
    let carSource = new VectorSource({features: [] }) as VectorSource<Feature<Point>>;
    this.mapobject.carsLayer = new VectorLayer({ source: carSource })
    this.mapobject.map?.addLayer(this.mapobject.carsLayer!);

    // слой статусов автомобилей
    let carStateSource = new VectorSource({features: [] }) as VectorSource<Feature<Geometry>>;
    this.mapobject.carsStateLayer = new VectorLayer({ source: carStateSource })
    this.mapobject.map?.addLayer(this.mapobject.carsStateLayer!);
    
    // слой отделений
    let branchSource = new VectorSource({features: [] }) as VectorSource<Feature<Point>>;
    this.mapobject.branchesLayer = new VectorLayer({ source: branchSource })
    this.mapobject.map?.addLayer(this.mapobject.branchesLayer!);

    // обработка клика по машине
    this.mapobject.map.on("click", (evt: any)=> {
      var feature = this.mapobject.map?.forEachFeatureAtPixel(evt.pixel,
        (feature)=>{
          const carid=feature.get("carid");
          if(carid) {
            evt.preventDefault();
            evt.stopPropagation();
            console.log('click carId=', carid);
            this.ui.info.carid=carid;
            this.ui.info.show=true;
          }
          return feature;
        });
    });
  }

  // перерисовать все геозоны с предварительной очисткой
  public RedrawGeozones(geozones: IApiGeozone[]|null) {  
    this.data.geozones = geozones;
    console.log('RedrawGeozones', geozones);
    this.mapobject.geozonesLayer?.getSource()?.clear();

    // если не показывать геозоны
    if(!this.authService.getUser()?.settings?.show_geozones) {
      return;
    };

    let user=this.authService.getUser();
    (geozones||[]).forEach(g=>{
      if(g.geojson) {
//        console.log('Geozone add', g.name, g.geojson);
        // geojson геозоны
        let geo_json = new GeoJSON().readFeature(g.geojson) as Feature<Geometry>;
        // стиль геозоны
        let geoStyle=new Style({
          stroke: new Stroke({ color: user?.settings?.geozone_color??'blue', width: user?.settings?.track_width??2 }),
          fill: new Fill({ color: user?.settings?.geozone_fill ? user?.settings?.geozone_fill+'40':'rgba(0,0,255,0.1)' }),
          text: new Text({
            text: g.name??'',
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: this.authService.getUser()?.settings?.geozone_color??'blue' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            textBaseline: 'middle',
            textAlign: 'center',
            offsetX: 0,
            offsetY: 0
          })
        });
        geo_json.setStyle(geoStyle);
        this.mapobject.geozonesLayer?.getSource()?.addFeature(geo_json);
      }
    });
  }

  // перерисовать все автомобили
  public RedrawCars(cars: IApiCar[]|null, carStatus: IApiCarStatus[]|null) {
    this.data.cars = cars;
    this.data.carStatus = carStatus
    console.log('RedrawCars',cars, carStatus);

    this.mapobject.carsLayer?.getSource()?.clear();
    this.mapobject.carsStateLayer?.getSource()?.clear();
    const user=this.authService.getUser();
    (cars||[]).forEach(car=>{
      // Feature машины
      const carStat=this.GetCarStatus(car.id);
//      console.log('Car add', car.display_name, carStat);
      if(carStat?.lat && carStat?.lon) {
        // отображаем иконку машины
        const carFeature = new Feature({ geometry: new Point(fromLonLat([carStat?.lon, carStat?.lat])) });
        // отображаем точку вместо иконки машины
//        const carFeature = new Feature({ geometry: new Circle(fromLonLat([carStat?.lon, carStat?.lat]), 50) });
        carFeature.set("carid", car.id, true);

        // стиль машины
        let label=(car.display_name??'');
        if(user?.settings?.view_brand || user?.settings?.view_number) label+='\n';
        label+=(user?.settings?.view_brand?`${car.brand}`:'') + (user?.settings?.view_number?` ${car.number}`:'');
        const carStyle = new Style({
          image: new Icon({
            color: 'blue',
    //        crossOrigin: 'anonymous',
    //        size: [64,64],
    //        scale: 1,
            src: 'assets/images/car-default.svg',
          }),
          text: new Text({
            text: label,
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: 'blue' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            textBaseline: 'top',
            textAlign: 'center',
            offsetX: 0, //20,
            offsetY: 12,            
          }),                  
          stroke: new Stroke({ color: 'blue', width: 6 }),
          fill: new Fill({ color: 'blue' }), 
        });
        carFeature.setStyle(carStyle);
        this.mapobject.carsLayer?.getSource()?.addFeature(carFeature);


        // статус машины
        if(user?.settings?.show_car_status) {
          let color='blue', icon='', rotation=0;
          switch(carStat.status) {
            case IApiCarStatus_StatusEnum.Offline: color='red'; icon='car-times'; break;
            case IApiCarStatus_StatusEnum.IgnitionIsOn: color='green'; icon='car-key'; break;
            case IApiCarStatus_StatusEnum.Movement: color='green'; icon='car-play';
                                                    if(user.settings.show_azimuth && carStat.azimuth) { 
                                                      icon='car-arrow'; 
                                                      rotation=carStat.azimuth*2*Math.PI/360.0; 
                                                    } 
                                                    break;
            case IApiCarStatus_StatusEnum.Parking: color='blue'; icon='car-stop'; break;
          }
  
          // есть статус не none
          if(icon!='') {
            const carStateStyle = new Style({
              image:new Icon({
                rotation: rotation,
//                anchor:[-14,31],
//                anchorXUnits: 'pixels',
//                anchorYUnits: 'pixels',
                displacement: [15,15],
                color: color,
                src: `assets/images/${icon}.svg`,
                scale: 0.8,
              }),
              zIndex: 100
            });
            const carStateStyleBg = new Style({
              image:new Icon({
                rotation: rotation,
//                anchor:[-5,25],
//                anchorXUnits: 'pixels',
//                anchorYUnits: 'pixels',
                displacement: [15,15],          
                color: 'white',
                src: `assets/images/car-fill.svg`,
                scale: 1.2
              }),
            });
            const carStateFeature = new Feature({ geometry: new Point(fromLonLat([carStat?.lon, carStat?.lat])) });
            carStateFeature.set("carid", car.id, true);
            carStateFeature.setStyle(carStateStyle);
            const carStateFeatureBg = new Feature({ geometry: new Point(fromLonLat([carStat?.lon, carStat?.lat])) });
            carStateFeatureBg.set("carid", car.id, true);
            carStateFeatureBg.setStyle(carStateStyleBg);
            this.mapobject.carsStateLayer?.getSource()?.addFeatures([carStateFeature, carStateFeatureBg,]);  
          }  
        }
      }
    });
  }

  // перерисовать все пройденные треки
  public RedrawTrackHistory(history: IApiCarHistory[]|null) {
    this.data.carHistory=history;
    this.mapobject.tracksLayer?.getSource()?.clear();
    // если не показывать след
    if(!this.authService.getUser()?.settings?.show_cars_track) {
      return;   
    }

    let trackStyle = new Style({
      stroke: new Stroke({ color: this.authService.getUser()?.settings?.track_color??'blue', width: this.authService.getUser()?.settings?.track_width??4 }),
      fill: new Fill({ color: this.authService.getUser()?.settings?.track_color??'blue' }),
    });
    (history||[]).forEach(carhist=>{
      if((this.data.cars?.find(x=>x.id==carhist.car_id))) {
        let coords: Coordinate[]=[];
        (carhist.items||[]).forEach(pos=>{
          if(pos?.lat && pos.lon) coords.push(fromLonLat([pos.lon, pos.lat]));
        })
        let trackFeature = new Feature({ geometry: new LineString(coords)});
        trackFeature.setStyle(trackStyle);
        this.mapobject.tracksLayer?.getSource()?.addFeature(trackFeature);  
      }
    });
    
  }

  // перерисовать отделения
  public RedrawBranches() {
    let branches = this.preloaderService.getDistribBranches();     
    console.log('RedrawBranches', branches);

    this.mapobject.branchesLayer?.getSource()?.clear();
    (branches||[]).forEach(branch=>{
      if(branch?.lat && branch?.lon && branch.id && this.ui.branches_model[branch.id] ) {
        // отображаем иконку отделения
        const branchFeature = new Feature({ geometry: new Point(fromLonLat([branch?.lon, branch?.lat])) });

        const branchStyle = new Style({
          image: new Icon({
            color: 'red',
    //        crossOrigin: 'anonymous',
    //        size: [64,64],
    //        scale: 1,
            src: 'assets/images/building-solid.svg',
          }),
          text: new Text({
            text: branch.name??'',
            font: '14px Calibri,sans-serif',
            fill: new Fill({ color: 'red' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            textBaseline: 'top',
            textAlign: 'center',
            offsetX: 0, //20,
            offsetY: 12,            
          }),                  
          stroke: new Stroke({ color: 'red', width: 6 }),
          fill: new Fill({ color: 'red' }), 
        });
        branchFeature.setStyle(branchStyle);
        this.mapobject.branchesLayer?.getSource()?.addFeature(branchFeature);
      }  
    });
  }

  public CenterView(lon: number|null, lat: number|null) {
    if(lon && lat) 
      this.mapobject.map?.setView(
        new View({
          center: fromLonLat([lon, lat]),
          zoom: 14, maxZoom: 18, 
          padding: [16,16,16,16]
        }));
  }

  private GetCarStatus(carid?: string|null) {
    return (this.data.carStatus||[]).find(x=>x.car_id==carid)??null;
  }

  Settings_Hide() {
    this._saveUserSettingsFromOverlay();
  }

  _loadUserSettingsToOverlay() {
    this.userSettings = { ...(this.authService.getUser()?.settings??{})};
    this.ui.branches_model = {};

    this.preloaderService.loaded$.pipe(takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1)).subscribe(()=>{
      let branches=this.preloaderService.getDistribBranches();
      branches?.forEach(branch=>{
        this.ui.branches_model[branch.id!] = this.userSettings.view_branches?.includes(branch.id!)??false 
      })
      console.log('ui.branches_model', this.ui.branches_model); 
    })
  }

  _saveUserSettingsFromOverlay() {
    let user=this.authService.getUser();
    if(user) {
      if(!user.settings) user.settings={};
      user.settings.show_car_status = this.userSettings.show_car_status??false;
      user.settings.show_cars_track = this.userSettings.show_cars_track??false;
      user.settings.follow_car = this.userSettings.follow_car??false;
      user.settings.cluster_cars_on_map = this.userSettings.cluster_cars_on_map??false;
      user.settings.show_geozones = this.userSettings.show_geozones??false;
      user.settings.show_azimuth = this.userSettings.show_azimuth??false;
      user.settings.view_number = this.userSettings.view_number??false;
      user.settings.view_brand = this.userSettings.view_brand??false;

//      console.log('ui.branches_model', this.ui.branches_model);
      let branches=this.preloaderService.getDistribBranches();
      user.settings.view_branches = [];
      branches?.forEach(branch=>{
        if(branch.id && this.ui.branches_model[branch.id]) user!.settings!.view_branches!.push(branch.id) 
      }) 
//      console.log('user!.settings!.view_branches', user!.settings!.view_branches);

      console.log('userService.savesettings', user);
      this.userService.savesettings(user.id??'xxx', user.settings).subscribe({
        next: (res)=>{
          let u = this.authService.getUser()!;
          u.settings=res.user?.settings;
          this.authService.updateUser(u, true);
        },
        error: (err)=>console.error('UserService.savesettings', err)
      });

      this.RedrawGeozones(this.data.geozones);
      this.RedrawCars(this.data.cars, this.data.carStatus);
      this.RedrawTrackHistory(this.data.carHistory);
      this.RedrawBranches();
    }
  }

  getBranches() {
    return this.preloaderService.getDistribBranches()||[]
  }

}
