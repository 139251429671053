import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiDriver, IApiDriverListResponse, IApiDriverResponse } from "../model.backend/driver.model";
import { IApiBusinessTransactionListResponse, IApiBusinessTransactionResponse } from "../model.backend/business-transaction.model";
import { map } from "rxjs/internal/operators/map";

@Injectable({
    providedIn: 'root'
  })
  export class BusinessTransactionService extends BaseService {
    protected controller: string = "balancetransaction";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(from?: string|null): Observable<IApiBusinessTransactionListResponse> {
      let f=`${from?'/'+encodeURIComponent(from) : ''}`;
        return this.http.get<IApiBusinessTransactionListResponse>(`${this.apiUrl}/${this.controller}/list${f}`, {headers: this.headers} )
            .pipe(
              map(x=>{
                // расчитать balance/balance_bonus для незаполненных
                (x.transactions||[])?.sort((a,b)=>(a.action_date??'')>(b.action_date??'')?1:-1);
                let balance=0, balance_bonus=0;
                (x.transactions||[]).forEach(x=>{
                  if(x.outgoing!=null) {
                    if(balance_bonus!=0) {
                      if(x.outgoing>balance_bonus) {
                        balance-=x.outgoing-balance_bonus
                        balance_bonus=0;
                      } else {
                        balance-=x.outgoing;
                      }
                    } else {
                      balance = balance-x.outgoing;
                    }
                  }
                  if(x.incoming!=null) {
                    balance+=x.incoming;
                  }
                  if(x.balance==null) x.balance=balance;
                  else balance=x.balance;
                  if(x.balance_bonus==null) x.balance_bonus=balance_bonus;
                  else balance_bonus=x.balance_bonus;
                });
                return x;
              }),
              catchError(this.handleError<IApiBusinessTransactionListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения баланса.'))
            );    
    }   

    get(id: string): Observable<IApiBusinessTransactionResponse> {
      return this.http.get<IApiBusinessTransactionResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBusinessTransactionResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о транзакции.'))
          );    
    }

}
  