import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { filter } from 'rxjs/internal/operators/filter';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';
import { DriverTripService } from 'src/app/service.backend/driver-trip.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';
import { nanoid } from 'nanoid'
import { Table } from 'primeng/table';
import { IApiCar } from 'src/app/model.backend/car.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-driver-trips',
  templateUrl: './driver-trips.component.html',
  styleUrls: ['./driver-trips.component.css']
})
export class DriverTripsComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() driverid = null as string|null;
  @Input() customerid = null as string|null;
  @Input() default_carid = null as string|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiDriverTrip>();
  @Output() onInsert = new EventEmitter<IApiDriverTrip>();
  @Output() onDelete = new EventEmitter<IApiDriverTrip>();
   

  @ViewChild('Table') table?: Table;

  destroy$ = new Subject<boolean>();
  data = {
    loading: false,
    saving: false,
    error: null as string|null,  
    items: [] as IApiDriverTrip[],
    clonedTrips: {} as { [s: string]: IApiDriverTrip },
    editingNewRecord: false,
    dataFrom: null as Date|null,
    dataTo: null as Date|null
  };
  ui = {
    disabledEdit: false,
    rowError: false
  }
  options = {
    cars: [] as INameCode[]
  };


  constructor(
    private driverTripService: DriverTripService,
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    public utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    let userid= this.authService.getUser()?.id;
    let customerid = this.authService.getUser()?.customerid;
    if(!customerid || !userid) {
      // глобальный администратор не может редактировать водителя
      this.ui.disabledEdit = true;
      return;
    }

    this.data.loading=true;  
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1),
      tap( ()=> {
        // загрузка options
        this.options.cars = this.preloaderService.getCarsForUser(userid!, customerid!)
          .filter(car=>car.customers_access?.includes(customerid!) || car.customerid==customerid )                             
          .map( car=> { return { name: this.utils.concats([car.number??'', car.brand??'', car.vin??''], ', '), code: car.id } });
          this.options.cars.unshift({name: 'Не выбрано', code: null});
      }),
        // загрузка данных
      switchMap( ()=> forkJoin(
        {
          trips: this.driverTripService.list(this.driverid)
        }
      ))
    ).subscribe({
      next: (res)=>{
        this.data.loading = false;
        if(res.trips.ok) {
          this.data.items = res.trips.items||[];
          this._applySort();
        } else {
          console.error('loadRecords', res.trips?.errorMessage, res.trips?.errorMessageForUser);
          this.data.error = res.trips?.errorMessageForUser ?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
          this.ui.disabledEdit = true;
        }
        
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading = false;
        this.ui.disabledEdit = true;
      }
    });  
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  _applySort() {
    this.data.items = this.data.items.sort((a,b) =>  a.to==null || ((a.to??'') > (b.to??'')) ? 1:-1);
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }


  getCarName(carid: string|null) {
    let car = carid?this.preloaderService.getCar(carid):null;
    return car ? this.utils.concats([car.number, car.brand, car.vin], ', ') : null;
  }

  canEditTrips() {
    return this.authService.isAllow(IApiUser_Rights.admin) || this.authService.isAllow(IApiUser_Rights.driverManage) ;
  }

  clickAddTrip() {
    if(!this.driverid || !this.customerid || !this.table) return;

    this.data.editingNewRecord = true;
    let obj: IApiDriverTrip = {
      id: nanoid(),
      driverid: this.driverid,
      customerid: this.customerid,
      carid: this.default_carid
    }
    this.data.items.unshift({...obj});
    this.table.editingRowKeys = {[obj.id]: true};
    this.data.dataFrom = this.data.dataTo = null;
    this.ui.rowError = false;
  }

  onRowEditInit(trip: IApiDriverTrip) {
    this.data.clonedTrips[trip.id as string] = { ...trip };   
    this.data.dataFrom = this.utils.isoDateParseToDate(trip.from);
    this.data.dataTo = this.utils.isoDateParseToDate(trip.to);
    if(this.table) this.table.editingRowKeys = {[trip.id]: true};
    this.ui.rowError = false;
  }

  onRowEditSave(trip: IApiDriverTrip) {
    if(this.data.dataFrom==null || trip.carid==null) {
      this.ui.rowError=true;
      if(this.table) this.table.editingRowKeys = {[trip.id]: true};
      console.log('onRowEditSave exit')
      return;
    }

    if(this.data.dataFrom) {
      trip.from = this.utils.dateTimeToUtc(this.data.dataFrom).toISOString();
    } else {
      trip.from = null;
    }
    if(this.data.dataTo) {
      trip.to = this.utils.dateTimeToUtc(this.data.dataTo).toISOString();
    } else {
      trip.to = null;
    }

    let oldid=trip.id;

    this.data.saving = true;
    ( this.data.editingNewRecord 
      ? this.driverTripService.insert(trip)
      : this.driverTripService.update(trip)
    ).subscribe({
      next: (res)=>{ 
        if(res.ok) { 
          if(this.data.editingNewRecord) {
            let ix = this.data.items.findIndex(x=>x.id==oldid);
            if((ix<=0) && res.item?.id) this.data.items[ix].id=res.item?.id!;
            this.onInsert.emit();
          } else {
            this.onUpdate.emit();
          }
          this.data.editingNewRecord=false;     
          delete this.data.clonedTrips[trip.id as string];
          this.data.saving=false;
          this._applySort();   
        }
        else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          let e = this.data.editingNewRecord 
            ? res.errorMessageForUser??this.translate.instant('form.addError')
            : res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: e??'' });  
          this.data.saving=false;         
          if(this.table) this.table.editingRowKeys = {[trip.id]: true};
          }
      },
      error: (err)=> {
        console.error('saveRecord', err);
        let e = this.data.editingNewRecord
          ? this.translate.instant('form.addError')
          : this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: e??'' });
        this.data.saving=false;         
        if(this.table) this.table.editingRowKeys = {[trip.id]: true};
      }
    })

  }

  onRowEditCancel(trip: IApiDriverTrip, index: number) {
    this.data.items[index] = this.data.clonedTrips[trip.id as string];
    delete this.data.clonedTrips[trip.id as string];
    if(this.data.editingNewRecord) {
      this.data.editingNewRecord=false;
      this.data.items.splice(0,1);
    }
  }

  inRowEditing() {
    return Object.keys(this.table?.editingRowKeys||{}).length!=0;
  }

  clickTripDelete(trip: IApiDriverTrip, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('driver-trips.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.driverTripService.delete(trip.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickTripDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              let ix = this.data.items?.findIndex(x=>x.id==trip.id)??-1;
              if(ix>=0) this.data.items?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
              this.onDelete.next(trip);
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

}
