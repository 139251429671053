<p-dialog [header]="headerKey|translate" 
    [(visible)]="visible" 
    [style]="{ width: '40vw'}" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{headerKey|translate}}</span>
    </ng-template>

    <div [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3 mb-3">
            <div class="col-12 flex align-items-center">
                <p-fileUpload #fileUploadElement
                    [disabled]="ui.fileLoaded"
                    mode="basic" 
                    id="filename" 
                    accept=".xls,.xlsx,.xlsm,.csv"
                    (onSelect)="onSelect($event)"
                    (uploadHandler)="uploadHandler($event)"
                    [invalidFileTypeMessageSummary]="'import.messageIncorrectFileSummary'|translate"
                    [invalidFileTypeMessageDetail]="'import.messageIncorrectFileDetail'|translate"
                    [auto]="false" 
                    [customUpload]="true"
                    [chooseLabel]="'import.selectFile'|translate">
                </p-fileUpload>
                <div class="ml-2">
                    <label for="filename">{{(ui.fileLoaded ? 'import.fileLoaded':'import.fileSelect')|translate}}</label>
                </div>
            </div>
            <div *ngIf="exampleUrl" class="col-12">
                {{'import.example'|translate}}: <a target="_blank" [href]="exampleUrl"><i class="pi pi-link"></i>{{'import.link'|translate}}</a>
            </div>
            <div *ngIf="ui.fileLoaded" class="col-12">
                <div class="p-2 bg-gray-100">{{'import.exampleData'|translate}}</div>
                <p-table [value]="items">
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td *ngFor="let col of item">{{col}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button *ngIf="ui.fileLoaded" 
            (click)="clickImport()"
            [disabled]="loading"
            [label]="btnImportLabel()" 
            icon="pi pi-check" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

