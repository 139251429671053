import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiUser } from 'src/app/model.backend/user.model';
import { UserService } from 'src/app/service.backend/user.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Output() onHide = new EventEmitter();

  saving = false;
  error = null as string|null;  
  destroy$ = new Subject<boolean>();

//  isNew = () => !this.id;
  getHeader = () => this.translate.instant('changepassword.title');
  getButtonOkLabel = () => this.translate.instant('changepassword.change')
  getButtonOkIcon = () => ('pi-check')

  validatorPasswordMatch = (control: AbstractControl): {[key: string]: any} | null => {
    const password = this.form?.get('password')?.value as string;
    const passwordConfirm = control.value as string;
    if (password !== passwordConfirm) {
      return {passwordMatch: true};
    }  
    return null;
  };

  form = new FormGroup({
    password: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
    password2: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50), this.validatorPasswordMatch]),
  });
 
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  changePassword() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    this.saving = true;
    let u:IApiUser={
      id: this.authService.user$.value?.id,
      password: this.form.get('password')?.value,
      password_salt: this.form.get('password2')?.value,
    }
    this.userService.changepassword(u.id??'xxx', u)
    .subscribe({
      next: (res) => {
        if(res.ok) {
          this.visible=false;
          this.messageService.add({severity: 'success', detail: this.translate.instant('changepassword.success')})
        } else {
          console.error('changePassword', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser??this.translate.instant('changepassword.error');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('changePassword', err);
        this.error = this.translate.instant('changepassword.error')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });
  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  validatePasswordMatch(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['passwordMatch']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
