import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import pakageJson from 'package.json'
import { IApiUser, IApiUser_Rights } from 'src/app/model.backend/user.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PreloaderService } from 'src/app/service/preloader.service';
import { MasterMenuService } from './master-menu.service';
import { UtilsService } from 'src/app/service/utils.service';
import { SelectButtonOptionClickEvent } from 'primeng/selectbutton';

@Component({
  selector: 'app-master-menu',
  templateUrl: './master-menu.component.html',
  styleUrls: ['./master-menu.component.css']
})
export class MasterMenuComponent {
  version = pakageJson.version;
  IApiUser_Rights=IApiUser_Rights;
  show_time = '';
  
  ui = {
    profile: {
      show: false
    },
    topSelectButtonOptions: [
      {label: this.translateService.instant('masterMenu.btnDashboard'), value: 'Дашборд' },
      {label: this.translateService.instant('masterMenu.btnMap'), value: 'Карта' },
      {label: this.translateService.instant('masterMenu.btnReport'), value: 'Отчеты'  },
    ],
    topSelectButtonValue: null as any
  }

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private preloaderService: PreloaderService,
    private masterMenuService: MasterMenuService,
    private utils: UtilsService,
//    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    masterMenuService.checkRequestSupportCount();
    masterMenuService.checkMessageCount();
    setInterval(()=>{
      try{ 
        masterMenuService.checkRequestSupportCount();
        masterMenuService.checkMessageCount();
      }
      catch{}
    }, 60*1000);

    setInterval(()=>{
      let dt = new Date(Date.now());
      this.show_time = `${this.utils.dateToDDMMYY(dt)}<br/>${this.utils.dateToHHMM(dt)}`;
    }, 1000);

    this.router.events.subscribe(event=>{
      if(event instanceof NavigationEnd) {
        console.log('NavigationEnd', event, route.snapshot);
        if(event.url.startsWith('/map')) {
          this.ui.topSelectButtonValue = this.ui.topSelectButtonOptions[1];
        } else {
          this.ui.topSelectButtonValue = null;
        }
      };
    });

    this.translateService.onLangChange.subscribe(lang=>{
      this.ui.topSelectButtonOptions[0].label = this.translateService.instant('masterMenu.btnDashboard');
      this.ui.topSelectButtonOptions[1].label = this.translateService.instant('masterMenu.btnMap');
      this.ui.topSelectButtonOptions[2].label = this.translateService.instant('masterMenu.btnReport');
      this.ui.topSelectButtonOptions= [...this.ui.topSelectButtonOptions];
    })
  }

  isAdministrator=()=>this.authService.isMasterAdministrator();
  isAllow = (right: IApiUser_Rights)=>this.authService.isAllow(right)
  setLanguage = (lang: string)=>this.translateService.use(lang);
  getLanguage = ()=>(this.translateService.currentLang||'').toUpperCase()

  setClass(element_id: string, class_name: string) {
    document.getElementById(element_id)?.classList?.add(class_name);
  }

  clickLogout = ()=>this.authService.logOut() 
  getUserLoginName = ()=>`<b>${this.authService.user$?.value?.login}</b><br/><small>${this.authService.user$?.value?.name}</small>`;
  getUserName = ()=>this.authService.user$?.value?.name;
  isLoggedIn = ()=>this.authService.isLoggedIn();
  getUserRole = ()=> {
    let key = 'masterMenu.roleUserText'
    if(this.authService.user$.value?.customerid==null) key = 'masterMenu.roleAdminSysText';
    else if ( (this.authService.user$?.value?.original_user?.options?.indexOf(IApiUser_Rights.admin)??-1)>=0) key ="masterMenu.roleAdminText";
    return this.translateService.instant(key);
  }

  clickRelay(event: Event) {
      this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: this.translateService.instant('masterMenu.relayText'),
          header: this.translateService.instant('masterMenu.relayTextHeader'),
          icon: 'pi pi-exclamation-triangle',
          acceptIcon:"pi pi-check mr-1",
          acceptLabel: this.translateService.instant('form.ok'),
          rejectVisible: false,
//          rejectIcon:"none",
//          rejectButtonStyleClass:"p-button-text",
          accept: () => {
//              this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
          },
          reject: () => {
//              this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
          }
      });
  }

  getReportList$ = ()=>this.preloaderService.availableReportLists$;
  getRequestSuppportCount$  = ()=>this.masterMenuService.requestSupportCount$;
  getMessageCount$  = ()=>this.masterMenuService.messageCount$;


  hideMasterMenuOthers() {
    try {
      let el:any = document.getElementById('masterMenuOthers');
      el.classList.add("hidden"); 
    } catch {}
  }

  clickProfile() {
    this.hideMasterMenuOthers();
    this.ui.profile.show = true;
  }

  onProfile_Update(user: IApiUser) {
    console.log('onProfile_Update', user);
    this.authService.updateUser(user);
  }

  onTopSelectButtonClick(event: SelectButtonOptionClickEvent) {
    if(event.index==1) {
      this.router.navigate(['map']);
    } else {
      setTimeout(()=>{this.ui.topSelectButtonValue=null;},100);
    }

  }


}
