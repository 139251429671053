<div class="flex h-full w-full">
    <div id="map_panel_left" class="h-full flex-none -border-1 -border-round-right surface-ground -surface-border" [ngStyle]="{width: ui.panel_left.close ? '0px':'450px'}">
        <div class="flex h-full" style="overflow-x: hidden;">
            <div class="-flex-1 flex-none h-full transition-all transition-duration-300" [ngStyle]="{width: ui.panel_left.close ? '0px':'100%'}" >
                <div class="p-2">
                    <label>{{'common.customer'|translate}}</label>
                    <app-customer-select 
                        [(customerId)]="ui.selectedFilter.customerId" 
                        [(userId)]="ui.selectedFilter.userId"
                    ></app-customer-select>
                </div>
                <div class="p-2">
                    <p-selectButton (onChange)="correctInnerHeight2()"
                        [(ngModel)]="ui.selectButtonValue"
                        [options]="ui.selectButtonOptions" 
                        optionLabel="label" 
                        class="sav3-selectButton w-full">
                    </p-selectButton>
                </div>
                <div *ngIf="ui.selectButtonValue?.value=='car'">
                    <div class="-h-full">
                        <app-car-card-list 
                            [customerId]="ui.selectedFilter.customerId"
                            [userId]="ui.selectedFilter.userId"
                            [carGroupId]="ui.selectedFilter.carGroupUserId"
                            [cars]="data.cars"
                            [currentCarStatuses]="data.carstatuses"
                            [currentTrips]="data.currentTrips"
                            [(selectedCars)]="ui.selectedCars"
                            (onFiltered)="onFiltered($event)"
                            (onCarClick)="onCar_Click($event)"
                        ></app-car-card-list>
                    </div>
                </div>
                <div *ngIf="ui.selectButtonValue?.value=='group'">
                    <div class="h-full">
                        <app-cargroupuser-list></app-cargroupuser-list>

                        <!--
                        <div *ngIf="canViewGroupUser()" class="grid surface-ground px-2 pt-3">
                            <div class="col-12 text-right">
                                <p-button (onClick)="clickCarGroupUserAdd()" icon="pi pi-plus" [label]="'main.btnGroupAdd'|translate" styleClass="p-button-secondary p-button-sm p-button-rounded" ></p-button>
                            </div>
                            <div class="col-12">
                                <p-table [value]="data.cargroupsuser" class="p-datatable-sm sav3-table">
                                    <ng-template pTemplate="body" let-group>
                                        <tr>
                                            <td class="w-full">
                                                {{ group.name }}
                                            </td>
                                            <td class="p-1">
                                                <button (click)="$event.stopPropagation();clickCarGroupUserEdit(group)" class="dh-onhover p-button p-button-rounded p-button-text p-1 sav3" pButton>
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                            </td>
                                            <td class="p-1">
                                                <button (click)="$event.stopPropagation();clickCarGroupUserDelete($event,group)" class="dh-onhover p-button p-button-rounded p-button-text p-button-danger p-1 sav3" pButton>
                                                    <i class="pi pi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <app-norecords *ngIf="(data.cargroupsuser?.length??0)==0"></app-norecords>
                            </div>
                        </div>
-->

                    </div>
                </div>
                <div *ngIf="ui.selectButtonValue?.value=='driver'">
                    <div class="h-full">
                            <app-driver-card-list 
                                [drivers]="data.drivers"
                                [currentCarStatuses]="data.carstatuses"
                                [(selectedDrivers)]="ui.selectedDrivers"
                                (onDriverUpdated)="onDriverUpdated($event)"
                                (onDriverInserted)="onDriverInserted($event)"
                                (onDriverDeleted)="onDriverDeleted($event)"
                                (onTripUpdated)="onTripUpdated_event()"
                            ></app-driver-card-list>
                    </div>
                </div>
                <div *ngIf="ui.selectButtonValue?.value=='geozone'">
                    <div class="h-full">
                        <div class="grid surface-ground px-2 pt-2">
                            <div id='scrollelement2' class="col-12 -h-full" [ngStyle]="{'height':ui.innerHeight2, 'overflow-y': 'auto'}">
                                <app-geozone-card *ngFor="let geozone of data.geozones" 
                                    [geozone]="geozone"
                                    (onEditKeyPress)="editedGeozone.onEditKeyPress($event)"
                                    (onDblclick)="editedGeozone.onDblclick($event)"
                                >
                                </app-geozone-card>
                            </div>
                            <div *ngIf="editedGeozone.state==='add'" class="col-12">
                                <button (click)="editedGeozone.onAddGeozone($event)" class="p-button-sm p-button-primary w-full" label="{{editedGeozone.addGeozoneButtonName}}" pButton></button>
                            </div>
                            <div *ngIf="editedGeozone.state==='draw'" class="col-12 text-center">
                                <div>{{editedGeozone.addGeozoneButtonName}}</div>
                            </div>
                            <div *ngIf="editedGeozone.state==='edit'" class="col-12 text-center">
                                <div>{{editedGeozone.addGeozoneButtonName}}</div>
                            </div>                                
                            <div *ngIf="editedGeozone.state==='draw'" class="col-12">
                                <button (click)="editedGeozone.onCancelGeozone();" class="p-button-sm p-button-danger w-full" label="Отменить" pButton></button>
                            </div>
                            <div *ngIf="editedGeozone.state==='save' || editedGeozone.state==='edit'" class="col-6 text-center">
                                <button (click)="editedGeozone.onAddGeozone($event)" class="p-button-sm p-button-primary w-full" label="Сохранить" pButton></button>
                            </div>
                            <div *ngIf="editedGeozone.state==='save' || editedGeozone.state==='edit'" class="col-6">
                                <button (click)="editedGeozone.onCancelGeozone();" class="p-button-sm p-button-secondary w-full" label="Отменить" pButton></button>
                            </div>
                            <div *ngIf="editedGeozone.state==='edit'" class="col-12">
                                <button (click)="editedGeozone.onDeleteGeozone();" class="p-button-sm p-button-danger w-full" label="Удалить" pButton></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="h-full" style="flex: 0 0 4px;"></div>
    -->
    <!--
    <button (click)="op.toggle($event)" 
        [style]="{position: 'absolute', right: '10px', top: '60px', 'z-index': 100}" 
        icon="pi pi-cog" class="p-button-sm p-button-rounded p-button-primary" pButton >
    </button>
    <p-overlayPanel (onHide)="userSettingsOverlay_Hide()" #op>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_car_status" [binary]="true" inputId="show_car_status" class="mr-2"></p-checkbox>
            <label for="show_car_status">Отображать статусы ТС</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_cars_track" [binary]="true" inputId="show_cars_track" class="mr-2"></p-checkbox>
            <label for="show_cars_track">Отображать след ТС</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.follow_car" [binary]="true" inputId="follow_car" class="mr-2"></p-checkbox>
            <label for="follow_car">Cледить за выбранным ТС</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.cluster_cars_on_map" [binary]="true" inputId="cluster_cars_on_map" class="mr-2"></p-checkbox>
            <label for="cluster_cars_on_map">Группировать ТС на карте</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_geozones" [binary]="true" inputId="show_geozones" class="mr-2"></p-checkbox>
            <label for="show_geozones">Отображать геозоны</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.show_azimuth" [binary]="true" inputId="show_azimuth" class="mr-2"></p-checkbox>
            <label for="show_azimuth">Отображать направление движения</label>
        </div>
        <div class="mb-1 mt-3 flex align-items-center">
            <small class="font-bold">Формат отображения ТС на карте</small>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.view_number" [binary]="true" inputId="view_number" class="mr-2"></p-checkbox>
            <label for="view_number">Отображать номер ТС</label>
        </div>
        <div class="mb-1 flex align-items-center">
            <p-checkbox [(ngModel)]="userSettings.view_brand" [binary]="true" inputId="view_brand" class="mr-2"></p-checkbox>
            <label for="view_brand">Отображать модель ТС</label>
        </div>
    </p-overlayPanel>
-->
    <div class="h-full w-full border-1 surface-border border-round-left">
        <app-map #mapComponent></app-map>
    </div>
</div>


<app-geozone-name-edit *ngIf="editedGeozone.showGeozoneNameEdit"
    [geozone]="editedGeozone.geozone"
    [isNew]="editedGeozone.isNew"
    [isDel]="editedGeozone.isDel"
    (onHide)="editedGeozone.onHide($event)"
    (onSave)="editedGeozone.afterSave($event)"
    (onDel)="editedGeozone.afterDel($event)"
>
</app-geozone-name-edit>

<!--
<app-structure-park-edit *ngIf="ui.parkEdit.show"
    [id]="ui.parkEdit.id"
    [userid]="ui.parkEdit.userid"
    [customerid]="ui.parkEdit.customerid"
    [ownEdit]="true"
    (onHide)="onDialogParkEditHide()"
    (onUpdate)="onDialogParkEditUpdate($event)"
    (onInsert)="onDialogParkEditInsert($event)"
>
</app-structure-park-edit>
-->


