import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiCar, IApiCarCustomerRequest, IApiCarListResponse, IApiCarResponse } from "../model.backend/car.model";
import { IApiCarSensorsResponse } from "../model.backend/carsensor.model";

@Injectable({
    providedIn: 'root'
  })
  export class CarService extends BaseService {
    protected controller: string = "car";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(customerid: string|null|undefined, filter: string): Observable<IApiCarListResponse> {
        return this.http.get<IApiCarListResponse>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(customerid||'xxx')}?filter=${encodeURIComponent(filter||'')}`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiCarListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка транспортных средств.'))
            );
    }   

    listNameCode(customerid: string|null|undefined): Observable<IApiCarListResponse> {
      return this.http.get<IApiCarListResponse>(`${this.apiUrl}/${this.controller}/listNameCode/${encodeURIComponent(customerid||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка транспортных средств.'))
          );
    }

    get(id: string): Observable<IApiCarResponse> {
      return this.http.get<IApiCarResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о транспортном средстве.'))
          );    
    }

    getWithSensors(id: string): Observable<IApiCarSensorsResponse> {
      return this.http.get<IApiCarSensorsResponse>(`${this.apiUrl}/${this.controller}/sensors/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarSensorsResponse>(`GET ${this.controller}.getWithSensors`, null, 'Ошибка получения информации о транспортном средстве.'))
          );    
    }

    insert(obj: IApiCar): Observable<IApiCarResponse> {
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления транспортного средства.'))
          );    
    }
  
    update(id: string, obj: IApiCar): Observable<IApiCarResponse> {
//      console.log('update car', obj);
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о транспортном средстве.'))
          );    
    }

    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления транспортного средства.'))
          );    
    }


    changeOwner(id: string, newcustomerId: string, newpin: string): Observable<IApiCarResponse> {
      let obj: IApiCarCustomerRequest = { customerId: newcustomerId, pin: newpin, action: 'changeowner' };
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/changecustomer/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.changeOwner`, null, 'Ошибка смены владельца транспортного средства.'))
          );    
    }

    /*
    addAccess(id: string, newcustomerId: string): Observable<IApiCarResponse> {
      let obj: IApiCarCustomerRequest = { customerId: newcustomerId, action: 'addaccess' };
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/changecustomer/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.addAccess`, null, 'Ошибка добавления компании для доступа транспортного средства.'))
          );    
    }

    removeAccess(id: string, customerId: string): Observable<IApiCarResponse> {
      let obj: IApiCarCustomerRequest = { customerId: customerId, action: 'removeaccess' };
      return this.http.post<IApiCarResponse>(`${this.apiUrl}/${this.controller}/changecustomer/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCarResponse>(`POST ${this.controller}.removeAccess`, null, 'Ошибка добавления компании для доступа транспортного средства.'))
          );    
    }
*/

}
  