import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IApiUser_Rights } from '../model.backend/user.model';


export const accessGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  let res=false;
  try {
    let role = route.data['role'] as IApiUser_Rights;
    let role2 = route.data['role2'] as IApiUser_Rights;
    res = authService.isAllow(role) || (role2 && authService.isAllow(role2)); 
  } 
  catch {}
  return res;
};
