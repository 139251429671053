import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { RequestSupportService } from "src/app/service.backend/requestsupport.service";

@Injectable({
    providedIn: 'root'
  })
  export class MasterMenuService { 
    public requestSupportCount$ = new BehaviorSubject<number>(0);
    public messageCount$ = new BehaviorSubject<number>(0);

    constructor(
        private requestSupportService: RequestSupportService,
        private authService: AuthService
    ){
        this.checkRequestSupportCount();
        this.checkMessageCount();
    }

    public checkRequestSupportCount() {
        if(this.authService.isLoggedIn()) {
            this.requestSupportService.countUnread().subscribe(
                {
                    next: (res) => { if(res) this.requestSupportCount$.next(res.count??0); },
                    error: (err) => console.error('calcRequestSupportCount.error', err)
                }         
            )
        }
    }

    public checkMessageCount() {
        if(this.authService.isLoggedIn()) {

        }

    }

    hideMenuProfile() {
        try {
          let el:any = document.getElementById('tm_prof_02');
          el.classList.add("hidden"); 
        } catch {}
      }
  }
