import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiRequestSupport, IApiRequestSupportListResponse, IApiRequestSupportNoreadResponse, IApiRequestSupportResponse } from "../model.backend/requestsupport.model";

@Injectable({
    providedIn: 'root'
  })
  export class RequestSupportService extends BaseService {
    protected controller: string = "requestsupport";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(): Observable<IApiRequestSupportListResponse> {
        return this.http.get<IApiRequestSupportListResponse>(`${this.apiUrl}/${this.controller}/list`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiRequestSupportListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка заявок.'))
            );    
    }   

    get(id: string): Observable<IApiRequestSupportResponse> {
      return this.http.get<IApiRequestSupportResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiRequestSupportResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о заявке.'))
          );    
    }

    markAsRead(id: string): Observable<IApiRequestSupportResponse> {
      return this.http.get<IApiRequestSupportResponse>(`${this.apiUrl}/${this.controller}/markread/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiRequestSupportResponse>(`GET ${this.controller}.markread`, null, 'Ошибка изменения заявки.'))
          );    
    }

    question(obj: IApiRequestSupport): Observable<IApiRequestSupportResponse> {
      return this.http.post<IApiRequestSupportResponse>(`${this.apiUrl}/${this.controller}/question`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiRequestSupportResponse>(`POST ${this.controller}.question`, null, 'Ошибка добавления вопроса.'))
          );    
    }
  
    close(id: string): Observable<IApiRequestSupportResponse> {
      return this.http.get<IApiRequestSupportResponse>(`${this.apiUrl}/${this.controller}/close/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiRequestSupportResponse>(`GET ${this.controller}.close`, null, 'Ошибка закрытия заявки.'))
          );    
    }

    // вернуть число непрочитанных
    countUnread(): Observable<IApiRequestSupportNoreadResponse> {
      return this.http.get<IApiRequestSupportNoreadResponse>(`${this.apiUrl}/${this.controller}/countunread`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiRequestSupportNoreadResponse>(`POST ${this.controller}.countunread`, null, 'Ошибка получения информации о заявках.'))
          );    
    }


}
  