import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiSmsMessage, IApiSmsMessageListResponse, IApiSmsMessageResponse } from "../model.backend/smsmessage.model";

@Injectable({
    providedIn: 'root'
  })
  export class SmsMessageService extends BaseService {
    protected controller: string = "smsmessage";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(carid: string|null|undefined): Observable<IApiSmsMessageListResponse> {
        return this.http.get<IApiSmsMessageListResponse>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(carid||'xxx')}`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiSmsMessageListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка сообщений.'))
            );
    }   

    send(obj: IApiSmsMessage): Observable<IApiSmsMessageResponse> {
      return this.http.post<IApiSmsMessageResponse>(`${this.apiUrl}/${this.controller}/send`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiSmsMessageResponse>(`POST ${this.controller}.send`, null, 'Ошибка отправки команды.'))
          );    
    }
  
}
  