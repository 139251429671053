import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiUser } from 'src/app/model.backend/user.model';
import { UserService } from 'src/app/service.backend/user.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, INameCodeNumber, UtilsService } from 'src/app/service/utils.service';
import { MasterMenuService } from '../../master-page/master-menu/master-menu.service';
import { IApiSensorName_KeyEnum } from 'src/app/model.backend/sensor-name.model';

@Component({
  selector: 'app-profile-edit2',
  templateUrl: './profile-edit2.component.html',
  styleUrls: ['./profile-edit2.component.css']
})
export class ProfileEdit2Component implements OnInit, OnDestroy {
  @Input() visible=true;
//  @Input() id = null as string|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiUser>();
//  @Output() onInsert = new EventEmitter<IApiCharterer>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiUser|null;
  destroy$ = new Subject<boolean>();

  ui = {
    change: {
      show: false
    }
  }

  options = {
    cars: [] as INameCode[],
    languages: [
      { name: 'Русский', code: 'ru'},
      { name: 'English', code: 'en'}
    ] as INameCode[],
    timezones: [
      { name: "UTC+0 Среднее время Гринвича : Дублин, Эдинбург, Лиссабон, Лондон", code: 0},
      { name: "UTC+1 Брюссель, Копенгаген, Мадрид, Париж", code: 60},
      { name: "UTC+2 Хельсинки, Рига, Таллинн", code: 120},
      { name: "UTC+3 Москва, Санкт-Петербург, Волгоград", code: 180},
      { name: "UTC+4 Самара", code: 240},
      { name: "UTC+5 Екатеринбург", code: 300},
      { name: "UTC+6 Алматы, Новосибирск", code: 360},
      { name: "UTC+7 Красноярск", code: 420},
      { name: "UTC+8 Иркутск, Улан-Батаар", code: 480},
      { name: "UTC+9 Якутск", code: 540},
      { name: "UTC+10 Владивосток", code: 600},
      { name: "UTC+11 Магадан", code: 660}
    ] as INameCodeNumber[],
    viewmodes: [
      { name: 'Карточка', code: 'card'},
      { name: 'Таблица', code: 'table' }
    ] as INameCode[],

  };

  data = {
    samplecars: [
      {number: 'о777оо77', brand: 'Марка модели', vin:  'VIN 5669875645878455'}
    ] as IApiCar[],
    sensors: [] as (string|null)[]
  }

//  isNew = () => !this.id;
  getHeader = () => this.translate.instant('profile.edit');
  getButtonOkLabel = () => this.translate.instant('form.save')
  getButtonOkIcon = () => ('pi-save')

  form = new FormGroup({
    login: new FormControl(null as string|null, [Validators.required, Validators.maxLength(50)]),
    name: new FormControl(null as string|null, [Validators.maxLength(255)]),
    phone: new FormControl(null as string|null, [Validators.maxLength(100)]),
    email: new FormControl(null as string|null, [Validators.maxLength(255)]),
//    position: new FormControl(null as string|null, [Validators.maxLength(1000)]),
//    comment: new FormControl(null as string|null, [Validators.maxLength(255)]),
    utc_offset: new FormControl(null as number|null),
    show_popup_messages: new FormControl(null as boolean|null),
//    show_popup_messages_time: new FormControl(null as number|null),
    track_width: new FormControl(null as number|null),
    track_color: new FormControl(null as string|null),
    geozone_color: new FormControl(null as string|null),
    geozone_fill: new FormControl(null as string|null),
    language: new FormControl(null as string|null),
    viewmode: new FormControl(null as string|null)
//    show_cars_as_points: new FormControl(null as boolean|null),
//    show_car_status: new FormControl(null as boolean|null),
//    show_cars_track: new FormControl(null as boolean|null),
//    follow_car: new FormControl(null as boolean|null),
//    cluster_cars_on_map: new FormControl(null as boolean|null),
//    show_geozones: new FormControl(null as boolean|null),
//    show_azimuth: new FormControl(null as boolean|null),
//    view_number: new FormControl(null as boolean|null),
//    view_brand: new FormControl(null as boolean|null)
  });

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private masterMenuService: MasterMenuService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
//    this.isNew() ? this.newRecord(): this.editRecord(); 

    this.masterMenuService.hideMenuProfile()

    this.loading = true;   
    forkJoin({
      options: this.preloaderService.ensureLoad$(this.destroy$)
    })
    .subscribe({
      next: (res) => {
        this.loading = false;
        this.initOptions();
        this.editRecord(); 
      },
      error: err => {
        this.loading = false;
        this.error = err; 
        this.messageService.add({severity: 'error', detail: this.error??'' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  initOptions(){
    this.options.cars=this.preloaderService.getAvailableCarsOptions();
  }

  editRecord() {
    this.loading=true;
    this.userService.get(this.authService.user$.value?.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.user);
          this.item = res.user;
          this._loadFormFromObject(this.item);
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    this.item={};
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(user: IApiUser) {
    user.login = this.form.get('login')?.value;
    user.name = this.form.get('name')?.value;
    user.phone = this.utils.trimPhone(this.form.get('phone')?.value);
    user.email = this.form.get('email')?.value;
//    user.position = this.form.get('position')?.value;
    user.password=user.password_salt=null;
    if(!user.settings) user.settings={};
    user.settings.utc_offset = this.form.get('utc_offset')?.value;
    user.settings.show_popup_messages = this.form.get('show_popup_messages')?.value;
//    user.settings.show_popup_messages_time = this.form.get('show_popup_messages_time')?.value;
    user.settings.track_width = this.form.get('track_width')?.value;
    user.settings.track_color = this.form.get('track_color')?.value;
    user.settings.geozone_color = this.form.get('geozone_color')?.value;
    user.settings.geozone_fill = this.form.get('geozone_fill')?.value;
    user.settings.language = this.form.get('language')?.value;
    user.settings.viewmode = this.form.get('viewmode')?.value;

    user.settings.user_sensors=[{snk:this.data.sensors[0]}, {snk: this.data.sensors[1]}, {snk: this.data.sensors[2]}];

//    user.settings.show_cars_as_points  = this.form.get('show_cars_as_points')?.value;
//    user.settings.show_car_status = this.form.get('show_car_status')?.value;
//    user.settings.show_cars_track = this.form.get('show_cars_track')?.value;
//    user.settings.follow_car = this.form.get('follow_car')?.value;
//    user.settings.cluster_cars_on_map = this.form.get('cluster_cars_on_map')?.value;
//    user.settings.show_geozones = this.form.get('show_geozones')?.value;
//    user.settings.show_azimuth = this.form.get('show_azimuth')?.value;
//    user.settings.view_number = this.form.get('view_number')?.value;
//    user.settings.view_brand = this.form.get('view_brand')?.value;
    //user.settings.sensor_snks =
  }

  _loadFormFromObject(user: IApiUser|null) {
    this.form.get('login')?.patchValue(user?.login??null);
    this.form.get('name')?.patchValue(user?.name??null);
    this.form.get('phone')?.patchValue(this.utils.stdTrimPhone(user?.phone??null));
    this.form.get('email')?.patchValue(user?.email??null);
//    this.form.get('position')?.patchValue(user?.position??null);

    this.form.get('utc_offset')?.patchValue(user?.settings?.utc_offset??null);
    this.form.get('show_popup_messages')?.patchValue(user?.settings?.show_popup_messages??null);
//    this.form.get('show_popup_messages_time')?.patchValue(user?.settings?.show_popup_messages_time??null);
    this.form.get('track_width')?.patchValue(user?.settings?.track_width??null);
    this.form.get('track_color')?.patchValue(user?.settings?.track_color??null);
    this.form.get('geozone_color')?.patchValue(user?.settings?.geozone_color??null);
    this.form.get('geozone_fill')?.patchValue(user?.settings?.geozone_fill??null);
    this.form.get('language')?.patchValue(user?.settings?.language??null);
    this.form.get('viewmode')?.patchValue(user?.settings?.viewmode??null);
//    this.form.get('show_car_status')?.patchValue(user?.settings?.show_car_status??null);
//    this.form.get('show_cars_track')?.patchValue(user?.settings?.show_cars_track??null);
//    this.form.get('follow_car')?.patchValue(user?.settings?.follow_car??null);
//    this.form.get('cluster_cars_on_map')?.patchValue(user?.settings?.cluster_cars_on_map??null);
//    this.form.get('show_geozones')?.patchValue(user?.settings?.show_geozones??null);
//    this.form.get('show_azimuth')?.patchValue(user?.settings?.show_azimuth??null);
//    this.form.get('view_number')?.patchValue(user?.settings?.view_number??null);
//    this.form.get('view_brand')?.patchValue(user?.settings?.view_brand??null);
    // user?.settings?.sensor_snks


    this.data.sensors=[null,null,null];
    if( (user?.settings?.user_sensors?.length??-1)>0 && user!.settings!.user_sensors![0].snk ) this.data.sensors[0]=user!.settings!.user_sensors![0].snk;
    if( (user?.settings?.user_sensors?.length??-1)>1 && user!.settings!.user_sensors![1].snk ) this.data.sensors[1]=user!.settings!.user_sensors![1].snk;
    if( (user?.settings?.user_sensors?.length??-1)>2 && user!.settings!.user_sensors![2].snk ) this.data.sensors[2]=user!.settings!.user_sensors![2].snk;


    console.log('_loadFormFromObject', this.data.sensors);
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    if(!this.item) this.item={};
    this._saveFormToObject(this.item);
    console.log('saving',this.item);
    this.saving = true;
    this.userService.updateprofile(this.authService.user$.value?.id??'xxx', this.item)
    .subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.user};             
          this.visible=false;
          this.onUpdate.emit(this.item);
          this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

  changePassword() {
    this.ui.change.show=true;
  }

  onPreviewSensors_Change(sensors: (string|null)[]) {
    console.log('onPreviewSensors_Change', sensors)
    this.data.sensors=[...sensors,null,null,null];
  }
}
