import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

// openlayer
//import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { OSM, Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from 'ol/layer/Tile';
import {Vector as VectorLayer} from 'ol/layer.js';
import {ScaleLine, ZoomSlider} from 'ol/control.js';
import { DblClickDragZoom, defaults as defaultInteractions } from 'ol/interaction.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import {fromLonLat, toLonLat} from 'ol/proj.js';
import {Icon, Style, Fill, Stroke, Text} from 'ol/style.js';
import { Geometry } from 'ol/geom';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/service.backend/user.service';
import { IApiUserSettings } from 'src/app/model.backend/user.model';
import { IApiCar } from 'src/app/model.backend/car.model';
import { PreloaderService } from 'src/app/service/preloader.service';
import { Subject } from 'rxjs/internal/Subject';
import { filter, take, takeUntil } from 'rxjs';
import { IApiGeozone } from 'src/app/model.backend/geozone.model';
import { CarStatusService } from 'src/app/service.backend/carstatus.service';
import { IApiCarStatus } from 'src/app/model.backend/carstatus.model';
import { MapComponent } from 'src/app/component/map/map.component';
import { IApiCarHistory } from 'src/app/model.backend/carhistory.model';
import { IApiCarGroupUser } from 'src/app/model.backend/cargroupuser.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CarGroupUserService } from 'src/app/service.backend/cargroupuser.service';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { CarStatusHelper } from 'src/app/model.helpers/carstatus-helper';

@Component({
  selector: 'app-page-map',
  templateUrl: './page-map.component.html',
  styleUrls: ['./page-map.component.css']
})
export class PageMapComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mapComponent') mapComponent!: MapComponent;
  @HostListener('window:resize', ['$event']) onResize() { this.correctInnerHeight2() }
  
  CarStatusHelper = CarStatusHelper;

  destroy$ = new Subject<boolean>();
  showMapInAfterViewInit=false;
  ui = {
    panel_left: {
      close: false
    },    
    selectedFilter: this.preloaderService.selectedFilter,
    selectButtonValue: null as any,
    selectButtonOptions: [
      {label: this.translateService.instant('main.btnAuto'), value: 'car' },
      {label: this.translateService.instant('main.btnGroup'), value: 'group' },
      {label: this.translateService.instant('main.btnDriver'), value: 'driver'  },
      {label: this.translateService.instant('main.btnGeozone'), value: 'geozone' }
    ],
    // carList: {
    //   card: true
    // },
    selectedCars: [] as string[],
    //selectedCarGroupUser: null as string|null,
    selectedDrivers: [] as string[],
    // parkEdit: {
    //   show: false,
    //   id: null as string|null,
    //   userid: null as string|null,
    //   customerid: null as string|null
    // }
    innerHeight2: '0px'
  };

  data = {
    loading: false,
    error: null as string|null,
    geozones: [] as IApiGeozone[],

    // carGroupId_slected: '*' as string|null,
    // carFilterText: '',
    cars: [] as IApiCar[],
    cars_filtered: [] as IApiCar[],
    carstatuses: [] as IApiCarStatus[],
    trackshistory: [] as IApiCarHistory[],
//    cargroupsuser: [] as IApiCarGroupUser[],

    drivers: [] as IApiDriver[],
    currentTrips: [] as IApiDriverTrip[]
  }

  // https://openlayers.org/en/latest/examples/draw-features.html
  editedGeozone = {
    state: 'add' as 'add'|'draw'|'save'|'edit',
    isNew: true,
    isDel: false,
    addGeozoneButtonName: 'Добавить',
    showGeozoneNameEdit: false,
    setBtnName: () => {
      let bn = '';
      if (this.editedGeozone.state==='add') {
        bn = 'Добавить';
        this.editedGeozone.isNew = true;
        this.editedGeozone.isDel = false;
        this.editedGeozone.showGeozoneNameEdit = false;
      } else if (this.editedGeozone.state==='draw') {
        bn = 'Задайте замкнутую геозону...';
      } else if (this.editedGeozone.state==='save') {
        bn = 'Сохранить';
      } else if (this.editedGeozone.state==='edit') {
        bn = 'Редактирование геозоны ' + this.editedGeozone.geozone?.name;
      }
      this.editedGeozone.addGeozoneButtonName = bn;
    },    
    geozone: {
      id: '',
      name: '',
      geojson: '',  
      customerid: '',
      _selected: undefined,
    } as IApiGeozone,
    source: undefined as any,
    vector: undefined as VectorLayer<any>|undefined,
    draw: undefined as Draw|undefined,
    modify: undefined as Modify|undefined,
    drawendHandler: (x: any) => {      
      console.log('drawend', x.feature?.getGeometry()?.getCoordinates());
      console.log('geozones', this.data.geozones);
      this.editedGeozone.state='save';
      this.editedGeozone.setBtnName();
      
      // Выбранные точки геозоны в geojson
      try {      
        const format = new GeoJSON();       
        const jsonStr = format.writeFeatures([x.feature]);
        const geojson = JSON.parse(jsonStr);
        this.editedGeozone.geozone.geojson = JSON.stringify(geojson.features[0]);
      } 
      catch (ex) {
        console.warn('Ошибка кодирования геозоны');
        this.editedGeozone.geozone.geojson = '';
      }

      this.mapComponent.mapobject.map?.removeInteraction(this.editedGeozone.draw!);      
      this.editedGeozone.draw?.un('drawend', this.editedGeozone.drawendHandler);
      console.log('geojson', this.editedGeozone.geozone.geojson);
    },
    modifyendHandler: (x: ModifyEvent) => {
      x.features.forEach((feature: any) => {
        console.log('modifyendHandler', feature.getGeometry().getCoordinates());        
      });

            // Выбранные точки геозоны в geojson
      try {      
        const format = new GeoJSON();       
        const jsonStr = format.writeFeatures(this.editedGeozone.source?.getFeatures());
        const geojson = JSON.parse(jsonStr);
        this.editedGeozone.geozone.geojson = JSON.stringify(geojson.features[0]);
        //console.log('modifyendHandler geojson', this.editedGeozone.geozone.geojson);
      } 
      catch (ex) {
        console.warn('Ошибка кодирования геозоны', ex);
        this.editedGeozone.geozone.geojson = '';
      }
      

    },
    onDblclick: (geozoneId: string|null) => {
      // Центр геозоны
      const selectedGeozone = this.data.geozones.find(x => x.id === geozoneId);
      const geoJsonObj = JSON.parse(selectedGeozone!.geojson!);
      if (selectedGeozone && selectedGeozone.geojson) {
        const geoJsonObj = JSON.parse(selectedGeozone.geojson);
        //console.log('selGeo', geoJsonObj);
        if (geoJsonObj.geometry.coordinates.length===1) {
          let lat=0, lon=0, n=0;
          geoJsonObj.geometry.coordinates[0].forEach((arrCord: number[]) => {
            lat+=arrCord[0];
            lon+=arrCord[1];
            n++;
          });
          lat=lat/n;
          lon=lon/n;         
          const coord = toLonLat([lat, lon], "EPSG:900913"); // to EPSG:4326
          //console.log('coord', coord);
          this.mapComponent.mapobject.map?.setView(
            new View({
              center: fromLonLat(coord),
              zoom: 10, maxZoom: 18, 
              padding: [16,16,16,16]
            })
          );
          //map.zoomToMaxExtent();
        }
      }
    },
    onAddGeozone: (event: MouseEvent) => {
      console.log('onAddGeozone', this.editedGeozone.draw);
      //this.mapComponent.RedrawGeozones([]);
      //this.mapComponent.mapobject.geozonesLayer?.setVisible(false);
      if (this.editedGeozone.state==='add') {
        // Добавить
        this.editedGeozone.isNew = true;
        this.editedGeozone.geozone.id = '';
        this.editedGeozone.state='draw';
        this.editedGeozone.setBtnName();
        
        this.editedGeozone.source = new VectorSource();
        this.editedGeozone.vector = new VectorLayer({
          source: this.editedGeozone.source,
        });
        this.mapComponent.mapobject.map?.addLayer(this.editedGeozone.vector);
        this.mapComponent.mapobject.map?.addInteraction(
          this.editedGeozone.draw = new Draw({
            type: 'Polygon',
            source: this.editedGeozone.source,
          })
        );
        this.editedGeozone.draw?.on('drawend', this.editedGeozone.drawendHandler);        
      } else if (this.editedGeozone.state==='save') {
        // Сохранить новую геозону 
        this.editedGeozone.showGeozoneNameEdit = true;
      } else if (this.editedGeozone.state==='edit') {
        // Сохранить измененную геозону
        //this.editedGeozone.showGeozoneNameEdit = true;
        console.log('edit', this.editedGeozone.source);

        this.mapComponent.mapobject.map?.removeInteraction(this.editedGeozone.modify!);
        this.editedGeozone.modify?.un('modifyend', this.editedGeozone.modifyendHandler);
        console.log('modifyed', this.editedGeozone.geozone.geojson);
        this.editedGeozone.showGeozoneNameEdit = true;



      }
    },
    // https://openlayers.org/en/latest/examples/modify-features.html
    onEditKeyPress: (geozoneId: string|null) => {      
      const ind=this.data.geozones.findIndex(x => x.id===geozoneId);
      if (ind>=0) {
        this.editedGeozone.geozone = { ... this.data.geozones[ind]};
        this.editedGeozone.isNew = false;
        this.editedGeozone.state = 'edit';
        this.editedGeozone.setBtnName();
        console.log('onEditKeyPress', this.editedGeozone.geozone);

        if (this.editedGeozone.geozone.geojson?.length ?? -1 > 5) {
          const ftrs1 = {
            type: 'FeatureCollection',
	          features: [
              JSON.parse(this.editedGeozone.geozone.geojson!),
            ]
          } as any;
          const ftrs = new GeoJSON().readFeatures(ftrs1);
          console.log('ftrs', ftrs);
          this.editedGeozone.source = new VectorSource({            
            features: ftrs,
          });
          this.editedGeozone.vector = new VectorLayer({
            source: this.editedGeozone.source,
          });
          this.mapComponent.mapobject.map?.addLayer(this.editedGeozone.vector);
          this.mapComponent.mapobject.map?.addInteraction(
            this.editedGeozone.modify = new Modify({
              source: this.editedGeozone.source,
            })
          );
          this.editedGeozone.modify?.on('modifyend', this.editedGeozone.modifyendHandler);
        }
      }
    },
    onCancelGeozone: () => {
      this.editedGeozone.state='add';
      this.editedGeozone.setBtnName();      
      this.mapComponent.mapobject.map?.removeLayer(this.editedGeozone.vector!);

      this.mapComponent.mapobject.map?.removeInteraction(this.editedGeozone.draw!);      
      this.editedGeozone.draw?.un('drawend', this.editedGeozone.drawendHandler);
      this.editedGeozone.modify?.un('modifyend', this.editedGeozone.modifyendHandler);      
    },
    onDeleteGeozone: () => {
      this.editedGeozone.isDel = true;
      this.editedGeozone.showGeozoneNameEdit = true;

    },
    geozoneName: '',
    onHide: (event: any) => {
      console.log('onHide', event);
      this.editedGeozone.showGeozoneNameEdit=false;
      this.editedGeozone.onCancelGeozone();
    },
    afterSave: (geozone: IApiGeozone|null) => {
      console.log('afterSave', geozone);
      
      if (geozone) {
        if (this.editedGeozone.isNew) {
          geozone._selected = true;
          this.data.geozones.push(geozone);
        } else {
          const ind = this.data.geozones.findIndex(x => x.id===geozone.id);
          if (ind>=0) {
            if (geozone.geojson?.length ?? -1 > 5) {
              this.data.geozones[ind] = geozone;
            } else {
              this.data.geozones.splice(ind, 1);
            }
          }
        }
        this.mapComponent.RedrawGeozones(this.data.geozones);
      }

      this.editedGeozone.showGeozoneNameEdit=false;
      this.editedGeozone.onCancelGeozone();
    },
    afterDel: (geozoneId: string|null) => {
      console.log('afterDel', geozoneId);
      const ind = this.data.geozones.findIndex(x => x.id===geozoneId);
      if (ind>=0) {
        this.data.geozones.splice(ind, 1);
      }      
      this.mapComponent.RedrawGeozones(this.data.geozones);
      this.editedGeozone.showGeozoneNameEdit=false;
      this.editedGeozone.onCancelGeozone();
    },
  }

  // mapobject= {
  //   map: null as Map|null,
  //   geozonesLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null,
  //   carsLayer: null as VectorLayer<VectorSource<Feature<Geometry>>>|null,
  // }

  timer1: any;

  userSettings = {} as IApiUserSettings;

    

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private carStatusService: CarStatusService,
    private carGroupUserService: CarGroupUserService,
    private preloaderService: PreloaderService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ){
    this.ui.selectButtonValue=this.ui.selectButtonOptions[0];
  }

  ngOnInit(): void {   
    console.log('ngOnInit');
    // this._loadUserSettingsToOverlay();

    this.preloaderService.ensureLoad$(this.destroy$).subscribe(()=>{
      this.userSettings = { ...(this.authService.getUser()?.settings??{})};


//      this.data.cargroupsuser = this.preloaderService.getCarGroupUsersForUser(this.authService.getUser()?.id??'xxx')||[];
      // console.error('ensureLoad cargroupsuser', this.data.carGroupId_slected);

    //     this.LoadCars(); 
    //     this.ApplyCarsFilter(); 
    });

    this.translateService.onLangChange.subscribe(lang=>{
      this.ui.selectButtonOptions[0].label = this.translateService.instant('main.btnAuto');
      this.ui.selectButtonOptions[1].label = this.translateService.instant('main.btnGroup');
      this.ui.selectButtonOptions[2].label = this.translateService.instant('main.btnDriver');
      this.ui.selectButtonOptions[3].label = this.translateService.instant('main.btnGeozone');  
      this.ui.selectButtonOptions= [...this.ui.selectButtonOptions];
    })


//     const car_style = new Style({
//       image: new Icon({
//         color: 'blue',
// //          crossOrigin: 'anonymous',
// //        size: [64,64],
// //        scale: 1,
//         src: 'assets/images/car-default.svg',
//       }),
//       text: new Text({
//         text: 'Car No xxx',
//         font: '12px Calibri,sans-serif',
//         fill: new Fill({
//           color: 'blue',
//         }),
//         stroke: new Stroke({
//           color: 'white',
//           width: 2,
//         }),
//         textBaseline: 'top',
//         offsetX: 20,
//         offsetY: 10
//       }),
//     });


    // const geozone_style = new Style({
    //   stroke: new Stroke({ color: 'blue', width: 2 }),
    //   fill: new Fill({ color: 'rgba(0,0,255,0.1)' })
    // });

    // const car_feature = new Feature({ geometry: new Point(fromLonLat([37.618423,55.751244])) });
    // car_feature.setStyle(car_style);

    // const car_feature2 = new Feature({ geometry: new Point(fromLonLat([37.4375,55.9017])) });
    // car_feature2.setStyle(car_style);

    // const car_feature3 = new Feature({ geometry: new Point(fromLonLat([37.2818569,55.6733745])) });
    // car_feature3.setStyle(car_style);

    // let geozone = new Feature({
    //   geometry: new Polygon(
    //     [[
    //       fromLonLat([37.4375,55.9017]), fromLonLat([37.618423,55.751244]), fromLonLat([37.2818569,55.6733745])
    //     ]]
    //   ),
    // });
//    geozone.setStyle( geozone_style )
    // let writer = new GeoJSON();
    // let geojson=writer.writeFeature(geozone);
    // console.log('Geozone Geojson', geojson);
//    let geo_json = new GeoJSON().readFeature(geojson) as FeatureLike;


    // cars
    // const carsSource = new VectorSource({ features: [car_feature /*, car_feature2*/]});
    // const carsLayer = new VectorLayer({ source: carsSource })

    // geozone
//    const geozonesSource = new VectorSource({features: [geozone] })
//    const geozonesLayer = new VectorLayer({ source: geozonesSource as any, style: geozone_style })
//    const geo_jsonSource = new VectorSource({features: [geo_json] }) as VectorSource<Feature<Geometry>>;
//    const geozonesLayer = new VectorLayer({ source: geo_jsonSource, style: this.mapobject.geozoneStyle })

//    const mapLayer = new TileLayer({ source: new OSM() });

    // carsSource.addFeature(car_feature2);
    // carsSource.addFeature(car_feature3);

    /*
    this.mapobject.map = new Map({
      interactions: defaultInteractions().extend([new DblClickDragZoom()]),
      layers: [ new TileLayer({ source: new OSM() }) ],
      target: 'map',
      view: new View({ 
        center: fromLonLat([37.618423,55.751244]), //[0, 0],
        zoom: 10, maxZoom: 18, 
        padding: [16,16,16,16]
      }),
    });
    this.mapobject.map.addControl(new ScaleLine({units: "metric"}));
    this.mapobject.map.addControl(new ZoomSlider());
*/

    // setTimeout(()=>{
    //   this.mapobject.map?.getView().fit(carsSource.getExtent());  
    // }, 100);

//     setTimeout(()=>{
//       this.mapobject.map?.addLayer(carsLayer);
// //      this.map.addLayer(geozonesLayer);
//     }, 200);

//    featuresSource.removeFeature(car_feature);
//    carsLayer.setVisible(false);

//    geozonesLayer.setVisible(false);

//    geozonesLayer.setVisible(true);

    // setTimeout(()=>{
    //   carsSource.addFeature(car_feature2);
    //   this.map.getView().fit(carsSource.getExtent());  
    // }, 1000);

    // setTimeout(()=>{
    //   carsSource.addFeature(car_feature3);
    //   this.map.getView().fit(carsSource.getExtent(), );  
    // }, 2000);

    // setTimeout(()=>{
    //   geozonesLayer.setVisible(true);
    // }, 3000);

    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1)
    ).subscribe(()=>{ 

 //     this.ui.selectedClient = this.preloaderService.selectedClient;

      // geozones
      this.data.geozones=this.preloaderService.geozones$.value||[];
      this.editedGeozone.geozone.customerid=this.preloaderService.customer$.value?.id;
      this.data.drivers = this.preloaderService.drivers$.value||[];

//      console.log('mapComponent', this.mapComponent)

      // cars&objects
      this.LoadCars(); 
      this._ApplyCarsFilter(this.data.cars); 
      this.LoadCarStatuses();
    });

    this.timer1=setInterval(()=>{
      this.LoadCarStatuses();
    }, 120000 /* 10000*/);


    this.preloaderService.drivers$.subscribe(d=>this.data.drivers=d);

  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    if(this.timer1) {
      clearInterval(this.timer1);
      this.timer1=null;
    }
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
    if(this.showMapInAfterViewInit) {
      this.mapComponent.RedrawGeozones(this.data.geozones);
      this.mapComponent.RedrawCars(this.data.cars_filtered, this.data.carstatuses);  
      this.mapComponent.RedrawTrackHistory(this.data.trackshistory);
      this.mapComponent.RedrawBranches();
    }
  }

  /*
  Map_RedrawGeozones() {
    // если не показывать геозоны
    if(!this.authService.getUser()?.settings?.show_geozones) {
      if(this.mapobject.geozonesLayer) {
        this.mapobject.geozonesLayer.getSource()?.clear();
      }
      return;
    }

    // если слой геозон еще не создан, то создаем пустой
    if(!this.mapobject.geozonesLayer) {
      let geoSource = new VectorSource({features: [] }) as VectorSource<Feature<Geometry>>;
      this.mapobject.geozonesLayer = new VectorLayer({ source: geoSource  })
//      setTimeout(()=>{
        this.mapobject.map?.addLayer(this.mapobject.geozonesLayer!);
//      }, 200);
    } else {
      this.mapobject.geozonesLayer.getSource()?.clear();
    }

    let user=this.authService.getUser();
    this.preloaderService.geozones$.value.forEach(g=>{
      if(g.geojson) {
        console.log('Geozone add', g.name, g.geojson);
        // geojson геозоны
        let geo_json = new GeoJSON().readFeature(g.geojson) as Feature<Geometry>;
        // стиль геозоны
        let geoStyle=new Style({
          stroke: new Stroke({ color: user?.settings?.geozone_color??'blue', width: user?.settings?.track_width??2 }),
          fill: new Fill({ color: user?.settings?.geozone_fill ? user?.settings?.geozone_fill+'40':'rgba(0,0,255,0.1)' }),
          text: new Text({
            text: g.name??'',
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: this.authService.getUser()?.settings?.geozone_color??'blue' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            textBaseline: 'middle',
            textAlign: 'center',
            offsetX: 0,
            offsetY: 0
          })
        });
        geo_json.setStyle(geoStyle);
        this.mapobject.geozonesLayer?.getSource()?.addFeature(geo_json);
      }
    });
  }

  Map_RedrawCars() {
    // если слой машин еще не создан, то создаем пустой
    if(!this.mapobject.carsLayer) {
      let carSource = new VectorSource({features: [] }) as VectorSource<Feature<Point>>;
      this.mapobject.carsLayer = new VectorLayer({ source: carSource })
//      setTimeout(()=>{
        this.mapobject.map?.addLayer(this.mapobject.carsLayer!);
//      }, 200);
    } else {
      this.mapobject.carsLayer.getSource()?.clear();
    }

    const user=this.authService.getUser();
    (this.data.cars_filtered||[]).forEach(car=>{
      // Feature машины
      const carStat=this.getCarStatus(car.id);
//      console.log('Car add', car.display_name, carStat);
      if(carStat?.lat && carStat?.lon) {
        const carFeature = new Feature({ geometry: new Point(fromLonLat([carStat?.lon, carStat?.lat])) });
        // стиль машины
        let label=(car.display_name??'');
        if(user?.settings?.view_brand || user?.settings?.view_number) label+='\n';
        label+=(user?.settings?.view_brand?`${car.brand}`:'') + (user?.settings?.view_number?` ${car.number}`:'');
        const carStyle = new Style({
          image: new Icon({
            color: 'blue',
    //        crossOrigin: 'anonymous',
    //        size: [64,64],
    //        scale: 1,
            src: 'assets/images/car-default.svg',
          }),
          text: new Text({
            text: label,
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: 'blue' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            textBaseline: 'top',
            textAlign: 'center',
            offsetX: 0, //20,
            offsetY: 12
          }),
        });
        carFeature.setStyle(carStyle);
        this.mapobject.carsLayer?.getSource()?.addFeature(carFeature);
      }
    });
  }
*/

  LoadCars() {
    this.data.cars=this.preloaderService.availablecars$.value;
    console.log('cars', this.data.cars);
  }

  LoadCarStatuses() {
    this.data.loading=true;
    console.log('LoadCarStatuses 0', this.userSettings);
    this.carStatusService.list(this.userSettings.user_sensors).subscribe({
      next: (res) => {
        console.log('LoadCarStatuses', res)
        this.data.loading = false;
        this.data.carstatuses = res.items||[];
        if(!this.mapComponent) {
          this.showMapInAfterViewInit=true; 
        }
        else {
          this.mapComponent?.RedrawGeozones(this.data.geozones);
          this.mapComponent?.RedrawCars(this.data.cars_filtered, this.data.carstatuses);
          // prepare fake history
          this.data.carstatuses.forEach(stat=>{
            let c=this.data.trackshistory.find(x=>x.car_id==stat.car_id);
            if(!c) {
              if(!this.data.trackshistory) this.data.trackshistory=[];
              c = {car_id: stat.car_id, items: []};
              this.data.trackshistory.push(c);
            }
            if(!c.items) c.items=[];
            c.items.push({last_check_time: stat.last_check_time, lat: stat.lat, lon: stat.lon });
          });
          this.mapComponent?.RedrawTrackHistory(this.data.trackshistory);
          this.mapComponent?.RedrawBranches();
        }
      },
      error: err => {
        this.data.loading = false;
        this.data.error = err; 
      }
    });

  }

  _ApplyCarsFilter(cars: IApiCar[]) {
    this.data.cars_filtered = this.data.cars.filter(x=>cars.find(k=>k.id==x.id));
  }

  // _loadUserSettingsToOverlay() {
  //   this.userSettings = { ...(this.authService.getUser()?.settings??{})};
  // }

  // _saveUserSettingsFromOverlay() {
  //   let user=this.authService.getUser();
  //   if(user) {
  //     if(!user.settings) user.settings={};
  //     user.settings.show_car_status = this.userSettings.show_car_status??false;
  //     user.settings.show_cars_track = this.userSettings.show_cars_track??false;
  //     user.settings.follow_car = this.userSettings.follow_car??false;
  //     user.settings.cluster_cars_on_map = this.userSettings.cluster_cars_on_map??false;
  //     user.settings.show_geozones = this.userSettings.show_geozones??false;
  //     user.settings.show_azimuth = this.userSettings.show_azimuth??false;
  //     user.settings.view_number = this.userSettings.view_number??false;
  //     user.settings.view_brand = this.userSettings.view_brand??false;

  //     console.log('userService.savesettings', user);
  //     this.userService.savesettings(user.id??'xxx', user.settings).subscribe({
  //       next: (res)=>{
  //         let u = this.authService.getUser()!;
  //         u.settings=res.user?.settings;
  //         this.authService.updateUser(u);
  //       },
  //       error: (err)=>console.error('UserService.savesettings', err)
  //     });

  //     this.mapComponent.RedrawGeozones(this.data.geozones);
  //     this.mapComponent.RedrawCars(this.data.cars_filtered, this.data.carstatuses);
  //   }
  // }

  // userSettingsOverlay_Hide() {
  //   this._saveUserSettingsFromOverlay();
  // }

//   CarGroup_onSelect(carGroupId: string|null) {
// //    console.log('CarGroup_onSelect', carGroupId);
//     this.data.carGroupId_slected=carGroupId;
//     this._ApplyCarsFilter();
//     this.mapComponent.RedrawCars(this.data.cars_filtered, this.data.carstatuses);
//     this.mapComponent.RedrawTrackHistory(this.data.trackshistory);
//   }

  onFiltered(cars: IApiCar[]) {
    console.log('onFiltered');
    this._ApplyCarsFilter(cars);
    this.mapComponent.RedrawCars(this.data.cars_filtered, this.data.carstatuses);
    this.mapComponent.RedrawTrackHistory(this.data.trackshistory);
  }


  // CarFilterText_onChange(event: any) {
  //   console.log('CarFilterText_onChange', event);
  //   let old = JSON.stringify(this.data.cars_filtered);
  //   this._ApplyCarsFilter();
  //   if(old!=JSON.stringify(this.data.cars_filtered)) {
  //     this.mapComponent.RedrawCars(this.data.cars_filtered, this.data.carstatuses);
  //     this.mapComponent.RedrawTrackHistory(this.data.trackshistory); 
  //   }

  // }

  // onCarCard_Update(car: IApiCar) {
  //   let ix=(this.data.cars||[]).findIndex(x=>x.id==car.id);
  //   if(ix>=0) {
  //     this.data.cars[ix]={...car};
  //   }
  // }

  // onCarCard_Select(car: IApiCar) {
  //   (this.data.cars||[]).forEach((x:any)=>{
  //     if(x.id===car.id) {
  //       x._selected=!(<any>car)['_selected'];
  //     } else {
  //       x._selected=false;
  //     }
  //   });
  // }

  // onCarCard_Dblclick(car: IApiCar) {
  //   (this.data.cars||[]).forEach((x:any)=>{
  //     if(x.id!=car.id) x._selected=false;
  //   });
  //   const carStat=this.getCarStatus(car.id);
  //   this.mapComponent.CenterView(carStat?.lon??null, carStat?.lat??null);
  // }


  getCarStatus(carid?: string|null) {
    return (this.data.carstatuses||[]).find(x=>x.car_id==carid)??null;
  }


  
// Группы

//   clickCarGroupUserEdit(group: IApiCarGroupUser) {
//     this.ui.parkEdit.id = group.id??null;
//     this.ui.parkEdit.userid = this.authService.getUser()?.id??null;
//     this.ui.parkEdit.customerid = this.preloaderService.customer$.value?.id??null;
//     if(this.ui.parkEdit.userid && this.ui.parkEdit.customerid) {
//       this.ui.parkEdit.show = true;
//     }
//   }

//   clickCarGroupUserAdd() {
//     this.ui.parkEdit.id = null;
//     this.ui.parkEdit.userid = this.authService.getUser()?.id??null;
//     this.ui.parkEdit.customerid = this.preloaderService.customer$.value?.id??null;
//     if(this.ui.parkEdit.userid && this.ui.parkEdit.customerid) {
//       this.ui.parkEdit.show = true;
//     }
//   }

//   clickCarGroupUserDelete(event: Event, group: IApiCarGroupUser) {
//     if(group==null) return;
// //    let record=this.getParkSelected();
//     let uid = this.authService.getUser()?.id??'xxx';
//     this.confirmationService.confirm({
//       target: event.target as EventTarget,
//       message: this.translateService.instant('main.questGroupDelete'),      
//       acceptLabel: this.translateService.instant('form.delete'),
//       acceptButtonStyleClass: 'p-button-danger',
//       rejectLabel: this.translateService.instant('form.cancel'),
//       rejectButtonStyleClass: 'p-button-text p-button-secondary',
//       icon: 'pi pi-exclamation-triangle',
//       accept: () => {
//         this.carGroupUserService.delete(group?.id??'xxx').subscribe({
//           next: (res)=>{
//             if(!res.ok) {
//               console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
//               this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translateService.instant('form.deleteError')})
//             } else {
//               this.preloaderService.deleteCarGroupUser(uid, group?.id??'xxx');
//               let ix = this.data.cargroupsuser?.findIndex(x=>x.id==group?.id)??-1;
//               if(ix>=0) this.data.cargroupsuser?.splice(ix,1);
//               this.messageService.add({severity: 'success', detail: this.translateService.instant('form.deleteSuccess')});
//             }
//           },
//           error: (err)=>{
//             console.error('clickRecordDelete',err);
//             this.messageService.add({severity: 'error', detail: this.translateService.instant('form.deleteError')});
//           }
//         })
//       },
//     });
//   }
  
  // onDialogParkEditHide() {
  //   this.ui.parkEdit.show=false;
  // }

  // onDialogParkEditUpdate(group: IApiCarGroupUser) {
  //   let ix = this.data.cargroupsuser?.findIndex(x=>x.id==group?.id)??-1;
  //   if(ix>=0) {
  //     this.data.cargroupsuser?.splice(ix,1,group);
  //   }
  // }

  // onDialogParkEditInsert(group: IApiCarGroupUser) {
  //   this.data.cargroupsuser?.unshift(group);
  //  }


   // водители
   onDriverUpdated(driver: IApiDriver) {
    this.preloaderService.updateDriver(driver)
   }
   onDriverInserted(driver: IApiDriver) {
    this.preloaderService.addNewDriver(driver)
   }
   onDriverDeleted(driver: IApiDriver) {
    this.preloaderService.deleteDriver(driver?.id??'xxx')
   }


  //  canViewGroupUser() {
  //   return this.authService.getUser()?.customerid;
  //  }

   onTripUpdated_event() {
//    console.log('onTripUpdated_event');
    this.LoadCarStatuses();
   }

   correctInnerHeight2() {
    setTimeout(()=>{
      let h = window.innerHeight;
      let rect2=document.getElementById('scrollelement2')?.getBoundingClientRect();
      h = Math.round(h - (rect2?.top??0)-80 );
      this.ui.innerHeight2 = h+"px"; 
    }, 1)
  }

  onCar_Click(car: IApiCar) {
//    console.log('onCar_Click', car);
    const carStat=this.getCarStatus(car.id);
    this.mapComponent.CenterView(carStat?.lon??null, carStat?.lat??null);
  }
}
