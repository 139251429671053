<p-dialog  
    [modal]="false"
    [(visible)]="visible" 
    [style]="{ width: '60vw' }" 
    styleClass="sav3-dialog sav3-dialog-nopadding sav3-dialog-inner fadeinleft animation-duration-200"
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <div [formGroup]="form" [ngClass]="{'p-disabled':data.loading || ui.disabledEdit}">
        <div class="bg-white sticky top-0 z-1">
            <div style="height: 3px;"><app-progressbar [loading]="data.loading"></app-progressbar></div>       
            <p-messages *ngIf="data.error" [value]="[{severity:'error', detail:data.error}]" [closable]="true" [enableService]="false"></p-messages>
            <div class="flex align-items-center p-4">
                <div *ngIf="!isNew()">
                    <p-avatar [size]="'large'" [label]="getDriverAvatarName()"></p-avatar>
                </div>
                <div *ngIf="!isNew()" class="pl-4">
                    <div class="text-2xl font-bold">
                        {{data.item?.family}} {{data.item?.name}} {{data.item?.surname}}
                    </div>
                    <div class="text-sm text-gray-500">
                        {{'driver.id'|translate}} {{data.item?.id}}
                    </div>
                </div>
                <div class="ml-auto">
                    <p-button *ngIf="!ui.editMode"
                        (onClick)="ui.disabledEdit ? null:ui.editMode=true" 
                        [ngClass]="{'p-disabled': canEditDriver()}"
                        icon="pi pi-pencil" 
                        [label]="'form.change'|translate" 
                        styleClass="p-button-primary p-button-rounded mr-2" 
                    ></p-button>
                    <p-button *ngIf="!ui.editMode"
                        (onClick)="hideDialog()" 
                        icon="pi pi-times" 
                        [label]="'form.close'|translate" 
                        styleClass="p-button-secondary p-button-rounded" 
                    ></p-button>
                    <p-button *ngIf="ui.editMode"
                        (onClick)="ui.editMode=false;isNew()?hideDialog():null" 
                        icon="pi pi-ban" 
                        [label]="'form.cancel'|translate" 
                        styleClass="p-button-secondary p-button-rounded p-button-text mr-2" 
                    ></p-button>
                    <p-button *ngIf="ui.editMode"
                        (onClick)="saveRecord()" 
                        styleClass="p-button-success p-button-rounded" 
                        [disabled]="!validateForm() || data.loading || ui.disabledEdit"
                        [loading]="data.saving" 
                        [label]="getButtonOkLabel()" 
                        icon="pi {{getButtonOkIcon()}}" 
                    ></p-button>
                </div>
            </div>   
        </div>
        <div class="grid grid-nogutter mt-3 px-2">
            <div class="col-2 p-2 text-center">
                <div class="sav3-border-radius bg-gray-300 w-full mb-2" style="height: 150px;"></div>
                <p-button icon="pi pi-upload" [label]="'driver.uploadPhoto'|translate" class="p-disabled" styleClass="p-button-secondary p-button-rounded p-button-sm" ></p-button>
            </div>
            <div class="col-5 p-2 flex-grow-1">
                <div class="sav3-div">
                    <div [ngClass]="{'p-disabled':!ui.editMode}">
                        <label class="font-bold">{{'driver.generalInfo'|translate}}</label>
                        <div class="field mt-3">
                            <div><label class="sav3-label" for="family">{{'form.family'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                            <input formControlName="family" id="family" type="text" pInputText class="w-full sav3-input p-inputtext-sm" >
                            <p-message *ngIf="!validateRequired('family')" severity="error" [text]="'form.familyRequired'|translate"></p-message>
                            <p-message *ngIf="!validateMaxLength('family')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>
                        <div class="field">
                            <div><label class="sav3-label" for="name">{{'form.name'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                            <input formControlName="name" id="name" type="text" pInputText class="w-full sav3-input p-inputtext-sm" >
                            <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.nameRequired'|translate"></p-message>
                            <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>
                        <div class="field">
                            <div><label class="sav3-label" for="surname">{{'form.surname'|translate}}</label></div>
                            <input formControlName="surname" id="surname" type="text" pInputText class="w-full sav3-input p-inputtext-sm" >
                            <p-message *ngIf="!validateMaxLength('surname')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>       
                        <div class="field">
                            <div><label class="sav3-label" for="cardno">{{'driver.cardno'|translate}}</label></div>
                            <input formControlName="cardno" id="cardno" type="text" pInputText class="w-full sav3-input p-inputtext-sm" >
                            <p-message *ngIf="!validateMaxLength('cardno')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
                        </div>       
                        <div class="field">
                            <div><label class="sav3-label" for="birthdate">{{'form.birthdate'|translate}}</label></div>
                            <p-calendar formControlName="birthdate" inputId="birthdate" dateFormat="dd.mm.yy" appendTo="body" styleClass="w-full sav3-input p-inputtext-sm"></p-calendar>
                        </div>           
                    </div>
                </div>
            </div>
            <div class="col-5 p-2 flex-grow-1">
                <div class="sav3-div">
                    <div class="grid">
                        <div class="col">
                            <div class="flex py-2">
                                <div class="ml-auto">
                                    <p-button icon="pi pi-whatsapp" label="WhatsApp" class="p-disabled" styleClass="p-button-secondary p-button-rounded p-button-sm mr-2"></p-button>
                                </div>
                                <div>
                                    <p-button icon="pi pi-telegram" label="Telegram" class="p-disabled" styleClass="p-button-secondary p-button-rounded p-button-sm"></p-button>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'p-disabled':!ui.editMode}" class="mt-2">
                        <label class="font-bold">{{'driver.contactInfo'|translate}}</label>
                        <div class="field mt-3">
                            <div><label class="sav3-label" for="email">{{'form.email'|translate}}</label></div>
                            <input formControlName="email" id="email" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                            <p-message *ngIf="!validateMaxLength('email')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>
                        <div class="field">
                            <div><label class="sav3-label" for="phone">{{'form.phone'|translate}}</label></div>
                            <input formControlName="phone" id="phone" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                            <p-message *ngIf="!validateMaxLength('phone')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>
                        <div class="field">
                            <div><label class="sav3-label" for="phone_wa">WhatsApp</label></div>
                            <input formControlName="phone_wa" id="phone_wa" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                            <p-message *ngIf="!validateMaxLength('phone_wa')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>
                        <div class="field">
                            <div><label class="sav3-label" for="telegram">Telegram</label></div>
                            <input formControlName="telegram" id="telegram" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                            <p-message *ngIf="!validateMaxLength('telegram')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                        </div>       
                    </div>
                </div>
            </div>

            <div class="col-2"></div>
            <div class="col-10 p-2">
                <div class="sav3-div w-full">
                    <div class="grid"  [ngClass]="{'p-disabled':!ui.editMode}">
                        <div class="col-6">
                            <label class="font-bold">{{'driver.vu'|translate}}</label>
                            <div class="field mt-3">
                                <div><label class="sav3-label" for="vu_no">{{'driver.vu_no'|translate}}</label></div>
                                <input formControlName="vu_no" id="vu_no" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                                <p-message *ngIf="!validateMaxLength('vu_no')" severity="error" [text]="'form.maxLength30'|translate"></p-message>
                            </div>
                            <div class="field">
                                <div><label class="sav3-label" for="vu_category">{{'driver.vu_category'|translate}}</label></div>
                                <p-multiSelect inputId="vu_category" 
                                    formControlName="vu_category" 
                                    [options]="options.categories" 
                                    styleClass="sav3-input sav3-multiselect p-inputtext-sm w-full"
                                    class="p-inputtext-sm"
                                    optionLabel="name"
                                    optionValue="code"
                                    [filter]="true"
                                    appendTo="body"
                                ></p-multiSelect>
                            </div>       
                        </div>
                        <div class="col-6">
                            <label>&nbsp;</label>
                            <div class="field mt-3">
                                <div><label class="sav3-label" for="vu_date">{{'driver.vu_date'|translate}}</label></div>
                                <p-calendar formControlName="vu_date" inputId="vu_date" dateFormat="dd.mm.yy" appendTo="body" styleClass="w-full sav3-input p-inputtext-sm"></p-calendar>
                            </div>           
                            <div class="field">
                                <div><label class="sav3-label" for="vu_valid_to">{{'driver.vu_valid_to'|translate}}</label></div>
                                <p-calendar formControlName="vu_valid_to" inputId="vu_valid_to" dateFormat="dd.mm.yy" appendTo="body" styleClass="w-full sav3-input p-inputtext-sm"></p-calendar>
                            </div>           
        
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-12 p-2">
                <div class="sav3-div w-full">
                    <div class="field" [ngClass]="{'p-disabled':!ui.editMode}">
                        <div><label class="sav3-label" for="comment">{{'form.comment'|translate}}</label></div>
                        <input formControlName="comment" id="comment" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                        <p-message *ngIf="!validateMaxLength('comment')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
                    </div>                          
                </div>
            </div>

            <div class="col-12 p-2">
                <div class="sav3-div w-full">
                    <div class="flex align-items-center">
                        <div class="field w-full" [ngClass]="{'p-disabled':!ui.editMode}">
                            <div><label class="sav3-label" for="default_carid">{{'driver.default_carid'|translate}}</label></div>
                            <p-dropdown inputId="default_carid" 
                                formControlName="default_carid" 
                                [options]="options.cars" 
                                styleClass="sav3-input p-inputtext-sm w-full"
                                class="p-inputtext-sm"
                                optionLabel="name"
                                optionValue="code"
                                [filter]="true"
                                appendTo="body"
                            ></p-dropdown>
                        </div>       
                        <div class="ml-auto pl-2">
                            <p-button (onClick)="clickTripsShow()" [label]="'driver-trips.showTrips'|translate" styleClass="p-button-rounded p-button-secondary p-button-sm white-space-nowrap"></p-button>
                        </div>                
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-2">&nbsp;</div>
    </div>
</p-dialog>

<app-driver-trips *ngIf="dialog.trips.show"
    [driverid]="dialog.trips.driverid"
    [customerid]="dialog.trips.customerid"
    (onHide)="onTripsHide()"
    (onUpdate)="onTripUpdated_event()"
    (onInsert)="onTripUpdated_event()"
    (onDelete)="onTripUpdated_event()"
>
</app-driver-trips>