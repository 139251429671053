<p-dialog [header]="header" 
    [(visible)]="visible" 
    [style]="{ width: '40vw' }" 
    styleClass="shadow-5 sav3-dialog"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div [formGroup]="form" class="grid mt-3">
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="name">{{'structure.edit.fieldName'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.nameRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="inn">{{'structure.edit.fieldInn'|translate}}</label></div>
                <input formControlName="inn" id="inn" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('inn')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="pin">{{'structure.edit.fieldPin'|translate}}</label></div>
                <input formControlName="pin" id="pin" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('pin')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="balance">{{'structure.edit.fieldBalance'|translate}}</label></div>
                <p-inputNumber formControlName="balance" id="balance" [maxFractionDigits]="2" class="w-full p-inputtext-sm" styleClass="sav3-input w-full"></p-inputNumber>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="bonuse">{{'structure.edit.fieldBonuse'|translate}}</label></div>
                <p-inputNumber formControlName="bonuse" [maxFractionDigits]="2" id="bonuse" class="w-full p-inputtext-sm" styleClass="sav3-input w-full"></p-inputNumber>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="credit_limit">{{'structure.edit.fieldCreditLimit'|translate}}</label></div>
                <p-inputNumber formControlName="credit_limit" id="credit_limit" [maxFractionDigits]="0" class="w-full p-inputtext-sm" styleClass="sav3-input w-full"></p-inputNumber>
            </div>
            <div class="col-12">
                <div><label class="sav3-label" for="status">{{'structure.edit.fieldStatus'|translate}}</label></div>
                <p-dropdown formControlName="status" id="status" optionLabel="label" optionValue="code" [options]="options.status" class="sav3-input w-full p-inputtext-sm" styleClass="sav3-input w-full " ></p-dropdown>
            </div>
            <div class="col-12 pb-0">
                <div class="w-full sav3-checkbox">
                    <p-checkbox formControlName="in_adv" [label]="'structure.edit.fieldAdv'|translate" [binary]="true"></p-checkbox>
                </div>
            </div>
            <div *ngIf="!!parent" class="col-12 pb-0">
                <div><label class="sav3-label" for="owner">{{'structure.edit.fieldOwner'|translate}}</label></div>
                <input formControlName="owner" id="owner" type="text" pInputText class="w-full sav3-input p-inputtext-sm p-disabled">
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="email">{{'structure.edit.fieldEmail'|translate}}</label></div>
                <input formControlName="email" id="email" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('email')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
            </div>
            <div class="col-12 pb-0">
                <div><label class="sav3-label" for="comment">{{'structure.edit.fieldComment'|translate}}</label></div>
                <input formControlName="comment" id="comment" type="text" pInputText class="w-full sav3-input p-inputtext-sm">
                <p-message *ngIf="!validateMaxLength('comment')" severity="error" [text]="'form.maxLength1000'|translate"></p-message>
            </div>
        </div>
        <div class="grid">
            <div *ngIf="isDistributor()" class="col-12 pb-0">
                <h4 class="text-center mb-2">{{'structure.edit.nameBranches'|translate}}</h4>
                <p-table *ngIf="(item?.branches||[]).length!=0"
                    [value]="item?.branches||[]"
                    class="p-datatable-sm sav3-tablewithheader" 
                    [tableStyle]="{'width':'100%'}"
                    scrollHeight="200px"               
                >
                    <ng-template pTemplate="header">
                        <tr class="text-sm">
                            <th class="p-2 bg-white">{{'structure.edit.tableBranchName'|translate}}</th>
                            <th class="p-2 bg-white">{{'structure.edit.tableBranchPhone'|translate}}</th>
                            <th class="p-2 bg-white">{{'structure.edit.tableBranchEmail'|translate}}</th>
                            <th class="p-2 bg-white">{{'structure.edit.tableBranchVisible'|translate}}</th>
                            <th class="p-2 bg-white"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-branch>
                        <tr class="text-sm">
                            <td class="p-1">{{branch.name}}</td>
                            <td class="p-1">{{branch.phone}}</td>
                            <td class="p-1">{{branch.email}}</td>
                            <td class="p-1 text-center">
                                <p-inputSwitch [(ngModel)]="branch.visible" />
                            </td>
                            <td class="p-1 white-space-nowrap">
                                <p-button (onClick)="clickBranchEdit(branch)" icon="pi pi-pencil" styleClass="p-button-rounded p-button-xs p-button-text ml-1" />
                                <p-button (onClick)="clickBranchDelete($event, branch)" icon="pi pi-trash" styleClass="p-button-rounded p-button-xs p-button-text p-button-danger ml-1" />              
                            </td>                            
                        </tr>
                    </ng-template>
                </p-table>
                <app-norecords *ngIf="(item?.branches||[]).length==0"></app-norecords>
                <div class="mt-2">
                    <p-button (onClick)="clickBranchAdd()" [label]="'structure.edit.tableAdd'|translate" icon="pi pi-plus" styleClass="p-button p-button-sm p-button-rounded p-button-secondary"></p-button>
                </div>
            </div>
            <div class="col-12 field">

            </div>
        </div>
    </div>

    <ng-template pTemplate="footer" class="text-center">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary p-button-rounded"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading || disabeSave"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary p-button-rounded">
        </p-button>
    </ng-template>
</p-dialog>

<app-structure-branch-edit *ngIf="dialog.branch.show"
    [branch]="dialog.branch.branch"
    (onHide)="dialog.branch.show=false"
    (onInsert)="onCustomerBranchInsert($event)"
    (onUpdate)="onCustomerBranchUpdate($event)"
>
</app-structure-branch-edit>