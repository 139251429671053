<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '70vw', 'min-height': '90%' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}" class="py-3">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <p-tabView>
            <p-tabPanel [header]="'task.headerGeneralSettings'|translate">        
                <p-panel [header]="'task.headerReport'|translate">
                    <div class="grid">
                        <div class="col-10">
                            <div><label for="name">{{'task.fieldName'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <input formControlName="name" id="name" type="text" pInputText class="w-full" [readOnly]="viewMode">
                            <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'task.fieldNameRequired'|translate"></p-message>
                            <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
                        </div>
                        <div class="col-2">
                            <div class="flex align-items-center justify-content-start pt-5">
                                <p-checkbox formControlName="is_active" [binary]="true" inputId="is_active" class="mr-1"></p-checkbox>
                                <div><label for="is_active">{{'task.fieldIsActive'|translate}}</label></div>    
                            </div>
                        </div>
                        <div class="col-10">
                            <div><label for="reportid">{{'task.fieldReportid'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-dropdown formControlName="reportid" inputId="reportid" styleClass="w-full"
                                [options]="ui.options.reportid"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-dropdown>
                            <p-message *ngIf="!validateRequired('reportid')" severity="error" [text]="'task.fieldReportidRequired'|translate"></p-message>
                        </div>
                        <div class="col-2">
                            <div><label for="report_format">{{'task.fieldReport_format'|translate}}</label></div>
                            <p-dropdown formControlName="report_format" inputId="report_format" styleClass="w-full"
                                [options]="ui.options.report_format" optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-dropdown>
                        </div>
                        <!--
                        <div class="col-3">
                            <div><label for="hit_counter">{{'task.fieldHit_counter'|translate}}</label></div>
                            <p-inputNumber formControlName="hit_counter" inputId="hit_counter" inputStyleClass="w-5rem text-center" [min]="0" [showButtons]="true" [disabled]="viewMode"></p-inputNumber>
                        </div>        
                        -->
                    </div>
                </p-panel>

                <p-panel [header]="'task.headerPeriod'|translate" styleClass="mt-3">
                    <div class="grid">
                        <div class="col-4">
                            <div><label for="periodic_type">{{'task.fieldPeriodicType'|translate}}</label></div>
                            <p-dropdown formControlName="periodic_type" inputId="periodic_type" styleClass="w-full"
                                [options]="ui.options.periodic_type"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-dropdown>
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value=='once'" class="col-3">
                            <div><label for="report_from_date">{{'task.fieldReportFromDate'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-calendar formControlName="report_from_date" inputId="report_from_date" styleClass="w-full" inputStyleClass="text-center"
                                appendTo="body"
                            ></p-calendar>
                            <p-message *ngIf="!validateRequired('report_from_date')" severity="error" [text]="'task.fieldReportFromDateRequired'|translate"></p-message>
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value=='once'" class="col-3">
                            <div><label for="report_to_date">{{'task.fieldReportToDate'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-calendar formControlName="report_to_date" inputId="report_to_date" styleClass="w-full" inputStyleClass="text-center"
                                appendTo="body"
                            ></p-calendar>
                            <p-message *ngIf="!validateRequired('report_to_date')" severity="error" [text]="'task.fieldReportToDateRequired'|translate"></p-message>
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value=='once'" class="col-2">
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value=='weekdays'" class="col-4">
                            <div><label for="week_days">{{'task.fieldWeekDays'|translate}}</label></div>
                            <p-multiSelect formControlName="week_days" inputId="week_days" styleClass="w-full" [showHeader]="false"
                                [options]="ui.options.week_days"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-multiSelect>
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value=='days'" class="col-4">
                            <div><label for="month_days">{{'task.fieldMonthDays'|translate}}</label></div>
                            <p-multiSelect formControlName="month_days" inputId="month_days" styleClass="w-full" [showHeader]="false"
                                [options]="ui.options.month_days"
                                optionLabel="name" optionValue="code"
                                appendTo="body"
                            ></p-multiSelect>
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value!='once'" class="col-4">
                            <div class="flex">
                                <div>
                                    <div><label for="last">{{'task.fieldLast'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                                    <p-inputNumber formControlName="last" inputId="last" inputStyleClass="w-full text-center" [min]="0" [showButtons]="true"></p-inputNumber>
                                    <div>
                                        <p-message *ngIf="!validateRequired('last')" severity="error" [text]="'form.required'|translate"></p-message>       
                                    </div>
                                </div>
                                <div class="ml-2">
                                    <div><label for="last_type">&nbsp;</label></div>
                                    <p-dropdown formControlName="last_type" inputId="last_type"
                                        [options]="ui.options.last_type"
                                        optionLabel="name" optionValue="code"
                                        appendTo="body"
                                    ></p-dropdown>        
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div><label for="task_start_time">{{'task.fieldTaskStartTime'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                            <p-calendar formControlName="task_start_time" inputId="task_start_time" [showTime]="true" [timeOnly]="true" styleClass="w-full" inputStyleClass="text-center"
                                appendTo="body"
                            ></p-calendar>
                            <p-message *ngIf="!validateRequired('task_start_time')" severity="error" [text]="'task.fieldTaskStartTimeRequired'|translate"></p-message>
                        </div>
                        <div *ngIf="form.get('periodic_type')?.value!='once'" class="col-4">
                            <div><label for="task_delay_hours">{{'task.fieldTaskDelayHours'|translate}}</label></div>
                            <p-inputNumber formControlName="task_delay_hours" inputId="task_delay_hours" inputStyleClass="text-center" styleClass="w-full" class="w-full" [min]="0" [showButtons]="true"></p-inputNumber>
                        </div>        
                    </div>
                </p-panel>
            </p-tabPanel>
            <p-tabPanel [header]="'task.headerGroup'|translate">        
                <div class="grid">
                    <div class="flex col-12">
                        <div class="flex align-items-center">
                            <p-radioButton formControlName="_group" value="y" inputId="_group1"></p-radioButton>
                            <label for="_group1" class="ml-1">{{'task.fieldSelectGroup'|translate}}</label>
                        </div>
                        
                        <div class="flex align-items-center ml-4">
                            <p-radioButton formControlName="_group" value="n" inputId="_group2"></p-radioButton>
                            <label for="_group2" class="ml-1">{{'task.fieldSelectCar'|translate}}</label>
                        </div>
                    </div>
                    <div *ngIf="form.get('_group')?.value=='y'"class="col-12">
                        <div><label for="car_groups">{{'task.fieldCarGroups'|translate}}</label></div>
                        <p-multiSelect formControlName="car_groups" inputId="car_groups" styleClass="w-full"
                            [options]="ui.options.car_groups" optionLabel="name" optionValue="code" 
                            appendTo="body">
                        </p-multiSelect>
                    </div>
                    <div *ngIf="form.get('_group')?.value!='y'" class="col-12">
                        <div><label for="cars">{{'task.fieldCars'|translate}}</label></div>
                        <p-multiSelect formControlName="cars" inputId="cars" styleClass="w-full"
                            [options]="ui.options.cars" optionLabel="name" optionValue="code" 
                            appendTo="body">
                        </p-multiSelect>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel [header]="'task.headerDestination'|translate">      
                <div class="grid">
                    <div class="col-12">
                        <div><label for="emails">{{'task.fieldEmail'|translate}}</label></div>
                        <p-chips formControlName="emails" id="emails" inputStyleClass="w-full" styleClass="w-full" class="w-full"></p-chips>
                    </div>
                    <div class="col-4">
                        <div><label for="ftp">{{'task.fieldFtp'|translate}}</label></div>
                        <input formControlName="ftp" id="ftp" type="text" pInputText class="w-full" [readOnly]="viewMode">
                        <p-message *ngIf="!validateMaxLength('ftp')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
                    </div>
                    <div class="col-4">
                        <div><label for="ftp_login">{{'task.fieldFtpLogin'|translate}}</label></div>
                        <input formControlName="ftp_login" id="ftp_login" type="text" pInputText class="w-full" [readOnly]="viewMode">
                        <p-message *ngIf="!validateMaxLength('ftp_login')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                    </div>
                    <div class="col-4">
                        <div><label for="ftp_password">{{'task.fieldFtpPassword'|translate}}</label></div>
                        <input formControlName="ftp_password" id="ftp_password" type="text" pInputText class="w-full" [readOnly]="viewMode">
                        <p-message *ngIf="!validateMaxLength('ftp_password')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
                    </div>
                    <div class="col-12">
                        <div><label for="file_name">{{'task.fieldFileName'|translate}}<span *ngIf="!viewMode" class="text-red-500 pl-1">*</span></label></div>
                        <input formControlName="file_name" id="file_name" type="text" pInputText class="w-full" [readOnly]="viewMode"/>
                        <p-message *ngIf="!validateRequired('file_name')" severity="error" [text]="'task.fieldFileNameRequired'|translate"></p-message>
                        <p-message *ngIf="!validateMaxLength('file_name')" severity="error" [text]="'form.maxLength255'|translate"></p-message>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button *ngIf="!viewMode"
                  (click)="saveRecord()" 
                  [disabled]="loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

