<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '50vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="true"
    [modal]="true"
    [blockScroll]="true"
    >  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold"><i class="pi pi-car mr-2" style="font-size: 1.5rem;"></i>{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading || viewMode}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid pt-3">
            <div class="col-4 field">
                <div><label for="number">{{'car-edit.fieldNumber'|translate}}</label></div>
                <input formControlName="number" [readOnly]="true" id="number" type="text" pInputText class="w-full p-disabled">
            </div>
            <div class="col-4 field">
                <div><label for="vin">VIN</label></div>
                <input formControlName="vin" [readOnly]="true" id="vin" type="text" pInputText class="w-full p-disabled">
            </div>
            <div class="col-4 field">
                <!--
                <div><label for="driver">Водитель</label></div>
                <input formControlName="driver" [readOnly]="true" id="driver" type="text" pInputText class="w-full p-disabled">
                -->
            </div>
            <div class="col-4 field">
                <div><label for="pin">PIN</label></div>
                <input formControlName="pin" [readOnly]="true" id="pin" type="text" pInputText class="w-full p-disabled">
            </div>
            <div class="col-4 field">
                <div><label for="active_state">{{'car-edit.fieldStatus'|translate}}</label></div>
                <p-dropdown formControlName="active_state"
                    [disabled]="true"
                    [options]="options.state" 
                    optionLabel="name" 
                    optionValue="code" 
                    class="p-disabled"
                    styleClass="w-full"
                    appendTo="body"
                    [readonly]="true"
                >
                </p-dropdown>                            
            </div>
            <div class="col-4 field">
                <div><label for="paid_before">{{'car-edit.fieldPaidBefore'|translate}}</label></div>
                <p-calendar formControlName="paid_before" dateFormat="dd.mm.yy" inputId="paid_before" appendTo="body" inputStyleClass="p-disabled text-center w-full"></p-calendar>
            </div>   

            <div class="col-4 field">
                <div><label for="brand">{{'car-edit.fieldBrand'|translate}}</label></div>
                <input formControlName="brand" id="brand" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateMaxLength('brand')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>
            <div class="col-4 field">
                <div><label for="year">{{'car-edit.fieldYear'|translate}}</label></div>
                <p-calendar formControlName="year" view="year" dateFormat="yy" inputId="year" appendTo="body" styleClass="w-full" inputStyleClass="text-center"></p-calendar>
            </div>
            <div class="col-4 field">
                <div><label for="color">{{'car-edit.fieldColor'|translate}}</label></div>
                <input formControlName="color" id="color" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateMaxLength('color')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>

            <div class="col-6 field">
                <div><label for="iconid">{{'car-edit.fieldIcon'|translate}}</label></div>
                <p-dropdown formControlName="iconid"
                    [options]="options.icon" 
                    optionLabel="name" 
                    optionValue="code" 
                    styleClass="w-full"
                    appendTo="body"
                >
                </p-dropdown>
            </div>
            <div class="col-6 field">
                <div><label for="display_name">{{'car-edit.fieldDisplayName'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="display_name" id="display_name" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateRequired('display_name')" severity="error" [text]="'car-edit.fieldDisplayNameRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('display_name')" severity="error" [text]="'form.maxLength50'|translate"></p-message>
            </div>

            <div class="col-12 field">
                <div><label for="comment">{{'car-edit.fieldComment'|translate}}</label></div>
                <input formControlName="comment" id="comment" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateMaxLength('comment')" severity="error" [text]="'form.maxLength2000'|translate"></p-message>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button *ngIf="!viewMode" (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>


