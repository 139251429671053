<div (click)="clickSelected()" (dblclick)="dblclickCard()" class="geozone-card border-1 border-round border-gray-300 shadow-1 hover:shadow-3 mb-2">
    <div [ngClass]="{'geozone-card-selected':isSelected()}" class="border-round p-1">
        <div class="flex" style="overflow-x: hidden;">
            <div class="p-1">
                <i class="pi pi-map text-gray-400" style="font-size: 2rem;" ></i>
            </div>
            <div class="flex align-items-center pl-2 white-space-nowrap" style="overflow-x: hidden;">
                <div>
                    <b>{{geozone.name}}</b>
                </div>
            </div>
            <div class="ml-auto mr-2 pt-2">
                <button (click)="dblclickCard();$event.stopPropagation()" class="p-button-sm p-button-text p-button-secondary p-1" pButton>
                    <i class="pi pi-search"></i>
                </button>
            </div>
            <div class="pt-2 mr-2">
                <button (click)="onEditGeozone($event);$event.stopPropagation()" class="p-button-sm p-button-text p-button-secondary p-1" pButton>
                    <i class="pi pi-pencil"></i>
                </button>
            </div>
            <!--
            <div class="ml-auto pt-2 mr-2">
                <button (click)="onEditGeozone($event);$event.stopPropagation()" class="p-button-sm p-button-text p-button-secondary p-1" pButton>
                    <i class="pi pi-pencil"></i>
                </button>
            </div>
            -->
        </div>
    </div>

</div>
