<p-table [value]="data.records||[]" [rowHover]="true" styleClass="p-datatable">
    <ng-template pTemplate="header">
        <tr>
            <td colspan="6" style="height: 5px;">
                <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
            </td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <th>{{'common.datetime'|translate}}</th>
            <th>{{'finances.description'|translate}}</th>
            <th class="text-right">{{'finances.incoming'|translate}}</th>
            <th class="text-right">{{'finances.outgoing'|translate}}</th>
            <th class="text-right">{{'finances.balance'|translate}}</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-index="rowIndex">
        <tr>
            <td class="white-space-nowrap">
                {{record.action_date|isoToDDMMYYYY_HHMMSS}}
            </td>
            <td>
                {{record.description}}
                <div class="text-sm text-gray-500">
                    {{getServiceName(record)}}
                </div>
            </td>
            <td class="text-right">
                {{record.incoming|number:'0.2-2'}}
            </td>
            <td class="text-right">
                {{record.outgoing|number:'0.2-2'}}
            </td>
            <td class="text-right">
                {{record.balance|number:'0.2-2'}}
            </td>
            <td class="text-right white-space-nowrap">
                <!--
                <p-button (click)="clickRecordEdit(record);$event.stopPropagation()" [pTooltip]="'form.edit'|translate" icon="pi pi-pencil" styleClass="p-button-sm mr-1 dh-onhover"></p-button>
                <p-button (click)="clickRecordDelete(record, $event);$event.stopPropagation()" [pTooltip]="'form.delete'|translate" icon="pi pi-times" styleClass="p-button-sm p-button-danger dh-onhover"></p-button>
                -->
            </td>
        </tr>
    </ng-template>
</p-table>
<app-norecords *ngIf="(data.records||[]).length==0 && !data.error"></app-norecords>

