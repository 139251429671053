import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { filter } from 'rxjs/internal/operators/filter';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCar } from 'src/app/model.backend/car.model';
import { IApiCarStatus } from 'src/app/model.backend/carstatus.model';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { SensorTypeKey } from 'src/app/model.backend/sensor.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';
import { CarService } from 'src/app/service.backend/car.service';
import { CarStatusService } from 'src/app/service.backend/carstatus.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';
import { CarStatusHelper } from '../../../model.helpers/carstatus-helper';
import { IApiSensorName_KeyEnum } from 'src/app/model.backend/sensor-name.model';
import { UserHelper } from 'src/app/service/user-helper.service';

@Component({
  selector: 'app-car-edit2',
  templateUrl: './car-edit2.component.html',
  styleUrls: ['./car-edit2.component.css']
})
export class CarEdit2Component implements OnInit, OnDestroy, OnChanges {
  @Input() visible=true;
  @Input() car = null as IApiCar|null;
//  @Input() driver = null as IApiDriver|null;
  @Input() carstatus!: IApiCarStatus|null;
//  @Input() drivertrip!: IApiDriverTrip|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiCar>();
  @Output() onInsert = new EventEmitter<IApiCar>();

  IApiSensorName_KeyEnum = IApiSensorName_KeyEnum;
  CarStatusHelper = CarStatusHelper;

  
  destroy$ = new Subject<boolean>();
  data = {
    loading: false,
    saving: false,
    error: null as string|null,  
    car: null as IApiCar|null,
//    driver: null as IApiDriver|null,
    carstatus: null as IApiCarStatus|null,
//    drivertrip: null as IApiDriverTrip|null
  };
  ui = {
    selectButtonsValue: null as any,
    sendForm: {
      carid: null as string|null,
      show: false
    }
    // disabledEdit: false,
    // editMode: false
  }
  options = {
    selectButtons: [
      { name: '', index: 0 }, 
      { name: '', index: 1 }, 
      { name: '', index: 2 }, 
      { name: '', index: 3}
    ]
  };
  dialog = {
    trips: {
      show: false,
      customerid: null as string|null,
      driverid: null as string|null
    }
  }

  isNew = () => false;
  getHeader = () => this.translate.instant('car.edit');
  // getButtonOkLabel = () => this.translate.instant('form.save');
  // getButtonOkIcon = () => 'pi-save';

  // form = new FormGroup({
  //   // family: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
  //   // name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
  //   // surname: new FormControl(null as string|null, [Validators.maxLength(100)]),
  //   // birthdate: new FormControl(null as Date|null),
  //   // phone: new FormControl(null as string|null, [Validators.maxLength(100)]),
  //   // phone_wa: new FormControl(null as string|null, [Validators.maxLength(100)]),
  //   // telegram: new FormControl(null as string|null, [Validators.maxLength(100)]),
  //   // email: new FormControl(null as string|null, [Validators.maxLength(100)]),
  //   // comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),

  //   // cardno: new FormControl(null as string|null, [Validators.maxLength(50)]),
  //   // vu_no: new FormControl(null as string|null, [Validators.maxLength(30)]),
  //   // vu_date: new FormControl(null as Date|null),
  //   // vu_valid_to: new FormControl(null as Date|null),
  //   // vu_category: new FormControl(null as string[]|null),

  //   // default_carid: new FormControl(null as string|null),
  // });

  constructor(
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    public utils: UtilsService
  ) {
  }

  ngOnInit(): void {
//    console.log('ngOnInit', this.carid);
    // this.ui.disabledEdit = !this.canEditCar();
    this._fillSelectButtons();
    this.ui.selectButtonsValue = this.options.selectButtons[0];
    this.translate.onLangChange.subscribe(()=>this._fillSelectButtons());
    this._load();
  }

  _fillSelectButtons() {
//    console.log('_fillSelectButtons0');
    let ix = this.ui.selectButtonsValue?.index??-1;
    this.options.selectButtons = [
      { name: this.translate.instant('cars.titleGeneral'), index: 0},
      { name: this.translate.instant('cars.titleTo'), index: 1 },
      { name: this.translate.instant('cars.titleVideo'), index: 2},
      { name: this.translate.instant('cars.titleSensors'), index: 3},
    ];
    this.ui.selectButtonsValue = ix>=0 ? this.options.selectButtons.find(x=>x.index==ix) : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
//    console.log('ngOnChanges', this.driverid)
    if(changes['carid'] || changes['drivertrip'] || changes['carstatus'] || changes['driver']) {
      this._load();
    }
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  _load() {
    // this.ui.editMode = false;
    let userid= this.authService.getUser()?.id;
    // let customerid = this.authService.getUser()?.customerid;
    if(!userid) {
      // this.ui.disabledEdit = true;
      return;
    }

    this.data.loading=true;  
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1),
      tap( ()=> {
        // загрузка options
      }),
      switchMap( ()=> forkJoin(
        {
          carstatus: of(null)
        }
      ))
    ).subscribe({
      next: (res)=>{
        this.data.loading = false;

        this.data.car = this.car;
        this.data.carstatus = this.carstatus;
//        this.data.driver = this.driver;
//        this.data.drivertrip = this.drivertrip;
        
        // редактирование записи
        // if(res.carstatus?.ok) {
        //   console.log('res', res);
        //   this.data.item = res.carstatus.car;
        //   // this._loadFormFromObject(this.data.item);
        // } else {
        //   console.error('editRecord', res.car?.errorMessage, res.car?.errorMessageForUser);
        //   this.data.error = res.car?.errorMessageForUser ?? this.translate.instant('form.loadError');
        //   this.messageService.add({severity: 'error', detail: this.data.error??'' });
        //   // this.ui.disabledEdit = true;
        // }
      },
      // error: (err)=>{
      //   console.error('loadRecords', err);
      //   this.data.error = this.translate.instant('form.loadError')
      //   this.messageService.add({severity: 'error', detail: this.data.error??'' });
      //   this.data.loading = false;
      //   // this.ui.disabledEdit = true;
      // }
    });  
  }

  // _newItem() {
  //   this.data.item= {
  //     customerid: this.authService.getUser()?.customerid
  //   }
  // }

  // _saveFormToObject(driver: IApiDriver) {
  //   // driver.name = this.form.get('name')?.value;
  //   // driver.surname = this.form.get('surname')?.value;
  //   // driver.family = this.form.get('family')?.value;
  //   // driver.phone = this.utils.stdTrimPhone(this.form.get('phone')?.value);
  //   // driver.phone_wa = this.utils.stdTrimPhone(this.form.get('phone_wa')?.value);
  //   // driver.telegram = this.form.get('telegram')?.value;
  //   // driver.email = this.form.get('email')?.value;
  //   // driver.comment = this.form.get('comment')?.value;

  //   // driver.cardno = this.form.get('cardno')?.value;
  //   // driver.vu_no = this.form.get('vu_no')?.value;
  //   // driver.vu_category = this.form.get('vu_category')?.value;

  //   // driver.birthdate = this.utils.dateToIso(this.form.get('birthdate')?.value);
  //   // driver.vu_date = this.utils.dateToIso(this.form.get('vu_date')?.value);
  //   // driver.vu_valid_to = this.utils.dateToIso(this.form.get('vu_valid_to')?.value);

  //   // driver.default_carid = this.form.get('default_carid')?.value;
  // }

  // _loadFormFromObject(driver: IApiDriver|null) {
  //   // this.form.get('name')?.patchValue(driver?.name??null);
  //   // this.form.get('surname')?.patchValue(driver?.surname??null);
  //   // this.form.get('family')?.patchValue(driver?.family??null);
  //   // this.form.get('birthdate')?.patchValue( this.utils.isoDateParseToDate(driver?.birthdate));
    
  //   // this.form.get('phone')?.patchValue( this.utils.stdPhone(driver?.phone??null));
  //   // this.form.get('phone_wa')?.patchValue( this.utils.stdPhone(driver?.phone_wa??null));
  //   // this.form.get('telegram')?.patchValue(driver?.telegram??null);
  //   // this.form.get('email')?.patchValue(driver?.email??null);
  //   // this.form.get('comment')?.patchValue(driver?.comment??null);

  //   // this.form.get('cardno')?.patchValue(driver?.cardno??null);
  //   // this.form.get('vu_no')?.patchValue(driver?.vu_no??null);
  //   // this.form.get('vu_date')?.patchValue( this.utils.isoDateParseToDate(driver?.vu_date??undefined));
  //   // this.form.get('vu_valid_to')?.patchValue( this.utils.isoDateParseToDate(driver?.vu_valid_to??undefined));
  //   // this.form.get('vu_category')?.patchValue(driver?.vu_category??null);

  //   // this.form.get('default_carid')?.patchValue(driver?.default_carid??null);

  // }

  // saveRecord() {
  //   if(this.form.invalid || !this.data.item /*|| this.ui.disabledEdit*/) {
  //     this.form.markAllAsTouched();
  //     return;
  //   }

  //   this._saveFormToObject(this.data.item);
  //   console.log('saving',this.data.item);
  //   this.data.saving = true;
  //   this.carService.update(this.data.item.id??'xxx', this.data.item)
  //   .subscribe({
  //     next: (res) => {
  //       if(res.ok) {
  //         this.data.item = {...res.car};             
  //         this.visible=false;
  //         // this.ui.editMode = false;
  //         // if(this.isNew()){
  //         //   this.onInsert.emit(this.data.item);
  //         //   this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
  //         // } else {
  //           this.onUpdate.emit(this.data.item);
  //           this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
  //         // }
  //       } else {
  //         console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
  //         if(this.isNew()) this.data.error = res.errorMessageForUser??this.translate.instant('form.addError');
  //         else this.data.error = res.errorMessageForUser??this.translate.instant('form.updateError');
  //         this.messageService.add({severity: 'error', detail: this.data.error??'' });  
  //       }
  //       this.data.saving = false;
  //     },
  //     error: (err) => {
  //       console.error('saveRecord', err);
  //       if(this.isNew()) this.data.error = this.translate.instant('form.addError')
  //       else this.data.error = this.translate.instant('form.updateError')
  //       this.messageService.add({severity: 'error', detail: this.data.error??'' });
  //       this.data.saving=false;  
  //     }
  //   });

  // }

  // validateForm() {
  //   return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  // }

  // validateMaxLength(name: string) {
  //   const f=this.form.get(name);
  //   if(f?.invalid && (f.touched||f.dirty))
  //     if(f.errors?.['maxlength']) return false;
  //   return true;
  // }

  // validateRequired(name: string) {
  //   const f=this.form.get(name);
  //   if(f?.invalid && (f.touched||f.dirty))
  //     if(f.errors?.['required']) return false;
  //   return true;
  // }

  // isPristine() {
  //   return this.form.pristine;
  // }

  // canEditDriver() {
  //   return this.authService.isAllow(IApiUser_Rights.admin) || this.authService.isAllow(IApiUser_Rights.driverManage) || this.authService.isMasterAdministrator();
  // }

  getCarAvatarName = () =>'CAR';
  getDriverName = () => this.data.carstatus?.driver_info?.name;
  getLocation = ()=>this.data.carstatus?.location;

  getSensorValueN(snk: string, postfix: string='') {
    let carstatus = this.data.carstatus;
    return carstatus ? CarStatusHelper.getSensorValueN(carstatus, snk, postfix, '-') : '-';
  }

  getSensorValueS(snk: string, postfix: string='') {
    let carstatus = this.data.carstatus;
    return carstatus ? CarStatusHelper.getSensorValueS(carstatus, snk, postfix, '-') : '-';
  }

  getSensorValueB(snk: string) {
    let b = CarStatusHelper.getSensorValueB(this.data.carstatus, snk);
    return b==null ? '-' : b ? 'да': 'нет';
  }

  getToTO = ()=>'???'

  getCarGroup() {
//    console.log('cargroups', this.preloaderService.allcargroups$.value);
    let groups = this.preloaderService.getCarGroups(this.car?.id??'xxx')
    return groups;
  }

  canSendCommand() {
    let user = this.authService.getUser();
    return user ? UserHelper.isMasterAdmin(user) || UserHelper.isCompanyAdmin(user) || UserHelper.isAllow(user, IApiUser_Rights.commandSend) : false;
  }
  
  clickSendCommand() {
    this.ui.sendForm.carid = this.data.car?.id??null;
    this.ui.sendForm.show = true;
  }

  onSendForm_Hide() {
    this.ui.sendForm.show = false;
  }

}
