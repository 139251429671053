<div class="px-1 py-2 flex align-items-center">
    <div>
        <h2 class="dh-page-caption mr-5 my-0">
            <p-avatar styleClass="mr-1" size="large" shape="circle" icon="pi pi-building"></p-avatar>
            {{'charterers.title'|translate}}
        </h2>
    </div>
    <div>
        <p-button (click)="clickRecordAdd()" icon="pi pi-plus" [label]="'charterers.add'|translate" styleClass="dh-button-hover p-button-sm p-button-outlined p-button-secondary mr-1"></p-button>
    </div>
    <div class="ml-auto">
        <p-splitButton [label]="'charterers.export'|translate" icon="pi pi-file-export" appendTo="body" [model]="options.export" styleClass="p-button-sm p-button-secondary"></p-splitButton>
        <p-button (click)="clickImport()" [label]="'driver.import'|translate" icon="pi pi-file-import" styleClass="p-button-sm p-button-secondary ml-1"></p-button>
    </div>
</div>
<div id="toexport">
    <p-table [value]="data.records||[]" [rowHover]="true" styleClass="p-datatable">
        <ng-template pTemplate="header">
            <tr>
                <td colspan="3" style="height: 5px;">
                    <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                    <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
                </td>
            </tr>
            <tr class="bg-gray-100 font-bold">
                <th>№</th>
                <th class="w-full">{{'charterers.charterer'|translate}}</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record let-index="rowIndex">
            <tr>
                <td>
                    {{index+1}}.
                </td>
                <td>
                    {{record.name}}
                </td>
                <td class="text-right white-space-nowrap">
                    <p-button (click)="clickRecordEdit(record);$event.stopPropagation()" [pTooltip]="'form.edit'|translate" icon="pi pi-pencil" styleClass="p-button-sm mr-1 dh-onhover"></p-button>
                    <p-button (click)="clickRecordDelete(record, $event);$event.stopPropagation()" [pTooltip]="'form.delete'|translate" icon="pi pi-times" styleClass="p-button-sm p-button-danger dh-onhover"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <app-norecords *ngIf="(data.records||[]).length==0 && !data.error"></app-norecords>   
</div>


<app-charterer-edit *ngIf="dialog.edit.show"
    [id]="dialog.edit.id" 
    (onHide)="onDialogEditHide()"
    (onUpdate)="onDialogEditUpdate($event)"
    (onInsert)="onDialogEditInsert($event)" 
>
</app-charterer-edit>

<app-import-dialog *ngIf="dialog.import.show"
    exampleUrl="assets\import\Заказчики-Образец для импорта.xlsx"
    (onImport)="onImport($event)"
    (onHide)="dialog.import.show=false"
></app-import-dialog>
