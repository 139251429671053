import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { load } from 'ol/Image';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { filter } from 'rxjs/internal/operators/filter';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiDriverTrip } from 'src/app/model.backend/driver-trip.model';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { IApiUser_Rights } from 'src/app/model.backend/user.model';
import { DriverService } from 'src/app/service.backend/driver.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-driver-edit2',
  templateUrl: './driver-edit2.component.html',
  styleUrls: ['./driver-edit2.component.css']
})
export class DriverEdit2Component implements OnInit, OnDestroy, OnChanges {
  @Input() visible=true;
  @Input() driverid = null as string|null;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiDriver>();
  @Output() onInsert = new EventEmitter<IApiDriver>();
  @Output() onTripUpdated = new EventEmitter();

  destroy$ = new Subject<boolean>();
  data = {
    loading: false,
    saving: false,
    error: null as string|null,  
    item: null as IApiDriver|null  
  };
  ui = {
    disabledEdit: false,
    editMode: false
  }
  options = {
    cars: [] as INameCode[],
    categories: [
      {name:'M', code: 'M'},      
      {name:'A', code: 'A'},  {name:'A1', code: 'A1'},
      {name:'B', code: 'B'},  {name:'B1', code: 'B1'},  
      {name:'C', code: 'C'},  {name:'C1', code: 'C1'},  {name:'CE', code: 'CE'},  {name:'C1E', code: 'C1E'},  
      {name:'D', code: 'D'},  {name:'D1', code: 'D1'},  {name:'DE', code: 'DE'},  {name:'D1E', code: 'D1E'},
      {name:'TM', code: 'TM'},
      {name:'TB', code: 'TB'}
    ] as INameCode[]
  };
  dialog = {
    trips: {
      show: false,
      customerid: null as string|null,
      driverid: null as string|null
    }
  }

  isNew = () => !this.driverid;
  getHeader = () => this.translate.instant(this.isNew() ? 'driver.add':'driver.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.save')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-save')

  form = new FormGroup({
    family: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
    name: new FormControl(null as string|null, [Validators.required, Validators.maxLength(100)]),
    surname: new FormControl(null as string|null, [Validators.maxLength(100)]),
    birthdate: new FormControl(null as Date|null),
    phone: new FormControl(null as string|null, [Validators.maxLength(100)]),
    phone_wa: new FormControl(null as string|null, [Validators.maxLength(100)]),
    telegram: new FormControl(null as string|null, [Validators.maxLength(100)]),
    email: new FormControl(null as string|null, [Validators.maxLength(100)]),
    comment: new FormControl(null as string|null, [Validators.maxLength(1000)]),

    cardno: new FormControl(null as string|null, [Validators.maxLength(50)]),
    vu_no: new FormControl(null as string|null, [Validators.maxLength(30)]),
    vu_date: new FormControl(null as Date|null),
    vu_valid_to: new FormControl(null as Date|null),
    vu_category: new FormControl(null as string[]|null),

    default_carid: new FormControl(null as string|null),
  });

  constructor(
    private driverService: DriverService,
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    console.log('ngOnInit', this.driverid);
    this.ui.disabledEdit = !this.canEditDriver();
    this._load();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges', this.driverid)
    if(changes['driverid']) this._load();
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  _load() {
    this.ui.editMode = false;
    let userid= this.authService.getUser()?.id;
    let customerid = this.authService.getUser()?.customerid;
    if(!customerid || !userid) {
      // глобальный администратор не может редактировать водителя
      this.ui.disabledEdit = true;
      return;
    }

    this.data.loading=true;  
    this.preloaderService.loaded$.pipe(
      takeUntil(this.destroy$), filter(loaded=>!!loaded), take(1),
      tap( ()=> {
        // загрузка options
        this.options.cars = this.preloaderService.getCarsForUser(userid!, customerid!)
          .filter(car=>car.customers_access?.includes(customerid!) || car.customerid==customerid )                             
          .map( car=> { return { name: this.utils.concats([car.number??'', car.brand??'', car.vin??''], ', '), code: car.id } })
      }),
      switchMap( ()=> forkJoin(
        {
          driver: this.isNew() 
                  ? of(null) 
                  : this.driverService.get(this.driverid!)
        }
      ))
    ).subscribe({
      next: (res)=>{
        this.data.loading = false;

        if(this.isNew()) {
          // новая запись
          this.ui.editMode = true;
          this._newItem()          
          this._loadFormFromObject(this.data.item);
        } else {
          // редактирование записи
          if(res.driver?.ok) {
            console.log('res', res);
            this.data.item = res.driver.driver;
            this._loadFormFromObject(this.data.item);
          } else {
            console.error('editRecord', res.driver?.errorMessage, res.driver?.errorMessageForUser);
            this.data.error = res.driver?.errorMessageForUser ?? this.translate.instant('form.loadError');
            this.messageService.add({severity: 'error', detail: this.data.error??'' });
            this.ui.disabledEdit = true;
          }
        }
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading = false;
        this.ui.disabledEdit = true;
      }
    });  
  }

  _newItem() {
    this.data.item= {
      customerid: this.authService.getUser()?.customerid
    }
  }
  _saveFormToObject(driver: IApiDriver) {
    driver.name = this.form.get('name')?.value;
    driver.surname = this.form.get('surname')?.value;
    driver.family = this.form.get('family')?.value;
    driver.phone = this.utils.stdTrimPhone(this.form.get('phone')?.value);
    driver.phone_wa = this.utils.stdTrimPhone(this.form.get('phone_wa')?.value);
    driver.telegram = this.form.get('telegram')?.value;
    driver.email = this.form.get('email')?.value;
    driver.comment = this.form.get('comment')?.value;

    driver.cardno = this.form.get('cardno')?.value;
    driver.vu_no = this.form.get('vu_no')?.value;
    driver.vu_category = this.form.get('vu_category')?.value;

    driver.birthdate = this.utils.dateToIso(this.form.get('birthdate')?.value);
    driver.vu_date = this.utils.dateToIso(this.form.get('vu_date')?.value);
    driver.vu_valid_to = this.utils.dateToIso(this.form.get('vu_valid_to')?.value);

    driver.default_carid = this.form.get('default_carid')?.value;
  }

  _loadFormFromObject(driver: IApiDriver|null) {
    this.form.get('name')?.patchValue(driver?.name??null);
    this.form.get('surname')?.patchValue(driver?.surname??null);
    this.form.get('family')?.patchValue(driver?.family??null);
    this.form.get('birthdate')?.patchValue( this.utils.isoDateParseToDate(driver?.birthdate));
    
    this.form.get('phone')?.patchValue( this.utils.stdPhone(driver?.phone??null));
    this.form.get('phone_wa')?.patchValue( this.utils.stdPhone(driver?.phone_wa??null));
    this.form.get('telegram')?.patchValue(driver?.telegram??null);
    this.form.get('email')?.patchValue(driver?.email??null);
    this.form.get('comment')?.patchValue(driver?.comment??null);

    this.form.get('cardno')?.patchValue(driver?.cardno??null);
    this.form.get('vu_no')?.patchValue(driver?.vu_no??null);
    this.form.get('vu_date')?.patchValue( this.utils.isoDateParseToDate(driver?.vu_date??undefined));
    this.form.get('vu_valid_to')?.patchValue( this.utils.isoDateParseToDate(driver?.vu_valid_to??undefined));
    this.form.get('vu_category')?.patchValue(driver?.vu_category??null);

    this.form.get('default_carid')?.patchValue(driver?.default_carid??null);

  }

  saveRecord() {
    if(this.form.invalid || !this.data.item || this.ui.disabledEdit) {
      this.form.markAllAsTouched();
      return;
    }

    this._saveFormToObject(this.data.item);
    console.log('saving',this.data.item);
    this.data.saving = true;
    (
      this.isNew() 
      ? this.driverService.insert(this.data.item)
      : this.driverService.update(this.data.item.id??'xxx', this.data.item)
    ).subscribe({
      next: (res) => {
        if(res.ok) {
          this.data.item = {...res.driver};             
          this.visible=false;
          this.ui.editMode = false;
          if(this.isNew()){
            this.onInsert.emit(this.data.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.data.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.data.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.data.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });  
        }
        this.data.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.data.error = this.translate.instant('form.addError')
        else this.data.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

  canEditDriver() {
    return this.authService.isAllow(IApiUser_Rights.admin) || this.authService.isAllow(IApiUser_Rights.driverManage) || this.authService.isMasterAdministrator();
  }

  getDriverAvatarName() {
    return (this.data.item?.family+' ').substring(0,1)+(this.data.item?.name+' ').substring(0,1);
  }

  onTripsHide() {
    this.dialog.trips.show=false;
  }

  clickTripsShow() {
    let cid = this.authService.getUser()?.customerid;
    if(!this.driverid || !cid || this.isNew()) return;
    
    this.dialog.trips.driverid = this.driverid;
    this.dialog.trips.customerid = cid;
    this.dialog.trips.show = true;
  }

  onTripUpdated_event() {
//    console.log('onTripUpdated_event')
    this.onTripUpdated.emit();
  }
}
