import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiUser, IApiUserListResponse, IApiUserResponse, IApiUserSettings } from "../model.backend/user.model";
import { IApiBaseResponse } from "../model.backend/_base.model";


@Injectable({
    providedIn: 'root'
  })
  export class UserService extends BaseService {
    protected controller: string = "user";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(customerid: string): Observable<IApiUserListResponse> {
        return this.http.get<IApiUserListResponse>(`${this.apiUrl}/${this.controller}/list/${encodeURIComponent(customerid||'xxx')}`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiUserListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка пользователей.'))
            );    
    }   

    get(id: string): Observable<IApiUserResponse> {
      return this.http.get<IApiUserResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о пользователе.'))
          );    
    }

    insert(obj: IApiUser): Observable<IApiUserResponse> {
      return this.http.post<IApiUserResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления пользователя.'))
          );    
    }
  
    update(id: string, obj: IApiUser): Observable<IApiUserResponse> {
      return this.http.post<IApiUserResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о пользователе.'))
          );    
    }

    updateFirstLogin(id: string, obj: IApiUser): Observable<IApiUserResponse> {
      return this.http.post<IApiUserResponse>(`${this.apiUrl}/${this.controller}/updatefirstlogin/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`POST ${this.controller}.updatefirstlogin`, null, 'Ошибка обновления информации о пользователе.'))
          );    
    }

    updateprofile(id: string, obj: IApiUser): Observable<IApiUserResponse> {
      return this.http.post<IApiUserResponse>(`${this.apiUrl}/${this.controller}/updateprofile/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`POST ${this.controller}.updateprofile`, null, 'Ошибка обновления профиля пользователя.'))
          );    
    }
   

    changepassword(id: string, obj: IApiUser): Observable<IApiUserResponse> {
      return this.http.post<IApiUserResponse>(`${this.apiUrl}/${this.controller}/changepassword/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`POST ${this.controller}.changepassword`, null, 'Ошибка обновления пароля.'))
          );    
    }

    savesettings(id: string, obj: IApiUserSettings): Observable<IApiUserResponse> {
      return this.http.post<IApiUserResponse>(`${this.apiUrl}/${this.controller}/savesettings/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiUserResponse>(`POST ${this.controller}.savesettings`, null, 'Ошибка сохранения настроек.'))
          );    
    }

    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления пользователя.'))
          );    
    }

}
  