import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { IApiRequestSupport } from 'src/app/model.backend/requestsupport.model';
import { RequestSupportService } from 'src/app/service.backend/requestsupport.service';
import { TelegramBotService } from 'src/app/service.backend/telegram-bot.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-requestsupport-edit',
  templateUrl: './requestsupport-edit.component.html',
  styleUrls: ['./requestsupport-edit.component.css']
})
export class RequestSupportEditComponent implements OnInit, OnDestroy {
  @Input() visible=true;
  @Input() id = null as string|null;
  @Input() viewMode = false;

  @Output() onHide = new EventEmitter();
  @Output() onUpdate = new EventEmitter<IApiRequestSupport>();
  @Output() onInsert = new EventEmitter<IApiRequestSupport>();

  loading = false;
  saving = false;
  error = null as string|null;  
  item = null as IApiRequestSupport|null;
  destroy$ = new Subject<boolean>();

  options = {
  };

  isNew = () => !this.id;
  getHeader = () => this.translate.instant(this.isNew() ? 'requestsupport.add':'requestsupport.edit');
  getButtonOkLabel = () => this.translate.instant(this.isNew() ? 'form.add':'form.add')
  getButtonOkIcon = () => (this.isNew() ? 'pi-plus':'pi-plus')

  form = new FormGroup({
    title: new FormControl(null as string|null, [Validators.required, Validators.maxLength(255)]),
    message: new FormControl(null as string|null, [Validators.required, Validators.maxLength(4000)]),
  });

  constructor(
    private requestsupportService: RequestSupportService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private telegramBotService: TelegramBotService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.isNew() ? this.newRecord(): this.editRecord(); 

    // this.loading = true;   
    // forkJoin({
    //   options: this.preloaderService.ensureLoad$(this.destroy$)
    // })
    // .subscribe({
    //   next: (res) => {
    //     this.loading = false;
    //     this.isNew() ? this.newRecord(): this.editRecord(); 
    //   },
    //   error: err => {
    //     this.loading = false;
    //     this.error = err; 
    //     this.messageService.add({severity: 'error', detail: this.error??'' });
    //   }
    // });

      
  
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  editRecord() {
    this.loading=true;
    this.requestsupportService.get(this.id??'xxx').subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('editRecord', res.request_support);
          this.item = res.request_support;
          this._loadFormFromObject(this.item);
          if(!this.item?.viewed_by_customer) {
            this.requestsupportService.markAsRead(this.id??'xxx').subscribe((res2)=>{
              if(res2.ok) this.onUpdate.emit(res2.request_support??undefined);
            });
          }
        } else {
          console.error('editRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser?? this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.error??'' });
        }
        this.loading=false;
      },
      error: (err)=>{
        console.error('editRecord', err);
        this.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.loading=false;
      }
    })
  }

  newRecord() {
    this._loadFormFromObject(this.item);
  }

  _saveFormToObject(request: IApiRequestSupport) {
    request.title = this.form.get('title')?.value;
    request.items=[{ message: this.form.get('message')?.value }];
  }

  _loadFormFromObject(request: IApiRequestSupport|null) {
    this.form.get('title')?.patchValue(request?.title??null);
  }

  closeRecord() {
    this.requestsupportService.close(this.item?.id??'xxx')
    .subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.request_support};     
          this.onUpdate.emit(this.item);       
          this.visible=false;
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;

        
        
        this.sendTelegramAlert();

        

      },
      error: (err) => {
        console.error('saveRecord', err);
        this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });
  }

  sendTelegramAlert(){
    this.telegramBotService.sendMessage('Новый запрос в техподдержку')
    .subscribe({
      next: (res) => console.log('Уведомление в Telegram успешно отправлено.'),
      error: (err) => console.warn('Ошибка при отправке уведомления в Telegram.'),
    })
  }

  saveRecord() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    var obj: IApiRequestSupport= { id: this.id??null };
    this._saveFormToObject(obj);
    if (!obj.id) {
      obj.id = '';
    }    
    console.log('saving',obj);
    this.saving = true;
    this.requestsupportService.question(obj)
    .subscribe({
      next: (res) => {
        if(res.ok) {
          this.item = {...res.request_support};             
          this.visible=false;
          if(this.isNew()){
            this.onInsert.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.addSuccess')})  
          } else {
            this.onUpdate.emit(this.item);
            this.messageService.add({severity: 'success', detail: this.translate.instant('form.updateSuccess')})
          }
        } else {
          console.error('saveRecord', res.errorMessage, res.errorMessageForUser);
          if(this.isNew()) this.error = res.errorMessageForUser??this.translate.instant('form.addError');
          else this.error = res.errorMessageForUser??this.translate.instant('form.updateError');
          this.messageService.add({severity: 'error', detail: this.error??'' });  
        }
        this.saving = false;
      },
      error: (err) => {
        console.error('saveRecord', err);
        if(this.isNew()) this.error = this.translate.instant('form.addError')
        else this.error = this.translate.instant('form.updateError')
        this.messageService.add({severity: 'error', detail: this.error??'' });
        this.saving=false;  
      }
    });

  }

  validateForm() {
    return (this.form.touched || this.form.dirty) ? this.form.valid : true;
  }

  validateMaxLength(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['maxlength']) return false;
    return true;
  }

  validateRequired(name: string) {
    const f=this.form.get(name);
    if(f?.invalid && (f.touched||f.dirty))
      if(f.errors?.['required']) return false;
    return true;
  }

  isPristine() {
    return this.form.pristine;
  }

}
