import { Injectable } from "@angular/core";

// класс настроек пользователя
export interface BrowserStorageSettings {
    param1?: string

}

@Injectable({
    providedIn: 'root'
  })
  export class BrowserStorageService {
    private storageKey = 'user-settings';
    public settings: BrowserStorageSettings = {};

    constructor(
    ){
    }

    get() {
        return this.settings;
    }

    public saveSettings() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.settings??{}));
    }
    
    public loadSettings() {
        let str = localStorage.getItem(this.storageKey);
        try {
            this.settings={};
            this.settings = JSON.parse(str??"{}");
            console.warn('New user browser settings loaded', this.settings);
        } 
        catch {}
    }

    public removeSettings() {
        localStorage.removeItem(this.storageKey);
        this.settings={};
    }
    
}