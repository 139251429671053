import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiCustomer, IApiCustomerListResponse, IApiCustomerResponse } from "../model.backend/customer.model";
import { IApiBaseResponse } from "../model.backend/_base.model";

@Injectable({
    providedIn: 'root'
  })
  export class CustomerService extends BaseService {
    protected controller: string = "customer";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(): Observable<IApiCustomerListResponse> {
        return this.http.get<IApiCustomerListResponse>(`${this.apiUrl}/${this.controller}/list`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiCustomerListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка клиентов.'))
            );    
    }   

    get(id: string): Observable<IApiCustomerResponse> {
      return this.http.get<IApiCustomerResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCustomerResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о клиенте.'))
          );    
    }

    // listShort(): Observable<IApiCustomerListResponse> {
    //   return this.http.get<IApiCustomerListResponse>(`${this.apiUrl}/${this.controller}/listshort`, {headers: this.headers} )
    //       .pipe(
    //         catchError(this.handleError<IApiCustomerListResponse>(`GET ${this.controller}.listshort`, null, 'Ошибка получения списка клиентов.'))
    //       );    
    // }   


    insert(obj: IApiCustomer): Observable<IApiCustomerResponse> {
      return this.http.post<IApiCustomerResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
             .pipe(
               catchError(this.handleError<IApiCustomerResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления клиента.'))
             );    
    }
  
    update(id: string, obj: IApiCustomer): Observable<IApiCustomerResponse> {
      return this.http.post<IApiCustomerResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiCustomerResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о клиенте.'))
          );    
    }
   
    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
        .pipe(
          catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления клиента.'))
        );    
    }

}
  