import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.css']
})
export class ImportDialogComponent implements OnInit {
  @Input() visible=true;
  @Input() headerKey="import.header";
  @Input() exampleUrl: string|null=null;
  @ViewChild('fileUploadElement') fileUploadElement!: FileUpload;

  @Output() onHide = new EventEmitter();
  @Output() onImport = new EventEmitter<any[]>();

  file: any = null;
  fileBase64= null as string|null;
  fileType=null as string|null;
  items: any[]=[];

  loading = false;
  saving = false;
  error = null as string|null;  

  ui = {
//    fileSelected: false,
    fileLoaded: false
  };
  options = {
  };

  constructor(
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
  }

  hideDialog() {
    this.onHide.emit();
    this.visible=false;
  }

  clickCancel() {
    this.visible=false;
  }

  onSelect(event:any) {
    console.log('onSelect', event);
    if(event?.currentFiles && event.currentFiles.length==1) {
      this.file = event.currentFiles[0];
      this.fileType=event.currentFiles[0].type
      this.LoadFile();
    };  
  }

  uploadHandler(event:any) {
    console.log('uploadHandler', event);
    this.fileUploadElement.clear(); 
  }

  LoadFile() {
    if(this.file) {
      this.loading=true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        let data = XLSX.utils.sheet_to_json(ws);
        this.items=[];
        for(let r_ix=0; r_ix<(data?.length??0); r_ix++)
        { 
          let cols: string[] = [];
          let keys=Object.keys(data[r_ix]!);
          for(let c_ix=0; c_ix<keys.length; c_ix++) cols.push((data[r_ix] as any)[keys[c_ix]]);
          this.items.push(cols);
        }
        this.ui.fileLoaded=true;
      };
      reader.onloadend = (e) => { this.loading=false; };
      reader.readAsBinaryString(this.file);
    }
  }

  clickImport() {
    this.onImport.emit(this.items);
  }

  btnImportLabel() {
    return this.translate.instant('import.btnImport');
  }

}
