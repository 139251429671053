import { Component } from '@angular/core';

@Component({
  selector: 'app-page-requestsupport',
  templateUrl: './page-requestsupport.component.html',
  styleUrls: ['./page-requestsupport.component.css']
})
export class PageRequestSupportComponent {

}
