import { Component } from '@angular/core';

@Component({
  selector: 'app-page-drivers',
  templateUrl: './page-drivers.component.html',
  styleUrls: ['./page-drivers.component.css']
})
export class PageDriversComponent {

}
