<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '50vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="true"
    [modal]="true"
    [blockScroll]="true"
    >  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold"><i class="pi pi-car mr-2" style="font-size: 1.5rem;"></i>{{getHeader()}}</span>
    </ng-template>

    <div [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid pt-3">
            <div class="col-4">
                <div><label for="display_name">{{'car-edit.fieldDisplayName'|translate}}</label></div>
                <input [ngModel]="item?.display_name" id="display_name" type="text" pInputText class="w-full p-disabled">
            </div>
            <div class="col-4">
                <div><label for="number">{{'car-edit.fieldNumber'|translate}}</label></div>
                <input [ngModel]="item?.number" [readOnly]="true" id="number" type="text" pInputText class="w-full p-disabled">
            </div>
            <div class="col-4">
                <div><label for="vin">VIN</label></div>
                <input [ngModel]="item?.vin" [readOnly]="true" id="vin" type="text" pInputText class="w-full p-disabled">
            </div>

            <div class="col-4">
                <div><label for="brand">{{'car-edit.fieldBrand'|translate}}</label></div>
                <input [ngModel]="item?.brand" [readOnly]="true" id="brand" type="text" pInputText class="w-full p-disabled">
            </div>
            <div class="col-4">
                <div><label for="year">{{'car-edit.fieldYear'|translate}}</label></div>
                <p-calendar [ngModel]="item?.year" view="year" dateFormat="yy" inputId="year" appendTo="body" styleClass="w-full p-disabled" inputStyleClass="text-center"></p-calendar>
            </div>
            <div class="col-4">
                <div><label for="color">{{'car-edit.fieldColor'|translate}}</label></div>
                <input [ngModel]="item?.color" [readOnly]="true" id="color" type="text" pInputText class="w-full p-disabled">
            </div>

            <div class="col-12 field">
                <!--
                <div class="bg-gray-100 p-2">
                    {{'car-view.sensorlist'|translate}}
                </div>
                -->
                <p-table [value]="sensors" className="p-datatable p-datatable-sm w-full">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="w-6">{{'car-view.sensorname'|translate}}</th>
                            <th class="w-6">{{'car-view.sensorvalue'|translate}}</th>
                            <th>{{'car-view.checktime'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sensor>
                        <tr>
                            <td>
                                <div class="font-bold">{{getSensorDisplayName(sensor.snk)}}</div>
                                <span class="text-sm">{{sensor.snk}}</span>
                            </td>
                            <td>
                                {{ sensor.s??(sensor.n|number:'0.2-2') }}
                                <div *ngIf="sensor.add_s" class="text-sm text-gray-500">
                                    {{sensor.add_s}}
                                </div>
                            </td>
                            <td class="text-right text-sm text-gray-500 white-space-nowrap">
                                {{sensor.t|isoToDDMMYYYY_HHMMSS}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
    </ng-template>
</p-dialog>


