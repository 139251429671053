import { IApiBaseTable } from "./_base.model";

export interface IApiSensorName extends IApiBaseTable {
    key?: string|null;
    // старый id
    num?: number|null;
    display_name?: string|null;
    // Тип данных. SensorName_ValueType
    value_type?: string|null;
    // для валидаторов используется is_main==true
    is_main?: boolean|null;
    is_virtual?: boolean|null;
}

export enum IApiSensorName_KeyEnum
{
    Lat = "lat",
    Lon = "lon",
    Speed = "speed",
    Course = "course",
    Mileage = "mileage",
    Mileage_gps = "mileage_gps",
    Mileage_can = "mileage_can",
    Engine_hours = "engine_hours",
    //Fuel = "fuel",
    Fuel_lvl = "fuel_lvl",
    Fuel_lvl1 = "fuel_lvl1",
    Fuel_lvl2 = "fuel_lvl2",
    Adblue = "adblue",
    Ignition = "ignition",
    Engine_work = "engine_work",
    Engine_rpm = "engine_rpm",
    Fuel_lvl_med = "fuel_lvl_med",
    Fuel_lvl_filtr = "fuel_lvl_filtr",
    Fuel_rate = "fuel_rate",

    Battery_lvl = "battery_lvl",
    Battery_voltage = "battery_voltage",
    Power_voltage = "power_voltage",
    Gsm_lvl = "gsm_lvl",
    Sats = "sats",
    Hdop = "hdop",   
    Nogps = "nogps",
    Charging = "charging",
    Engine_block = "engineblock",
    Power_takeoff = "power_takeoff",
    Power_save = "power_save",
    Parking = "parking",
    Alarm = "alarm",
    Guard = "guard",
    Weight_cargo = "weight_cargo",
    Trailer_weight_cargo = "trailer_weight_cargo",
    Door_open = "door_open",
    Panic_button = "panic_button",
    Wheel1 = "wheel1",

    Auth_key = "auth_key",
    Time_power_on = "time_power_on",
    Time_power_off = "time_power_off",
    Engine_rpm_high = "engine_rpm_high",
    Parking_work = "parking_work",

    Engine_load = "engine_load",
    Engine_idle = "engine_idle",
    Cruise = "cruise",
    Weight = "weight",
    Add_eqip_work = "add_eqip_work",
    Terminal = "terminal",
    Validator = "validator",
    Rot_brush = "rot_brush",
    Ref_work = "ref_work",
    Mixer_rotation = "mixer_rotation",
    Mixer_unload = "mixer_unload",
    Engine_hours_ext = "engine_hours_ext",
    Hydro_pressure = "hydro_pressure",
    Hydro_temp = "hydro_temp",
    Oil_pressure = "oil_pressure",
    Oil_temp = "oil_temp",
    Oil_lvl = "oil_lvl",
    Angle_agg1 = "angle_agg1",
    Angle_agg2 = "angle_agg2",
    Angle_agg3 = "angle_agg3",
    Bucket = "bucket",
    Arm = "arm",
    Blade = "blade",
    Air_pressure = "air_pressure",
    Air_temp = "air_temp",

    Error_count = "error_count",
    Error_address = "error_address",
    Error_lamp = "error_lamp",
    Error_fmi = "error_fmi",
    Error_spn = "error_spn",
    Error_description = "error_description",

    Pressure_tire1 = "pressure_tire1",
    Pressure_tire2 = "pressure_tire2",
    Pressure_tire3 = "pressure_tire3",
    Pressure_tire4 = "pressure_tire4",
    Pressure_tire5 = "pressure_tire5",
    Pressure_tire6 = "pressure_tire6",
    Pressure_tire7 = "pressure_tire7",
    Pressure_tire8 = "pressure_tire8",
    Pressure_tire9 = "pressure_tire9",
    Pressure_tire10 = "pressure_tire10",
    Pressure_tire11 = "pressure_tire11",
    Pressure_tire12 = "pressure_tire12",
    Pressure_tire13 = "pressure_tire13",
    Pressure_tire14 = "pressure_tire14",
    Pressure_tire15 = "pressure_tire15",
    Pressure_tire16 = "pressure_tire16",
    Pressure_tire17 = "pressure_tire17",
    Pressure_tire18 = "pressure_tire18",
    Pressure_tire19 = "pressure_tire19",
    Pressure_tire20 = "pressure_tire20",

    Temp_tire1 = "temp_tire1",
    Temp_tire2 = "temp_tire2",
    Temp_tire3 = "temp_tire3",
    Temp_tire4 = "temp_tire4",
    Temp_tire5 = "temp_tire5",
    Temp_tire6 = "temp_tire6",
    Temp_tire7 = "temp_tire7",
    Temp_tire8 = "temp_tire8",
    Temp_tire9 = "temp_tire9",
    Temp_tire10 = "temp_tire10",
    Temp_tire11 = "temp_tire11",
    Temp_tire12 = "temp_tire12",
    Temp_tire13 = "temp_tire13",
    Temp_tire14 = "temp_tire14",
    Temp_tire15 = "temp_tire15",
    Temp_tire16 = "temp_tire16",
    Temp_tire17 = "temp_tire17",
    Temp_tire18 = "temp_tire18",
    Temp_tire19 = "temp_tire19",
    Temp_tire20 = "temp_tire20",


}
