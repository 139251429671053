import { Injectable } from "@angular/core";
import { BaseService } from "./_base.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { IApiBaseResponse } from "../model.backend/_base.model";
import { IApiDriver, IApiDriverListResponse, IApiDriverResponse } from "../model.backend/driver.model";

@Injectable({
    providedIn: 'root'
  })
  export class DriverService extends BaseService {
    protected controller: string = "driver";
  
    constructor(
      private http: HttpClient,
    ) 
    { 
        super();
    }

    list(): Observable<IApiDriverListResponse> {
        return this.http.get<IApiDriverListResponse>(`${this.apiUrl}/${this.controller}/list`, {headers: this.headers} )
            .pipe(
              catchError(this.handleError<IApiDriverListResponse>(`GET ${this.controller}.list`, null, 'Ошибка получения списка водителей.'))
            );    
    }   

    get(id: string): Observable<IApiDriverResponse> {
      return this.http.get<IApiDriverResponse>(`${this.apiUrl}/${this.controller}/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiDriverResponse>(`GET ${this.controller}.get`, null, 'Ошибка получения информации о водителе.'))
          );    
    }

    insert(obj: IApiDriver): Observable<IApiDriverResponse> {
      return this.http.post<IApiDriverResponse>(`${this.apiUrl}/${this.controller}/insert`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiDriverResponse>(`POST ${this.controller}.insert`, null, 'Ошибка добавления водителя.'))
          );    
    }
  
    import(obj: IApiDriver[]): Observable<IApiDriverListResponse> {
      return this.http.post<IApiDriverListResponse>(`${this.apiUrl}/${this.controller}/import`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiDriverListResponse>(`POST ${this.controller}.import`, null, 'Ошибка импорта водителей.'))
          );    
    }
  
    update(id: string, obj: IApiDriver): Observable<IApiDriverResponse> {
      return this.http.post<IApiDriverResponse>(`${this.apiUrl}/${this.controller}/update/${encodeURIComponent(id||'xxx')}`, JSON.stringify(obj), {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiDriverResponse>(`POST ${this.controller}.update`, null, 'Ошибка обновления информации о водителе.'))
          );    
    }

    delete(id: string): Observable<IApiBaseResponse> {
      return this.http.get<IApiBaseResponse>(`${this.apiUrl}/${this.controller}/delete/${encodeURIComponent(id||'xxx')}`, {headers: this.headers} )
          .pipe(
            catchError(this.handleError<IApiBaseResponse>(`POST ${this.controller}.delete`, null, 'Ошибка удаления водителя.'))
          );    
    }


}
  