<p-toast></p-toast>
<p-confirmPopup styleClass="bg-red-50 shadow-5"></p-confirmPopup>

<!--
<div class="min-h-screen flex flex-column surface-ground">
  <app-master-menu *ngIf="!hideMenu"></app-master-menu>
  <!--
  <app-master-breadcrumb></app-master-breadcrumb>
  -->
  <!--
  <div class="flex flex-column flex-auto p-1">
      <div class="surface-section flex-auto p-1 border-1 border-dashed surface-border border-round">
        <router-outlet></router-outlet>
      </div>
  </div>
</div>
-->

<app-master-menu></app-master-menu>
<div id="mainWindow1" class="w-full -surface-ground">
  <div class="w-full">
<!--
      <div class="surface-section w-full p-1 border-1 border-solid surface-border border-round" [style]="{height:heightMain}">
        <router-outlet id="mainWindow2"></router-outlet>
      </div>
-->
      <div [style]="{height:heightMain}">
        <router-outlet id="mainWindow2"></router-outlet>
      </div>
  </div>
</div>


