import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiNotice } from 'src/app/model.backend/notice.model';
import { NoticeService } from 'src/app/service.backend/notice.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  options = {
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiNotice[]|null
  };
  dialog = {
    edit: {
      show: false,
      id: null as string|null,
      viewMode: false
    }
  }

  constructor(
    private noticeService: NoticeService,
    private preloaderService: PreloaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.hideMasterMenuReports();
    this.loadRecords();
  }

  hideMasterMenuReports() {
    try {
      let el:any = document.getElementById('masterMenuOthers');
      el.classList.add("hidden"); 
    } catch {}
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  loadRecords() {
    this.data.loading=true;
    this.noticeService.list().subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('loadRecords', res.notices);
          this.data.records = res.notices;
        } else {
          console.error('loadRecords', res.errorMessage, res.errorMessageForUser);
          this.data.error = res.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.applyFilterAndSort();
        this.data.loading=false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading=false;
      }
    })
  }

  applyFilterAndSort() {
    (this.data.records||[]).sort((a,b)=>(a.created??'')>(b.created??'')?1:-1);
  }

  clickRecordEdit(record: IApiNotice) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
    this.dialog.edit.viewMode = false;
  }

  clickRecordView(record: IApiNotice) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
    this.dialog.edit.viewMode = true;
  }

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }


}
