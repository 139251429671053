<p-dialog [header]="getHeader()" 
    [(visible)]="visible" 
    [style]="{ width: '40vw' }" 
    styleClass="shadow-5"
    [resizable]="true" 
    [maximizable]="true" 
    appendTo="body"
    (onHide)="hideDialog()"            
    [closeOnEscape]="false">  

    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{getHeader()}}</span>
    </ng-template>

    <div [formGroup]="form" [ngClass]="{'p-disabled':loading}">
        <app-progressbar [loading]="loading"></app-progressbar>
        <p-messages *ngIf="error" [value]="[{severity:'error', detail:error}]" [closable]="true" [enableService]="false"></p-messages>
        <div class="grid mt-3">
            <div class="col-12 field">
                <div><label for="name">{{'charterers.fieldName'|translate}}<span class="text-red-500 pl-1">*</span></label></div>
                <input formControlName="name" id="name" type="text" pInputText class="w-full">
                <p-message *ngIf="!validateRequired('name')" severity="error" [text]="'form.nameRequired'|translate"></p-message>
                <p-message *ngIf="!validateMaxLength('name')" severity="error" [text]="'form.maxLength100'|translate"></p-message>
            </div>
            <div class="col-12 field">
                <div><label for="cars">{{'charterers.fieldCars'|translate}}</label></div>
                <p-multiSelect 
                    formControlName="cars" 
                    [options]="options.cars" 
                    appendTo="body"
                    inputId="cars" styleClass="w-full" 
                    optionLabel="name" optionValue="code"
                ></p-multiSelect>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="clickCancel()" icon="pi pi-ban" [label]="'form.cancel'|translate" styleClass="p-button-text p-button-secondary"></p-button>
        <p-button (click)="saveRecord()" 
                  [disabled]="!validateForm() || loading"
                  [loading]="saving" 
                  [label]="getButtonOkLabel()" 
                  icon="pi {{getButtonOkIcon()}}" styleClass="p-button-primary">
        </p-button>
    </ng-template>
</p-dialog>

