<div class="car-card shadow-1 hover:shadow-3 mb-2">
    <div class="flex align-items-start w-full">
        <div class="mr-3">
            <p-checkbox (onChange)="onSelectChange.emit(ui.select)" [(ngModel)]="ui.select" [binary]="true"></p-checkbox>
        </div>
        <div class="w-full">
            <div class="flex w-full dh-onhover-master">
                <div class="mr-3" style="padding: 3px;">
                    <p-avatar [label]="getDriverAvatarName()" size="xlarge" />
                </div>
                <div class="flex flex-column w-full">
                    <div class="flex align-items-start w-full mb-2">
                        <div class="mr-2">
                            <div class="font-bold">{{driver.family}} {{driver.name}} {{driver.surname}}</div>
                            <div class="text-xs text-gray-500">
                                {{'driverCard.vuCategory'|translate}}: 
                                <span *ngFor="let c of driver.vu_category; let last=last" class="ml-1 text-gray-400">{{c}}<span *ngIf="!last">, </span></span>
                            </div>
                        </div>
                        <div class="ml-auto flex align-items-start">
                            <div class="mr-1">
                                <button (click)="$event.stopPropagation(); onClickEdit.emit(driver)" class="p-button p-button-rounded p-button-text p-1 sav3 dh-onhover" pButton>
                                    <i class="pi pi-pencil"></i>
                                </button>
                            </div>                
                            <div *ngIf="!viewMode">
                                <button (click)="$event.stopPropagation(); onClickDelete.emit({driver: driver, event: $event})" class="p-button p-button-rounded p-button-text p-button-danger p-1 sav3 dh-onhover" pButton>
                                    <i class="pi pi-trash"></i>
                                </button>
                            </div>                
                        </div>    
                    </div>
                    <div class="flex align-items-center mb-2">
                        <div class="mr-2 text-sm">
                            <i class="pi pi-phone text-sm"></i>
                            {{getDriverPhone()}}
                        </div>
                        <div class="ml-auto">
                            <a *ngIf="driver.telegram" href="https://t.me/{{driver.telegram}}" target="_blank" class="p-button p-button-rounded p-button-text p-1 p-button-sm sav3 p-button-primary mr-1" pButton>
                                <i class="pi pi-telegram text-sm mr-1"></i>Telegram
                            </a>
                            <a *ngIf="driver.phone_wa" href="https://wa.me/{{getDriverPhoneWA()}}" target="_blank" class="p-button p-button-rounded p-button-text p-1 p-button-sm sav3 p-button-success" pButton>
                                <i class="pi pi-whatsapp text-sm mr-1"></i>Whatsapp
                            </a>
                        </div>                
                    </div>
                    <div class="flex align-items-center text-xs">
                        <div *ngIf="carStatus" class="mr-2">
                            <app-car-card2-status [carstatus]="carStatus"></app-car-card2-status>
                        </div>
                        <div *ngIf="(getCurrentCar$()|async)?.number" class="mr-2 sav3-car-number">{{(getCurrentCar$()|async)?.number}}</div>
                        <div *ngIf="(getCurrentCar$()|async)?.brand" class="text-gray-500">{{(getCurrentCar$()|async)?.brand}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
