import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { IApiDriver } from 'src/app/model.backend/driver.model';
import { DriverService } from 'src/app/service.backend/driver.service';
import { PreloaderService } from 'src/app/service/preloader.service';
import { INameCode, UtilsService } from 'src/app/service/utils.service';
import {jsPDF} from "jspdf";
import * as XLSX from "xlsx";
import { forkJoin } from 'rxjs';
import { CarService } from 'src/app/service.backend/car.service';
import { AuthService } from 'src/app/auth/auth.service';
import { IApiCar } from 'src/app/model.backend/car.model';

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.css']
})
export class DriverListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  options = {
    export: [
      { label: "PDF", icon: "pi pi-file-pdf", command: ()=>this.exportPDF() },
      { label: "XLS", icon: "pi pi-file-excel", command: ()=>this.exportXLS() },
    ]
  };
  data = {
    loading: false,
    error: null as string|null,
    records: null as IApiDriver[]|null,
    cars: [] as INameCode[],
  };
  dialog = {
    edit: {
      show: false,
      id: null as string|null     
    },
    import: {
      show: false
    }
  }

  constructor(
    private driverService: DriverService,
    private preloaderService: PreloaderService,
    private carService: CarService,
    private authService: AuthService,    
    private messageService: MessageService,
    private confirmationService: ConfirmationService,    
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.hideMasterManuOthers();
    this.loadRecords();
  }

  hideMasterManuOthers() {
    try {
      let el:any = document.getElementById('masterMenuOthers');
      el.classList.add("hidden"); 
    } catch {}
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();      
  }

  /*
  loadRecords() {
    this.data.loading=true;
    this.driverService.list().subscribe({
      next: (res)=>{
        if(res.ok) {
          console.log('loadRecords', res.drivers);
          this.data.records = res.drivers;
        } else {
          console.error('loadRecords', res.errorMessage, res.errorMessageForUser);
          this.data.error = res.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.applyFilterAndSort();
        this.data.loading=false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading=false;
      }
    })
  }
  */
  loadRecords() {
    this.data.loading=true;  
    forkJoin({
      dr: this.driverService.list(),
      crs: this.carService.listNameCode(this.authService.user$.value?.customerid),
    })
    .subscribe({
      next: (res)=>{
        if(res.dr.ok && res.crs.ok) {
          console.log('res', res);
          this.data.records = res.dr.drivers;
          this.data.cars = res.crs.cars!.map((x: IApiCar) => ({name: x.display_name, code: x.id??''}));          
          this.data.records?.forEach((driver: IApiDriver) => {
              // driver.carsAvaliable = '';
              // driver.cars?.forEach(carId => {
              //   const carName = this.data.cars.find(x => x.code === carId)?.name;
              //   if (carName) {
              //     driver.carsAvaliable += carName + '; ';
              //   }
              // })
          });
        } else {
          console.error('loadRecords', res.dr.errorMessage, res.dr.errorMessageForUser);
          this.data.error = res.dr.errorMessageForUser??this.translate.instant('form.loadError');
          this.messageService.add({severity: 'error', detail: this.data.error??'' });
        }
        this.applyFilterAndSort();
        this.data.loading=false;
      },
      error: (err)=>{
        console.error('loadRecords', err);
        this.data.error = this.translate.instant('form.loadError')
        this.messageService.add({severity: 'error', detail: this.data.error??'' });
        this.data.loading=false;
      }
    })
  }

  applyFilterAndSort() {
    (this.data.records||[]).sort((a,b)=>(a.name??'')>(b.name??'')?1:-1);
  }

  clickRecordEdit(record: IApiDriver) {
    this.dialog.edit.id = record.id??'xxx';
    this.dialog.edit.show =true;
  }

  clickRecordAdd() {
    this.dialog.edit.id = null;
    this.dialog.edit.show =true;
  }

  clickRecordDelete(record: IApiDriver, event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('driver.questDelete'),      
      acceptLabel: this.translate.instant('form.delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translate.instant('form.cancel'),
      rejectButtonStyleClass: 'p-button-text p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.driverService.delete(record.id??'xxx').subscribe({
          next: (res)=>{
            if(!res.ok) {
              console.error('clickRecordDelete', res.errorMessage, res.errorMessageForUser);
              this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.deleteError')})
            } else {
              let ix = this.data.records?.findIndex(x=>x.id==record.id)??-1;
              if(ix>=0) this.data.records?.splice(ix,1);
              this.messageService.add({severity: 'success', detail: this.translate.instant('form.deleteSuccess')});
            }
          },
          error: (err)=>{
            console.error('clickRecordDelete',err);
            this.messageService.add({severity: 'error', detail: this.translate.instant('form.deleteError')});
          }
        })
      },
    });
  }

  onDialogEditHide() {
    this.dialog.edit.show = false;
  }

  onDialogEditUpdate(record: IApiDriver) {
    let ix=this.data.records?.findIndex(x=>x.id==record.id)??-1;
    if(ix>=0 && this.data.records) {
      this.data.records[ix] = { ...record };
    } 
    this.applyFilterAndSort();
  }

  onDialogEditInsert(record: IApiDriver) {
    if(!this.data.records) this.data.records=[];
    this.data.records.push({...record});
    this.applyFilterAndSort();
  }

  exportPDF() {
    var pdf = new jsPDF('p', 'pt', 'A4');   
   pdf.addFont("assets/fonts/PTSans.ttf", "PTSans", "normal");   
   pdf.setFont("PTSans"); 
    pdf.html(document.getElementById('toexport')!, {
      callback: function (pdf2) {
        pdf2.output("dataurlnewwindow", {filename: 'Водители.pdf'});
      },
      x:0, y:0, html2canvas: {scale: 0.75}
    });
 }

 exportXLS() {
   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('toexport'));
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

   /* save to file */
   XLSX.writeFile(wb, "Водители.xlsx");
 }

 clickImport() {
  this.dialog.import.show=true;
 }

 onImport(items: any[]) {
  this.dialog.import.show=false;

  if((items||[]).length==0 || items[0].length<4 ) {
    this.messageService.add({severity: 'error', detail: this.translate.instant('form.importIncorrectFormat') })
    return
  }

  let drivers: IApiDriver[]=[];
  (items||[]).forEach(item=>{
    drivers.push({
      family: item[0],
      name: item[1],
      surname: item[2],
      phone: this.utils.stdTrimPhone(item[3])
    })
  });

  this.driverService.import(drivers).subscribe({
    next: (res)=>{
      if(!res.ok) {
        console.error('onImport', res.errorMessage, res.errorMessageForUser);
        this.messageService.add({severity: 'error', detail: res.errorMessageForUser??this.translate.instant('form.importError')});
      } else {
        this.messageService.add({severity: 'success', detail: this.translate.instant('form.importSuccess')});
        this.loadRecords();
      }
    },
    error: (err)=>{
      console.error('onImport',err);
      this.messageService.add({severity: 'error', detail: this.translate.instant('form.importError')});
    }
  })
 }

}
