<div class="w-full flex flex-auto flex-column white-space-nowrap overflow-x-hidden transition-all transition-duration-200">
    <div class="flex align-items-center p-1 flex-grow-0 flex-shrink-0" style="height: 52px;">
        <div class="mr-5">
            <span class="p-input-icon-right">
                <input (change)="ApplyCarFilter()" [(ngModel)]="formdata.filterTC.text" type="text" pInputText class="dh-button-hover p-inputtext-sm border-secondary p-button-secondary mr-1" style="width: 250px;" />
                <i (click)="clickCarClearFilter()" class="pi pi-times"></i>
            </span>
            <p-button (click)="ApplyCarFilter()" icon="pi pi-filter" styleClass="p-button-sm p-button-outlined p-button-secondary mr-1"></p-button>   
            <p-splitButton icon="pi pi-file-export" appendTo="body" 
                [label]="'common.export'|translate" [model]="options.export" 
                styleClass="p-button-sm p-button-outlined p-button-secondary ml-2">
            </p-splitButton>
        </div>
        <div class="ml-auto flex align-items-center justify-content-between">
            <div class="mr-1 text-center">
                <p-inputSwitch (onChange)="ApplyCarFilter()" [(ngModel)]="formdata.filterTC.show_active"></p-inputSwitch>
                <div class="px-1 text-xs text-center border-round border-1 border-gray-400">{{'cars.statusActive'|translate}}</div>
            </div>
            <div class="mr-1 text-center">
                <p-inputSwitch (onChange)="ApplyCarFilter()" [(ngModel)]="formdata.filterTC.show_block"></p-inputSwitch>
                <div class="px-1 text-xs text-center bg-red-600 text-white border-round">{{'cars.statusBlocked'|translate}}</div>
            </div>
            <div class="mr-1 text-center">
                <p-inputSwitch (onChange)="ApplyCarFilter()" [(ngModel)]="formdata.filterTC.show_notPaid"></p-inputSwitch>
                <div class="px-1 text-xs text-center bg-pink-200 border-round border-1 border-pink-300">{{'cars.statusNotpaid'|translate}}</div>
            </div>           
            <!--
            <div class="mr-1 text-center">
                <p-inputSwitch (onChange)="ApplyCarFilter()" [(ngModel)]="formdata.filterTC.show_notOwn"></p-inputSwitch>
                <div class="px-1 text-xs text-center bg-orange-300 text-white border-round border-1 border-orange-400">чужие</div>
            </div>
            <div class="mr-1 text-center">
                <p-inputSwitch (onChange)="ApplyCarFilter()" [(ngModel)]="formdata.filterTC.show_notBilling"></p-inputSwitch>
                <div class="px-1 text-xs text-center bg-gray-200 border-round border-1 border-gray-300">не биллинг</div>
            </div>
            -->
        </div>
    </div>
    <!--
                 [tableStyle]="{ 'min-width': '50rem', 'width': (panel.third.width-30)+'px' }" 
    -->
    <div class="-flex-auto -h-full -overflow-y-hidden flex flex-column flex-auto h-full overflow-y-auto w-full" id="toexport">
        <p-table [value]="data.carsFiltered||[]" 
                 [rowHover]="true"
                 [(selection)]="data.selectedCars"
                 selectionMode="multiple" 
                 styleClass="p-datatable-sm -flex h-full -overflow-y-auto w-full"
                 (onRowSelect)="onRowCarSelectUnselect($event, true)"
                 (onRowUnselect)="onRowCarSelectUnselect($event, false)"
                 (onHeaderCheckboxToggle)="onHeaderCarCheckboxToggle($event)"
        >
            <ng-template pTemplate="header">
                <tr class="text-sm" class="sticky top-0 z-1">
                    <th style="width: 40px; height: 48px;" class="text-center">
                        <p-tableHeaderCheckbox *ngIf="showcheck && groupId!='*'"></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="active_state">{{'cars.status'|translate}} <p-sortIcon field="active_state"></p-sortIcon></th>
                    <th pSortableColumn="type">{{'cars.type'|translate}} <p-sortIcon field="type"></p-sortIcon></th>
                    <th pSortableColumn="pin">{{'cars.number'|translate}}, PIN <p-sortIcon field="pin"></p-sortIcon></th>
                    <th pSortableColumn="paid_before">{{'cars.paidbefore'|translate}} <p-sortIcon field="paid_before"></p-sortIcon></th>
                    <th pSortableColumn="display_name">{{'cars.name'|translate}} <p-sortIcon field="display_name"></p-sortIcon>, <br>{{'cars.brand'|translate}}, {{'cars.year'|translate}}, VIN, {{'cars.color'|translate}}</th>
                    <th>{{'cars.comment'|translate}}</th>
                    <th></th>
                </tr>
                <tr>
                    <td colspan="8" style="height: 5px;">
                        <app-progressbar [loading]="data.loading" class="flex-grow-0 flex-shrink-0"></app-progressbar>
                        <app-errorbar *ngIf="data.error" [showError]="data.error" class="flex-grow-0 flex-shrink-0"></app-errorbar>    
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car >
                <tr [ngClass]="{'bg-gray-300':car.state==1}">
                    <td style="width: 40px;" class="text-center">
                        <p-tableCheckbox *ngIf="showcheck && !car['_locale']?.saving && groupId!='*'" [value]="car"></p-tableCheckbox>
                    </td>
                    <td>
                        <div class="flex flex-column">
                            <div *ngIf="car.active_state==CarActiveState.Active" class="text-center">
                                <i class="pi pi-check text-green-600 font-bold"></i>
                            </div>
                            <div *ngIf="car.active_state==CarActiveState.BlockingByBilling" class="text-center">
                                <div class="mb-1 px-1 text-xs text-center bg-pink-200 border-round border-1 border-pink-300 inline-block">{{'cars.statusNotpaid'|translate}}</div>
                            </div>
                            <div *ngIf="car.active_state==CarActiveState.Blocking" class="text-center">
                                <div class="mt-1 px-1 text-xs text-center bg-red-600 text-white border-round inline-block">{{'cars.statusBlocked1'|translate}}</div>
                            </div>
                            <!--
                            <div *ngIf="!car.in_billing" class="text-center">
                                <div class="mt-1 px-1 text-xs text-center bg-gray-200 border-round border-1 border-gray-300 inline-block">не биллинг</div>
                            </div>
                            <div *ngIf="!isOwnCar(car)" class="text-center">
                                <div class="mt-1 px-1 text-xs text-center bg-orange-300 text-white border-round border-1 border-orange-400 inline-block">чужие</div>
                            </div>
                            -->
                        </div>
                    </td>
                    <td>
                        <div class="text-center">
                            <span *ngIf="car.type=='car'">{{'cars.typeCar'|translate}}</span>
                            <span *ngIf="car.type=='object'">{{'cars.typeObject'|translate}}</span>
                        </div>
                    </td>
                    <td>
                        <p-badge *ngIf="(car.number||'')!=''" [severity]="(car.active_state==CarActiveState.Active?'success':'danger')" value="{{car.number}}"></p-badge>
                        <div>
                            <b>{{car.pin}}</b>
                        </div>
                    </td>
                    <td class="text-sm">
                        {{car.paid_before|isoToDDMMYYYY}}
                    </td>
                    <td>
                        <div class="ml-2 font-bold">{{car.display_name}}</div>
                        <div class="text-sm text-500 ml-2 white-space-normal">{{utils.concats([car.brand, car.year, car.color, car.vin], ", ")}}</div>
                    </td>
                    <td class="text-sm white-space-normal">{{car.comment}}</td>
                    <!--
                    <td class="text-sm white-space-normal">
                        <!--
                        <div *ngIf="(car.groups||[]).length>0">
                            <div class="text-sm text-gray-400">Входит в группы:</div>
                            <div class="ml-2 pl-1 border-left-1 border-gray-400">
                                {{car.groups}}
                            </div>   
                        </div>
                        <div class="pt-2">
                            <div class="text-sm text-gray-400">Доступно клиентам:</div>
                            <div class="ml-2 pl-1 border-left-1 border-gray-400">
                                Клиент1, Клиент2
                            </div>
                        </div>
                    --><!--
                </td>
                    -->
                    <td class="text-right">
                        <!--
                        <p-button (click)="clickCarMessage(car);$event.stopPropagation()" pTooltip="Сообщения ТС" icon="pi pi-file" styleClass="p-button-xs p-button-outlined mr-1 dh-onhover"></p-button>
                        <p-button (click)="clickCarView(car);$event.stopPropagation()" pTooltip="Просмотр ТС" icon="pi pi-search" styleClass="p-button-xs p-button-outlined mr-1 dh-onhover"></p-button>
                        -->
                        <p-button (click)="clickCarEdit(car);$event.stopPropagation()" icon="pi pi-pencil" styleClass="mr-1 dh-onhover"></p-button>
                        <!--
                        <p-button (click)="clickCarDelete(car, $event);$event.stopPropagation()" pTooltip="Удалить ТС" icon="pi pi-times" styleClass="p-button-xs p-button-danger dh-onhover"></p-button>
                        -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <app-norecords *ngIf="(data.cars||[]).length==0 && !data.error"></app-norecords>
    </div>
</div>

<app-car-edit *ngIf="dialog.caredit.show"
    [carId]="dialog.caredit.carId"
    [viewMode]="dialog.caredit.viewMode"
    (onHide)="dialog.caredit.show=false"
    (onUpdate)="onCarEdit_Update($event)"
></app-car-edit>

